import React from 'react'
import { useSelector } from 'react-redux'
import Select from 'src/Components/Select'
import { Form } from 'react-bootstrap'
import LocalizedDatepicker from 'src/Components/LocalizedDatepicker'
import { translate } from 'src/Services/translation'
import translations from 'src/Components/FieldValueSetter/translations'
import { BaseFieldType, Field, FieldOption, FieldValue } from 'src/Types/Field'
import { Size } from 'src/Types/Size'
import { StoreState } from 'src/Services/Store/reducers'
import { FIELD_BASE_TYPES_WITH_MULTIPLE_VALUES, FIELD_BASE_TYPES_WITH_SINGLE_VALUES } from 'src/Services/Constants'

interface Props {
  field: Field
  value: string
  onChange: (value: FieldValue) => void
  size?: Size.SMALL | Size.MEDIUM | Size.LARGE
}

const FieldValueSetter = ({ field, value, onChange, size = Size.MEDIUM }: Props) => {
  const { language } = useSelector((state: StoreState) => state.Root.user)

  const trans = translate(translations)(language)
  const getFieldOptionValue = (): FieldOption => field.options.values.find(option => option.systemName === value) || null
  const getFieldOptionValues = (): FieldOption[] => value ? field.options.values.filter(option => value.includes(option.systemName)) : []

  if (!field?.type || !field?.options)
    return <></>
  else if (field.type.baseFieldType === BaseFieldType.DATE_TIME)
    return (
      <LocalizedDatepicker
        showDateOnly={ field.options.showDate && !field.options.showTime }
        showTimeOnly={ !field.options.showDate && field.options.showTime }
        id={ `fieldValueSetter${ field.id }` }
        name={ `fieldValueSetter${ field.id }` }
        placeholder={ trans('valuePlaceholder') }
        size={ size }
        selected={ value }
        onChange={ onChange }
        isClearable
      />
    )
  else if (FIELD_BASE_TYPES_WITH_MULTIPLE_VALUES.includes(field.type.baseFieldType))
    return <Select
      id={ `fieldValueSetter${ field.id }` }
      name={ `fieldValueSetter${ field.id }` }
      value={ getFieldOptionValues() }
      onChange={ (values: FieldOption[]) => onChange(values.map(v => v.systemName)) }
      placeholder={ trans('valuePlaceholder') }
      options={ field.options.values }
      getOptionValue={ field => field.systemName }
      getOptionLabel={ field => field.systemName }
      isClearable
      isMulti
    />
  else if (FIELD_BASE_TYPES_WITH_SINGLE_VALUES.includes(field.type.baseFieldType))
    return <Select
      id={ `fieldValueSetter${ field.id }` }
      name={ `fieldValueSetter${ field.id }` }
      value={ getFieldOptionValue() }
      onChange={ f => onChange(f?.systemName || null) }
      placeholder={ trans('valuePlaceholder') }
      options={ field.options.values }
      getOptionValue={ field => field.systemName }
      getOptionLabel={ field => field.systemName }
      isClearable
    />
  else if (field.type.baseFieldType === BaseFieldType.REFERENCE)
    return <Form.Control
      type={ 'text' }
      size={ size as Size.SMALL | Size.LARGE }
      placeholder={ trans('referenceValuePlaceholder') }
      id={ `fieldValueSetter${ field.id }` }
      name={ `fieldValueSetter${ field.id }` }
      value={ value || '' }
      onChange={ e => onChange(e.target.value) }
    />
  else
    return <Form.Control
      type={ 'text' }
      size={ size as Size.SMALL | Size.LARGE }
      placeholder={ trans('valuePlaceholder') }
      id={ `fieldValueSetter${ field.id }` }
      name={ `fieldValueSetter${ field.id }` }
      value={ value || '' }
      onChange={ e => onChange(e.target.value) }
    />
}

export default FieldValueSetter
