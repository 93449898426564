import React from 'react'
import { Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { translate } from 'src/Services/translation'
import Modal from '../BaseModal'

const ConfirmModal = ({
                        title = '',
                        isOpen = false,
                        onClose = () => null,
                        onConfirm = () => null,
                        children = null,
                        ...props
                      }) => {
  const { language } = useSelector(state => state.Root?.user)
  const trans = translate({})(language)

  const onConfirmClick = () => {
    onConfirm()
    onClose()
  }

  const onCancelClick = () => {
    onClose()
  }

  return (
    <Modal
      show={ isOpen }
      onClose={ onClose }
      title={ title }
      footer={
        <>
          <Button variant={ 'outline-danger' } onClick={ onCancelClick }>
            { trans('cancel') }
          </Button>
          <Button variant={ 'primary' } onClick={ onConfirmClick } style={ { float: 'right' } }>
            { trans('confirm') }
          </Button>
        </>
      }
      { ...props }
    >
      { children }
    </Modal>
  )
}

export default ConfirmModal
