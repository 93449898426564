import React, { useEffect, useState } from 'react'
import { Form, Row, Col, Button } from 'react-bootstrap'
import AddressSearch from 'src/Components/AddressSearch'
import { useDispatch, useSelector } from 'react-redux'
import { translate } from 'src/Services/translation'
import { FieldEvent } from 'src/Views/FormFiller/Types/Field'
import ValidationTrigger from 'src/Components/ValidationTrigger/index'
import translation from './translations'
import { fieldEvent } from '../../../state/actions'

const AddressField = ({
  field = null,
  isDisable = false,
  isRequired = false,
}) => {
  const dispatch = useDispatch()

  const { user: { language } } = useSelector(state => state.Root)
  const trans = translate(translation)(language)

  const [ isSearchAvailable, setIsSearchAvailable ] = useState(true)
  const [ hasSearched, setHasSearched ] = useState(false)
  const [ isAddressCustom, setIsAddressCustom ] = useState(false)
  const [ address, setAddress ] = useState(field.value)

  useEffect(() => {
    if (isAddressCustom)
      setAddress({ ...address, lat: null, lng: null, country: null })
  }, [ isAddressCustom ])

  const generateNewLabel = () => {

    let label = ''

    if (address.number) label += `${ address.number } `
    label += `${ address.street },`
    if (address.details) label += ` ${ address.details },`
    label += ` ${ address.postalCode } ${ address.city }`

    return label
  }

  const onClear = () => {
    setAddress(null)
    setHasSearched(false)
    setIsAddressCustom(false)
    dispatch(fieldEvent(field, FieldEvent.VALUE_CHANGE, { value: null }))
  }

  const onSubmit = () => {
    const label = isAddressCustom ? generateNewLabel() : address.label
    const value = JSON.stringify({ ...address, label })

    dispatch(fieldEvent(field, FieldEvent.VALUE_CHANGE, { value }))
    setHasSearched(false)
  }

  const onSearch = e => setHasSearched(true)
  const onSearchAddressChange = address => {
    setIsAddressCustom(false)
    setAddress(address)
  }

  const onFieldChange = field => e => {
    setAddress({ ...address, [field]: e.target.value })
    if (!isAddressCustom) setIsAddressCustom(true)
  }

  const getFormError = () => {
    if (!address)
      return null
    if (!address.street)
      return `${ trans('fieldRequired') } : ${ trans('field.street') }`
    if (!address.postalCode)
      return `${ trans('fieldRequired') } : ${ trans('field.postalCode') }`
    if (!address.city)
      return `${ trans('fieldRequired') } : ${ trans('field.city') }`

    return null
  }

  const isSubmitDisable = () =>
    isDisable || !hasSearched || !address || getFormError() ||
    (field.value || null) === (address && JSON.stringify(address))

  const isValid = () => !isRequired || Boolean(address)

  return <div className={ `blue-card ${ isValid() ? '' : 'is-invalid' }` }>
    { !isDisable && isSearchAvailable && <Form.Group>
      <Form.Label className={ 'fw-bold m-0' }>{ trans('field.search') }</Form.Label>
      <AddressSearch onKeyUp={ onSearch } onChange={ onSearchAddressChange }
        onUnableToLoad={ () => {
          setHasSearched(true)
          setIsSearchAvailable(false)
        } } />
    </Form.Group> }
    <Row className={ 'mt-1' }>
      <Col sm={ 2 }>
        <Form.Group>
          <Form.Label className={ 'm-0' }>{ trans('field.number') }</Form.Label>
          <Form.Control size={ 'sm' } disabled={ isDisable || !hasSearched }
            value={ address?.number || '' }
            onChange={ onFieldChange('number') }
          />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Label className={ 'm-0' }>{ trans('field.street') }</Form.Label>
          <Form.Control size={ 'sm' } disabled={ isDisable || !hasSearched }
            value={ address?.street || '' }
            onChange={ onFieldChange('street') }
          />
        </Form.Group>
      </Col>
    </Row>
    <Row className={ 'mt-1' }>
      <Col>
        <Form.Group>
          <Form.Label className={ 'm-0' }>{ trans('field.details') }</Form.Label>
          <Form.Control size={ 'sm' } disabled={ isDisable || !hasSearched }
            value={ address?.details || '' }
            onChange={ onFieldChange('details') }
          />
        </Form.Group>
      </Col>
    </Row>
    <Row className={ 'mt-1' }>
      <Col sm={ 3 }>
        <Form.Group>
          <Form.Label className={ 'm-0' }>{ trans('field.postalCode') }</Form.Label>
          <Form.Control size={ 'sm' } disabled={ isDisable || !hasSearched }
            value={ address?.postalCode || '' }
            onChange={ onFieldChange('postalCode') }
          />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Label className={ 'm-0' }>{ trans('field.city') }</Form.Label>
          <Form.Control size={ 'sm' } disabled={ isDisable || !hasSearched }
            value={ address?.city || '' }
            onChange={ onFieldChange('city') }
          />
        </Form.Group>
      </Col>
    </Row>
    <div className={ 'mt-1 text-end' }>
      <Button onClick={ onClear }
        variant={ 'outline-danger' }
        disabled={ isDisable }
        size={ 'sm' }
        className={ 'me-1' }
      >
        { trans('clear') }
      </Button>
      <Button onClick={ onSubmit } disabled={ isSubmitDisable() } size={ 'sm' }>
        { trans('submit') }
      </Button>
      <div>
        <Form.Text className={ 'text-danger' }>{ getFormError() }</Form.Text>
      </div>
    </div>

    <ValidationTrigger isRequired={ isRequired } isValid={ isValid() } />
  </div>
}

export default AddressField
