import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Form } from 'react-bootstrap'
import { StoreState } from 'src/Services/Store/reducers'
import { translate } from 'src/Services/translation'
import IntlTelInput, { CountryData } from 'react-intl-tel-input'
import { parsePhoneNumber } from 'libphonenumber-js'
import { CountryCode } from 'libphonenumber-js/types'
import { localeToNearSimpleLocales, localeToSimpleLocale } from 'src/Types/Locale'
import LabelWithWarning from './LabelWithWarning'


interface Props {
  label: string
  enableSearch?: boolean
  initialValue: string | null
  value: string | null,
  onChange: (value: any) => void
  inputClass: string
  isValid: (value: any) => boolean
}

const PhoneField = ({
  label, enableSearch = true,
  initialValue, value, onChange, inputClass, isValid
}: Props) => {
  const { portalLocale, language } = useSelector((state: StoreState) => state.Root.user)

  const { user } = useSelector((state: StoreState) => state.Root)
  const trans = translate({})(user.language)
  const [ invalid, setIsInvalid ] = useState(false)
  const [ formattedPhoneNumber, setFormattedPhoneNumber ] = useState('')

  const hasFieldChanged = value !== initialValue

  const onPhoneNumberChange = (isValid: boolean,value: string,selectedCountryData: CountryData,fullNumber:string) => {
    formatValidPhoneNumber(value)
  }

  const onPhoneNumberBlur = (isValid: boolean,value: string,selectedCountryData: CountryData,fullNumber:string) => {
    if ('' === value) {
      onChange(value)
      return
    }

    if (isValid) {
      try {
        const phoneNumber = parsePhoneNumber(value, (selectedCountryData.iso2.toUpperCase() as CountryCode))
        setFormattedPhoneNumber(phoneNumber.formatNational())
        onChange(phoneNumber.number)
      } catch (err) {
        setIsInvalid(true)
      }
    }

    setIsInvalid(!isValid)
  }

  const formatValidPhoneNumber = (value: string) => {
    try {
      const phoneNumber = parsePhoneNumber(value as string)
      setFormattedPhoneNumber(phoneNumber.formatNational())
    } catch (err) {
      setFormattedPhoneNumber(value as string)
    }
  }

  useEffect(() => {
    formatValidPhoneNumber(value as string)
  }, [ value ])

  return (
    <Form.Group className={ 'phone-field' }>
      <LabelWithWarning
        label={ label }
        warningText={ trans('unsaved') }
        isWarningShown={ hasFieldChanged }
      />
      <IntlTelInput
        preferredCountries={ localeToNearSimpleLocales[portalLocale]  }
        defaultCountry={ localeToSimpleLocale[portalLocale] }
        value={  formattedPhoneNumber || '' }
        onPhoneNumberChange={ (isValid,value,selectedCountryData,fullNumber) => onPhoneNumberChange(isValid,value,selectedCountryData,fullNumber) }
        onPhoneNumberBlur={ (isValid,value,selectedCountryData,fullNumber) => onPhoneNumberBlur(isValid,value,selectedCountryData,fullNumber)  }

      />
    </Form.Group>
  )
}

export default PhoneField
