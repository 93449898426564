import React from 'react'
import { useSelector } from 'react-redux'
import Select from 'src/Components/Select'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { translate } from 'src/Services/translation'
import { FIELD_BASE_TYPES_NOT_AUTO_SETABLE } from 'src/Services/Constants'
import { EditableFieldOptionAutocompleteField } from 'src/Types/Field'
import { getFields } from 'src/Views/FormEditor/state/formReducer'
import { StoreState } from 'src/Services/Store/reducers'
import { TargetValueType } from 'src/Types/FormCustomizationOrder'
import TargetValueField from './TargetValueField'
import translations from '../translations'

interface Props {
  item: EditableFieldOptionAutocompleteField
  onChange: (item: EditableFieldOptionAutocompleteField) => void
  onDelete: () => void
}

const AutocompleteField = ({ item, onChange, onDelete }: Props) => {
  const { language } = useSelector((state: StoreState) => state.Root.user)
  const { form } = useSelector((state: StoreState) => state.FormEditor.formReducer)

  const trans = translate(translations)(language)

  const getFieldsForSelect = () =>
    getFields(form)
      .filter(f => f.systemName && !FIELD_BASE_TYPES_NOT_AUTO_SETABLE.includes(f.type.baseFieldType))
      .map(f => ({ systemName: f.systemName, label: f.label }))

  const getTargetField = () => item?.field ? getFields(form).find(f => f.systemName === item.field) : null

  const onTypeChange = (type: TargetValueType) => onChange({ ...item, value: null, type })

  return <Row className={ 'mb-1' }>
    <Col sm={ 5 }>
      <Form.Group className={ 'mb-2' }>
        <Select name={ 'field' }
          options={ getFieldsForSelect() }
          getOptionValue={ field => field.systemName }
          getOptionLabel={ field => field.systemName }
          value={ getTargetField() }
          onChange={ f => onChange({ ...item, field: f?.systemName || null }) }
        />
      </Form.Group>
    </Col>
    <TargetValueField
      value={ item.value }
      type={ item.type }
      targetField={ getTargetField() }
      onValueChange={ value => onChange({ ...item, value }) }
      onTypeChange={ onTypeChange }
    />
    <Col sm={ 1 } className={ 'd-flex align-items-start' }>
      <Button variant={ 'danger' } onClick={ onDelete } size={ 'sm' }>
        <i className={ 'fa fa-trash' } />
      </Button>
    </Col>
  </Row>
}

export default AutocompleteField
