export default {
  english: {
    title: 'Tasks Status',
    addNew: 'Add a new status',
    form: {
      name: 'Name',
      form: 'Form',
    },
    modal: {
      create: {
        title: 'Create a new status'
      },
      update: {
        title: 'Update a status'
      }
    },
    confirmDeleteModal: {
      title: 'Delete status',
      description: 'Are you sure you want to delete this status ?'
    }
  },
  french: {
    title: 'Catégories de Tâche',
    addNew: 'Ajouter un nouveau statut',
    form: {
      name: 'Nom',
      form: 'Formulaire',
    },
    modal: {
      create: {
        title: 'Créer un nouveau statut'
      },
      update: {
        title: 'Éditer un statut'
      }
    },
    confirmDeleteModal: {
      title: 'Supprimer le statut',
      description: 'Êtes-vous sûr de supprimer ce statut ?'
    }
  }
}
