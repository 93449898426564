import React, { ChangeEvent } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { FieldEvent } from 'src/Views/FormFiller/Types/Field'
import { Field, FieldOption } from 'src/Types/Field'
import getFieldOptionValues from 'src/Views/FormFiller/utils/getFieldOptionValues'
import { forMultiOptionValue } from 'src/Views/FormFiller/utils/getFieldSelectedOptionValue'
import { fieldEvent } from '../../state/actions'

export interface Props {
  field: Field
  isDisabled: boolean
  isRequired: boolean
}

const CheckboxesField = ({ field, isDisabled = false, isRequired = false }: Props) => {
  const dispatch = useDispatch()
  const fieldsContainer = React.useRef()

  const getValue = () => forMultiOptionValue(field)

  const onOptionCheck = (option: FieldOption) => (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked
      ? getValue().some(v => v.systemName === option.systemName) ? getValue() : [ ...getValue(), option ]
      : getValue().filter(v => v.systemName !== option.systemName)

    dispatch(fieldEvent(field, FieldEvent.VALUE_CHANGE, { value: value.map(v => v.systemName) }))
  }

  const showRequired = () => isRequired && getValue().length === 0

  const options = React.useMemo(() => getFieldOptionValues(field), [ field, field.options ])

  return <div key={ `field${ field.id }` } ref={ fieldsContainer }>
    { options.map(option =>
      <Form.Check inline={ !field.options.alignOptionsVertically }
                  id={ option.systemName }
                  key={ option.systemName }
                  name={ option.systemName }
                  label={ option.label }
                  value={ option.systemName }
                  checked={ getValue().some(v => v.systemName === option.systemName) }
                  onChange={ onOptionCheck(option) }
                  disabled={ isDisabled }
                  required={ showRequired() }
      />
    ) }
  </div>
}

export default CheckboxesField
