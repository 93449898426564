import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import List from './List'
import Header from './Header'
import { fetchListsAction } from '../../state/actions'

const Lists = () => {
  const dispatch = useDispatch()

  const { autoRefresh } = useSelector(state => state.Root)

  useEffect(() => {
    if (autoRefresh)
      dispatch(fetchListsAction())
  }, [ autoRefresh ])

  return <>
    <Header />
    <List />
  </>
}

export default Lists
