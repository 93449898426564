import React, { SyntheticEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { translate } from 'src/Services/translation'
import Modal from 'src/Components/Modals/BaseModal'
import { toggleCalculationFieldModal, updateFieldOption } from 'src/Views/FormEditor/state/actions'
import SystemFieldSelect from 'src/Components/SystemFieldSelect'
import { CalculationField, CalculationFieldType } from 'src/Types/Field'
import { FieldOption } from 'src/Types/FieldOption'
import translations from '../translations'
import SystemField from '../../../Types/SystemField'


const CalculationFieldModal = () => {
  const dispatch = useDispatch()

  // @ts-ignore
  const { language } = useSelector(state => state.Root.user)
  // @ts-ignore
  const { isCalculationFieldModalOpen, editedField, form } = useSelector(state => state.FormEditor.formReducer)

  const trans = translate(translations)(language)

  const [ fields, setFields ] = useState<CalculationField[]>([])
  const formId = form.id

  useEffect(() => {
    setFields(editedField?.options?.calculationFields ?? [])
  }, [ editedField ])

  const addField = (type: CalculationFieldType, value: CalculationField['value'] = '') =>
    setFields(fields => [ ...fields, { type, value } ])

  const removeField = () => {
    const fieldsClone = [ ...fields ]
    fieldsClone.pop()
    setFields(fieldsClone)
  }

  const editField = (value: string, i: number) => {
    const fieldsClone = [ ...fields ]
    fieldsClone[i].value = value
    setFields(fields => fieldsClone)
  }
  const clear = () => {
    setFields(fields => [])
  }
  const closeModal = () => {
    dispatch(toggleCalculationFieldModal())
  }

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault()
    const targetedFields: CalculationField['value'][] = []
    fields.map(f => f.type === CalculationFieldType.SYSTEM_FIELD ? targetedFields.push(f.value) : false)

    dispatch(updateFieldOption(FieldOption.TARGETED_FIELDS, targetedFields.length !== 0 ? targetedFields : null))
    dispatch(updateFieldOption(FieldOption.CALCULATION, fields))
    closeModal()
  }

  const onFieldDelete = (i: number) =>
    setFields(fields => fields.filter((f, j) => i !== j))

  return <Modal title={ trans('modal.calculationField.title') }
    onClose={ closeModal }
    isOpen={ isCalculationFieldModalOpen }
    size={ 'lg' }
    id={ 'autocomplete-fields-modal' }
  >
    <Form onSubmit={ onSubmit }>
      <Row className={ 'mb-4 border-top border-bottom bg-light p-2 d-flex justify-content-center' }>
        { [ '+', '-', '*', '/', '²', '(', ')' ].map((operator, i) =>
          <Col md={ 'auto' } key={ i }>
            <Button className={ 'pl-0' } variant={ 'outline-primary' } onClick={ () => addField(CalculationFieldType.OPERATOR, operator) } >
              { operator }
            </Button>
          </Col>
        )
        }
        <Col md={ 'auto' } >
          <Button variant={ 'outline-primary' } onClick={ removeField } size={ 'sm' }>
            <i className={ 'fas fa-lg fa-backspace' }/>
          </Button>
        </Col>
        <Col md={ 'auto' } >
          <Button variant={ 'outline-danger' } onClick={ clear } size={ 'sm' }>
            <i className={ 'fas fa-lg fa-trash' }/>
          </Button>
        </Col>
      </Row>

      <Row className={ 'p-1' }>
        { Array.isArray(fields) && fields.map((field, i) => {
          if (field.type === CalculationFieldType.VALUE)
            return (
              <Col className={ 'p-0 mb-2' } md={ 2 } key={ i }>
                <Form.Control
                  name={ 'sysField' + i }
                  onChange={ e => editField(e.target.value, i) }
                  value={ field.value }
                />
              </Col>
            )

          if (field.type === CalculationFieldType.SYSTEM_FIELD)
            return (
              <Col className={ 'p-0 mb-2' } md={ 3 } key={ i }>
                <SystemFieldSelect
                  value={ field ? field.value : null }
                  formId={ formId } onChange={ (e:SystemField) => editField(e.name, i) }
                />
              </Col>
            )

          if (field.type === CalculationFieldType.OPERATOR)
            return <Col md={ 'auto' } key={ i }><Button disabled>{ field.value }</Button></Col>
        }) }
      </Row>
      <Row className={ 'mt-4' }>
        <Col>
          <Button className={ 'm-2' } variant={ 'outline-primary' } onClick={ () => addField(CalculationFieldType.VALUE) } size={ 'sm' }>
            { trans('editField.addValue') }
          </Button>
          <Button variant={ 'outline-primary' } onClick={ () => addField(CalculationFieldType.SYSTEM_FIELD) } size={ 'sm' }>
            { trans('editField.addSysField') }
          </Button>
        </Col>
        <Col>
          <Button className={ 'float-end' } onClick={ onSubmit }>{ trans('submit') }</Button>
        </Col>
      </Row>
    </Form>
  </Modal>
}

export default CalculationFieldModal
