import '../Style/App.scss'
import { useDispatch, useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import React, { useEffect } from 'react'
import { registerLicense } from '@syncfusion/ej2-base'
import { registerLocale } from 'react-datepicker'
import fr from 'date-fns/locale/fr'
import enUS from 'date-fns/locale/en-US'
import { matchRoutes, useLocation } from 'react-router-dom'
import { routesMap } from 'src/Services/Router/Routes'
import { SnackbarProvider } from 'notistack'
import Notifier from 'src/Services/notifier'
import Firewall from 'src/Services/Firewall/index.tsx'
import HttpLibraryProvider from 'src/Services/HttpLibraryProvider'
import AppRouter from 'src/Services/Router'
import ScriptExecutor from 'src/Services/ScriptExecutor'
import { handleError, locationChanged, setAppConfig, setAutoRefresh } from 'src/Services/Store/Root/actions'
import { AppLoader } from 'src/Components/AppLoader'
import bios from 'src/_Themes/bios'
import ErrorBoundary from 'src/Components/ErrorBoundary'
import { OverlayLoader } from 'src/Components/OverlayLoader'
import EventSourceManager from 'src/Services/EventSourceManager'
import PatientCreator from 'src/Components/PatientCreator'
import SharedConfirmModal from 'src/Components/Modals/SharedConfirmModal'

/**
 * User not necessarily authenticated here
 */
export const App = () => {
  const dispatch = useDispatch()
  const notistackRef = React.createRef()

  const { config, user: { language } } = useSelector(state => state.Root)

  const onConfigFetched = config => {
    dispatch(setAppConfig(config))
    // Activate SyncFusion library by providing a license
    registerLicense(config.SYNCFUSION_LICENSE_KEY)
  }

  const location = useLocation()

  useEffect(() => {
    dispatch(setAutoRefresh(false))

    const matchedRoutes = matchRoutes(routesMap, location)

    if (matchedRoutes.length) {
      const currentMatchedRoute = matchedRoutes.shift()

      dispatch(locationChanged(currentMatchedRoute))
      dispatch(setAutoRefresh(!!currentMatchedRoute?.route?.autoRefresh))
    }
  }, [ location ])

  const fetchConfig = () =>
    window.config = fetch('/config.json')
      .then(res => res.json())
      .then(onConfigFetched)
      .catch(err => {
        alert(`Unable to fetch or decode application required config.\n\nReason: '${ err }'`)
        dispatch(handleError(err, null, 'Unable to fetch config'))
      })

  useEffect(() => {
    registerLocale('fr', fr)
    registerLocale('en-US', enUS)

    fetchConfig()
  }, [])

  return config ? <ErrorBoundary language={ language }>
    <ThemeProvider theme={ bios }>
      <HttpLibraryProvider />
      <OverlayLoader/>
      <EventSourceManager/>
      <SnackbarProvider dense
        maxSnack={ 3 }
        preventDuplicate
        anchorOrigin={ {
          vertical: 'top',
          horizontal: 'right'
        } }
        ref={ notistackRef }
      >
        <Notifier/>
        <Firewall>
          <AppRouter/>
        </Firewall>
      </SnackbarProvider>
      <SharedConfirmModal/>
      { /** Domain components, TODO : should be inside Router */ }
      <ScriptExecutor/>
      <PatientCreator/>
      { /** */ }
    </ThemeProvider>
  </ErrorBoundary>
    : <AppLoader/>
}
