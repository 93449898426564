export const RESET_INSTANCE = '[FORM_FILLER] RESET_INSTANCE'
export const FETCH_INSTANCE = '[FORM_FILLER] FETCH_INSTANCE'
export const FETCH_INSTANCE_FIELD = '[FORM_FILLER] FETCH_INSTANCE_FIELD'
export const FETCH_INSTANCE_FAILED = '[FORM_FILLER] FETCH_INSTANCE_FAILED'
export const SET_ACTIVE_SECTION_INDEX = '[FORM_FILLER] SET_ACTIVE_SECTION_INDEX'
export const SET_INSTANCE = '[FORM_FILLER] SET_INSTANCE'
export const SET_INSTANCE_FIELD = '[FORM_FILLER] SET_INSTANCE_FIELD'
export const SAVE_INSTANCE_FIELD_VALUE = '[FORM_FILLER] SAVE_INSTANCE_FIELD_VALUE'
export const SAVE_INSTANCE = '[FORM_FILLER] SAVE_INSTANCE'
export const SAVE_INSTANCE_SUCCEEDED = '[FORM_FILLER] SAVE_INSTANCE_SUCCEEDED'
export const SAVE_INSTANCE_ABORTED = '[FORM_FILLER] SAVE_INSTANCE_ABORTED'
export const SAVE_INSTANCE_FAILED = '[FORM_FILLER] SAVE_INSTANCE_FAILED'
export const SET_SAVE_INSTANCE_STATUS = '[FORM_FILLER] SET_SAVE_INSTANCE_STATUS'
export const SET_INSTANCE_SYSTEM_FIELDS = '[FORM_FILLER] SET_INSTANCE_SYSTEM_FIELDS'
export const DELETE_INSTANCE = '[FORM_FILLER] DELETE_INSTANCE'
export const RESTORE_INSTANCE = '[FORM_FILLER] RESTORE_INSTANCE'
export const PRINT_CONTENT = '[FORM_FILLER] PRINT_CONTENT'
export const TOGGLE_VALIDATE_USER_AUTHENTICITY_MODAL = '[FORM_FILLER] TOGGLE_VALIDATE_USER_AUTHENTICITY_MODAL'
export const TOGGLE_MS_SANTE_MODAL = '[FORM_FILLER] TOGGLE_MS_SANTE_MODAL'
export const TOGGLE_MS_SANTE_TRACKING_MODAL = '[FORM_FILLER] TOGGLE_MS_SANTE_TRACKING_MODAL'
export const SEND_MS_SANTE_TRACKING_REQUEST = '[FORM_FILLER] SEND_MS_SANTE_TRACKING_REQUEST'
export const FETCH_MS_SANTE_TRACKING_DATA = '[FORM_FILLER] FETCH_MS_SANTE_TRACKING_DATA'
export const SET_MS_SANTE_TRACKING_DATA = '[FORM_FILLER] SET_MS_SANTE_TRACKING_DATA'
export const TOGGLE_MS_SANTE_RECIPIENTS_FETCHING = '[FORM_FILLER] TOGGLE_MS_SANTE_RECIPIENTS_FETCHING'
export const TOGGLE_MS_SANTE_CONTENTS_FETCHING = '[FORM_FILLER] TOGGLE_MS_SANTE_CONTENTS_FETCHING'
export const SET_USER_PIN = '[FORM_FILLER] SET_USER_PIN'
export const SEND_MS_SANTE_DOCUMENTS = '[FORM_FILLER] SEND_MS_SANTE_DOCUMENTS'
export const TOGGLE_MS_SANTE_MODAL_LOADING = '[FORM_FILLER] TOGGLE_MS_SANTE_MODAL_LOADING'
export const FETCH_MS_SANTE_RECIPIENTS = '[FORM_FILLER] FETCH_MS_SANTE_RECIPIENTS'
export const SET_MS_SANTE_RECIPIENTS = '[FORM_FILLER] SET_MS_SANTE_RECIPIENTS'
export const FETCH_MS_SANTE_CONTENTS_TO_SEND = '[FORM_FILLER] FETCH_MS_SANTE_CONTENTS_TO_SEND'
export const SET_MS_SANTE_CONTENTS_TO_SEND = '[FORM_FILLER] SET_MS_SANTE_CONTENTS_TO_SEND'

export const IS_EDITING_REFERENCE = '[FORM_FILLER] IS_EDITING_REFERENCE'
export const CHANGE_REFERENCE = '[FORM_FILLER] CHANGE_REFERENCE'
export const CHANGE_REFERENCE_TO_SELF = '[FORM_FILLER] CHANGE_REFERENCE_TO_SELF'
export const CLEAR_REFERENCE = '[FORM_FILLER] CLEAR_REFERENCE'
export const SEARCH_REFERENCE = '[FORM_FILLER] SEARCH_REFERENCE'
export const REFERENCE_SEARCH_SUCCEEDED = '[FORM_FILLER] REFERENCE_SEARCH_SUCCEEDED'
export const SET_REFERENCE_SEARCH_FILTERS = '[FORM_FILLER] SET_REFERENCE_SEARCH_FILTERS'
export const REFERENCE_SEARCH_FAILED = '[FORM_FILLER] REFERENCE_SEARCH_FAILED'
export const HIDE_REFERENCE_SEARCH_MODAL = '[FORM_FILLER] HIDE_REFERENCE_SEARCH_MODAL'
export const CREATE_INSTANCE_FOR_REFERENCE = '[FORM_FILLER] CREATE_INSTANCE_FOR_REFERENCE'
export const REQUEST_INSTANCE_CREATION_FOR_REFERENCE = '[FORM_FILLER] REQUEST_INSTANCE_CREATION_FOR_REFERENCE'
export const REQUEST_INSTANCE_EDIT_FOR_REFERENCE = '[FORM_FILLER] REQUEST_INSTANCE_EDIT_FOR_REFERENCE'
export const EDIT_INSTANCE_FOR_REFERENCE = '[FORM_FILLER] EDIT_INSTANCE_FOR_REFERENCE'
export const REFERENCE_INSTANCE_CREATED = '[FORM_FILLER] REFERENCE_INSTANCE_CREATED'
export const REFERENCE_INSTANCE_CREATION_FAILED = '[FORM_FILLER] REFERENCE_INSTANCE_CREATION_FAILED'

export const ADD_EXTENSIBLE_ROW = '[FORM_FILLER] ADD_EXTENSIBLE_ROW'
export const REMOVE_EXTENSIBLE_ROW = '[FORM_FILLER] REMOVE_EXTENSIBLE_ROW'
export const CREATE_EXTENSIBLE_ROW = '[FORM_FILLER] CREATE_EXTENSIBLE_ROW'
export const EDIT_EXTENSIBLE_ROW = '[FORM_FILLER] EDIT_EXTENSIBLE_ROW'
export const DELETE_EXTENSIBLE_ROW = '[FORM_FILLER] DELETE_EXTENSIBLE_ROW'
export const ADD_EXTENSIBLE_ROW_LOADING = '[FORM_FILLER] ADD_EXTENSIBLE_ROW_LOADING'
export const REMOVE_EXTENSIBLE_ROW_LOADING = '[FORM_FILLER] REMOVE_EXTENSIBLE_ROW_LOADING'

export const DOWNLOAD_FILE = '[FORM_FILLER] DOWNLOAD_FILE'
export const DOWNLOAD_FILE_FROM_URL = '[FORM_FILLER] DOWNLOAD_FILE_FROM_URL'
export const DELETE_FILE = '[FORM_FILLER] DELETE_FILE'
export const DELETE_FILE_SUCCEED = '[FORM_FILLER] DELETE_FILE_SUCCEED'
export const LOCK_FILE = '[FORM_FILLER] LOCK_FILE'
export const LOCK_FILE_SUCCEED = '[FORM_FILLER] LOCK_FILE_SUCCEED'
export const UNLOCK_FILE = '[FORM_FILLER] UNLOCK_FILE'
export const UNLOCK_FILE_SUCCEED = '[FORM_FILLER] UNLOCK_FILE_SUCCEED'
export const EDIT_FILE = '[FORM_FILLER] EDIT_FILE'
export const EDIT_FILE_SUCCEED = '[FORM_FILLER] EDIT_FILE_SUCCEED'

export const PUSH_CUSTOMIZATION_ORDER = '[FORM_FILLER] PUSH_CUSTOMIZATION_ORDER'
export const RESET_CUSTOMIZATIONS = '[FORM_FILLER] RESET_CUSTOMIZATIONS'
export const REMOVE_CUSTOMIZATION_CONDITION_TRIGGERED = '[FORM_FILLER] REMOVE_CUSTOMIZATION_CONDITION_TRIGGERED'
export const SET_FORM_CUSTOMIZATIONS_CONDITIONS_TRIGGERED = '[FORM_FILLER] SET_FORM_CUSTOMIZATIONS_CONDITIONS_TRIGGERED'

export const FIELD_EVENT = '[FORM_FILLER] FIELD_EVENT'
export const CHANGE_FIELD_VALUE = '[FORM_FILLER] CHANGE_FIELD_VALUE'

export const GENERATE_DOCUMENT_FILE = '[FORM_FILLER] GENERATE_DOCUMENT_FILE'
export const IS_GENERATE_TEMPLATE_LOADING = '[FORM_FILLER] IS_GENERATE_TEMPLATE_LOADING'
export const SET_DOCUMENT_DATA = '[FORM_FILLER] SET_DOCUMENT_DATA'
export const SET_CALCULATION_FIELDS = '[FORM_FILLER] SET_CALCULATION_FIELDS'

/** SWP */
export const INIT_SWP = '[FORM_FILLER] INIT_SWP'
export const OPEN_SWP_RECORD = '[FORM_FILLER] OPEN_SWP_RECORD'
export const OPEN_SWP_PLAYBACK = '[FORM_FILLER] OPEN_SWP_PLAYBACK'
export const OPEN_SWP_MONITOR = '[FORM_FILLER] OPEN_SWP_MONITOR'
export const INITIATE_SWP_ACQUISITION = '[FORM_FILLER] INITIATE_SWP_ACQUISITION'
export const REDIRECT_TO_SWP_DYNAMIC_LINK = '[FORM_FILLER] REDIRECT_TO_SWP_DYNAMIC_LINK'

export const FILL_PATIENT_INSTANCE = '[FORM_FILLER] FILL_PATIENT_INSTANCE'
export const CHANGE_REFERENCE_FROM_ID = '[FORM_FILLER] CHANGE_REFERENCE_FROM_ID'

export const SEND_EMAIl_PREVIEW = '[DOCUMENT_EDITOR] SEND_EMAIl_PREVIEW'
export const TOGGLE_SAVE_LOADING = '[FORM_FILLER] TOGGLE_SAVE_LOADING'

export const SET_IS_OPENED_INSTANCE_READY = '[FORM_FILLER] SET_IS_OPENED_INSTANCE_READY'
export const SET_SWP_FEATURE_STATUS = '[FORM_FILLER] SET_SWP_FEATURE_STATUS'

export const SET_DOCUMENT_AS_FINAL = '[FORM_FILLER] SET_DOCUMENT_AS_FINAL'
export const SET_PENDING_PROTECTED_ACTION = '[FORM_FILLER] SET_PENDING_PROTECTED_ACTION'

export const SET_VALUE_BY_AVAILABILITY = '[FORM_FILLER] SET_VALUE_BY_AVAILABILITY'
export const SET_VALUE_TO_TARGET_FIELDS = '[FORM_FILLER] SET_VALUE_TO_TARGET_FIELDS'

/** RD */
export const CREATE_RD_SESSION = '[FORM_FILLER] CREATE_RD_SESSION'
export const OPEN_RD_SESSION = '[FORM_FILLER] OPEN_RD_SESSION'

export const EXECUTE_BUTTON_FIELD_ACTIONS = '[FORM_FILLER] EXECUTE_BUTTON_FIELD_ACTIONS'
export const EXECUTE_AUTO_COMPLETE_ACTION = '[FORM_FILLER] EXECUTE_AUTO_COMPLETE_ACTION'
export const EXECUTE_CONSENT_ACTION = '[FORM_FILLER] EXECUTE_CONSENT_ACTION'
export const ASK_CONSENT = '[FORM_FILLER] ASK_CONSENT'
export const ABORT_CONSENT = '[FORM_FILLER] ABORT_CONSENT'
