import { isFieldMatchExtensibleRow } from 'src/Views/FormFiller/utils'
import { getSystemFieldByName, getSystemFieldsStartingByName } from 'src/Services/Selectors'
import FormFillerSection from 'src/Views/FormFiller/Types/FormFillerSection'
import OpenedInstance from 'src/Views/FormFiller/Types/OpenedInstance'
import { Field, FieldValue } from 'src/Types/Field'
import { StoreState } from 'src/Services/Store/reducers'
import FormCustomizationOrder from 'src/Types/FormCustomizationOrder'
import ScriptFormCustomizationOrder, { FormElementStatus, FormElementType } from 'src/Views/FormFiller/Types/ScriptFormCustomizationOrder'
import SystemField from 'src/Types/SystemField'
import FormCustomizationCondition from 'src/Types/FormCustomizationCondition'
import { getCustomizationElementStatus } from './reducer'

export const getSectionsWithFields = (sections: FormFillerSection[], fields: OpenedInstance['fields']) => {

  if (!sections || sections.length < 1 || !fields)
    return []

  return sections.map(section => ({
    ...section,
    rows: section.rows.map(row => ({
      ...row,
      fields: row.fields.map(id => {
        const field = fields[id]

        if (!field)
          throw new Error(`Field with id ${ id } not found`)

        return { ...field, id }
      })
    }))
  }))
}

export type ExtensibleRowValues = { [fieldSystemName: string]: FieldValue }
export const getExtensibleNewRowFieldsToSave = (fields: OpenedInstance['fields'], extensibleFieldId: string): ExtensibleRowValues => {
  const extensible = fields[extensibleFieldId]

  return (extensible.fields as string[]).reduce((results, fieldId) => {

    const field = fields[fieldId]

    if (field.value)
      return { ...results, [field.systemName]: field.value }
    else return results
  }, {})
}

export const getExtensibleFieldsToSaveByRow = (fields: OpenedInstance['fields'], extensibleFieldId: string, rowId: string): ExtensibleRowValues => {
  const results: ExtensibleRowValues = {}

  for (const fieldKey of Object.keys(fields)) {
    const field = fields[fieldKey]

    if (!isFieldMatchExtensibleRow(field, extensibleFieldId, rowId))
      continue

    results[field.systemName] = field.value
  }

  return results
}

// Return orders that have all conditions that match triggered conditions
export const getFormCustomizationOrdersTriggerred = (customizationOrders: FormCustomizationOrder[], conditionsTriggerred: FormCustomizationCondition[]): FormCustomizationOrder[] =>
  customizationOrders.filter(order =>
    order.conditions.every(condition =>
      conditionsTriggerred.some(c => c.id === condition.id)
    )
  )

// Final customization orders are not exactly the same os a form customization order (they allow to do more tstuff)
// Ignore actions : SET_VALUE_BY_AVAILABILITY as it is not a customization.
export const formatFormCustomizationOrders = (formOrders: FormCustomizationOrder[]): ScriptFormCustomizationOrder[] =>
  formOrders.reduce((orders, formOrder) => [
    ...orders,
    ...formOrder.targetFields.map(field => ({
      elementType: FormElementType.FIELD,
      elementId: field.id,
      elementStatus: getCustomizationElementStatus(formOrder.action, formOrder.options)
    }))
  ], [])

// Get all form customizations orders and script customizations orders
export const getCustomizationOrdersTriggerred = (state: StoreState['FormFiller']): ScriptFormCustomizationOrder[] =>
  [ ...formatFormCustomizationOrders(state.formCustomizationOrdersTriggered),
    ...state.scriptCustomizationOrders ]

// Will process all customizations triggered for element and determine a final status
export const getElementStatusFromCustomizations = (state: StoreState['FormFiller'], type: FormElementType, id: string): FormElementStatus => {
  return getCustomizationOrdersTriggerred(state)
    .reduce((elementStatus, order) => {
      if (order.elementType !== type || order.elementId !== id)
        return elementStatus

      return { ...elementStatus, ...order.elementStatus }
    }, {
      isDisable: undefined,
      isHidden: undefined,
      isLoading: undefined,
      info: undefined,
      value: undefined
    })
}

export const getFieldBySystemName = (state: StoreState['FormFiller'], systemName: string, isPrefix = true): Field => {
  const fields = state.openedInstance?.fields || {}

  return Object.values(fields)
    .find(f => isPrefix ? f.systemName.startsWith(systemName) : f.systemName === systemName) || null
}

export const getFieldsBySystemFieldName = (state: StoreState['FormFiller'], name: string): Field[] => {
  const fields = state.openedInstance?.fields || {}
  const systemFields = state.openedInstanceSystemFields || []

  const matchingSystemFields = getSystemFieldsStartingByName(systemFields, name) || []

  if (!matchingSystemFields.length)
    return []

  return Object.values(fields).filter(f => matchingSystemFields.some(sf => f.id === sf.field.id))
}

export const getFieldBySystemFieldName = (state: StoreState['FormFiller'], name: string, isPrefix = true): Field => {
  const fields = state.openedInstance?.fields || {}
  const systemFields = state.openedInstanceSystemFields || []

  const systemField = isPrefix
    ? getSystemFieldsStartingByName(systemFields, name)[0] || null
    : getSystemFieldByName(systemFields, name) || null

  if (!systemField)
    return null

  return Object.values(fields).find(f => f.id === systemField.field.id) || null
}

export const getFieldsBySystemName = (state: StoreState['FormFiller'], systemName: string, isPrefix = true): Field[] => {
  const fields = state.openedInstance?.fields || {}

  return Object.values(fields)
    .filter(f => isPrefix ? f.systemName.startsWith(systemName) : f.systemName === systemName)
}

export const getFieldValueBySystemName = (state: StoreState['FormFiller'], systemName: string): FieldValue => getFieldBySystemName(state, systemName)?.value

export const getSystemFieldFromFinalField = (finalField: Field, systemFields: SystemField[]): SystemField => {
  return systemFields.find(systemField => systemField.field === finalField)
}
