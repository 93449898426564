import { CombinedState, combineReducers } from 'redux'
import _ from 'lodash'
import View, { ViewState } from 'src/Layouts/View/state/reducer'
import About from 'src/Views/About/state/reducer'
import Permissions, { PermissionsState } from 'src/Views/Permissions/state/reducer'
import UserForm, { UserFormState } from 'src/Views/UserForm/state/reducer'
import AdminTools, { AdminToolsState } from 'src/Views/AdminTools/state/reducer'
import Announcements from 'src/Views/Announcements/state/reducer'
import Calendar, { CalendarState } from 'src/Components/Calendar/state/reducer'
import Dictionary, { DictionaryState } from 'src/Services/Dictionary/state/reducer'
import FieldTypes from 'src/Views/FieldTypes/state/reducer'
import EventTypes from 'src/Views/EventTypes/state/reducer'
import EventStatuses from 'src/Views/EventStatuses/state/reducer'
import EventCancellationReasons from 'src/Views/EventCancellationReasons/state/reducer'
import FormEditor from 'src/Views/FormEditor/state'
import FormFiller, { FormFillerState } from 'src/Views/FormFiller/state/reducer'
import FormList from 'src/Views/FormList/state/reducer'
import Header, { HeaderState } from 'src/Layouts/Header/state/reducer'
import Homepage from 'src/Views/Homepage/state/reducer'
import Icons from 'src/Views/Icons/state/reducer'
import Modules from 'src/Views/Modules/state/reducer'
import NavigationItems from 'src/Views/NavigationItems/state/reducer'
import Notification from 'src/Services/notifier/reducer'
import Patient from 'src/Views/Patient/state/reducer'
import Reports from 'src/Views/Reports/state/reducer'
import RestoreDeletedFields from 'src/Views/RestoreDeletedInstances/Components/restoreDeletedFields/state/reducer'
import RestoreDeletedForms from 'src/Views/RestoreDeletedInstances/Components/restoreForms/state/reducer'
import RestoreDeletedInstances from 'src/Views/RestoreDeletedInstances/Components/restoreInstances/state/reducer'
import RestoreDeletedSections from 'src/Views/RestoreDeletedInstances/Components/restoreSections/state/reducer'
import SystemFields from 'src/Views/SystemFields/state/reducer'
import ScriptExecutor, { ScriptExecutorState } from 'src/Services/ScriptExecutor/state/reducer'
import Scheduling from 'src/Views/Calendar/state/reducer'
import SlotTypes from 'src/Views/SlotTypes/state/reducer'
import Tasks from 'src/Views/Tasks/state/reducer'
import TasksCategories from 'src/Views/TasksCategories/state/reducer'
import TasksStatus from 'src/Views/TasksStatus/state/reducer'
import TasksTypes from 'src/Views/TasksTypes/state/reducer'
import UserList, { UserListState } from 'src/Views/UserList/state/reducer'
import LandingPages from 'src/Views/LandingPages/state/reducer'
import Lists from 'src/Views/Lists/state/reducer'
import ListRun from 'src/Views/ListRun/state/reducer'
import ListEdit from 'src/Views/ListEdit/state/reducer'
import MessageConfigs, { MessageConfigsState } from 'src/Views/MessageConfigs/state/reducer'
import Translations from 'src/Views/Translations/state/reducer'
import WorkflowItems from 'src/Views/Workflows/Modals/Workflows/state/reducer'
import WorkflowTriggers from 'src/Views/Workflows/Modals/Triggers/state/reducer'
import WorkflowActions from 'src/Views/Workflows/Modals/Actions/state/reducer'
import WorkflowManager, { WorkflowManagerState } from 'src/Views/Workflows/state/reducer'
import ImportInstances from 'src/Views/ImportInstances/state/reducer'
import SectionNavigator from 'src/Components/SectionNavigator/state/reducer'
import ListResultTables from 'src/Components/ListResultTable/state/reducer'
import PatientCreator, { PatientCreatorState } from 'src/Components/PatientCreator/state/reducer'
import AuditTrailsSimpleTable, { AuditTrailsSimpleTableState } from 'src/Components/AuditTrailsSimpleTable/state/reducer'
import EventSourceManager from 'src/Services/EventSourceManager/state/reducer'
import AuditTrails, { AuditTrailsState } from 'src/Views/AuditTrails/state/reducer'
import Root, { RootState } from './Root/reducer'
import GlobalControls from './GlobalControls/reducer'

export interface Action<T = any> {
  type: string
  payload?: T
  [extraProps: string]: any
}

export interface StoreState {
  Root: RootState
  View: ViewState
  About: any
  AuditTrails: AuditTrailsState
  AuditTrailsSimpleTable: AuditTrailsSimpleTableState
  Permissions: PermissionsState
  AdminTools: AdminToolsState
  Announcements: any
  Calendar: CalendarState
  Dictionary: DictionaryState
  FieldTypes: any
  EventSourceManager: any
  EventTypes: any
  EventStatuses: any
  MessageConfigs: MessageConfigsState
  FormEditor: any
  FormList: any
  FormFiller: FormFillerState
  GlobalControls: any
  Homepage: any
  Header: HeaderState
  Icons: any
  ImportInstances: any
  LandingPages: any
  Modules: any
  NavigationItems: any
  Notification: any
  Patient: any
  PatientCreator: PatientCreatorState
  Reports: any
  RestoreDeletedFields: any
  RestoreDeletedForms: any
  RestoreDeletedInstances: any
  RestoreDeletedSections: any
  ScriptExecutor: ScriptExecutorState
  Scheduling: any
  SectionNavigator: any
  SlotTypes: any
  SystemFields: any
  UserForm: UserFormState
  Tasks: any
  TasksCategories: any
  TasksStatus: any
  TasksTypes: any
  Translations: any
  UserList: UserListState
  WorkflowManager: WorkflowManagerState
  WorkflowItems: any
  WorkflowTriggers: any
  WorkflowActions: any
  ListEdit: any
  Lists: any
  ListResultTables: any
  ListRun: any
}

export const rootReducer = (state: CombinedState<any>, action: Action) => {
  if (action.type === 'SET_NEW_STATE_FOR_REDUCER' && action.reducer)
    _.set(state, action.reducer, action.state)

  return {
    ...combineReducers({
      Root,
      View,
      About,
      AuditTrails,
      AuditTrailsSimpleTable,
      Permissions,
      AdminTools,
      Announcements,
      Calendar,
      Dictionary,
      FieldTypes,
      EventSourceManager,
      EventTypes,
      EventStatuses,
      EventCancellationReasons,
      MessageConfigs,
      FormEditor,
      FormList,
      FormFiller,
      GlobalControls,
      Homepage,
      Header,
      Icons,
      ImportInstances,
      LandingPages,
      Modules,
      NavigationItems,
      Notification,
      Patient,
      PatientCreator,
      Reports,
      RestoreDeletedFields,
      RestoreDeletedForms,
      RestoreDeletedInstances,
      RestoreDeletedSections,
      ScriptExecutor,
      Scheduling,
      SectionNavigator,
      SlotTypes,
      SystemFields,
      UserForm,
      Tasks,
      TasksCategories,
      TasksStatus,
      TasksTypes,
      Translations,
      UserList,
      WorkflowManager,
      WorkflowItems,
      WorkflowTriggers,
      WorkflowActions,
      ListEdit,
      Lists,
      ListResultTables,
      ListRun,
    })(state, action),
  }
}
