import React, { useEffect }  from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container } from 'react-bootstrap'
import { translate } from 'src/Services/translation'
import Modal from 'src/Components/Modals/BaseModal'
import DeleteModal from 'src/Components/Modals/DeleteModal'
import { StoreState } from 'src/Services/Store/reducers'
import { setOptions } from 'src/Layouts/View/state/actions'
import { EventCancellationStoreState } from 'src/Views/EventCancellationReasons/state/reducer'
import { EventCancellationReason } from 'src/Types/EventCancellationReason'
import Table from './Components/Table'
import Form from './Components/Form'
import translation from './translations'
import { setDeletingItem, deleteItem, setUpdatingItem, updateItem, setCreatingItem,
  createItem, fetchItems } from './state/actions'


const EventCancellationReasons = () => {
  const dispatch = useDispatch()

  const { language } = useSelector((state: StoreState) => state.Root.user)
  const trans = translate(translation)(language)

  useEffect(() => {
    dispatch(setOptions({
      hasPrimaryButton: true,
      onPrimaryButtonClick: onCreateClick
    }))
    dispatch(fetchItems())
  }, [])

  const { creatingItem, updatingItem, deletingItem  } = useSelector(
      (state: EventCancellationStoreState) => state.EventCancellationReasons
  )

  const onCreateClick = () => dispatch(setCreatingItem({ id: null, label: null, systemName: '' }))
  const onModalCreateClose = () => dispatch(setCreatingItem(null))
  const onModalCreateFormSubmit = (data:EventCancellationReason) => {
    dispatch(setCreatingItem(data))
    dispatch(createItem())
  }

  const onUpdateClick = (item:EventCancellationReason) => dispatch(setUpdatingItem(item))
  const onModalUpdateClose = () => dispatch(setUpdatingItem(null))
  const onModalUpdateFormSubmit = (data:EventCancellationReason) => {
    dispatch(setUpdatingItem(data))
    dispatch(updateItem(data.id))
  }

  const onDeleteClick = (item:EventCancellationReason) => dispatch(setDeletingItem(item))
  const onConfirmDeleteModalClose = () => dispatch(setDeletingItem(null))
  const onConfirmDeleteModalConfirm = () => dispatch(deleteItem())

  return (
    <Container>
      <Table onDeleteClick={ onDeleteClick } onUpdateClick={ onUpdateClick } />

      <Modal isOpen={ !!creatingItem } title={ trans('modal.create.title') } onClose={ onModalCreateClose } size={ 'md' }>
        <Form onSubmit={ onModalCreateFormSubmit } />
      </Modal>

      <Modal isOpen={ !!updatingItem } title={ trans('modal.update.title') } onClose={ onModalUpdateClose } size={ 'md' }>
        <Form onSubmit={ onModalUpdateFormSubmit } item={ updatingItem } />
      </Modal>

      <DeleteModal title={ trans('confirmDeleteModal.title') }
                   isOpen={ !!deletingItem }
                   onClose={ onConfirmDeleteModalClose }
                   onDelete={ onConfirmDeleteModalConfirm }
      >
        { trans('confirmDeleteModal.description') }
      </DeleteModal>
    </Container>
  )
}

export default EventCancellationReasons
