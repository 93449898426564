import React, { ChangeEvent } from 'react'
import { useDispatch } from 'react-redux'
import { FormControl } from 'react-bootstrap'
import { FieldEvent } from 'src/Views/FormFiller/Types/Field'
import { BaseFieldType, Field } from 'src/Types/Field'
import { fieldEvent } from '../../state/actions'

interface Props {
  field: Field<BaseFieldType.TEXT>,
  isRequired: boolean
  isDisabled: boolean
}

const TextField = ({ field = null, isDisabled = false, isRequired = false }: Props) => {
  const dispatch = useDispatch()

  const [ changeTimeout, setChangeTimeout ] = React.useState(null)
  const [ localValue, setLocalValue ] = React.useState<string | null>(field.value)

  React.useEffect(() => {
    setLocalValue(field.value)
  }, [ field.value ])

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.target.value)
    clearTimeout(changeTimeout)
    // to avoid trigerring event on each key press
    setChangeTimeout(setTimeout(() => {
      dispatch(fieldEvent(field, FieldEvent.VALUE_CHANGE, { value: e.target.value }))
    }, 500))
  }

  const isValid = isRequired && localValue

  return (
    <FormControl id={ `field${ field.id }` }
      className={ `text-field ${ isValid ? 'valid' : isRequired ? 'invalid' : '' }` }
      value={ localValue || '' }
      onChange={ onChange }
      size={ 'sm' }
      disabled={ isDisabled }
      required={ isRequired }
    />
  )
}

export default TextField
