import * as types from 'src/Views/FormFiller/state/actionTypes'
import { call, put, select, takeLatest, take, cancel } from 'redux-saga/effects'
import { GlobalActions } from 'src/Types/GlobalActions'
import { StoreState } from 'src/Services/Store/reducers'
import apiMethods from 'src/Services/api/apiMethods'
import { showError, showSuccess } from 'src/Services/notifier/actions'
import { translate } from 'src/Services/translation'
import translation from 'src/Views/FormFiller/translations'
import { pushCustomizationOrder, saveInstance } from 'src/Views/FormFiller/state/actions'
import { FormElementType } from 'src/Views/FormFiller/Types/ScriptFormCustomizationOrder'
import { getFieldBySystemName } from 'src/Views/FormFiller/state/selectors'
import * as featureSystemFields from 'src/Services/Constants/FeatureSystemFields'
import { Action } from 'src/Views/FormFiller/Types/Field'
import { SAVE_INSTANCE_SUCCEEDED, SAVE_INSTANCE_FAILED, SAVE_INSTANCE_ABORTED } from 'src/Views/FormFiller/state/actionTypes'

function* doCreateSession(props: GlobalActions): Generator<any, void, any> {
  const state = ((yield select()) as StoreState).FormFiller
  const { user: { language } } = ((yield select()) as StoreState).Root
  const { openedInstance: instance } = state

  const trans = translate(translation)(language)

  yield put(saveInstance(instance.id, true))

  // Wait for saveInstance result action
  const action = yield take([ SAVE_INSTANCE_SUCCEEDED, SAVE_INSTANCE_FAILED, SAVE_INSTANCE_ABORTED ])

  if (action.type === SAVE_INSTANCE_FAILED) {
    yield cancel()
  }

  const createSessionButton = getFieldBySystemName(state, featureSystemFields.RD_SYSTEM_FIELD_PREFIX_CREATE_SESSION)

  yield put(pushCustomizationOrder(FormElementType.FIELD, createSessionButton.id, Action.START_LOADING))

  try {
    const { data } = yield call(apiMethods.post, '/middlewares/rd/session', { data: { instance: instance.id } })

    yield put(showSuccess(trans('rd.sessionCreating')))
  } catch (e) {
    yield put(showError(e?.response?.data?.error?.code || trans('rd.unknownError')))

    yield put(props.globalActions.handleError(e))
  } finally {
    yield put(pushCustomizationOrder(FormElementType.FIELD, createSessionButton.id, Action.STOP_LOADING))
  }
}

function* doOpenSession(props: GlobalActions) {
  const state = ((yield select()) as StoreState).FormFiller
  const { openedInstance: instance } = state

  // TODO
}

export default function* formFillerRdSagaWatcher(props: GlobalActions) {
  yield takeLatest(types.CREATE_RD_SESSION, doCreateSession, props)
  yield takeLatest(types.OPEN_RD_SESSION, doOpenSession, props)
}
