import React from 'react'
import { useSelector } from 'react-redux'
import { Form } from 'react-bootstrap'
import { StoreState } from 'src/Services/Store/reducers'
import { translate } from 'src/Services/translation'
import LabelWithWarning from './LabelWithWarning'

interface Props {
  type?: string
  label: string
  value: string
  initialValue: string
  name: string
  onChange: (field: string, value: any) => void
  error: string | undefined
  required?: boolean
  disabled?: boolean
  maxLength?: number
}

const TextField = ({
  type, label, name, value = '', initialValue = '', onChange, error, required = false, disabled = false, maxLength
}: Props) => {
  const { user } = useSelector((state: StoreState) => state.Root)
  const trans = translate({})(user.language)

  const hasFieldChanged = value !== initialValue

  return (
    <Form.Group className={ 'text-field' }>
      <LabelWithWarning
        label={ `${ label } ${ required ? '*' : '' }` }
        warningText={ trans('unsaved') }
        isWarningShown={ hasFieldChanged }
      />
      <Form.Control
        type={ type }
        name={ name }
        value={ value }
        required={ required }
        disabled={ disabled }
        maxLength={ maxLength }
        onChange={ e =>
          onChange(name, e.target.value)
        }
      />

      <Form.Text className={ 'text-danger' }>
        { error }
      </Form.Text>
    </Form.Group>
  )
}

export default TextField
