import React from 'react'
import { translate } from 'src/Services/translation'
import { formatDateTime } from 'src/Utils/Date'
import { useSelector } from 'react-redux'
import { LogLevel } from 'src/Types/Log'
import { AuditTrailLogFields } from 'src/Types/AuditTrailLog'
import ListCell from 'src/Components/ListCell'
import { Field } from 'src/Types/Field'
import { StoreState } from 'src/Services/Store/reducers'
import translations from './translations'

interface Props {
  column: AuditTrailLogFields
  level?: LogLevel | null
  value: any
}

const AuditTrailLogCell = ({ column, value, level = null }: Props) => {

  const { locale, language, portalTimezone } = useSelector((state: StoreState) => state.Root.user)

  const trans = translate(translations)(language)
  const formatDateTimeService = formatDateTime(locale, portalTimezone)

  const getColor = (level: LogLevel | null) => {
    switch (level) {
      case LogLevel.INFO:
        return 'info'
      case LogLevel.ERROR:
        return 'danger'
      default:
        return 'secondary'
    }
  }

  const formatObjectValue = (value: any, prefix: string, level = 1) => <>
    { prefix && <span className={ 'fw-bold' }>{ prefix } : </span> }

    <div style={ { marginLeft: level * 4 } }>
      { Object.keys(value).map((key, index) =>
                                  <span key={ `${ level }.${ index }` }>{ formatValue(value[key], key, level + 1) }</span>,
      ) }
    </div>
  </>

  const formatArrayValue = (value: any, prefix: string, level = 1) => <>
    { prefix && <span className={ 'fw-bold' }>{ prefix } : </span> }
    <div style={ { marginLeft: level * 4 } }>
      { value.map((item: any, index: string | number) =>
                     <span key={ `${ level }.${ index }` }>{ formatValue(item, null, level + 1) }</span>,
      ) }
    </div>
  </>

  const formatValue = (value: any, prefix: null | string = null, level = 1) => {

    const prefixTemplate = prefix ? <span className={ 'fw-bold' }>{ prefix }&nbsp;</span> : null

    // Is field
    if (value?.id && value?.systemName)
      return <div>{ prefixTemplate }<ListCell value={ value.value } field={ (value as Field) } /></div>
    else if (value === null || value === undefined)
      return <div>{ prefixTemplate }<i>{ trans('empty') }</i></div>
    else if (typeof value === 'object' && Array.isArray(value))
      return formatArrayValue(value, prefix, level)
    else if (typeof value === 'object')
      return formatObjectValue(value, prefix, level)
    else if (value === true)
      return <div>{ prefixTemplate }{ trans('yes') }</div>
    else if (value === false)
      return <div>{ prefixTemplate }{ trans('no') }</div>
    else
      return <div>{ prefixTemplate }{ value.toString() }</div>
  }

  const renderData = (value: any) => {
    if (!value)
      return <small className={ 'text-muted' }>{ trans('empty') }</small>
    else if (Array.isArray(value) && value.length)
      return <>
        { value.map((data: any, i: number) => <div key={ i }>{ formatValue(data) }</div>) }
      </>
    else
      return <>
        { Object.keys(value).map((key: any, i: number) => <div key={ i }>{ formatValue(value[key], key) }</div>) }
      </>
  }

  if (column === AuditTrailLogFields.TIMESTAMP)
    return <span>{ formatDateTimeService(value) }</span>
  else if (column === AuditTrailLogFields.CONTEXT)
    return <span>{ value }</span>
  else if (column === AuditTrailLogFields.CODE)
    return <span className={ `text-${ getColor(level) }` }>{ value }</span>
  else if (column === AuditTrailLogFields.USER)
    return <span><b>{ value?.id }</b> ({ value?.username })</span>
  else if (column === AuditTrailLogFields.IP)
    return <span>{ value }</span>
  else if (column === AuditTrailLogFields.IMPERSONATOR)
    return <span>
      { value?.id
       ? <span><b>{ value?.id }</b> ({ value?.username })</span>
       : <small className={ 'text-muted' }>{ trans('empty') }</small> }
    </span>
  else if (column === AuditTrailLogFields.WORKFLOW_ID)
    return <span>{ value || <small className={ 'text-muted' }>{ trans('empty') }</small> }</span>
  else if (column === AuditTrailLogFields.DATA)
    return <div style={ { textAlign: 'left' } }>{ renderData(value) }</div>
  else if (column === AuditTrailLogFields.CONTEXT_IDENTIFIER)
    return <span>{ value || <small className={ 'text-muted' }>{ trans('empty') }</small> }</span>
  else if (column === AuditTrailLogFields.SERVICE)
    return <span>{ value || <small className={ 'text-muted' }>{ trans('empty') }</small> }</span>
  else if (column === AuditTrailLogFields.CONFIG_ID)
    return <span>{ value || <small className={ 'text-muted' }>{ trans('empty') }</small> }</span>

  return trans('unknown')
}

export default AuditTrailLogCell
