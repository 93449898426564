import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Row } from 'react-bootstrap'
import { translate } from 'src/Services/translation'
import translation from 'src/Views/FormEditor/translations'
import Modal from 'src/Components/Modals/BaseModal'
import { toggleRdConfigModal, updateDetails } from 'src/Views/FormEditor/state/actions'
import { getSystemFieldsStartingByName } from 'src/Services/Selectors'
import SystemField from 'src/Types/SystemField'
import { StoreState } from 'src/Services/Store/reducers'
import { Field } from 'src/Types/Field'
import { getFieldById } from 'src/Views/FormEditor/state/selectors'
import { RD_SYSTEM_FIELDS } from './Constants'

const RdConfigModal = () => {
  const dispatch = useDispatch()

  const { language } = useSelector((state: StoreState) => state.Root.user)
  const state = useSelector((state: StoreState) => state.FormEditor)
  const { systemFields, form, isRdConfigModalOpen } = state.formReducer

  const trans = translate(translation)(language)

  const neededFields = useMemo(() => RD_SYSTEM_FIELDS
    .map(field => ({
      ...field,
      matchingFields: getSystemFieldsStartingByName(systemFields, field.prefix),
    })), [ systemFields ])

  const getSoftwareField = (): Field | null => {
    const fieldId = neededFields.find(f => f.name === 'software')?.matchingFields[0]?.field?.id

    if (!fieldId)
      return null

    return getFieldById(state, fieldId)
  }

  const isPrimaryButtonDisabled = () => {
    if (form.isRdEnabled)
      return false

    const isThereAMissingField = neededFields.some(f => f.isRequired && f.matchingFields.length === 0)

    if (isThereAMissingField)
      return true

    const isSoftwaresPresent = getSoftwareField()?.options?.values?.length > 0

    if (!isSoftwaresPresent)
      return true

    for (const option of getSoftwareField().options.values)
      if (!isSoftwareHasAssociatedFileField(option.systemName.split('#')[0]))
        return true

    return false
  }

  const isSoftwareHasAssociatedFileField = (software: string) =>
    Boolean(neededFields.find(f => f.name === 'acquisitionFiles').matchingFields?.some(mf => mf.name.includes(software)))

  const onPrimaryButtonClick = () => {
    dispatch(updateDetails({ isRdEnabled: !form.isRdEnabled }))
    dispatch(toggleRdConfigModal())
  }

  return (
    <Modal title={ trans('rdConfig.title') }
      onClose={ () => dispatch(toggleRdConfigModal()) }
      isOpen={ isRdConfigModalOpen }
      size={ 'lg' }
    >
      { systemFields.length && <Row className={ 'mb-2' }>
        { neededFields.map(field => <Col key={ field.name } sm={ 6 } className={ 'mb-2' }>
          <strong>{ trans(`rdConfig.field.${ field.name }`) }&nbsp;
            <small className={ 'text-muted' }>({ trans(`baseFieldTypes.${ field.type }`) })</small>&nbsp;
            { field.isRequired ? <span className={ 'text-danger' }>*</span> : '' }
          </strong>
          { field.isMultiple ? <small className={ 'text-muted' }>{ trans('rdConfig.multiple') }</small> : '' }
          <br/>
          { field.matchingFields.length > 0
            ? <span className={ 'text-success' }>
              { trans('rdConfig.fieldDetected') } :<br/>
              { field.matchingFields.map((matchingField: SystemField) => <i key={ matchingField.id }>{ matchingField.name }<br/></i>) }
            </span>
            : <span className={ field.isRequired ? 'text-danger' : 'text-warning' }>
              <span>{ trans(`rdConfig.${ field.isMultiple ? 'mustContainFields' : 'mustContainAField' }`) }
                  : <i>{ field.prefix }</i></span>
            </span> }
        </Col>) }
      </Row> }
      <Row className={ 'mb-2' }>
        <h5>{ trans('rdConfig.softwareFound') } :</h5><br/>
        <small className={ 'text-muted' }>{ trans('rdConfig.softwareInfo') }</small><br/>
        { getSoftwareField()?.options?.values?.length > 0
          ? getSoftwareField().options.values.map(v =>
            <div className={ `text-${ isSoftwareHasAssociatedFileField(v.systemName.split('#')[0]) ? 'success' : 'danger' }` } key={ v.systemName }>
              { v.systemName.split('#')[0] }{ !isSoftwareHasAssociatedFileField(v.systemName.split('#')[0]) && ' (No associated files field found)' }
            </div>,
          ) : <i className={ 'text-danger' }>{ trans('none') }</i> }
      </Row>
      <Button variant={ form.isRdEnabled ? 'danger' : 'primary' }
        className={ 'd-block ms-auto' }
        disabled={ isPrimaryButtonDisabled() }
        onClick={ onPrimaryButtonClick }
      >
        { trans(form.isRdEnabled ? 'deactivate' : 'activate') }
      </Button>
    </Modal>
  )
}

export default RdConfigModal
