import React, { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { Col, Row, Spinner, Table as BTable } from 'react-bootstrap'
import styled from 'styled-components'
import { getUUID } from 'rc-select/lib/utils/commonUtil'
import { translate } from 'src/Services/translation'
import TableColumnHeader from 'src/Components/NavTable/Types/TableColumnHeader'
import TableRow from 'src/Components/NavTable/Types/TableRow'
import { SortItem, SortItemDirection } from 'src/Types/Pagination'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { StoreState } from 'src/Services/Store/reducers'
import translation from './translations'

interface Props {
  tableId?: string | null
  columnHeaders: TableColumnHeader[]
  sortItem?: SortItem | null
  rows: TableRow[]
  renderRow?: (row: TableRow) => ReactNode
  isLoading?: boolean
  onSortUserClick?: (sortItem: SortItem) => void,
  onEmptyBodyComponent?: ReactJSXElement | null
}

const Table = ({
  tableId = null,
  columnHeaders = [],
  sortItem = null,
  rows = [],
  renderRow = null,
  isLoading = false,
  onSortUserClick,
  onEmptyBodyComponent = null,
}: Props) => {
  const { language } = useSelector((state: StoreState) => state.Root.user)

  const trans = translate(translation)(language)
  const isEmpty = !rows || rows.length === 0

  if (isLoading)
    return <div className={ 'd-flex' }><Spinner animation={ 'border' } className={ 'm-auto' }/></div>

  if (isEmpty)
    return <>
      <Row className={ 'mt-5' }>
        <Col className={ 'd-flex align-items-center justify-content-center' }>
          <div>{ trans('noResults') }</div>
        </Col>
      </Row>
      <Row className={ 'mb-5' }>
        { onEmptyBodyComponent &&
          <Col className={ 'd-flex align-items-center justify-content-center' }>
            <div className={ 'clearfix' }>{ onEmptyBodyComponent }</div>
          </Col>
        }
      </Row>
    </>

  const getTableId = () => tableId || getUUID()

  const renderRows = (rows: TableRow[]): ReactNode | ReactNode[] => renderRow
    ? rows.map(renderRow)
    : rows.map((cells: any, rowIdx: number) =>
      <tr key={ `row_${ rowIdx }` }>
        { cells.map((cell: any, cellIdx: number) => <td key={ `cell_${ cellIdx }` }>{ cell }</td>) }
      </tr>,
    )

  return <BTable striped borderless>
    <thead className={ 'text-center' }>
      <tr>
        { columnHeaders.map((col, i) =>
          <th key={ `${ col.id }${ getTableId() }` } style={ { verticalAlign: 'middle' } }>
            { col.label }
            { col?.isSortable && onSortUserClick && <>
              <span>&#8194;</span>
              <SortButtons>
                <span className={ `btn-sort ${ col.id === sortItem?.column && sortItem?.direction === SortItemDirection.ASC ? 'active' : '' }` }
                  onClick={ () => onSortUserClick({ column: col.id, direction: SortItemDirection.ASC }) }
                >
                            &#9652;
                </span>
                <span className={
                  `btn-sort ${ col.id === sortItem?.column && sortItem?.direction === SortItemDirection.DESC
                    ? 'active' : '' }` }
                onClick={ () => onSortUserClick({ column: col.id, direction: SortItemDirection.DESC }) }
                >
                            &#9662;
                </span>
              </SortButtons>
            </>
            }
          </th>,
        ) }

      </tr>
    </thead>
    <TableBody className={ 'table-body' }>
      { renderRows(rows) }
    </TableBody>
  </BTable>
}

export default Table

const SortButtons = styled.div`
	display: inline-flex;
	flex-direction: column;
	vertical-align: inherit;

	.btn-sort {
		line-height: 0.7rem;
		font-size: 1rem;
		opacity: 0.5;
		color: #000000;
		cursor: pointer;
	}

	.btn-sort.active {
		opacity: 1;
	}
`

const TableBody = styled.tbody`
	&.table-body {
		border-top: none;
	}
`
