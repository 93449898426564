import { getFieldsFromForm } from 'src/Utils/FormUtils'
import { FormPermissionSubject, PermissionSubject } from 'src/Views/Permissions/Types/Subject'
import { PermissionsState } from 'src/Views/Permissions/state/reducer'
import FormPermission from 'src/Types/FormPermission'
import SectionPermission from 'src/Types/SectionPermission'
import FieldPermission from 'src/Types/FieldPermission'
import CompleteFormPermissions from 'src/Views/Permissions/Types/CompleteFormPermissions'

export const getFormPermissions = (state: PermissionsState): FormPermission[] =>
  PermissionSubject.FORM in state.newSubjectData ? state.newSubjectData[PermissionSubject.FORM] : []
export const getSectionPermissions = (state: PermissionsState): SectionPermission[] =>
  PermissionSubject.SECTION in state.newSubjectData ? state.newSubjectData[PermissionSubject.SECTION] : []
export const getFieldPermissions = (state: PermissionsState): FieldPermission[] =>
  PermissionSubject.FIELD in state.newSubjectData ? state.newSubjectData[PermissionSubject.FIELD] : []

export const addPermission = <T extends FormPermission | SectionPermission | FieldPermission>(
  state: PermissionsState,
  subject: FormPermissionSubject,
  permission: Partial<T>
): Partial<T>[] => {
  const { subjectData, newSubjectData } = state

  if (!(subject in subjectData))
    return []

  /** @ts-expect-error */
  const existingPermission = subjectData[subject].find(p => p.id && p[subject].id === permission[subject].id)
  const newPermission = existingPermission ? { ...permission, id: existingPermission.id } : permission

  return ([ ...(newSubjectData as CompleteFormPermissions)[subject], newPermission ] as Partial<T>[])
}
export const removeFormPermissions = (state: PermissionsState, permission: FormPermission): CompleteFormPermissions => {
  const form = state.forms.find(f => f.id === permission.form.id)

  if (!form)
    return state.newSubjectData as CompleteFormPermissions

  const newSubjectData: CompleteFormPermissions = {
    [PermissionSubject.FORM]: getFormPermissions(state).filter(p => p.id !== permission.id),
    [PermissionSubject.SECTION]: getSectionPermissions(state).filter(p => !form.sections.some(s => s.id === p.section.id)),
    [PermissionSubject.FIELD]: getFieldPermissions(state).filter(p => !getFieldsFromForm(form).some(f => f.id === p.field.id)),
  }

  return newSubjectData
}
