import React from 'react'
import { FormCheck } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { FieldEvent } from 'src/Views/FormFiller/Types/Field'
import { BaseFieldType, Field, FieldOption } from 'src/Types/Field'
import getFieldOptionValues from 'src/Views/FormFiller/utils/getFieldOptionValues'
import { forSingleOptionValue } from 'src/Views/FormFiller/utils/getFieldSelectedOptionValue'
import { fieldEvent } from '../../state/actions'

export interface Props {
  field: Field<BaseFieldType.RADIO>
  isDisabled: boolean
  isRequired: boolean
}

const RadioField = ({ field, isDisabled = false, isRequired = false }: Props) => {

  const dispatch = useDispatch()

  const getValue = () => forSingleOptionValue(field)

  const onChange = (value: FieldOption) => dispatch(fieldEvent(field, FieldEvent.VALUE_CHANGE, { value: value.systemName }))

  const options = React.useMemo(() => getFieldOptionValues(field), [ field, field.options ])

  return <div key={ `field${ field.id }` }>
    { options.map(option =>
      <FormCheck inline={ !field.options.alignOptionsVertically }
        id={ option.systemName }
        key={ option.systemName }
        name={ field.systemName }
        type={ 'radio' }
        label={ option.label }
        value={ option.systemName }
        checked={ getValue()?.systemName === option.systemName }
        onChange={ () => onChange(option) }
        disabled={ isDisabled }
        required={ isRequired }
      />
    ) }
  </div>
}

export default RadioField
