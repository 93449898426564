import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from 'src/Services/Store/reducers'
import { translate } from 'src/Services/translation'
import translations from 'src/Views/FormEditor/translations'
import { toggleAdvancedFieldConfigModal } from 'src/Views/FormEditor/state/actions'
import { Alert, Button, Col, Form, Row } from 'react-bootstrap'
import Select from 'src/Components/Select'
import React, { useEffect, useState } from 'react'
import { getAllFields } from 'src/Views/FormFiller/utils/Fields'
import { BaseFieldType, EditableField, FieldOptions } from 'src/Types/Field'
import AutocompleteFields from 'src/Views/FormEditor/Components/AutocompleteFields'

interface Props {
  field: EditableField
  onChange: (field: EditableField) => void
  onSubmit: () => void
}

const DEFAULT_BUTTON_OPTIONS: FieldOptions = { validateUserIdentity: false, autocompleteFields: null, consentFields: [], saveInstance: false, redirectToHomePage: false }

const AdvancedFieldSettings = ({ field, onChange, onSubmit }: Props) => {
  const dispatch = useDispatch()

  const { language } = useSelector((state: StoreState) => state.Root.user)
  const { form } = useSelector((state: StoreState) => state.FormEditor.formReducer)

  const [ isJsButton, setIsJsButton ] = useState<boolean>(false)
  const [ isConsentButton, setIsConsentButton ] = useState<boolean>(false)

  const trans = translate(translations)(language)

  useEffect(() => {
    if (isJsButton)
      onChange({ ...field, options: { ...field.options, ...DEFAULT_BUTTON_OPTIONS } })
    else
      onChange({ ...field, javaScriptCode: { onClick: null } })
  }, [ isJsButton ])

  useEffect(() => {
    if (field.options.consentFields?.length > 0)
      setIsConsentButton(true)
    else
      setIsConsentButton(false)
  }, [ field.options.consentFields ])

  const getConsentFields = () => getAllFields(form.sections)
    .filter(field => field.type?.baseFieldType === BaseFieldType.CONSENT)
    .map(({ label, systemName }) => ({ label, systemName }))

  const onConsentToggle = (isConsentButton: boolean) => {
    if (!isConsentButton) {
      onChange({ ...field, options: { ...field.options, consentFields: [] } })
    }
    setIsConsentButton(isConsentButton)
  }

  const submit = () => {
    dispatch(toggleAdvancedFieldConfigModal())
    onSubmit()
  }

  return <div>
    <Row>
      <Col>
        <Form.Switch
          checked={ isJsButton }
          label={ trans('modal.advancedFieldConfig.isJsButtonLabel') }
          onChange={ e => setIsJsButton(e.target.checked) }
        />
      </Col>
    </Row>
    <hr className={ 'text-primary' } />
    <Row>
      <Col>
        { isJsButton ?
          <div>
            <Form.Group>
              <Form.Label>{ trans('editField.onClickCode') }</Form.Label>
              <Form.Control
                as={ 'textarea' }
                name={ 'onClickCode' }
                size={ 'sm' }
                defaultValue={ field.javaScriptCode?.onClick || '' }
                onChange={ e => onChange({ ...field, javaScriptCode: { onClick: e.target.value } }) }
              />
              <Form.Text className={ 'text-muted' }>{ trans('editField.onClickCodeInfo') }</Form.Text>
            </Form.Group>
          </div>
          :
          <div>
            <Alert variant={ 'info' }>
              <i className={ 'fa-solid fa-triangle-exclamation' } /> { trans('modal.advancedFieldConfig.infoText') }
            </Alert>
            <Row>
              <Col sm={ 1 } className={ 'text-bold' }><span className={ 'float-right' }>1.</span></Col>
              <Col>
                <Form.Switch
                  checked={ field.options.validateUserIdentity || false  }
                  name={ 'validateUserIdentity' }
                  label={ trans('editField.validateUserIdentity') }
                  onChange={ e => onChange({ ...field, options: { ...field.options, validateUserIdentity: e.target.checked } }) }
                />
              </Col>
            </Row>

            <Row>
              <Col sm={ 1 } className={ 'text-bold' }><span className={ 'float-right' }>2.</span></Col>
              <Col>
                <Form.Switch
                  checked={ Array.isArray(field.options.autocompleteFields)  }
                  name={ 'autocompleteFieldsSwitch' }
                  label={ trans('editField.autocompleteFields') }
                  onChange={ e => onChange({ ...field, options: { ...field.options, autocompleteFields: e.target.checked ? [] : null } }) }
                />

                { Array.isArray(field.options.autocompleteFields) && <div className={ 'mb-2' }><AutocompleteFields field={ field } onChange={ onChange } /></div> }
              </Col>
            </Row>

            <Row>
              <Col sm={ 1 } className={ 'text-bold' }><span className={ 'float-right' }>3.</span></Col>
              <Col>
                <Form.Group>
                  <Form.Switch
                    checked={ isConsentButton  }
                    name={ 'consentFieldsSwitch' }
                    label={ trans('editField.consentFields') }
                    onChange={ e => onConsentToggle(e.target.checked) }
                  />

                  { isConsentButton && <div className={ 'mb-2' }>
                    <Form.Label className={ 'fw-bold' }>{ trans('modal.advancedFieldConfig.consentFieldsTargetFieldsLabel') }</Form.Label>
                    <Select
                      id={ 'consent-fields' }
                      name={ 'consentFields' }
                      isClearable
                      isMulti
                      options={ getConsentFields() }
                      getOptionValue={ o => o.systemName }
                      getOptionLabel={ o => o.label }
                      value={ getConsentFields().filter(consentField => field.options.consentFields.includes(consentField.systemName)) }
                      onChange={ selected =>
                        onChange({
                          ...field,
                          options: {
                            ...field.options,
                            consentFields: selected?.map((
                              item: { label: string, systemName: string }
                            ) =>
                              item.systemName) || []
                          }
                        })
                      }
                    />
                  </div> }
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={ 1 } className={ 'text-bold' }><span className={ 'float-right' }>4.</span></Col>
              <Col>
                <Form.Switch
                  checked={ field.options.saveInstance || false }
                  name={ 'saveInstance' }
                  label={ trans('modal.advancedFieldConfig.saveStepText') }
                  onChange={ e => onChange({ ...field, options: { ...field.options, saveInstance: e.target.checked } }) }
                />
              </Col>
            </Row>

            <Row>
              <Col sm={ 1 } className={ 'text-bold' }><span className={ 'float-right' }>5.</span></Col>
              <Col>{ trans('modal.advancedFieldConfig.step7Text') }</Col>
            </Row>

            <Row>
              <Col sm={ 1 } className={ 'text-bold' }><span className={ 'float-right' }>6.</span></Col>
              <Col>
                <Form.Switch
                  checked={ field.options.redirectToHomePage || false  }
                  name={ 'redirectToHomePage' }
                  label={ trans('editField.redirectToHomePage') }
                  onChange={ e => onChange({ ...field, options: { ...field.options, redirectToHomePage: e.target.checked } }) }
                />
              </Col>
            </Row>

          </div> }
      </Col>
    </Row>
    <Row>
      <Col>
        <Button className={ 'float-right' } onClick={ submit }>{ trans('submit') }</Button>
      </Col>
    </Row>
  </div>
}

export default AdvancedFieldSettings
