export enum FieldOption {
  READ_ONLY = 'readOnly',
  VALUES = 'values',
  FILE_FIELD = 'fileField',
  IS_TEMPLATE_CREATOR = 'isTemplateCreator',
  FONT_SIZE = 'fontSize',
  FONT_COLOR = 'fontColor',
  CSS_CLASS = 'cssClass',
  LABEL_HOVER = 'labelHover',
  DEFAULT_VALUE = 'defaultValue',
  DEFAULT_OPTION = 'defaultOption',
  SHOW_TIME = 'showTime',
  SHOW_DATE = 'showDate',
  SHOW_ADD_BUTTON = 'showAddButton',
  SHOW_EDIT_BUTTON = 'showEditButton',
  SHOW_CLEAR_BUTTON = 'showClearButton',
  AUTOCOMPLETE_FIELDS = 'autocompleteFields',
  ALIGN_OPTIONS_VERTICALLY = 'alignOptionsVertically',
  REQUIRED = 'required',
  REDIRECT_HOME_PAGE = 'redirectToHomePage',
  SAVE_INSTANCE = 'saveInstance',
  SORT_ALPHA = 'sortAlpha',
  SORT_ORDER = 'sortOrder',
  AUTOCOMPLETE_AT_CREATION = 'autocompleteAtCreation',
  FILE_TYPES = 'fileTypes',
  DOCUMENT_TYPE = 'documentType',
  NECESSARY = 'necessary',
  EXCLUDE_FROM_CLONE = 'excludeFromClone',
  VALIDATE_USER_IDENTITY = 'validateUserIdentity',
  CONSENT_DESCRIPTION = 'consentDescription',
  CONTENT = 'content',
  CONSENT_FIELDS = 'consentFields',
  DEFAULT_TEMPLATE = 'defaultTemplate',
  SUPPORT_ON_BLUR_CODE = 'supportOnBlurCode',
  SUPPORT_ON_CHANGE_CODE = 'supportOnChangeCode',
  SUPPORT_ON_CLICK_CODE = 'supportOnClickCode',
  SET_AT_MODIFICATION = 'setAtModification',
  SET_AT_CREATION = 'setAtCreation',
  SET_NOW_AT_MODIFICATION = 'setNowAtModification',
  SET_NOW_AT_CREATION = 'setNowAtCreation',
  USER_TEXT_RESPONSE = 'userTextResponse',
  SELF_POPULATION = 'selfPopulation',
  IS_SENSITIVE = 'isSensitive',
  SAVE_TYPE = 'saveType',
  CALCULATION = 'calculation',
  TARGETED_FIELDS = 'targetedFields,'
}

export enum FieldOptionSaveTypes {
  SAVE_ONLY = 'saveOnly',
  SAVE_AND_SIGN = 'saveAndSign',
  SAVE_AS_FINAL = 'saveAsFinal',
  SAVE_AND_SIGN_WITH_PIN_CODE = 'saveAndSignWithPinCode',
  SAVE_AS_FINAL_WITH_PIN_CODE = 'saveAsFinalWithPinCode'
}
