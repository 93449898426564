import { Instance } from 'src/Types/Instance'
import File from 'src/Types/File'
import { Form } from 'src/Types/Form'
import { ConsentFieldValue } from 'src/Types/FieldType/ConsentField'
import { ButtonFieldValue } from 'src/Types/FieldType/ButtonField'
import FileConstraint from 'src/Types/FieldOption/FileConstraint'
import { List } from 'src/Types/List'
import { ListColumn } from 'src/Types/ListColumn'
import { UuidV4 } from 'src/Types/Uuid'
import { FieldOptionSaveTypes } from 'src/Types/FieldOption'
import Row from 'src/Types/Row'
import { PartialDeep } from 'src/Utils/Typing/PartialDeep'
import { MergeWithPrecedence } from 'src/Utils/Typing/MergeWithPrecedence'
import { TargetValueType } from 'src/Types/FormCustomizationOrder'
import { Iso8601 } from 'src/Types/Date'
import { Todo } from 'src/Utils/Typing/Todo'

export interface Field<T extends BaseFieldType | unknown = unknown> {
  id: UuidV4 | string | null
  systemName: string
  label: string | null
  form: Form | null
  referenceFields?: Field[] | null
  referenceFieldId?: string | null
  javaScriptCode?: null | FieldJavaScriptCode
  extensible: Field | null
  type: FieldType
  options: FieldOptions
  // Extensible fields or extensible fields ids
  fields?: Field[] | string[]
  value: FieldValue<T>
  accessLevel?: number | null
  list?: List
  // Only in reference field's children
  listColumn?: ListColumn
  row?: Row | null
  extensibleFieldId?: string | null
  rowColumn?: 1 | 2 | 3 | null
  rowSize?: 1 | 2 | 3 | null
  isFromCache?: boolean
}

export interface FieldOptions {
  isSensitive?: boolean | null
  validateUserIdentity?: boolean | null
  required?: boolean | null
  readOnly?: boolean | null
  showTime?: boolean | null
  showDate?: boolean | null
  showAddButton?: boolean | null
  showEditButton?: boolean | null
  showClearButton?: boolean | null
  supportOnBlurCode?: boolean | null
  supportOnChangeCode?: boolean | null
  supportOnClickCode?: boolean | null
  setAtCreation?: boolean | null
  setAtModification?: boolean | null
  setNowAtModification?: boolean | null
  setNowAtCreation?: boolean | null
  redirectToHomePage?: boolean | null
  isTemplateCreator?: boolean | null
  alignOptionsVertically?: boolean | null
  autocompleteAtCreation?: boolean | null
  saveInstance?: boolean | null
  excludeFromClone?: boolean | null
  selfPopulation?: boolean | null
  sortAlpha?: boolean | null
  necessary?: boolean | null
  consentDescription?: string | null
  userTextResponse?: string | null
  content?: any
  sortOrder?: number | null
  fileField?: string | null
  fontSize?: string | null
  fontColor?: string | null
  cssClass?: string | null
  labelHover?: string | null
  defaultTemplate?: string | null
  defaultOption?: string | null
  defaultValue?: string | null
  saveType?: FieldOptionSaveTypes | null
  values?: FieldOption[]
  calculation?: object | null
  targetedFields?: CalculationField['value'][]
  consentFields?: Field['systemName'][]
  autocompleteFields?: FieldOptionAutocompleteField[]
  documentType?: string | null
  fileTypes?: FileConstraint[]
}

export type EditableField = PartialDeep<MergeWithPrecedence<Field, {
  javaScriptCode?: null | EditableFieldJavaScriptCode
  fields?: EditableField[] | string[]
}>, { recurseIntoArrays: true }>

export type FieldValue<T extends BaseFieldType | unknown = unknown> =
  T extends BaseFieldType.CONSENT ? null | ConsentFieldValue :
    T extends BaseFieldType.FILE ? null | File[] :
      T extends BaseFieldType.BUTTON ? null | ButtonFieldValue :
        T extends BaseFieldType.EXTENSIBLE ? null | ExtensibleRow[] :
          T extends BaseFieldType.DROPDOWN ? null | FieldOption :
            T extends BaseFieldType.RADIO ? null | FieldOption :
              T extends BaseFieldType.CHECKBOX ? null | FieldOption[] :
                T extends BaseFieldType.MULTISELECT ? null | FieldOption[] :
                  T extends BaseFieldType.DATE_TIME ? null | Iso8601 :
                    T extends BaseFieldType.TEXT ? null | string :
                      T extends BaseFieldType.REFERENCE ? null | Instance :
                        T extends BaseFieldType.PHONE ? null | string :
                          T extends BaseFieldType.EMAIL ? null | string :
                            T extends BaseFieldType.INSTANCE_ID ? null | string :
                              T extends BaseFieldType.USERNAME ? null | string :
                                string | null | boolean | number | Instance | FieldValue[] | File | File[] | ConsentFieldValue
                                | ButtonFieldValue | FieldOption | FieldOption[] | ExtensibleRow[] | Iso8601

export interface ExtensibleRow {
  id: string
  values: {
    [fieldSystemName: string]: FieldValue
  },
  createdAt?: Iso8601
}

/**
 * Value for dropdown, radio, checkbox fields
 */
export interface FieldOption {
  systemName: string
  label: string | null
  isHidden: boolean | null
}

export interface CalculationField {
  type: CalculationFieldType
  value: Todo
}

export enum CalculationFieldType {
  OPERATOR = 'operator',
  VALUE = 'value',
  SYSTEM_FIELD = 'systemField',
}

export enum BaseFieldType {
  REFERENCE = 'REFERENCE',
  EXTENSIBLE = 'EXTENSIBLE',
  BUTTON = 'BUTTON',
  DROPDOWN = 'DROPDOWN',
  MULTISELECT = 'MULTISELECT',
  CHECKBOX = 'CHECKBOX',
  RADIO = 'RADIO',
  DATE_TIME = 'DATE_TIME',
  FILE = 'FILE',
  ESSAY = 'ESSAY',
  JSON = 'JSON',
  TEXT = 'TEXT',
  DOCUMENT = 'DOCUMENT',
  INSTANCE_ID = 'INSTANCE_ID',
  SIGNATURE = 'SIGNATURE',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  /** @deprecated use regular field that you hide */
  HIDDEN = 'HIDDEN',
  ADDRESS = 'ADDRESS',
  USERNAME = 'USERNAME',
  SENTENCE = 'SENTENCE',
  HTML_CONTENT = 'HTML_CONTENT',
  CALCULATION = 'CALCULATION',
  CONSENT = 'CONSENT',
}

export enum OptionFieldType {
  DROPDOWN = 'DROPDOWN',
  MULTISELECT = 'MULTISELECT',
  CHECKBOX = 'CHECKBOX',
  RADIO = 'RADIO',
}

export enum SingleOptionFieldType {
  DROPDOWN = 'DROPDOWN',
  RADIO = 'RADIO',
}

export enum MultiOptionFieldType {
  MULTISELECT = 'MULTISELECT',
  CHECKBOX = 'CHECKBOX',
}

export interface FieldType {
  id: string | null
  name: string | null
  icon: string | null
  baseFieldType: BaseFieldType
}

export interface FieldJavaScriptCode {
  onClick?: () => null
  onChange?: () => null
  onBlur?: () => null
}

export interface EditableFieldJavaScriptCode {
  onClick?: string
  onChange?: string
  onBlur?: string
}

export interface FieldOptionAutocompleteFieldDynamicValue {
  systemFieldName: string
  value: string
}

export interface FieldOptionAutocompleteField {
  field: Field['systemName']
  type: TargetValueType
  value: string
}

export type EditableFieldOptionAutocompleteField = PartialDeep<FieldOptionAutocompleteField, { recurseIntoArrays: true }>
