import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { translate } from 'src/Services/translation'
import { FIELD_BASE_TYPE_HTML_CONTENT } from 'src/Services/Constants'
import { FieldEvent } from 'src/Views/FormFiller/Types/Field'
import { handleError } from 'src/Services/Store/Root/actions'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { getSpecificFields } from 'src/Views/FormFiller/utils/Fields.ts'
import { saveHtmlDataFromSfdt } from 'src/Components/DocumentEditor/state/actions'
import { FieldOption, FieldOptionSaveTypes } from 'src/Types/FieldOption'
import translation from 'src/Views/FormFiller/translations.ts'
import ValidationTrigger from 'src/Components/ValidationTrigger/index'
import DeleteModal from 'src/Components/Modals/DeleteModal'
import DocumentEditor from 'src/Components/DocumentEditor/index.tsx'
import { fetchInstanceField, fieldEvent, saveInstance, saveInstanceFieldValue, setIsGenerateTemplateLoading,
  setDocumentData, setDocumentAsFinal } from '../../state/actions'
import MissingFieldsModal from '../MissingFieldsModal'

const DocumentField = ({ field, isDisable, isRequired = false }) => {
  const dispatch = useDispatch()
  const documentEditor = useRef()
  const { id: firstId, instanceId: secondId } = useParams()

  const instanceId = secondId ? secondId : firstId

  const { language } = useSelector(state => state.Root.user)
  const { isGenerateTemplateLoading, documentData, openedInstance } = useSelector(state => state.FormFiller)

  const trans = translate(translation)(language)

  const [ documentStatus, setDocumentStatus ] = useState(field.value?.status || null)
  const [ isModalOpen, setIsModalOpen ] = useState(false)
  const [ isConfirmOpen, setIsConfirmOpen ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(false)
  const [ isMissingFieldsModalOpen, setIsMissingFieldsModalOpen ] = useState(false)

  //documentData === value
  const isSaveDisabled = false

  const onToggle = () => {
    dispatch(setDocumentData(field.value?.content || null))
    setIsModalOpen(!isModalOpen)
  }

  useEffect(() => {
    setDocumentStatus(field.value?.status || null)
  }, [ field.value ])

  const onContentChange = content => dispatch(setDocumentData(content))
  const onModalClose = () => setIsModalOpen(false)

  const onGenerateDocument = () => {
    if (getMissingFields().length)
      showMissingFieldsModal()
    else
      setIsConfirmOpen(true)
  }
  const onSaveClick = () => {
    setIsLoading(true)

    save({ content: documentData, status: documentStatus })

    setIsModalOpen(false)
    setIsLoading(false)
  }

  const onSaveAsFinalClick = () => {
    if (window.confirm(trans('documentFinalMayAlreadyExists'))) {
      setIsLoading(true)

      documentEditor.current.getDocumentAsPdf()
        .then(async res => {

          save({ content: documentData, status: documentStatus }, true)

          dispatch(setDocumentAsFinal(openedInstance.id, field, res))
        })
        .catch(err => {
          dispatch(handleError(err, 'saveFailed', 'While rendering PDF'))
        })
        .finally(() => {
          setIsModalOpen(false)
          setIsLoading(false)
        })
    }
  }

  const onSaveAndSignClick = () => {
    if (window.confirm(trans('documentSignMayAlreadyExists'))) {
      setIsLoading(true)

      documentEditor.current.signAndGetDocumentAsPdf()
        .then(async res => {

          if (!res) {
            return
          }

          save({ content: documentData, status: documentStatus }, true)
          dispatch(setDocumentAsFinal(openedInstance.id, field, res))

          setIsModalOpen(false)
        })
        .catch(err => {
          dispatch(handleError(err, 'saveFailed', 'While rendering PDF'))
          setIsModalOpen(false)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  const save = (value, isSilent = false) => {
    dispatch(fieldEvent(
      field,
      FieldEvent.VALUE_CHANGE,
      { value }
    ))
    dispatch(saveInstanceFieldValue(instanceId, field.id, value, isSilent))

    const htmlField = getSpecificFields(openedInstance.fields, FIELD_BASE_TYPE_HTML_CONTENT)[0]

    if (htmlField) {
      dispatch(saveHtmlDataFromSfdt(instanceId, htmlField.id, value))
    }
  }
  const showMissingFieldsModal = () => setIsMissingFieldsModalOpen(true)

  const generateNewDocument = () => {
    dispatch(setIsGenerateTemplateLoading(true))
    dispatch(fieldEvent(field, FieldEvent.VALUE_CHANGE, { value: { content: null, status: true } }))
    dispatch(
      saveInstance(
        instanceId,
        true,
        () => dispatch(fetchInstanceField(instanceId, field.id, () => setIsModalOpen(true)))
      )
    )
  }

  const onConfirmClick = () => {
    generateNewDocument()
  }

  const onCancelClick = () => setIsConfirmOpen(false)

  const isValid = () => !isRequired || Boolean(field.value?.content)
  const getMissingFields = () =>
      Object.values(openedInstance?.fields).filter(field =>
          field.options?.required === true && (!field.value || field.value?.length === 0)
      )
  return (
    <div className={ `blue-card ${ isValid() ? '' : 'is-invalid' }` }>
      <MissingFieldsModal isOpen={ isMissingFieldsModalOpen }
                          missingFields={ getMissingFields() }
                          onClose={ () => setIsMissingFieldsModalOpen(false) }
                          trans={ trans }
      />
      <Modal
        isOpen={ isModalOpen }
        onClosed={ onModalClose }
        centered
        toggle={ onToggle }
        size={ 'xl' }
        id={ 'documentFieldModal' }
      >
        <ModalHeader toggle={ onToggle }>
          { trans('documentModalTitle') }
        </ModalHeader>
        <ModalBody>
          { field.options[FieldOption.IS_TEMPLATE_CREATOR] && <Row className={ 'mb-2' }>
            <Col>
              <small className={ 'text-muted' }>{ trans('templateCreatorInfo') }</small>
            </Col>
          </Row> }
          <Row className={ 'mb-2' }>
            <Col>
              <DocumentEditor
                showPrintButton={ false }
                showDownloadButton
                contentData={ documentData }
                onChange={ onContentChange }
                ref={ documentEditor }
                isTemplateCreator={ field.options[FieldOption.IS_TEMPLATE_CREATOR] || false }
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button onClick={ onSaveClick }
                  disabled={ isSaveDisabled }
                  style={ { float: 'right' } }
                  variant={
                    field.options[FieldOption.SAVE_TYPE] === FieldOptionSaveTypes.SAVE_AS_FINAL ||
                    field.options[FieldOption.SAVE_TYPE] === FieldOptionSaveTypes.SAVE_AS_FINAL_WITH_PIN_CODE
                      ? 'outline-primary' : 'primary'
                  }
          >
            { isLoading ? <Spinner as={ 'span' }
                                   animation={ 'border' }
                                   size={ 'sm' }
                                   aria-hidden={ 'true' }
                                   className={ 'me-1' }
            /> : <i className={ 'me-1 fas fa-save' }/> }
            { trans('save') }
          </Button>
          { ( field.options[FieldOption.SAVE_TYPE] === FieldOptionSaveTypes.SAVE_AS_FINAL ||
              field.options[FieldOption.SAVE_TYPE] === FieldOptionSaveTypes.SAVE_AS_FINAL_WITH_PIN_CODE ) &&
            <Button onClick={ onSaveAsFinalClick }
                    disabled={ isSaveDisabled }
                    variant={ 'primary' }
            >
              { isLoading ? <Spinner as={ 'span' }
                                     animation={ 'border' }
                                     size={ 'sm' }
                                     aria-hidden={ 'true' }
                                     className={ 'me-1' }
              /> : <i className={ 'me-1 fas fa-save' }/> }
              { trans('saveAsFinalLabel') }
            </Button>
          }
          { ( field.options[FieldOption.SAVE_TYPE] === FieldOptionSaveTypes.SAVE_AND_SIGN ||
              field.options[FieldOption.SAVE_TYPE] === FieldOptionSaveTypes.SAVE_AND_SIGN_WITH_PIN_CODE ) &&
            <Button onClick={ onSaveAndSignClick }
                    disabled={ isSaveDisabled }
                    variant={ 'outline-primary' }
                    className={ 'me-1' }
            >
              { isLoading ? <Spinner as={ 'span' }
                                     animation={ 'border' }
                                     size={ 'sm' }
                                     aria-hidden={ 'true' }
                                     className={ 'me-1' }
              /> : <i className={ 'me-1 fas fa-signature' }/> }
              { trans('signLabel') }
            </Button>
          }
        </ModalFooter>
      </Modal>
      <DeleteModal
        title={ trans('confirmModal.title') }
        isOpen={ isConfirmOpen }
        onClose={ onCancelClick }
        onDelete={ onConfirmClick }
      >
        { trans('confirmModal.description') }
      </DeleteModal>
      { !field.options[FieldOption.IS_TEMPLATE_CREATOR] &&
        <Button
          variant={ 'info' }
          disabled={ isDisable }
          className={ 'me-2' }
          onClick={ onGenerateDocument }
        >
          { isGenerateTemplateLoading &&
            <Spinner
              as={ 'span' }
              animation={ 'border' }
              size={ 'sm' }
              aria-hidden={ 'true' }
              className={ 'me-1' }
            /> }
          { trans('generate') }
        </Button>
      }

      { (!documentStatus || field.options[FieldOption.IS_TEMPLATE_CREATOR]) &&
        <Button variant={ 'outline-info' } disabled={ isDisable } onClick={ onToggle }>
          { trans('open') }
        </Button>
      }
      <ValidationTrigger isRequired={ isRequired } isValid={ isValid() } />
    </div>
  )
}

export default DocumentField
