export const MODULE_AUDIT_TRAILS = 'AUDIT_TRAILS'
export const MODULE_SCHEDULING = 'SCHEDULING'
export const MODULE_ADMIN_TOOLS = 'ADMIN_TOOLS'
export const MODULE_LISTS = 'LISTS'
export const MODULE_DASHBOARD = 'DASHBOARD'
export const MODULE_TASKS = 'TASKS'
export const MODULE_MY_TASKS = 'MY_TASKS'
export const MODULE_EVERYBODY_TASKS = 'EVERYBODY_TASKS'
export const MODULE_TASK_CATEGORIES = 'TASK_CATEGORIES'
export const MODULE_TASK_STATUS = 'TASK_STATUS'
export const MODULE_TASK_TYPES = 'TASK_TYPES'
export const MODULE_EVENT_TYPES = 'EVENT_TYPES'
export const MODULE_EVENT_STATUSES = 'EVENT_STATUSES'
export const MODULE_FORMS = 'FORMS'
export const MODULE_USERS = 'USERS'
export const MODULE_ICONS = 'ICONS'
export const MODULE_WORKFLOWS = 'WORKFLOWS'
export const MODULE_PERMISSIONS = 'PERMISSIONS'
export const MODULE_ANNOUNCEMENTS = 'ANNOUNCEMENTS'
export const MODULE_FIELD_TYPES = 'FIELD_TYPES'
export const MODULE_SLOT_TYPES = 'SLOT_TYPES'
export const MODULE_SLOTS = 'SLOTS'
export const MODULE_NUMERIC_REPORTS = 'NUMERIC_REPORTS'
export const MODULE_SURVEYS = 'SURVEYS'
export const MODULE_ABOUT = 'ABOUT'
export const MODULE_MODULES = 'MODULES'
export const MODULE_MESSAGE_CONFIGS = 'MESSAGE_CONFIGS'
export const MODULE_TRANSLATIONS = 'TRANSLATIONS'
export const MODULE_RESTORER = 'RESTORER'
export const MODULE_LANDING_PAGES = 'LANDING_PAGES'
export const MODULE_PATIENT_ACCOUNTS = 'PATIENT_ACCOUNTS'
export const MODULE_NAVIGATION_ITEMS = 'NAVIGATION_ITEMS'
export const MODULE_IMPORT_INSTANCES = 'IMPORT_INSTANCES'
export const MODULE_PATIENT_SEARCH_ALL = 'PATIENT_SEARCH_ALL'
export const MODULE_PATIENT_SEARCH_LOCATION = 'PATIENT_SEARCH_LOCATION'
export const MODULE_PATIENT_SEARCH_REFERS_TO_ME = 'PATIENT_SEARCH_REFERS_TO_ME'

export const MODULES = [
  MODULE_AUDIT_TRAILS,
  MODULE_SCHEDULING,
  MODULE_TASKS,
  MODULE_MY_TASKS,
  MODULE_EVERYBODY_TASKS,
  MODULE_DASHBOARD,
  MODULE_LISTS,
  MODULE_FORMS,
  MODULE_ICONS,
  MODULE_USERS,
  MODULE_PERMISSIONS,
  MODULE_ADMIN_TOOLS,
  MODULE_ANNOUNCEMENTS,
  MODULE_FIELD_TYPES,
  MODULE_EVENT_TYPES,
  MODULE_SLOT_TYPES,
  MODULE_SLOTS,
  MODULE_TASK_CATEGORIES,
  MODULE_NUMERIC_REPORTS,
  MODULE_TASK_STATUS,
  MODULE_TASK_TYPES,
  MODULE_WORKFLOWS,
  MODULE_SURVEYS,
  MODULE_ABOUT,
  MODULE_MODULES,
  MODULE_MESSAGE_CONFIGS,
  MODULE_TRANSLATIONS,
  MODULE_RESTORER,
  MODULE_LANDING_PAGES,
  MODULE_PATIENT_ACCOUNTS,
  MODULE_NAVIGATION_ITEMS,
  MODULE_EVENT_STATUSES,
  MODULE_IMPORT_INSTANCES,
  MODULE_PATIENT_SEARCH_ALL,
  MODULE_PATIENT_SEARCH_LOCATION,
  MODULE_PATIENT_SEARCH_REFERS_TO_ME,
]
