import { Field, FieldOption, MultiOptionFieldType, OptionFieldType } from 'src/Types/Field'
import { forSingleOptionValue, forMultiOptionValue } from 'src/Views/FormFiller/utils/getFieldSelectedOptionValue'

export default (field: Field): FieldOption[] => {
  if (!(field.type.baseFieldType in OptionFieldType))
    return []

  const value = (field.type.baseFieldType in MultiOptionFieldType ? forMultiOptionValue : forSingleOptionValue)(field)
  const valueAsArray = Array.isArray(value) ? value : (value ? [ value ] : [])

  const results = field.options.values.filter(value => !value.isHidden || valueAsArray.some(v => value.systemName === v.systemName))

  return field.options.sortAlpha === true
    ? results.sort((a, b) => a.label > b.label ? 1 : -1)
    : results
}
