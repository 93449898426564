import React, { ChangeEvent, FormEvent, FormEventHandler, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Form, Row } from 'react-bootstrap'
import translation from 'src/Components/Calendar/translations'
import { translate } from 'src/Services/translation'
import LocalizedDatepicker from 'src/Components/LocalizedDatepicker'
import { WEEK_DAYS } from 'src/Services/Constants'
import {
  createSlot,
  createSlotSerie,
  setSlotFormModal,
  setSlotResources,
  setToggleIsCreatingASlotSerie
} from 'src/Components/Calendar/state/actions'
import { StoreState } from 'src/Services/Store/reducers'
import { WeekDay } from 'src/Components/Calendar/Types/Calendar'
import { Size } from 'src/Types/Size'
import { Iso8601 } from 'src/Types/Date'
import { WEEK_DAYS_TEMPLATES } from 'src/Components/Calendar/Constants'
import { Instance } from 'src/Types/Instance'
import { UuidV4 } from 'src/Types/Uuid'
import day from 'dayjs'
import { formatDateAsUtcToIso8601 } from 'src/Utils/Date'
import EntitySelect from '../../EntitySelect'

const SlotSerieForm = () => {
  const dispatch = useDispatch()

  const { language } = useSelector((state: StoreState) => state.Root.user)
  const { locations , eventTypes } = useSelector((state: StoreState) => state.Dictionary)
  const { slotType } = useSelector((state: StoreState) => state.Calendar.slotForm)
  const { isCreatingASlotSerie } = useSelector((state: StoreState) => state.Calendar)

  const trans = translate(translation)(language)

  const [ formErrors, setFormErrors ] = useState<any>({})

  const [ location, setLocation ] = useState(undefined)
  const [ templateName ] = useState<string>(null)
  const [ startDate, setStartDate ] = useState<Iso8601>(null)
  const [ endDate, setEndDate ] = useState<Iso8601>(null)
  const [ startTime, setStartTime ] = useState<Iso8601>(null)
  const [ endTime, setEndTime ] = useState<Iso8601>(null)
  const [ duration, setDuration ] = useState<number>(null)
  const [ isAvailability, setIsAvailability ] = useState<boolean>(true)
  const [ isRecurrent, setIsRecurrent ] = useState<boolean>(false)
  const [ days, setDays ] = useState<WeekDay[]>([])
  const [ resource, setResource ] = useState<Instance | null>(null)

  useEffect(() => {
    const eventType = eventTypes.find(eventType => eventType.id === slotType.eventType.id)
    setDuration(eventType.defaultDuration)
  }, [])

  useEffect(() => {
    const newEndDate = formatDateAsUtcToIso8601((day(startDate).add(duration, 'minutes')).toDate())

    if (newEndDate !== endDate) {
      setEndDate(newEndDate)
    }
  }, [ startDate, duration ])

  useEffect(() => {
    const newEndTime = formatDateAsUtcToIso8601((day(startTime).add(duration, 'minutes')).toDate())

    if (newEndTime !== endTime) {
      setEndTime(newEndTime)
    }
  }, [ startTime, duration ])

  const handleWeekDaysTemplateChange = (e: ChangeEvent<HTMLSelectElement>) =>
    setDays(e.target.value ? WEEK_DAYS_TEMPLATES[e.target.value]?.days : null)
  const handleWeekDaysChange = (day: WeekDay) =>
    setDays(days.includes(day) ? days.filter(_ => _ !== day) : [ ...days, day ])

  const handleLocationChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setLocation(e.target.value)
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!isValid()) return

    if (isCreatingASlotSerie) {
      dispatch(createSlotSerie({
        resource: resource?.id, templateName, isAvailability, days, duration,
        type: slotType.id, location: location, startAt: startDate, endAt: endDate, startTime, endTime
      }))
    } else {
      dispatch(createSlot({
        resource: resource?.id, startAt: startDate, endAt: endDate, isAvailability, location, type: slotType.id,
      }))
    }

    dispatch(setSlotFormModal(false, slotType))
    dispatch(setSlotResources([]))
  }

  const isValid = () => {
    let isValid = true
    const errors: any = {}

    if (isCreatingASlotSerie && !days.length) {
      errors.days = 'You have to select at least one day'
      isValid = false
    }
    if (endDate < startDate) {
      errors.startDate = 'End date can\'t be before start date'
      errors.endDate = 'End date can\'t be before start date'
      isValid = false
    }

    setFormErrors(errors)
    return isValid
  }

  return (
    <Form onSubmit={ handleSubmit }>
      <Row className={ 'mb-3' }>
        <Col>
          <Form.Group>
            <Form.Label>{ trans('form.slotSerie.startAt') }</Form.Label>
            <LocalizedDatepicker showDateOnly={ isCreatingASlotSerie }
              size={ Size.MEDIUM }
              selected={ startDate }
              onChange={ setStartDate }
              portalId={ 'root' }
              required
            />
            { formErrors.startDate && <Form.Text className={ 'text-danger' }>{ formErrors.startDate }</Form.Text> }
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>{ trans('form.slotSerie.endAt') }</Form.Label>
            <LocalizedDatepicker showDateOnly={ isCreatingASlotSerie }
              size={ Size.MEDIUM }
              selected={ endDate }
              onChange={ setEndDate }
              portalId={ 'root' }
              required
            />
            { formErrors.endDate && <Form.Text className={ 'text-danger' }>{ formErrors.endDate }</Form.Text> }
          </Form.Group>
        </Col>
      </Row>
      <Row className={ 'mb-3' }>
        <Col>
          <Form.Group>
            <Form.Label>{ trans('form.slotSerie.location') }</Form.Label>
            <Form.Select onChange={ handleLocationChange } value={ location }>
              <option value={ null }></option>
              { locations.map(val => <option value={ val.id } key={ val.id }>{ val.identityString }</option>) }
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>{ trans('form.slotSerie.resource') }</Form.Label>
            <EntitySelect fetchUrl={ `/instances/resources/${ slotType?.eventType?.id }` }
              name={ 'resourceSelect' }
              placeholder={ 'Select a resource' }
              labelKey={ 'identityString' }
              value={ resource ?? null }
              // key is dynamic to force a rerender of the resources when changing the location
              key={ location ?? 'resources' }
              additionalParams={ location ? { locationId: location } : null }
              isDefaultOptions
              onChange={ selected => setResource(selected ?? null) }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className={ 'mb-3' }>
        <Col md={ '6' }>
          <Form.Group>
            <Form.Label>{ trans('form.slotSerie.slotType') }</Form.Label>
            <Form.Control
              type={ 'text' }
              defaultValue={ slotType.label }
              disabled
            />
          </Form.Group>
        </Col>
        <Col md={ '6' }>
          <Form.Group>
            <Form.Label>{ trans('form.slotSerie.eventType') }</Form.Label>
            <Form.Control
              type={ 'text' }
              defaultValue={ slotType?.eventType.label }
              disabled
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className={ 'mb-3' }>
        <Col>
          <Form.Group>
            <Form.Switch checked={ isAvailability } label={ trans('form.slotSerie.availabilityOn') }
              onChange={ e => setIsAvailability(e.target.checked) }
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Switch checked={ isCreatingASlotSerie } label={ trans('form.slotSerie.recurrenceOn') }
              onChange={ e => dispatch(setToggleIsCreatingASlotSerie(e.target.checked)) }
            />
          </Form.Group>
        </Col>
      </Row>
      {
        isCreatingASlotSerie &&
                <>
                  <Row className={ 'mb-3' }>
                    <Col>
                      <Form.Group>
                        <Form.Group>
                          <Form.Label>{ trans('form.slotSerie.duration') }</Form.Label>
                          <Form.Control name={ 'duration' } type={ 'number' } min={ 1 } title={ 'min' }
                            value={ duration }
                            onChange={ e => setDuration(parseInt(e.target.value)) }
                            disabled
                          />
                        </Form.Group>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className={ 'mb-3' }>
                    <Col>
                      <Form.Group>
                        <Form.Label>{ trans('form.slotSerie.startTime') }</Form.Label>
                        <LocalizedDatepicker
                          size={ Size.MEDIUM }
                          showTimeOnly
                          timeIntervals={ 5 }
                          timeCaption={ 'Time' }
                          selected={ startTime }
                          onChange={ setStartTime }
                          portalId={ 'root' }
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>{ trans('form.slotSerie.endTime') }</Form.Label>
                        <LocalizedDatepicker
                          size={ Size.MEDIUM }
                          showTimeOnly
                          timeIntervals={ 5 }
                          timeCaption={ 'Time' }
                          selected={ endTime }
                          onChange={ setEndTime }
                          portalId={ 'root' }
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className={ 'mb-3' }>
                    <Col md={ 4 }>
                      <Form.Group>
                        <Form.Label>{ trans('form.slotSerie.recurrence') }</Form.Label>
                        <Form.Select onChange={ handleWeekDaysTemplateChange }>
                          <option value={ null }></option>
                          { Object.keys(WEEK_DAYS_TEMPLATES)
                            .map(_ => <option value={ _ } key={ _ }>{ trans(`weekdayTemplate.${ _ }`) }</option>) }
                        </Form.Select>
                        { formErrors.days && <Form.Text className={ 'text-danger' }>{ formErrors.days }</Form.Text> }
                      </Form.Group>
                    </Col>
                    <Col className={ 'd-flex align-items-end' }>
                      <Form.Group>
                        { WEEK_DAYS.map(day => <Form.Check inline key={ day } type={ 'checkbox' }
                          label={ trans(`weekday.abbr.${ day }`) + '.' }
                          checked={ days.includes(day) }
                          onChange={ () => handleWeekDaysChange(day) }
                        />) }
                      </Form.Group>
                    </Col>
                  </Row>
                </>
      }
      <Row>
        <Col>
          <Button type={ 'submit' } className={ 'float-right' } size={ 'sm' }
            variant={ 'primary' }>{ trans('create') }</Button>
        </Col>
      </Row>
    </Form>
  )
}
export default SlotSerieForm

