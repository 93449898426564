import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { translate } from 'src/Services/translation'
import Modal from 'src/Components/Modals/BaseModal'
import DeleteModal from 'src/Components/Modals/DeleteModal'
import translation from './translations'
import Form from './Components/Form'
import {
  setDeletingItem, deleteItem, setUpdatingItem, updateItem, toggleCreateActionModal, createItem
} from './state/actions'

const ActionModals = () => {
  const dispatch = useDispatch()
  const { language } = useSelector(state => state.Root?.user)
  const { creatingItem, updatingItem, deletingItem  } = useSelector(state => state.WorkflowActions)

  const trans = translate(translation)(language)

  const onModalCreateFormSubmit = data => dispatch(createItem(data))
  const onModalUpdateClose = () => dispatch(setUpdatingItem(null))
  const onModalUpdateFormSubmit = data => dispatch(updateItem(data))
  const onConfirmDeleteModalClose = () => dispatch(setDeletingItem(null))
  const onConfirmDeleteModalConfirm = () => dispatch(deleteItem())

  return (
    <>
      <Modal
        isOpen={ !!creatingItem }
        title={ trans('modal.create.title') }
        onClose={ () => dispatch(toggleCreateActionModal()) } size={ 'lg' }
      >
        <Form trans={ trans } onSubmit={ onModalCreateFormSubmit } />
      </Modal>

      <Modal isOpen={ !!updatingItem } title={ trans('modal.update.title') } onClose={ onModalUpdateClose } size={ 'lg' }>
        <Form trans={ trans }
              onSubmit={ onModalUpdateFormSubmit }
              item={ updatingItem }
        />
      </Modal>

      <DeleteModal title={ trans('confirmDeleteModal.title') }
                   isOpen={ !!deletingItem }
                   onClose={ onConfirmDeleteModalClose }
                   onDelete={ onConfirmDeleteModalConfirm }
      >
        { trans('confirmDeleteModal.description') }
      </DeleteModal>
    </>
  )
}

export default ActionModals
