import { SidebarTab, WeekDay } from 'src/Components/Calendar/Types/Calendar'
import { Locale } from 'src/Types/Locale'
import { FieldOption } from 'src/Types/FieldOption'
import { BaseFieldType } from 'src/Types/Field'

export const WEEK_DAYS = [ WeekDay.SUNDAY, WeekDay.MONDAY, WeekDay.TUESDAY, WeekDay.WEDNESDAY, WeekDay.THURSDAY,
  WeekDay.FRIDAY, WeekDay.SATURDAY ]

export const WEEK_DAYS_TEMPLATES = {
  all: {
    label: 'All',
    days: WEEK_DAYS
  },
  weekdays: {
    label: 'Weekdays',
    days: [ WeekDay.MONDAY, WeekDay.TUESDAY, WeekDay.WEDNESDAY, WeekDay.THURSDAY, WeekDay.FRIDAY ]
  },
  weekends: {
    label: 'Weekends',
    days: [ WeekDay.SATURDAY, WeekDay.SUNDAY ]
  },
  monWedFri: {
    label: 'Mon/Wed/Fri',
    days: [ WeekDay.MONDAY, WeekDay.WEDNESDAY, WeekDay.FRIDAY ]
  },
  tueThu: {
    label: 'Tue/Thu',
    days: [ WeekDay.TUESDAY, WeekDay.THURSDAY ]
  },
  none: {
    label: 'None',
    days: []
  }
}

export const ICON_TYPE_SVG = 'SVG'
export const ICON_TYPE_CLASS = 'CLASS'
export const ICON_TYPE_PNG = 'PNG'

export const ICON_TYPES = [
  ICON_TYPE_SVG, ICON_TYPE_CLASS, ICON_TYPE_PNG
]

/** @deprecated use Types\Field::BaseFieldType */
export const FIELD_BASE_TYPE_DATE_TIME = 'DATE_TIME'
/** @deprecated use Types\Field::BaseFieldType */
export const FIELD_BASE_TYPE_REFERENCE = 'REFERENCE'
/** @deprecated use Types\Field::BaseFieldType */
export const FIELD_BASE_TYPE_EXTENSIBLE = 'EXTENSIBLE'
/** @deprecated use Types\Field::BaseFieldType */
export const FIELD_BASE_TYPE_BUTTON = 'BUTTON'
/** @deprecated use Types\Field::BaseFieldType */
export const FIELD_BASE_TYPE_DOCUMENT = 'DOCUMENT'
/** @deprecated use Types\Field::BaseFieldType */
export const FIELD_BASE_TYPE_TEXT = 'TEXT'
/** @deprecated use Types\Field::BaseFieldType */
export const FIELD_BASE_TYPE_HIDDEN = 'HIDDEN'
/** @deprecated use Types\Field::BaseFieldType */
export const FIELD_BASE_TYPE_FILE = 'FILE'
/** @deprecated use Types\Field::BaseFieldType */
export const FIELD_BASE_TYPE_PHONE = 'PHONE'
/** @deprecated use Types\Field::BaseFieldType */
export const FIELD_BASE_TYPE_CONSENT = 'CONSENT'
/** @deprecated use Types\Field::BaseFieldType */
export const FIELD_BASE_TYPE_HTML_CONTENT = 'HTML_CONTENT'

// "OPTIONS" Here is HTML options
export const FIELD_BASE_TYPES_WITH_OPTIONS = [
  BaseFieldType.CHECKBOX, BaseFieldType.MULTISELECT, BaseFieldType.RADIO, BaseFieldType.DROPDOWN
]

export const FIELD_BASE_TYPES_WITH_SINGLE_VALUES = [
  BaseFieldType.RADIO, BaseFieldType.DROPDOWN
]

export const FIELD_BASE_TYPES_WITH_MULTIPLE_VALUES = [
  BaseFieldType.CHECKBOX, BaseFieldType.MULTISELECT
]

/** Field types that can't be automatically set by a value that come from user input */
export const FIELD_BASE_TYPES_NOT_AUTO_SETABLE = [
  BaseFieldType.EXTENSIBLE, BaseFieldType.FILE, BaseFieldType.ADDRESS, BaseFieldType.SIGNATURE, BaseFieldType.SENTENCE, BaseFieldType.DOCUMENT,
  BaseFieldType.BUTTON, BaseFieldType.REFERENCE, BaseFieldType.HTML_CONTENT
]

export const FIELD_BASE_TYPES_WITH_NO_LABEL = [
  BaseFieldType.HIDDEN, BaseFieldType.SENTENCE, BaseFieldType.BUTTON, BaseFieldType.CONSENT
]

export const LIST_TYPE_REFERENCE = 'Reference'
export const LIST_TYPE_PATIENT_SIDEBAR = 'Patient Sidebar'
export const LIST_TYPE_CALENDAR = 'Calendar'
export const LIST_TYPE_EXPANDED_SIDEBAR = 'Expanded Sidebar'
export const LIST_TYPE_SYSTEM = 'System'
export const LIST_TYPE_TREND = 'Trend'
export const LIST_TYPE_HOMEPAGE = 'Homepage'

export const COLOR_PRIMARY = 'primary'
export const COLOR_SECONDARY = 'secondary'
export const COLOR_SUCCESS = 'success'
export const COLOR_DANGER = 'danger'
export const COLOR_WARNING = 'warning'
export const COLOR_INFO = 'info'
export const COLOR_LIGHT = 'light'
export const COLOR_DARK = 'dark'

export const COLORS = [ COLOR_PRIMARY, COLOR_SECONDARY, COLOR_SUCCESS, COLOR_DANGER, COLOR_WARNING, COLOR_INFO,
  COLOR_LIGHT, COLOR_DARK ]

export const SIZE_XS = 'xs'
export const SIZE_SM = 'sm'
export const SIZE_MD = 'md'
export const SIZE_LG = 'lg'
export const SIZE_XL = 'xl'
export const SIZE_XXL = 'xxl'

export const SIZES = [ SIZE_SM, SIZE_MD, SIZE_LG, SIZE_XL, SIZE_XXL ]

export const IMG_SIZES = {
  [SIZE_SM]: { width: 16, height: 16 },
  [SIZE_MD]: { width: 20, height: 20 },
  [SIZE_LG]: { width: 30, height: 30 },
  [SIZE_XL]: { width: 50, height: 50 },
  [SIZE_XXL]: { width: 100, height: 100 }
}

export const FONT_SIZES = {
  [SIZE_SM]: '0.75em',
  [SIZE_MD]: '1em',
  [SIZE_LG]: '1.5em',
  [SIZE_XL]: '2em',
  [SIZE_XXL]: '3em'
}

export const CALENDAR_SIDEBAR_DEFAULT_TAB = SidebarTab.EVENTS

export const COORDINATES = {
  [Locale.FR]: {
    southWest: { lat: 42.57242831651362, lng: -4.759379299248366 },
    northEast: { lat: 51.724655844528854, lng: 8.347211295519514 }
  },
  [Locale.US]: {
    southWest: { lat: 25.015192782891432, lng: -170.22272797268144 },
    northEast: { lat: 71.13326980367239, lng: -65.88572524182713 }
  }
}

export const LANGUAGES = [ Locale.FR, Locale.US ]

export const NAVIGATION_ITEM_SCHEDULING = 'SCHEDULING'
export const NAVIGATION_ITEM_ADMIN_TOOLS = 'ADMIN_TOOLS'
export const NAVIGATION_ITEM_AUDIT_TRAILS = 'AUDIT_TRAILS'
export const NAVIGATION_ITEM_LISTS = 'LISTS'
export const NAVIGATION_ITEM_DASHBOARD = 'DASHBOARD'
export const NAVIGATION_ITEM_TASKS = 'TASKS'
export const NAVIGATION_ITEM_MY_TASKS = 'MY_TASKS'
export const NAVIGATION_ITEM_EVERYBODY_TASKS = 'EVERYBODY_TASKS'
export const NAVIGATION_ITEM_TASK_CATEGORIES = 'TASK_CATEGORIES'
export const NAVIGATION_ITEM_TASK_STATUS = 'TASK_STATUS'
export const NAVIGATION_ITEM_TASK_TYPES = 'TASK_TYPES'
export const NAVIGATION_ITEM_EVENT_TYPES = 'EVENT_TYPES'
export const NAVIGATION_ITEM_EVENT_STATUSES = 'EVENT_STATUSES'
export const NAVIGATION_ITEM_EVENT_CANCELLATION_REASONS = 'EVENT_CANCELLATION_REASONS'
export const NAVIGATION_ITEM_FORMS = 'FORMS'
export const NAVIGATION_ITEM_USERS = 'USERS'
export const NAVIGATION_ITEM_ICONS = 'ICONS'
export const NAVIGATION_ITEM_WORKFLOWS = 'WORKFLOWS'
export const NAVIGATION_ITEM_PERMISSIONS = 'PERMISSIONS'
export const NAVIGATION_ITEM_ANNOUNCEMENTS = 'ANNOUNCEMENTS'
export const NAVIGATION_ITEM_EVENT_STATUS = 'EVENT_STATUS'
export const NAVIGATION_ITEM_FIELD_TYPES = 'FIELD_TYPES'
export const NAVIGATION_ITEM_SLOT_TYPES = 'SLOT_TYPES'
export const NAVIGATION_ITEM_SYSTEM_FIELDS = 'SYSTEM_FIELDS'
export const NAVIGATION_ITEM_NUMERIC_REPORTS = 'NUMERIC_REPORTS'
export const NAVIGATION_ITEM_SURVEYS = 'SURVEYS'
export const NAVIGATION_ITEM_ABOUT = 'ABOUT'
export const NAVIGATION_ITEM_MODULES = 'MODULES'
export const NAVIGATION_ITEM_MESSAGE_CONFIGS = 'MESSAGE_CONFIGS'
export const NAVIGATION_ITEM_TRANSLATIONS = 'TRANSLATIONS'
export const NAVIGATION_ITEM_RESTORER = 'RESTORER'
export const NAVIGATION_ITEM_LANDING_PAGES = 'LANDING_PAGES'
export const NAVIGATION_ITEM_PATIENT_ACCOUNTS = 'PATIENT_ACCOUNTS'
export const NAVIGATION_ITEM_NAVIGATION_ITEMS = 'NAVIGATION_ITEMS'
export const NAVIGATION_ITEM_HOMEPAGE = 'HOMEPAGE'
export const NAVIGATION_ITEM_USER_CREATOR = 'USER_CREATOR'
export const NAVIGATION_ITEM_USER_EDITOR = 'USER_EDITOR'
export const NAVIGATION_ITEM_FORM_EDITOR = 'FORM_EDITOR'
export const NAVIGATION_ITEM_FORM_FILLER = 'FORM_FILLER'
export const NAVIGATION_ITEM_LIST_EDITOR = 'LIST_EDITOR'
export const NAVIGATION_ITEM_LIST_RUNNER = 'LIST_RUNNER'
export const NAVIGATION_ITEM_PATIENT = 'PATIENT'
export const NAVIGATION_ITEM_PDF_EDITOR = 'PDF_EDITOR'
export const NAVIGATION_ITEM_REPORT_RUNNER = 'REPORT_RUNNER'
export const NAVIGATION_ITEM_SYSTEM_FIELD_EDITOR = 'SYSTEM_FIELD_EDITOR'
export const NAVIGATION_ITEM_CALENDAR = 'CALENDAR'
export const NAVIGATION_ITEM_IMPORT_INSTANCES = 'IMPORT_INSTANCES'

export const NAVIGATION_ITEMS = [
  NAVIGATION_ITEM_AUDIT_TRAILS,
  NAVIGATION_ITEM_SCHEDULING,
  NAVIGATION_ITEM_TASKS,
  NAVIGATION_ITEM_MY_TASKS,
  NAVIGATION_ITEM_EVERYBODY_TASKS,
  NAVIGATION_ITEM_DASHBOARD,
  NAVIGATION_ITEM_LISTS,
  NAVIGATION_ITEM_FORMS,
  NAVIGATION_ITEM_ICONS,
  NAVIGATION_ITEM_USERS,
  NAVIGATION_ITEM_PERMISSIONS,
  NAVIGATION_ITEM_ADMIN_TOOLS,
  NAVIGATION_ITEM_ANNOUNCEMENTS,
  NAVIGATION_ITEM_EVENT_STATUS,
  NAVIGATION_ITEM_FIELD_TYPES,
  NAVIGATION_ITEM_EVENT_TYPES,
  NAVIGATION_ITEM_SLOT_TYPES,
  NAVIGATION_ITEM_SYSTEM_FIELDS,
  NAVIGATION_ITEM_TASK_CATEGORIES,
  NAVIGATION_ITEM_NUMERIC_REPORTS,
  NAVIGATION_ITEM_TASK_STATUS,
  NAVIGATION_ITEM_TASK_TYPES,
  NAVIGATION_ITEM_WORKFLOWS,
  NAVIGATION_ITEM_SURVEYS,
  NAVIGATION_ITEM_ABOUT,
  NAVIGATION_ITEM_MODULES,
  NAVIGATION_ITEM_MESSAGE_CONFIGS,
  NAVIGATION_ITEM_TRANSLATIONS,
  NAVIGATION_ITEM_RESTORER,
  NAVIGATION_ITEM_LANDING_PAGES,
  NAVIGATION_ITEM_PATIENT_ACCOUNTS,
  NAVIGATION_ITEM_NAVIGATION_ITEMS,
  NAVIGATION_ITEM_IMPORT_INSTANCES
]

export const PAGE_CONTENT_TYPE_LIST = 'LIST'
export const PAGE_CONTENT_TYPE_NUMERIC_REPORT = 'NUMERIC_REPORT'
export const PAGE_CONTENT_TYPE_ALL_LISTS = 'ALL_LISTS'
export const PAGE_CONTENT_TYPE_RECENT_LISTS = 'RECENT_LISTS'
export const PAGE_CONTENT_TYPE_RECENT_INSTANCES = 'RECENT_INSTANCES'

export const PAGE_CONTENT_TYPES = [
  PAGE_CONTENT_TYPE_ALL_LISTS, PAGE_CONTENT_TYPE_LIST, PAGE_CONTENT_TYPE_NUMERIC_REPORT, PAGE_CONTENT_TYPE_RECENT_LISTS,
  PAGE_CONTENT_TYPE_RECENT_INSTANCES
]

export const PAGE_CONTENT_TYPES_ICONS = {
  [PAGE_CONTENT_TYPE_ALL_LISTS]: 'fas fa-list-ul',
  [PAGE_CONTENT_TYPE_LIST]: 'fas fa-th-list',
  [PAGE_CONTENT_TYPE_NUMERIC_REPORT]: 'fas fa-calculator',
  [PAGE_CONTENT_TYPE_RECENT_LISTS]: 'fas fa-stream',
  [PAGE_CONTENT_TYPE_RECENT_INSTANCES]: 'fas fa-layer-group'
}

export const FIELD_ACTION_START_LOADING = 'START_LOADING'
export const FIELD_ACTION_STOP_LOADING = 'STOP_LOADING'
export const FIELD_ACTION_SHOW_INFO = 'SHOW_INFO'
export const FIELD_ACTION_HIDE_INFO = 'HIDE_INFO'
export const FIELD_ACTION_CHANGE_VALUE = 'CHANGE_VALUE'
export const FIELD_ACTION_RESET_VALUE = 'RESET_VALUE'
export const FIELD_ACTION_HIDE = 'HIDE'
export const FIELD_ACTION_SHOW = 'SHOW'
export const FIELD_ACTION_DISABLE = 'DISABLE'
export const FIELD_ACTION_ENABLE = 'ENABLE'

/** Actions available during a custom script execution */
export const SCRIPT_ACTIONS = [
  FIELD_ACTION_SHOW_INFO, FIELD_ACTION_HIDE_INFO, FIELD_ACTION_CHANGE_VALUE, FIELD_ACTION_RESET_VALUE,
  FIELD_ACTION_HIDE, FIELD_ACTION_SHOW, FIELD_ACTION_DISABLE, FIELD_ACTION_ENABLE, FIELD_ACTION_START_LOADING,
  FIELD_ACTION_STOP_LOADING
]

export const FIELD_ACTION_OPTION_VALUE = 'value'
export const FIELD_ACTION_OPTION_VARIANT = 'variant'

export const FIELD_ACTION_OPTIONS = [ FIELD_ACTION_OPTION_VALUE, FIELD_ACTION_OPTION_VARIANT ]

export const FORM_ELEMENT_FIELD = 'FIELD'
export const FORM_ELEMENT_ROW = 'ROW'
export const FORM_ELEMENT_SECTION = 'SECTION'

export const FORM_ELEMENT_TYPES = [ FORM_ELEMENT_FIELD, FORM_ELEMENT_ROW, FORM_ELEMENT_SECTION ]

export const USER_IS_BLOCKED = 'user is blocked'

export const AUDIT_TRAIL_LOG_LEVEL_DEBUG = 'DEBUG'
export const AUDIT_TRAIL_LOG_LEVEL_INFO = 'INFO'
export const AUDIT_TRAIL_LOG_LEVEL_NOTICE = 'NOTICE'
export const AUDIT_TRAIL_LOG_LEVEL_WARNING = 'WARNING'
export const AUDIT_TRAIL_LOG_LEVEL_ERROR = 'ERROR'
export const AUDIT_TRAIL_LOG_LEVEL_CRITICAL = 'CRITICAL'
export const AUDIT_TRAIL_LOG_LEVEL_ALERT = 'ALERT'
export const AUDIT_TRAIL_LOG_LEVEL_EMERGENCY = 'EMERGENCY'

export const AUDIT_TRAIL_LOG_LEVELS = [
  AUDIT_TRAIL_LOG_LEVEL_DEBUG, AUDIT_TRAIL_LOG_LEVEL_ALERT, AUDIT_TRAIL_LOG_LEVEL_ERROR, AUDIT_TRAIL_LOG_LEVEL_CRITICAL,
  AUDIT_TRAIL_LOG_LEVEL_EMERGENCY, AUDIT_TRAIL_LOG_LEVEL_INFO, AUDIT_TRAIL_LOG_LEVEL_NOTICE,
  AUDIT_TRAIL_LOG_LEVEL_WARNING
]

export const JSON_OPTIONS = [ FieldOption.AUTOCOMPLETE_FIELDS, FieldOption.CALCULATION, FieldOption.TARGETED_FIELDS ]

export const ID_SEPARATOR = '.'
export const TEMPLATE_VARIABLE_SEPARATOR = '###'
