import React from 'react'
import { useSelector } from 'react-redux'
import { Form } from 'react-bootstrap'
import { StoreState } from 'src/Services/Store/reducers'
import { translate } from 'src/Services/translation'
import LabelWithWarning from './LabelWithWarning'

interface Props {
  label: string
  name: string
  disabled?: boolean
  initialValue: boolean
  checked?: boolean
  onChange: (field: string, value: any) => void
}

const SwitchField = ({
  label, name, disabled = false, initialValue, checked = false, onChange
}: Props) => {
  const { user } = useSelector((state: StoreState) => state.Root)
  const trans = translate({})(user.language)

  const hasFieldChanged = checked !== initialValue

  return (
    <Form.Group className={ 'switch-field' }>
      <Form.Switch
        label={
          <LabelWithWarning
            label={ label }
            warningText={ trans('unsaved') }
            isWarningShown={ hasFieldChanged }
            warningAdditionalClass={ 'ms-2' }
          />
        }
        name={ name }
        disabled={ disabled }
        checked={ checked }
        onChange={ e => onChange(name, e.target.checked) }
      />


    </Form.Group>
  )
}

export default SwitchField
