import Patient from 'src/Types/Patient'
import * as types from 'src/Components/PatientCreator/state/actionTypes'
import { Action as ActionState } from 'src/Services/Store/reducers'

export interface PatientCreatorState {
  patient: Patient
  isOpen: boolean
  similarPatients: Patient[]
}

const initialState: PatientCreatorState = {
  patient: null,
  isOpen: false,
  similarPatients: [],
}

export default (state = initialState, { type, payload }: ActionState) => {
  switch (type) {
    case types.REQUEST_PATIENT_CREATION:
      return {
        ...state,
        isOpen: true,
        patient: payload.patient
      }
    case types.RESET_PATIENT_CREATION:
      return {
        ...state,
        isOpen: false,
        patient: initialState.patient,
        similarPatients: initialState.similarPatients,
      }
    case types.SET_SIMILAR_PATIENTS:
      return {
        ...state,
        similarPatients: payload.patients
      }
    default:
      return state
  }
}
