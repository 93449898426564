import {
  WORKFLOW_CREATED, WORKFLOW_DELETED, CREATE_WORKFLOW, DELETE_WORKFLOW, UPDATE_WORKFLOW,
  WORKFLOW_UPDATED, SET_DELETING_WORKFLOW, SET_UPDATING_WORKFLOW, TOGGLE_CREATE_WORKFLOW_MODAL
} from 'src/Views/Workflows/Modals/Workflows/state/actionTypes'
import WorkflowItem from 'src/Types/WorkflowItem'
import { Action } from 'src/Services/Store/reducers'

export interface WorkflowItemsState {
  isLoading: boolean
  creatingItem: WorkflowItem | null
  updatingItem: WorkflowItem | null
  deletingItem: WorkflowItem | null
}

const initialState: WorkflowItemsState = {
  isLoading: false,
  creatingItem: null,
  updatingItem: null,
  deletingItem: null,
}

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case CREATE_WORKFLOW:
      return {
        ...state,
        isLoading: true
      }
    case UPDATE_WORKFLOW:
      return {
        ...state,
        isLoading: true
      }
    case DELETE_WORKFLOW:
      return {
        ...state,
        isLoading: true
      }
    case WORKFLOW_CREATED:
      return {
        ...state,
        creatingItem: null,
      }
    case WORKFLOW_UPDATED:
      return {
        ...state,
        updatingItem: null,
      }
    case WORKFLOW_DELETED:
      return {
        ...state,
        deletingItem: null,
        updatingItem: null,
      }
    case SET_DELETING_WORKFLOW:
      return {
        ...state,
        deletingItem: payload.item
      }
    case SET_UPDATING_WORKFLOW:
      return {
        ...state,
        updatingItem: payload.item
      }
    case TOGGLE_CREATE_WORKFLOW_MODAL:
      return {
        ...state,
        creatingItem: !state.creatingItem
      }
    default:
      return state
    }
}
