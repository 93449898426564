import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'src/Components/Select'
import { ExportToCsv } from 'export-to-csv'
import Papa from 'papaparse'
import { translate } from 'src/Services/translation'
import { setOptions } from 'src/Layouts/View/state/actions'
import { Badge, Button, Col, Container, Form, Row } from 'react-bootstrap'
import { getFieldsFromForm } from 'src/Utils/FormUtils'
import { showError } from 'src/Services/notifier/actions'
import ValidateUserIdentityModal from 'src/Components/ValidateUserIdentityModal/index'
import { formatDateTime } from 'src/Utils/Date.ts'
import { Locale } from 'src/Types/Locale'
import translation from './translations'
import { fetchForm, sendImport, setForm } from './state/actions'
import { FIELD_BASE_TYPE_DATE_TIME } from '../../Services/Constants'

export const STORE = 'ImportInstances'

export const IMPORT_TASK_STATUS_STARTED = 'started'
export const IMPORT_TASK_STATUS_PENDING = 'pending'
export const IMPORT_TASK_STATUS_SUCCEEDED = 'succeeded'
export const IMPORT_TASK_STATUS_FAILED = 'failed'

const statusMapping = {
  [IMPORT_TASK_STATUS_SUCCEEDED]: IMPORT_TASK_STATUS_SUCCEEDED,
  [IMPORT_TASK_STATUS_FAILED]: IMPORT_TASK_STATUS_FAILED,
  [IMPORT_TASK_STATUS_PENDING]: IMPORT_TASK_STATUS_PENDING,
  default: IMPORT_TASK_STATUS_FAILED,
}

export const getMessageStatus = message => {
  const status = message.status || ''
  return statusMapping[status] || statusMapping.default
}

const ImportInstances = () => {
  const dispatch = useDispatch()
  const { forms } = useSelector(state => state.Dictionary)
  const { language, hasPin, locale, portalTimezone } = useSelector(state => state.Root.user)
  const trans = translate(translation)(language)

  const { form } = useSelector(state => state[STORE])

  const [ items, setItems ] = useState([])
  const [ unrecognisedHeaders, setUnrecognisedHeaders ] = useState([])

  const [ userPinModalOpen, setUserPinModalOpen ] = useState(false)
  const [ userPin, setUserPin ] = useState(null)

  useEffect(() => {
    dispatch(setOptions({
      hasPrimaryButton: false
    }))
  }, [ dispatch ])

  const onFormChange = form => {
    reset()

    form?.id
      ? dispatch(fetchForm(form.id))
      : dispatch(setForm(null))
  }

  const getHeadersAvailable = () => [
    'locationId', 'patientId',
    ...getFieldsFromForm(form).map(field => field.systemName)
  ]

  const downloadFormFieldsCsv = () => {
    const fileName = `${ form.systemName }`

    const data = getHeadersAvailable().reduce((results, header) => ({ ...results, [header]: '' }), {})

    const options = {
      filename: fileName,
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      useKeysAsHeaders: true
    }

    const csvExporter = new ExportToCsv(options)
    csvExporter.generateCsv([ data ])
  }

  const parseValue = (value, field = null) => {
    if (!field) return value

    /*if (field.type.baseFieldType === FIELD_BASE_TYPE_DATE_TIME) {
      try {
        return formatDateTime(locale, portalTimezone)(value)
      } catch (e) {
        console.error(e)
        dispatch(showError(trans('dateTimeParseError')))
        return null
      }
    }*/

    return value
  }

  // Removes empty columns and rows
  const parseImportItems = items =>
    items.reduce((results, item) => {
      const keys = Object.keys(item).filter(k => k)

      if (!keys.length)
        return results

      const result = {}
      for (const key of keys) {

        const field = getFieldsFromForm(form).find(f => f.systemName === key)

        if (getHeadersAvailable().some(h => h === key))
          result[key] = parseValue(item[key], field)
        else if (!unrecognisedHeaders.some(h => h === key))
          setUnrecognisedHeaders(headers => [ ...headers, key ])
      }

      return [ ...results, result ]
    }, [])

  const onFileInputChange = async e => {
    reset()

    const file = e.target.files[0]
    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      complete: ({ data }) => {
        setItems(parseImportItems(data))
      },
      error: err => {
        console.error(err)
        dispatch(showError(trans('parsingError')))
      }
    })
  }

  const submitImport = () => {

    if (hasPin && !userPin) {
      setUserPinModalOpen(true)
      return
    }

    dispatch(sendImport(form.id, items, userPin))
  }

  const reset = () => {
    setItems([])
    setUnrecognisedHeaders([])
  }

  return <Container>
    <Row className={ 'mb-2' }>
      <Col sm={ 4 }>
        <Form.Group>
          <Form.Label>{ trans('targetForm') }</Form.Label>
          <Select options={ forms } getOptionLabel={ f => f.systemName } getOptionValue={ f => f.id }
            onChange={ onFormChange }/>
        </Form.Group>
      </Col>
    </Row>

    { form && <><Row className={ 'mb-2' }>
      <Col className={ 'blue-card' } sm={ 6 }>
        <span className={ 'text-bold' }>{ trans('fieldsAvailable') } :</span><br/>
        { getHeadersAvailable().map(h =>
          <Badge bg={ 'secondary' } key={ h } className={ 'me-1' }>{ h }</Badge>) }
      </Col>
      <Col sm={ 1 }>
        <Button variant={ 'info' } onClick={ downloadFormFieldsCsv } title={ trans('downloadHeaderButton') }>
          <i className={ 'me-1 fas fa-download' }/>
        </Button>
      </Col>
    </Row>
    <Row className={ 'mb-2' }>
      <Col sm={ 10 }>
        <p className={ 'm-0' }>
          <span className={ 'text-bold' }>{ trans('instructionsPrefix') } :</span>
            &nbsp;{ trans('instructions') }<br/>
          <span className={ 'text-bold' }>{ trans('dateTimeInstructionsPrefix') } :</span>
            &nbsp;{ locale === Locale.US
            ? <span><i>MM/DD/YYYY hh:mm A</i>, <i>MM/DD/YYYY</i>, <i>hh:mm A</i></span>
            : <span><i>DD/MM/YYYY HH:mm A</i>, <i>DD/MM/YYYY</i>, <i>HH:mm</i></span> }
        </p>
        <Form.Control type={ 'file' } className={ 'mt-2' } accept={ '.csv' } onChange={ onFileInputChange }/>
      </Col>
      { !!items.length && <Col className={ 'd-flex' }>
        <Button className={ 'align-self-end' }
          onClick={ submitImport }
          disabled={ !items.length || !Object.keys(items[0]).length }
        >
          { trans('submit') }
        </Button>
      </Col> }
    </Row>
    { !!items.length && <>
      { !!Object.keys(items[0]).length && <Row className={ 'mb-2' }>
        <Col sm={ 10 }>
          <span className={ 'text-bold' }>{ trans('fieldsFound') } :</span><br/>
          { Object.keys(items[0]).map(key =>
            <Badge bg={ 'primary' } key={ key } className={ 'me-1' }>{ key }</Badge>) }
        </Col>
      </Row> }
      { !!unrecognisedHeaders.length && <Row className={ 'mb-2' }>
        <Col sm={ 10 }>
          <span className={ 'text-bold' }>{ trans('fieldsUnrecognised') } :</span><br/>
          { unrecognisedHeaders.map((header, i) =>
            <Badge bg={ 'danger' } key={ i } className={ 'me-1' }>{ header }</Badge>) }
        </Col>
      </Row> }
    </> }
    </> }
    <ValidateUserIdentityModal isOpen={ userPinModalOpen }
      onClose={ e => setUserPinModalOpen(false) }
      onSubmit={ pin => {
        dispatch(sendImport(form.id, items, pin))
        setUserPin(pin)
      } }
    />
  </Container>
}

export default ImportInstances
