import * as type from 'src/Components/PatientCreator/state/actionTypes'
import Patient, { EditablePatient } from 'src/Types/Patient'
import { NavigateFunction } from 'react-router/dist/lib/hooks'

export const requestPatientCreation = (patient: EditablePatient) => ({
  type: type.REQUEST_PATIENT_CREATION,
  payload: { patient }
})
export const resetPatientCreation = () => ({
  type: type.RESET_PATIENT_CREATION,
  payload: { }
})
export const searchSimilarPatients = (patient: Patient, fullPatient: Patient, navigate: NavigateFunction) => ({
  type: type.SEARCH_SIMILAR_PATIENTS,
  payload: { patient, fullPatient, navigate }
})
export const setSimilarPatients = (patients: Patient[], targetPatient: Patient, navigate: NavigateFunction) => ({
  type: type.SET_SIMILAR_PATIENTS,
  payload: { patients, targetPatient, navigate }
})
export const createPatient = (patient: Patient, navigate: NavigateFunction) => ({
  type: type.CREATE_PATIENT,
  payload: { patient, navigate }
})
