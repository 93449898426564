export const SCHEDULED = 'SCHEDULED'
export const RESCHEDULED = 'RESCHEDULED'
export const NO_SHOW = 'NO_SHOW'
export const CANCELLED = 'CANCELLED'
export const COMPLETED = 'COMPLETED'
export const CONFIRMED = 'CONFIRMED'

export const STATUS = [
    SCHEDULED, RESCHEDULED, NO_SHOW, CANCELLED, COMPLETED, CONFIRMED
]

