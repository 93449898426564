import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Badge, Button, Container, Row, Col, Card, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import AuditTrailsModal from 'src/Components/AuditTrailsModal'
import { translate } from 'src/Services/translation'
import { setOptions } from 'src/Layouts/View/state/actions'
import { AuditTrailLogFields } from 'src/Types/AuditTrailLog'
import { ColumnValueType, FilterOperator } from 'src/Types/RequestFilter'
import { SortItemDirection } from 'src/Types/Pagination'
import { User } from 'src/Types/User'
import { hasUserModulePermission } from 'src/Utils'
import { MODULE_AUDIT_TRAILS } from 'src/Services/Constants/Config/Modules'
import { PermissionAccessLevel } from 'src/Types/Permission'
import { fetchUser, createUser, updateUser, deleteUser, setNewUser, setUser,
  reset, blockUser, unblockUser } from 'src/Views/UserForm/state/actions'
import { StoreState } from 'src/Services/Store/reducers'
import TextField from './Components/TextField'
import SwitchField from './Components/SwitchField'
import SelectField from './Components/SelectField'
import PhoneField from './Components/PhoneField'
import translation from './translations'

const initialFormValues: User = {
  id: null,
  email: '',
  firstName: '',
  lastName: '',
  username: '',
  confirmPassword: '',
  isProvider: false,
  locations: [],
  secondaryRoles: [],
  password: '',
  mobilePhone: '',
  pin: '',
  primaryRole: null
}

const UserForm = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()

  const { user: appUser } = useSelector((state: StoreState) => state.Root)
  const { user, newUser } = useSelector((state: StoreState) => state.UserForm)
  const { roles, locations } = useSelector((state: StoreState) => state.Dictionary)

  const trans = translate(translation)(appUser.language)

  const getAvailableRoles = () => {
    return newUser
      ? roles.filter(
        r => r.id !== newUser.primaryRole?.id &&
          !newUser?.secondaryRoles?.some(s => s.id === r.id)
      )
      : roles
  }

  const [ formErrors, setFormErrors ] = useState<any>({})
  const [ hasFormChange, setHasFormChange ] = useState(false)
  const [ isChangelogModalOpen, setIsChangelogModalOpen ] = useState(false)
  const [ invalid, setIsInvalid ] = useState(false)

  const onChangePhoneMobile = (value: string) => {
    dispatch(setNewUser({ ...newUser, mobilePhone: value }))

    if (value !== '' && !value.match(/^[0-9]{11,14}$/)) {
      setIsInvalid(true)
    } else {
      setIsInvalid(false)
    }
  }

  const changeLogFilter = useMemo(() => ({
    offset: 0,
    limit: 100,
    sortItems: [ { column: AuditTrailLogFields.TIMESTAMP, direction: SortItemDirection.DESC } ],
    filters: [
      {
        column: AuditTrailLogFields.CONTEXT,
        type: ColumnValueType.STRING,
        operator: FilterOperator.EQUAL,
        value: 'USER'
      },
      {
        column: AuditTrailLogFields.CONTEXT_IDENTIFIER,
        type: ColumnValueType.STRING,
        operator: FilterOperator.EQUAL,
        value: id
      },
    ]
  }), [ id ])

  useEffect(() => {
    if (id && !user)
      dispatch(fetchUser(id))

    else if (!id && !newUser) {
      dispatch(setNewUser(initialFormValues))
      dispatch(setUser(initialFormValues))
    }
  }, [ id, user ])

  // Component unmount
  useEffect(() =>
    () => {
      dispatch(reset())
    }
  , [])

  const validateForm = () => {
    let errors = {}
    setFormErrors(errors)

    if (!id && !newUser.password)
      errors = { ...errors, password: 'Password is required' }
    if (newUser.password !== newUser.confirmPassword)
      errors = { ...errors, confirmPassword: 'Field does not match password' }
    if (!newUser.firstName)
      errors = { ...errors, firstName: 'First name is required' }
    if (!newUser.lastName)
      errors = { ...errors, lastName: 'Last name is required' }
    if (!newUser.username)
      errors = { ...errors, username: 'Username is required' }
    if (!newUser.email)
      errors = { ...errors, email: 'Email is required' }
    if (!newUser.primaryRole?.id)
      errors = { ...errors, primaryRole: 'Primary role is required' }

    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  const saveUser = () =>
    dispatch(id ? updateUser(id, newUser, navigate) : createUser(newUser, navigate))

  const onClickSaveUser = () => validateForm() && saveUser()
  const onClickBlockUser = () => dispatch(blockUser(id))
  const onClickUnblockUser = () => dispatch(unblockUser(id))
  const onClickDeleteUser = () => dispatch(deleteUser(id, navigate))
  const onClickEditPermissions = () => navigate(`/permissions/user/${ id }`)

  const onChangelogClick = () => setIsChangelogModalOpen(true)
  const onModalChangelogClose = () => setIsChangelogModalOpen(false)

  const renderButtons = () =>
    <>
      <Button className={ 'me-1' } size={ 'sm' } variant={ 'danger' } disabled={ !id } onClick={ onClickDeleteUser }>
        <i className={ 'me-1 fas fa-trash-alt' }/>
        <span>{ trans('delete') }</span>
      </Button>
      <ButtonGroup className={ 'me-1' }>
        <Button className={ 'text-white' } size={ 'sm' } variant={ 'warning' } disabled={ !id }
          onClick={ onClickBlockUser }>
          <i className={ 'me-1 fas fa-lock' }/>
          <span>{ trans('block') }</span>
        </Button>
        <Button size={ 'sm' } variant={ 'outline-warning' } disabled={ !id } onClick={ onClickUnblockUser }>
          <i className={ 'me-1 fas fa-lock-open' }/>
          <span>{ trans('unblock') }</span>
        </Button>
      </ButtonGroup>
      <ButtonGroup className={ 'me-1' }>
        { hasUserModulePermission(appUser, MODULE_AUDIT_TRAILS, PermissionAccessLevel.READ) &&
          <Button variant={ 'secondary' } size={ 'sm' } onClick={ onChangelogClick }>
            <i className={ 'me-1 fas fa-history' }/>
            <span>{ trans('btnAuditLog') }</span>
          </Button> }
        <Button size={ 'sm' } disabled={ !id } variant={ 'outline-secondary' }
          onClick={ onClickEditPermissions }>
          <i className={ 'me-1 far fa-check-square' }/>
          <span>{ trans('editPermissions') }</span>
        </Button>
      </ButtonGroup>
      <OverlayTrigger
        placement={ 'bottom' }
        trigger={ [ 'hover', 'focus' ]  }
        overlay={ props => <Tooltip { ...props }>{ trans('unsavedData') }</Tooltip> }
      >
        <Button
          size={ 'sm' }
          variant={ !hasFormChange ? 'primary' : 'warning' }
          disabled={ !hasFormChange }
          onClick={ onClickSaveUser }
        >
          <i className={ 'me-1 far fa-save' }></i>
          <span>{ trans('save') }</span>
        </Button>
      </OverlayTrigger>
    </>

  useEffect(() => {
    dispatch(setOptions({
      title: id
        ? <>
          <h1>{ user?.username || '' }</h1>
          { user?.authInformations?.isBlocked &&
            <Badge bg={ 'danger' }>{ trans('blocked') }</Badge>
          }
        </> : trans('title.createUser'),
      hasPrimaryButton: false,
      rightHeaderTemplate: renderButtons()
    }))
  }, [ dispatch, id, user, hasFormChange, newUser ])


  const onFieldChange = (field: string, value: any) => {
    dispatch(setNewUser({ ...newUser, [field]: value }))
  }

  useEffect( () => {
    setHasFormChange(JSON.stringify(newUser) !==  JSON.stringify(user))
  }, [ newUser ])

  return <Container>
    <Card className={ 'user-form' }>
      <Card.Body>
        <Row className={ 'mb-2' }>
          <Col>
            <TextField
              label={ trans('firstName') }
              value={ newUser?.firstName }
              initialValue={ user?.firstName }
              name={ 'firstName' }
              onChange={ onFieldChange }
              error={ formErrors.firstName }
              required={ true }
            />
          </Col>
          <Col>
            <TextField
              label={ trans('lastName') }
              value={ newUser?.lastName }
              initialValue={ user?.lastName }
              name={ 'lastName' }
              onChange={ onFieldChange }
              error={ formErrors.lastName }
              required={ true }
            />
          </Col>
          <Col>
            <TextField
              label={ trans('username') }
              value={ newUser?.username }
              initialValue={ user?.username }
              name={ 'username' }
              onChange={ onFieldChange }
              error={ formErrors.username }
              required={ !id }
              disabled={ !!id }
            />
          </Col>
        </Row>
        <Row className={ 'mb-2' }>
          <Col md={ 4 }>
            <TextField
              type={ 'password' }
              label={ trans('fields.pin') }
              value={ newUser?.pin }
              initialValue={ user?.pin }
              name={ 'pin' }
              onChange={ onFieldChange }
              error={ formErrors.pin }
              maxLength={ 4 }
            />
          </Col>
          <Col md={ 4 }>
            <PhoneField
              label={ trans('fields.mobilePhone') }
              initialValue={ user?.mobilePhone }
              value={ newUser?.mobilePhone }
              onChange={ onChangePhoneMobile }
              inputClass={ 'form-control form-control-sm w-100' }
              isValid={ value => !invalid }
            />
          </Col>
        </Row>
        <Row className={ 'mb-2' }>
          <Col >
            <TextField
              type={ 'password' }
              label={ trans('fields.password') }
              value={ newUser?.password }
              initialValue={ user?.password }
              name={ 'password' }
              onChange={ onFieldChange }
              error={ formErrors.password }
              required={ !id }
            />
          </Col>
          <Col >
            <TextField
              type={ 'password' }
              label={ trans('fields.confirmPassword') }
              value={ newUser?.confirmPassword }
              initialValue={ user?.confirmPassword }
              name={ 'confirmPassword' }
              onChange={ onFieldChange }
              error={ formErrors.confirmPassword }
              required={ !id }
            />
          </Col>
        </Row>
        <Row className={ 'mb-2' }>
          <Col>
            <SwitchField
              label={ trans('fields.useTwoFactorAuth') }
              name={ 'useTwoFactor' }
              disabled
              initialValue={ user?.useTwoFactor || false }
              checked={ newUser?.useTwoFactor || false }
              onChange={ onFieldChange }
            />
          </Col>
          <Col>
            <SwitchField
              label={ trans('fields.isProvider') }
              name={ 'isProvider' }
              initialValue={ user?.isProvider || false }
              checked={ newUser?.isProvider || false }
              onChange={ onFieldChange }
            />
          </Col>
        </Row>
        <Row className={ 'mb-2' }>
          <Col>
            <TextField
              type={ 'email' }
              label={ trans('email') }
              value={ newUser?.email }
              initialValue={ user?.email }
              name={ 'email' }
              onChange={ onFieldChange }
              error={ formErrors.email }
              required={ true }
            />
          </Col>
          <Col>
            <SelectField
              label={ trans('fields.locations') }
              isMulti
              initialValue={ user?.locations }
              value={ newUser?.locations }
              name={ 'locations' }
              getOptionLabel={ _ => _.identityString }
              getOptionValue={ _ => _.id }
              options={ locations.filter(_ => null === _.deletedAt) }
              onChange={ onFieldChange }
              error={ formErrors.locations }
            />
          </Col>
        </Row>
        <Row className={ 'mb-2' }>
          <Col>
            <SelectField
              label={ trans('fields.primaryRole') }
              initialValue={ user?.primaryRole }
              value={ newUser?.primaryRole }
              name={ 'primaryRole' }
              getOptionLabel={ _ => _.name }
              getOptionValue={ _ => _.id }
              options={ getAvailableRoles() }
              onChange={ onFieldChange }
              error={ formErrors.primaryRole }
            />
          </Col>
          <Col>
            <SelectField
              label={ trans('fields.secondaryRoles') }
              isMulti
              initialValue={ user?.secondaryRoles }
              value={ newUser?.secondaryRoles }
              name={ 'secondaryRoles' }
              getOptionLabel={ _ => _.name }
              getOptionValue={ _ => _.id }
              options={ getAvailableRoles() }
              onChange={ onFieldChange }
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
    { id && newUser && <Row className={ 'mt-1' }>
      <Col>
        <span className={ 'fw-bold' }>
          { trans('linkTo') } :
        </span>
        { newUser.personInstance &&
          <Link className={ 'ms-1' } to={ `/instance/${ newUser.personInstance.id }` }>
            <Badge bg={ 'success' }>{ trans('linkToPerson') }</Badge>
          </Link>
        }
        { newUser.primaryRoleInstance &&
          <Link className={ 'ms-1' } to={ `/instance/${ newUser.primaryRoleInstance.id }` }>
            <Badge bg={ 'primary' }>
              { trans('linkToPrimaryRoleInstance') } : <i>{ newUser.primaryRoleInstance.identityString }</i>
            </Badge>
          </Link> }
        { newUser.secondaryRolesInstances?.filter(i => i.id !== newUser.primaryRoleInstance?.id).map(instance =>
          <Link className={ 'ms-1' } key={ instance.id } to={ `/instance/${ instance.id }` }>
            <Badge bg={ 'secondary' }>
              { trans('linkToSecondaryRoleInstance') } : <i>{ instance.identityString }</i>
            </Badge>
          </Link>) }
      </Col>
    </Row> }
    { id &&
      <AuditTrailsModal
        isOpen={ isChangelogModalOpen }
        onClose={ onModalChangelogClose }
        filter={ changeLogFilter }
      />
    }
  </Container>
}

export default UserForm
