import React, { useState } from 'react'
import { Form as BForm, Button, Row, Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import Select from 'src/Components/Select'
import LocalizedDatepicker from 'src/Components/LocalizedDatepicker'
import { ListType } from 'src/Types/List'
import SystemNameInput from '../../../Components/SystemNameInput'
import { translateConf } from '../../../Services/translation/index'

const Form = ({ trans, onSubmit = () => {}, item = null }) => {
  const { configTranslations } = useSelector(state => state.Root)
  const { forms, lists } = useSelector(state => state.Dictionary)

  const [ resources, setResources ] = useState(item?.resources || [])
  const [ defaultStartTime, setDefaultStartTime ] = useState(item?.defaultStartTime)

  const [ label, setLabel ] = React.useState(null)
  const [ isAvailable, setIsAvailable ] = useState(null)

  const transConf = translateConf(configTranslations)

  const onChangeResources = resources => setResources(resources)
  const onChangeDefaultStartTime = defaultStartTime => {
    setDefaultStartTime(defaultStartTime)
  }

  const onFormSubmit = e => {
    e.preventDefault()

    const eventType = {
      ...item,
      label: e.target.label.value,
      defaultStartTime: defaultStartTime,
      defaultDuration: parseInt(e.target.defaultDuration.value, 10) || null,
      form: e.target.form.value || null,
      list: e.target.list.value || null,
      isSlotRequired: e.target.isSlotRequired.checked,
      resources: resources.map(_ => _.id)
    }

    if (!item?.systemName)
      eventType.systemName = e.target.systemName.value

    onSubmit(eventType)
    setLabel(null)
    setIsAvailable(null)
  }

  const onSystemNameChange = (systemName, isAvailable) => {
    setIsAvailable(isAvailable)
  }

  return (
    <BForm onSubmit={ onFormSubmit }>
      { item?.systemName && <small className={ 'text-muted' }>{ trans('systemName') } : { item.systemName }</small> }
      <Row className={ 'mb-2' }>
        <Col>
          <BForm.Group>
            <BForm.Label>{ trans('form.label') }</BForm.Label>
            <BForm.Control name={ 'label' } defaultValue={ item?.label || '' } placeholder={ trans('form.label') }
              onChange={ e => setLabel(e.target.value) } required/>
          </BForm.Group>
        </Col>
        { !item?.systemName &&
          <Col>
            <BForm.Group>
              <BForm.Label>{ trans('form.systemName') }</BForm.Label>
              <SystemNameInput label={ label } model={ 'EventType' } placeholder={ trans('form.systemName') }
                required onChange={ onSystemNameChange } name={ 'systemName' }/>
            </BForm.Group>
          </Col> }
      </Row>
      <Row className={ 'mb-2' }>
        <Col>
          <BForm.Group>
            <BForm.Label>{ trans('form.defaultStartTime') }</BForm.Label>
            <LocalizedDatepicker id={ 'defaultStartTime' }
              name={ 'defaultStartTime' }
              size={ 'md' }
              showTimeOnly
              timeIntervals={ 5 }
              timeCaption={ 'Time' }
              selected={ defaultStartTime }
              onChange={ onChangeDefaultStartTime }
              portalId={ 'root' }
              required
            />
          </BForm.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={ 3 }>
          <BForm.Group>
            <BForm.Label>{ trans('form.defaultDuration') }</BForm.Label>
            <BForm.Control defaultValue={ item?.defaultDuration || 60 } name={ 'defaultDuration' } required
              type={ 'number' }
              min={ 5 } step={ 5 } placeholder={ trans('form.defaultDuration') }/>
          </BForm.Group>
        </Col>
        <Col sm={ 3 } className={ 'd-flex' }>
          <BForm.Switch defaultChecked={ item?.isSlotRequired } className={ 'align-self-end' }
            name={ 'isSlotRequired' } label={ trans('form.isSlotRequired') }/>
        </Col>
      </Row>
      <Row className={ 'mb-2' }>
        <Col>
          <BForm.Group>
            <BForm.Label>{ trans('form.form') }</BForm.Label>
            <BForm.Select name={ 'form' } required defaultValue={ item?.form?.id }>
              <option value={ null }></option>
              { forms.map(form => <option key={ form.id } value={ form.id }>{ transConf('FORM')(form) }</option>) }
            </BForm.Select>
          </BForm.Group>
        </Col>
        <Col>
          <BForm.Group>
            <BForm.Label>{ trans('form.list') }</BForm.Label>
            <BForm.Select name={ 'list' } defaultValue={ item?.list?.id }>
              <option value={ '' }>{ trans('none') }</option>
              { lists.filter(list => list.type === ListType.CALENDAR).map(list => <option key={ list.id } value={ list.id }>{ transConf('LIST')(list) }</option>) }
            </BForm.Select>
          </BForm.Group>
        </Col>
      </Row>

      <Row className={ 'mb-2' }>
        <Col>
          <BForm.Group className={ 'grown' }>
            <BForm.Label htmlFor={ 'resources' }>{ trans('form.resources') }</BForm.Label>
            <Select isMulti
              id={ 'resources' }
              name={ 'resources' }
              buttonContent={ trans('title') }
              options={ forms.filter(_ => _.isResource) }
              getOptionLabel={ o => transConf('FORM')(o) }
              getOptionValue={ o => o.id }
              defaultValue={ resources }
              onChange={ onChangeResources }
            />
          </BForm.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Button variant={ 'primary' } style={ { float: 'right' } }
            disabled={ !item && !isAvailable } type={ 'submit' }>
            { trans('submit') }
          </Button>
        </Col>
      </Row>
    </BForm>
  )
}

export default Form
