import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FieldPermissionAccessLevel } from 'src/Types/FieldPermission'
import { Field } from 'src/Types/Field'
import File from 'src/Types/File'
import FileConstraint from 'src/Types/FieldOption/FileConstraint'
import FilesUploader from 'src/Components/FilesUploader'
import { fieldEvent } from 'src/Views/FormFiller/state/actions'
import { FieldEvent } from 'src/Views/FormFiller/Types/Field'
import { StoreState } from 'src/Services/Store/reducers'
import ValidationTrigger from 'src/Components/ValidationTrigger/index'
import FileDetails from './Components/FileDetails'

interface Props {
  field: Field,
  isRequired: boolean
  isDisabled: boolean
}

const FileField = ({ field, isRequired = false, isDisabled = false }: Props) => {
  const dispatch = useDispatch()

  const { userCode, openedInstance } = useSelector((state: StoreState) => state.FormFiller)
  const { id, options } = field

  const getFileTypes = (): FileConstraint[] => options?.fileTypes || []

  const getValue = (): File[] => (field.value as File[] | null) || []

  const getNbOfFilesOfFiletype = (type: string | string[]) =>
      getValue().filter((f: File) => {
        if (Array.isArray(type)) {
          // Vérifier si le type de fichier ou l'extension de fichier correspond à l'un des éléments dans le tableau
          return type.some(t => f.fileType === t || f.extension === t)
        } else {
          // Sinon, traiter le type comme une chaîne unique
          return f.fileType === type || f.extension === type
        }
      }).length

  const isFieldMinimalsCompleted = () => getFileTypes().some(constraint => {
    const filesCount = getNbOfFilesOfFiletype(constraint.fileType)
    return filesCount >= constraint.min
  })

  const canUpdateFiles = () => field.accessLevel >= FieldPermissionAccessLevel.UPDATE
  const canAddFiles = () => field.accessLevel >= FieldPermissionAccessLevel.CREATE
  const canDeleteFiles = () => field.accessLevel >= FieldPermissionAccessLevel.DELETE

  const isValid = () => !isRequired || !getFileTypes()?.length || isFieldMinimalsCompleted()

  const onFilesAdded = (files: File[]) =>
      dispatch(fieldEvent(field, FieldEvent.VALUE_CHANGE, { value: [ ...getValue(), ...files ] }))

  return <div className={ `blue-card ${ isValid() ? '' : 'is-invalid' }` }>
    { !isDisabled && canAddFiles() &&
        <FilesUploader constraints={ getFileTypes() }
                       onFilesAdded={ onFilesAdded }
                       defaultFiles={ getValue() }
                       isRequired={ isRequired }
                       instanceId={ openedInstance.id }
                       fieldId={ id }
                       userCode={ userCode }
                       dashboardOptions={ { height: 120 } }
        /> }
    { getValue().map((file: File) =>
                        <FileDetails key={ file.id } field={ field } file={ file }
                                     canUpdateFile={ canUpdateFiles() }
                                     canDeleteFile={ canDeleteFiles() }
                                     isDisabled={ isDisabled }
                        />) }
    <ValidationTrigger isRequired={ isRequired } isValid={ isValid() }/>
  </div>
}

export default FileField
