import React  from 'react'
import { useSelector } from 'react-redux'
import Select from 'src/Components/Select'
import { Button, Col, Form, ListGroup, Row, Spinner } from 'react-bootstrap'
import { translate } from 'src/Services/translation'
import translation from './translations'
import Column from './Components/Column'
import ColumnForm from './Components/ColumnForm'

const FastListCreator = ({ form, isSaving, onSubmit }) => {
  const { forms } = useSelector(state => state.Dictionary)
  const { language } = useSelector(state => state.Root.user)

  const [ refForm, setRefForm ] = React.useState(null)
  const [ columns, setColumns ] = React.useState([])
  const [ showForm, setShowForm ] = React.useState(false)

  const [ label, setLabel ] = React.useState(`Reference ${ Date.now() }`)
  const [ systemName, setSystemName ] = React.useState(`REFERENCE_${ Date.now() }`)

  const trans = translate(translation)(language)

  const reset = () => {
    setColumns([])
    setShowForm(false)
    setRefForm(null)
    setLabel(`Reference ${ Date.now() }`)
    setSystemName(`REFERENCE_${ Date.now() }`)
  }

  const onRefFormSelected = refForm => {
    setRefForm(refForm)
    setColumns([])
  }

  const onDeleteColumnClick = column => () =>
    setColumns(columns.filter(c => c !== column))

  const onSubmitColumn = column => {
    setColumns([ ...columns, column ])
    setShowForm(false)
  }

  const onSaveClick = () => {
    const list = {
      form, label, systemName, refForm, columns
    }

    onSubmit(list)
    reset()
  }

  return <>
    <Row>
      <Col>
        <small className="text-muted">
          { trans('label') } : { label } | { trans('systemName') } : { systemName }
        </small>
      </Col>
    </Row>

    <Row>
      <Col>
        <Form.Group className="mb-2">
          <Form.Label className="fw-bold m-0">{ trans('refForm') }</Form.Label>
          <Select value={ refForm } name={ 'refForm' } options={ forms }
                  onChange={ onRefFormSelected } required
                  getOptionValue={ o => o.id }/>
        </Form.Group>
      </Col>
    </Row>

    { refForm && <div className="mb-2">
      <Form.Label className="fw-bold m-0">{ trans('columns') }</Form.Label>
      <ListGroup as={ 'ol' } className={ 'mb-2' }>
        { columns.map((column, index) =>
          <Column column={ column } key={ index } trans={ trans } onDelete={ onDeleteColumnClick(column) }/>) }
        <ListGroup.Item as={ 'li' } onClick={ e => setShowForm(true) }
                        className="d-flex justify-content-between cursor-pointer bg-primary text-white">
          <strong>{ trans('addColumn') }</strong>
          <i className="fas fa-lg fa-plus-circle"/>
        </ListGroup.Item>
      </ListGroup>

      { showForm && <ColumnForm refForm={refForm} list={ { label, systemName } }
                                trans={ trans } onSubmit={ onSubmitColumn }/> }
    </div> }

    <Button disabled={ isSaving || !columns.length }
            onClick={ onSaveClick }
    >
      { isSaving && <Spinner animation="border" className="m-auto" size="md" /> }
      { trans('submit') }
    </Button>
  </>
}

export default FastListCreator
