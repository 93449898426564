import React, { ChangeEvent, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { FieldEvent } from 'src/Views/FormFiller/Types/Field'
import { useDispatch } from 'react-redux'
import { Field } from 'src/Types/Field'
import { fieldEvent } from '../../state/actions'

interface Props {
  field: Field,
  isRequired: boolean
  isDisabled: boolean
}

const EssayField = ({ field = null, isDisabled = false, isRequired = false }: Props) => {
  const dispatch = useDispatch()

  const [ changeTimeout, setChangeTimeout ] = React.useState(null)
  const [ localValue, setLocalValue ] = React.useState<string | null>(field.value as string)

  useEffect(() => {
    setLocalValue(field.value as string)
  }, [ field.value ])

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.target.value)
    clearTimeout(changeTimeout)
    // to avoid trigerring event on each key press
    setChangeTimeout(setTimeout(() => {
      dispatch(fieldEvent(field, FieldEvent.VALUE_CHANGE, { value: e.target.value }))
    }, 500))
  }

  return (
    <Form.Control
      size={ 'sm' }
      as={ 'textarea' }
      id={ `field${ field.id }` }
      value={ localValue || '' }
      onChange={ onChange }
      disabled={ isDisabled }
      required={ isRequired }
    />
  )
}

export default EssayField
