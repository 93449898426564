export default {
  english: {
    title: 'Tasks Categories',
    addNew: 'add a new task',
    form: {
      name: 'Name',
      form: 'Form',
    },
    modal: {
      create: {
        title: 'Create a new task'
      },
      update: {
        title: 'Update a task'
      }
    },
    confirmDeleteModal: {
      title: 'Delete task',
      description: 'Are you sure you want to delete this task ?'
    }
  },
  french: {
    title: 'Catégories de Tâche',
    addNew: 'Ajouter une nouvelle task',
    form: {
      name: 'Nom',
      form: 'Formulaire',
    },
    modal: {
      create: {
        title: 'Créer une nouvelle tâche'
      },
      update: {
        title: 'Éditer une tâche'
      }
    },
    confirmDeleteModal: {
      title: 'Supprimer la tâche',
      description: 'Êtes-vous sûr de supprimer cette tâche ?'
    }
  }
}
