import React from 'react'
import { useSelector } from 'react-redux'
import Select from 'src/Components/Select'
import { Form } from 'react-bootstrap'
import { StoreState } from 'src/Services/Store/reducers'
import { translate } from 'src/Services/translation'
import LabelWithWarning from './LabelWithWarning'

interface Props {
  label: string
  isMulti?: boolean
  initialValue: any
  value: any
  name: string
  getOptionLabel: (instance: any) => any
  getOptionValue: (instance: any) => any
  options: any
  onChange: (name: string, value: any) => void
  error?: string
}

const SelectField = ({
  label, isMulti = false, initialValue = [], value = [], name,
  getOptionLabel, getOptionValue, options, onChange, error
}: Props) => {
  const { user } = useSelector((state: StoreState) => state.Root)
  const trans = translate({})(user.language)

  const hasFieldChanged = JSON.stringify(initialValue) !== JSON.stringify(value)

  return (
    <Form.Group className={ 'select-field' }>
      <LabelWithWarning
        label={ label }
        warningText={ trans('unsaved') }
        isWarningShown={ hasFieldChanged }
      />
      <Select
        isMulti={ isMulti }
        value={ value }
        name={ name }
        getOptionLabel={ getOptionLabel }
        getOptionValue={ getOptionValue }
        // Not very good to get deleted fields in frontend, done it to avoid side effect
        //TODO filter it in backend after checking that it dos not cause any side effect
        options={ options }
        onChange={ value => onChange(name, value) }
      />
      <Form.Text className={ 'text-danger' }>
        { error }
      </Form.Text>
    </Form.Group>
  )
}

export default SelectField
