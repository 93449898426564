export enum Action {
  START_LOADING = 'START_LOADING',
  STOP_LOADING = 'STOP_LOADING',
  SHOW_INFO = 'SHOW_INFO',
  HIDE_INFO = 'HIDE_INFO',
  CHANGE_VALUE = 'CHANGE_VALUE',
  RESET_VALUE = 'RESET_VALUE',
  HIDE = 'HIDE',
  SHOW = 'SHOW',
  DISABLE = 'DISABLE',
  ENABLE = 'ENABLE',
  SET_VALUE = 'SET_VALUE',
  SET_VALUE_BY_AVAILABILITY = 'SET_VALUE_BY_AVAILABILITY',
}

export enum NonScriptAction {
  HIDE = 'HIDE',
  SHOW = 'SHOW',
  DISABLE = 'DISABLE',
  ENABLE = 'ENABLE',
  SET_VALUE = 'SET_VALUE',
  SET_VALUE_BY_AVAILABILITY = 'SET_VALUE_BY_AVAILABILITY',
}

export interface ActionOptions {
  value?: any,
  type?: any,
  variant?: string
}

export enum FieldEvent {
  VALUE_CHANGE = 'VALUE_CHANGE',
  CLICK = 'CLICK',
}

export interface FieldEventData {
  target?: any,
  value?: any,
}
