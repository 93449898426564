export const LOAD_FORM = '[FORM_EDITOR] LOAD_FORM'
export const RECEIVE_FORM = '[FORM_EDITOR] RECEIVE_FORM'
export const FETCH_FORM_FAILED = '[FORM_EDITOR] FETCH_FORM_FAILED'
export const SET_SYSTEM_FIELDS = '[FORM_EDITOR] SET_SYSTEM_FIELDS'
export const TOGGLE_DETAILS_MODAL = '[FORM_EDITOR] TOGGLE_DETAILS_MODAL'
export const TOGGLE_SWP_CONFIG_MODAL = '[FORM_EDITOR] TOGGLE_SWP_CONFIG_MODAL'
export const TOGGLE_MS_SANTE_CONFIG_MODAL = '[FORM_EDITOR] TOGGLE_MS_SANTE_CONFIG_MODAL'
export const TOGGLE_RD_CONFIG_MODAL = '[FORM_EDITOR] TOGGLE_RD_CONFIG_MODAL'
export const UPDATE_DETAILS = '[FORM_EDITOR] UPDATE_DETAILS'
export const DETAILS_UPDATED = '[FORM_EDITOR] DETAILS_UPDATED'
export const DETAILS_NOT_UPDATED = '[FORM_EDITOR] DETAILS_NOT_UPDATED'
export const TOGGLE_SCRIPT_DIALOG = '[FORM_EDITOR] TOGGLE_SCRIPT_DIALOG'
export const UPDATE_SCRIPT = '[FORM_EDITOR] UPDATE_SCRIPT'
export const SCRIPT_UPDATED = '[FORM_EDITOR] SCRIPT_UPDATED'
export const SCRIPT_NOT_UPDATED = '[FORM_EDITOR] SCRIPT_NOT_UPDATED'
export const DRAGGING_STOPPED = '[FORM_EDITOR] DRAGGING_STOPPED'
export const SET_DRAGGED_FIELD_TYPE = '[FORM_EDITOR] SET_DRAGGED_FIELD_TYPE'
export const SET_DRAGGED_EXISTING_FIELD = '[FORM_EDITOR] SET_DRAGGED_EXISTING_FIELD'
export const SET_IS_EDITING_TITLE_TEMPLATE = '[FORM_EDITOR] SET_IS_EDITING_TITLE_TEMPLATE'
export const MOVE_FIELD_TO_ANOTHER_PLACE = '[FORM_EDITOR] MOVE_FIELD_TO_ANOTHER_PLACE'
export const FIELD_MOVED = '[FORM_EDITOR] FIELD_MOVED'
export const SET_TARGET_FIELD = '[FORM_EDITOR] SET_TARGET_FIELD'
export const SET_DRAGGED_ROW = '[FORM_EDITOR] SET_DRAGGED_ROW'
export const REORDER_ROW = '[FORM_EDITOR] REORDER_ROW'
export const ROW_REORDERED = '[FORM_EDITOR] ROW_REORDERED'
export const REQUEST_ADD_FIELD = '[FORM_EDITOR] REQUEST_ADD_FIELD'
export const REQUEST_ADD_REFERENCE_FIELD = '[FORM_EDITOR] REQUEST_ADD_REFERENCE_FIELD'
export const ADD_FIELD = '[FORM_EDITOR] ADD_FIELD'
export const FIELD_ADDED = '[FORM_EDITOR] FIELD_ADDED'
export const ERROR_ADDING_FIELD = '[FORM_EDITOR] ERROR_ADDING_FIELD'
export const ADD_ROW = '[FORM_EDITOR] ADD_ROW'
export const ERROR_ADDING_ROW = '[FORM_EDITOR] ERROR_ADDING_ROW'
export const ROW_ADDED = '[FORM_EDITOR] ROW_ADDED'
export const DELETE_ROW = '[FORM_EDITOR] DELETE_ROW'
export const ROW_DELETED = '[FORM_EDITOR] ROW_DELETED'
export const ERROR_DELETING_ROW = '[FORM_EDITOR] ERROR_DELETING_ROW'
export const DELETE_FIELD = '[FORM_EDITOR] DELETE_FIELD'
export const FIELD_DELETED = '[FORM_EDITOR] FIELD_DELETED'
export const ERROR_DELETING_FIELD = '[FORM_EDITOR] ERROR_DELETING_FIELD'
export const OPEN_EDIT_FIELD = '[FORM_EDITOR] OPEN_EDIT_FIELD'
export const RECEIVE_EDIT_FIELD = '[FORM_EDITOR] RECEIVE_EDIT_FIELD'
export const CLOSE_EDIT_FIELD = '[FORM_EDITOR] CLOSE_EDIT_FIELD'
export const UPDATE_FIELD = '[FORM_EDITOR] UPDATE_FIELD'
export const FIELD_UPDATED = '[FORM_EDITOR] FIELD_UPDATED'
export const ERROR_UPDATING_FIELD = '[FORM_EDITOR] ERROR_UPDATING_FIELD'
export const UPDATE_SECTION = '[FORM_EDITOR] UPDATE_SECTION'
export const DELETE_SECTION = '[FORM_EDITOR] DELETE_SECTION'
export const ERROR_UPDATING_SECTION = '[FORM_EDITOR] ERROR_UPDATING_SECTION'
export const ERROR_DELETING_SECTION = '[FORM_EDITOR] ERROR_DELETING_SECTION'
export const SECTION_UPDATED = '[FORM_EDITOR] SECTION_UPDATED'
export const SECTION_DELETED = '[FORM_EDITOR] SECTION_DELETED'
export const SAVE_FORM = '[FORM_EDITOR] SAVE_FORM'
export const FORM_SAVED = '[FORM_EDITOR] FORM_SAVED'
export const ERROR_SAVING_FORM = '[FORM_EDITOR] ERROR_SAVING_FORM'
export const RESET_FORM_SAVE = '[FORM_EDITOR] RESET_FORM_SAVE'
export const UNDO_CHANGES = '[FORM_EDITOR] UNDO_CHANGES'
export const UNDO_CHANGES_FAILED = '[FORM_EDITOR] UNDO_CHANGES_FAILED'
export const SET_REF_FIELD_MODAL_OPENED = '[FORM_EDITOR] SET_REF_FIELD_MODAL_OPENED'
export const ADD_REFERENCE_FIELD = '[FORM_EDITOR] ADD_REFERENCE_FIELD'
export const SET_SAVING_REF_FIELD = '[FORM_EDITOR] SET_SAVING_REF_FIELD'
export const SET_CURRENT_DELETED_OPTION = '[FORM_EDITOR] SET_CURRENT_DELETED_OPTION'
export const ADD_FULL_ROW_FIELD = '[FORM_EDITOR] ADD_FULL_ROW_FIELD'
export const ADD_FIELD_IN_EXTENSIBLE = '[FORM_EDITOR] ADD_FIELD_IN_EXTENSIBLE'
export const SCROLL_EXTENSIBLE_FIELD = '[FORM_EDITOR] SCROLL_EXTENSIBLE_FIELD'
export const SET_EXTENSIBLE_SCROLL_BUTTONS = '[FORM_EDITOR] SET_EXTENSIBLE_SCROLL_BUTTONS'
export const INIT_EXTENSIBLE_SCROLL_BUTTONS = '[FORM_EDITOR] INIT_EXTENSIBLE_SCROLL_BUTTONS'
export const SET_NEW_FULL_ROW_FIELD_DETAILS = '[FORM_EDITOR] SET_NEW_FULL_ROW_FIELD_DETAILS'
export const OPEN_FULL_ROW_MODAL = '[FORM_EDITOR] OPEN_FULL_ROW_MODAL'
export const CLOSE_FULL_ROW_MODAL = '[FORM_EDITOR] CLOSE_FULL_ROW_MODAL'
export const SORT_INSIDE_AN_EXTENSIBLE = '[FORM_EDITOR] SORT_INSIDE_AN_EXTENSIBLE'
export const UPDATE_EXTENSIBLE_FIELDS_ORDER = '[FORM_EDITOR] UPDATE_EXTENSIBLE_FIELDS_ORDER'

export const UPDATE_FIELD_OPTION    = '[FORM_EDITOR] UPDATE_FIELD_OPTION'

export const TOGGLE_FIELD_DETAILS_MODAL                 = '[FORM_EDITOR] TOGGLE_FIELD_DETAILS_MODAL'
export const TOGGLE_FIELD_VALUES_MODAL                  = '[FORM_EDITOR] TOGGLE_FIELD_VALUES_MODAL'
export const TOGGLE_ADVANCED_FIELD_CONFIG_MODAL           = '[FORM_EDITOR] TOGGLE_ADVANCED_FIELD_CONFIG_MODAL'
export const TOGGLE_CALCULATION_FIELD_MODAL             = '[FORM_EDITOR] TOGGLE_CALCULATION_FIELD_MODAL'
export const TOGGLE_CUSTOMIZATION_ORDERS_MODAL          = '[FORM_EDITOR] TOGGLE_CUSTOMIZATION_ORDERS_MODAL'

export const SET_IS_CUSTOMIZATION_ORDER_LOADING = '[FORM_EDITOR] SET_IS_CUSTOMIZATION_ORDER_LOADING'
export const CREATE_CUSTOMIZATION_ORDER         = '[FORM_EDITOR] CREATE_CUSTOMIZATION_ORDER'
export const UPDATE_CUSTOMIZATION_ORDER         = '[FORM_EDITOR] UPDATE_CUSTOMIZATION_ORDER'
export const DELETE_CUSTOMIZATION_ORDER         = '[FORM_EDITOR] DELETE_CUSTOMIZATION_ORDER'

export const REPLACE_FIELD_OPTION_VALUES  = '[FORM_EDITOR] REPLACE_FIELD_OPTION_VALUES'

