import OpenedInstance from 'src/Views/FormFiller/Types/OpenedInstance'
import SystemField from 'src/Types/SystemField'
import { Field } from 'src/Types/Field'

// @ts-ignore
export const getId = (entity: object) => entity?.id || null

// It's more "get sys field without references" ?
export const getSystemFieldReferenceStartingBySystemName = (systemFields: SystemField[], systemName: string): SystemField[] => {
  const results: SystemField[] = []
  for (const field of systemFields)
    if (field.name?.startsWith(systemName) && null === field.referenceField)
      results.push(field)

  return results
}

export const getOpenedInstanceFieldsStartingBySystemName = (openedInstance: OpenedInstance, systemName: string): Field[] => {
  const results = []

  for (const [ key, field ] of Object.entries(openedInstance.fields))
    if (field?.systemName.startsWith(systemName))
      results.push(field)

  return results
}

export const getOpenedInstanceFieldById = (openedInstance: OpenedInstance, fieldId: string): Field | null => {
  for (const [ key, field ] of Object.entries(openedInstance.fields))
    if (field?.id === fieldId)
      return field

  return null
}

export const getSystemFieldsStartingByName = (systemFields: SystemField[], name: string, referenceName: string = null): SystemField[] =>
  systemFields.filter(sf =>
    sf.name?.startsWith(name) && (!referenceName || sf?.name.includes(referenceName)),
  )

export const getSystemFieldByName = (systemFields: SystemField[], name: string, referenceName: string = null): SystemField =>
  systemFields.find(sf =>
    sf.name === name && (!referenceName || sf?.name.includes(referenceName)),
  )
