import { List } from 'src/Types/List'
import NumericReport from 'src/Types/NumericReport'
import Role from 'src/Types/Role'
import { FormPermissionAccessLevel } from 'src/Types/FormPermission'
import { SectionPermissionAccessLevel } from 'src/Types/SectionPermission'
import { FieldPermissionAccessLevel } from 'src/Types/FieldPermission'

export interface GenericPermission {
  id: string | null
  accessLevel: PermissionAccessLevel | FormPermissionAccessLevel | SectionPermissionAccessLevel | FieldPermissionAccessLevel | null
}

export default interface Permission extends GenericPermission {
    accessLevel: PermissionAccessLevel | null
    list?: List | null
    numericReport?: NumericReport | null
    logAsRole?: Role | null
}

export enum PermissionAccessLevel {
    NONE = null,
    READ = 0,
    LIST = 1,
    UPDATE = 2,
    CREATE = 3,
    DELETE = 4,
    RESTORE = 5,
}
