import React, { FormEvent } from 'react'
import { useSelector } from 'src/Services/Store'
import { StoreState } from 'src/Services/Store/reducers'
import { translate } from 'src/Services/translation'
import translation from 'src/Views/Auth/translations'
import { Button, Form } from 'react-bootstrap'
import { getNonProdAuthToken } from 'src/Views/Auth/state/actions'
import { useDispatch } from 'react-redux'

interface EventForm extends HTMLFormElement {
  readonly elements: NonProdLoginFormElements
}

interface NonProdLoginFormElements extends HTMLFormControlsCollection {
  username: HTMLInputElement
  password: HTMLInputElement
}

const NonProdLogin = () => {
  const dispatch = useDispatch()

  const { user: { language } } = useSelector((state: StoreState) => state.Root)

  const trans = translate(translation)(language)

  const handleSubmit = (e: FormEvent<EventForm>) => {
    e.preventDefault()
    dispatch(getNonProdAuthToken(
        e.currentTarget.elements.username.value,
        e.currentTarget.elements.password.value,
    ))
  }

  return <Form onSubmit={handleSubmit}>
    <Form.Group className="mb-1">
      <Form.Label>{ trans('nonProdLogin.form.username.label') }</Form.Label>
      <Form.Control title={trans('nonProdLogin.form.username.title')}
                    name={'username'}
                    placeholder={trans('nonProdLogin.form.username.placeholder')}
                    required
      />
    </Form.Group>
    <Form.Group className="mb-2">
      <Form.Label>{ trans('nonProdLogin.form.password.label') }</Form.Label>
      <Form.Control title={trans('nonProdLogin.form.password.title')}
                    name={'password'}
                    type={'password'}
                    placeholder={trans('nonProdLogin.form.password.placeholder')}
                    required
      />
    </Form.Group>
    <Button type={ 'submit' }>{ trans('login') }</Button>
  </Form>
}

export default NonProdLogin
