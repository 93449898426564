import * as types from 'src/Components/SectionNavigator/state/actionTypes'
import { Action as ActionState } from 'src/Services/Store/reducers'

export interface SectionNavigatorState {
  sections: string[],
  activeSection: number
}

const initialState: SectionNavigatorState = {
  sections: [],
  activeSection: 0
}

export default (state = initialState, { type, payload }: ActionState) => {
  switch (type) {
    case types.SET_ACTIVE_SECTION:
      return {
        ...state,
        activeSection: payload.sectionIndex
      }
    case types.SET_SECTIONS:
      return {
        ...state,
        sections: payload.sectionNames
      }
    default:
      return state
  }
}
