import { Field, FieldOption, MultiOptionFieldType, SingleOptionFieldType } from 'src/Types/Field'

const forSingleOptionValue = (field: Field): FieldOption | null => {
  if (!(field.type.baseFieldType in SingleOptionFieldType))
    return null

  // We are not sure if the value will be in the correct format (FieldOption) or just the system name (string)
  const value = (field.value as FieldOption | string | null)

  return value ? field.options.values.find(o => (value as FieldOption).systemName === o.systemName || (value as string) === o.systemName) : null
}

const forMultiOptionValue = (field: Field): FieldOption[] => {
  if (!(field.type.baseFieldType in MultiOptionFieldType))
    return []

  // We are not sure if the value will be in the correct format (FieldOption) or just the system name (string)
  const value = (field.value as FieldOption[] | string[] | null)

  return value?.length > 0
    ? value.map(v => field.options.values.find(o => (v as FieldOption).systemName === o.systemName || (v as string) === o.systemName))
    : []
}

export { forSingleOptionValue, forMultiOptionValue }
