import * as type from 'src/Components/Calendar/state/actionTypes'
import { Iso8601 } from 'src/Types/Date'
import Slot, { SlotForApi, SlotSerie, SlotSerieForApi, SlotType } from 'src/Types/Slot'
import { Event, EventForApi } from 'src/Types/Event'
import { Instance } from 'src/Types/Instance'
import { SidebarTab } from 'src/Components/Calendar/Types/Calendar'
import CalendarFilter, { CalendarFilterName, CalendarFilterOption, CalendarFilterValues } from 'src/Types/CalendarFilter'
import { UuidV4 } from 'src/Types/Uuid'
import CalendarEvent from 'src/Components/Calendar/Types/CalendarEvent'
import slot from 'src/Types/Slot'

export const setCurrentStartDate = (startDate: Iso8601) => ({
  type: type.SET_CURRENT_START_DATE,
  payload: { startDate }
})

export const setCurrentEndDate = (endDate: Iso8601) => ({
  type: type.SET_CURRENT_END_DATE,
  payload: { endDate }
})

export const createSlotSerie = (slotSerie: SlotSerieForApi) => ({
  type: type.CREATE_SLOT_SERIE,
  payload: { slotSerie }
})

export const createSlot = (slot: SlotForApi) => ({
    type: type.CREATE_SLOT,
    payload: { slot }
})

export const setCreatingEvent = (event: Event) => ({
  type: type.SET_CREATING_EVENT,
  payload: { event }
})

export const createEvent = (event: EventForApi, patientId: UuidV4) => ({
  type: type.CREATE_EVENT,
  payload: { event, patientId }
})

export const eventCreated = (event: Event) => ({
  type: type.EVENT_CREATED,
  payload: { event }
})

export const slotSerieCreated = (slotSerie: SlotSerie) => ({
  type: type.SLOT_SERIE_CREATED,
  payload: { slotSerie }
})

export const slotCreated = (slot: slot) => ({
    type: type.SLOT_CREATED,
    payload: { slot }
})

export const setEditingEvent = (event: Event|null) => ({
  type: type.SET_EDITING_EVENT,
  payload: { event }
})

export const editEvent = (event: EventForApi, eventId: UuidV4, patientId: UuidV4) => ({
  type: type.EDIT_EVENT,
  payload: { event, eventId, patientId }
})

export const setDeletingEvent = (event: Event|null) => ({
  type: type.SET_DELETING_EVENT,
  payload: { event }
})

export const deleteEvent = () => ({
  type: type.DELETE_EVENT
})

export const eventDeleted = (event: Event) => ({
  type: type.EVENT_DELETED,
  payload: { event }
})

export const editSlot = (slot: SlotForApi) => ({
  type: type.EDIT_SLOT,
  payload: { slot }
})

export const setEditingSlot = (slot: Slot|null) => ({
  type: type.SET_EDITING_SLOT,
  payload: { slot }
})

export const setToggleIsCreatingASlotSerie = (isSlotSerie: boolean) => ({
    type: type.SET_TOGGLE_IS_CREATING_SLOT_SERIE,
    payload: { isSlotSerie }
})
export const setDeletingSlot = (slot: Slot|null) => ({
  type: type.SET_DELETING_SLOT,
  payload: { slot }
})

export const deleteSlot = () => ({
  type: type.DELETE_SLOT
})

export const slotDeleted = (slot: Slot) => ({
  type: type.SLOT_DELETED,
  payload: { slot }
})

export const fetchResources = (
    target: string|null = null,
    eventTypeId: UuidV4|null = null,
    locationId: UuidV4|null = null,
) => ({
  type: type.FETCH_RESOURCES,
  payload: { target, eventTypeId, locationId }
})

export const setSlotResources = (resources: Instance[]) => ({
  type: type.SET_SLOT_RESOURCES,
  payload: { resources }
})

export const setEventResources = (resources: Instance[]) => ({
  type: type.SET_EVENT_RESOURCES,
  payload: { resources }
})

export const setSlotFormModal = (isOpen: boolean, slotType: SlotType) => ({
  type: type.SET_SLOT_FORM_MODAL,
  payload: { slotType, isOpen }
})

export const getEvents = (start: Iso8601|null = null, end: Iso8601|null = null) => ({
  type: type.GET_EVENTS,
  payload: { start, end }
})

export const setEvents = (events: CalendarEvent[], start: Iso8601, end: Iso8601) => ({
  type: type.SET_EVENTS,
  payload: { events, start, end }
})

export const setActiveTab = (tab: SidebarTab) => ({
  type: type.SET_ACTIVE_TAB,
  payload: { tab }
})

export const setFilters = (filters: CalendarFilter[]) => ({
  type: type.SET_FILTERS,
  payload: { filters }
})

export const getResourceFilter = (locationId: UuidV4) => ({
  type: type.GET_RESOURCE_FILTER,
  payload: { locationId }
})

export const setFilterValues = (values: CalendarFilterValues) => ({
  type: type.SET_FILTER_VALUES,
  payload: { values }
})

export const setFilterValue = (filterName: CalendarFilterName, value: CalendarFilterOption['value'][]) => ({
  type: type.SET_FILTER_VALUE,
  payload: { filterName, value }
})

export const setFilter = (filter: CalendarFilter) => ({
  type: type.SET_FILTER,
  payload: { filter }
})
