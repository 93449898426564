import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Card, Form, ListGroup } from 'react-bootstrap'
import { allPass, cond, pathEq, pipe, tap } from 'ramda'
import styled from 'styled-components'
import {
  addSection, setDraggedElementId, setTargetElementId, swapElements
} from 'src/Views/FormEditor/Components/Section/state/actions'
import { translate } from 'src/Services/translation'
import { reorderRow } from 'src/Views/FormEditor/state/actions.ts'
import { getId } from 'src/Services/Selectors'
import { scrollToView } from 'src/Utils/index.ts'
import translation from './translations'
import { preventDefault, queryTargetValue, stopPropagation } from '../Util'
import './section.sass'


const Sections = () => {
  const dispatch = useDispatch()

  const { language } = useSelector(state => state.Root.user)
  const { sections, targetElementId, draggedElementId, loadingAddSection, addError  } =
    useSelector(state => state.FormEditor.sectionReducer)
  const { draggedRowTarget } = useSelector(state => state.FormEditor.formReducer)

  const trans = translate(translation)(language)
  const formId = useParams().id
  const [ fieldSection, setFieldSection ] = React.useState(null)
  const getSections = () => sections ? Object.values(sections).sort((a, b) => a.sortOrder - b.sortOrder) : []

  return (
    <Card>
      <Card.Header>{ trans('sections') }</Card.Header>
      <Card.Body className={ 'p-0' }>
        <ListGroup className={ 'p-0 m-0' } id={ 'section-draggable-container' }
                   onDragOver={ handleDragOver(
                     dispatch,
                     targetElementId,
                     draggedRowTarget?.section
                   ) }
                   onDrop={ handleOnDrop(dispatch) }
        >
          { getSections().map((section, i) => {
            const id = getId(section)
            const hasBackgroundPrimary =
              targetElementId === id || (draggedRowTarget?.row === '' && draggedRowTarget?.section === id)

            return (
              <ListGroup.Item key={ `draggable-section-${ id }` }
                              id={ `draggable-section-${ id }` }
                              data-section-id={ id }
                              active={ hasBackgroundPrimary }
                              draggable
                              onDragStart={ dispatchDraggedElementId(dispatch) }
                              onClick={ e => scrollToView(document.getElementById(`section${ id }`)) }
              >
                <UndroppableTarget>
                  <i className={ 'me-1 fas fa-bars' } />
                  <span>{ section.name }</span>
                </UndroppableTarget>
              </ListGroup.Item>)
          }) }
        </ListGroup>
      </Card.Body>
      <Card.Footer>
        <Form onSubmit={ addNewSection(dispatch, formId, setFieldSection) }
              className={ `card-footer-item${ loadingAddSection ? ' control is-loading' : '' }` }
              data-is={ 'newSection' }
        >
          <Form.Control size={ 'sm' }
                        className={ `input${ addError !== '' ? ' addError' : '' }` }
                        name={ 'section' }
                        placeholder={ trans('addSection') }
                        value={ fieldSection || '' }
                        onChange={ e=> setFieldSection(e?.target?.value) }
          />
        </Form>
      </Card.Footer>
    </Card>
  )
}

// dispatchDraggedElementId :: (Action -> *) -> Event -> SetDraggedElementIdAction
const dispatchDraggedElementId = dispatch =>
  pipe(
    tap(e => e.dataTransfer.setData('section', e.target.dataset.sectionId)),
    e => e.target.dataset.sectionId,
    id => dispatch(setDraggedElementId(id))
  )

// handleDragOver :: ((Action -> *), String, String) -> Event -> Action
const handleDragOver = (dispatch, targetElementId, targetSectionIdForRow) => {
  return pipe(
    preventDefault,
    cond([
      [
        allPass([
          e => targetElementId !== e.target.dataset.sectionId,
          pathEq([ 'dataTransfer', 'types', 0 ], 'section')
        ]),
        pipe(
          e => e.target.dataset.sectionId,
          id => dispatch(setTargetElementId(id))
        )
      ],
      [
        allPass([
          e => targetSectionIdForRow !== e.target.dataset.sectionId,
          pathEq([ 'dataTransfer', 'types', 0 ], 'row')
        ]),
        e => dispatch(reorderRow(e.target.dataset.sectionId, '')) // todo
      ]
    ])
  )
}

// handleOnDrop :: (Action -> *) -> Event -> Action
const handleOnDrop = dispatch => // todo
  pipe(
    preventDefault,
    stopPropagation,
    cond([
      [
        pathEq([ 'dataTransfer', 'types', 0 ], 'section'),
        () => dispatch(swapElements())
      ],
      [
        pathEq([ 'dataTransfer', 'types', 0 ], 'row'),
        e => dispatch(reorderRow(e.target.dataset.sectionId))
      ]
    ])
  )

// addNewSection :: (Action -> *) -> Event -> AddSectionAction
const addNewSection = (dispatch, id, setFieldSection = ()=> {}) =>
  pipe(preventDefault, queryTargetValue('input[name="section"]'),
   section =>
    dispatch(addSection({
      data: section,
      id,
      setFieldSection
    })),
    () => (setFieldSection(''))
  )

const UndroppableTarget = styled.span`
  pointer-events: none;
`

export default Sections
