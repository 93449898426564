import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleError } from 'src/Services/Store/Root/actions'
import { translate } from 'src/Services/translation'
import translation from 'src/Views/FormFiller/translations'
import TextField from 'src/Views/FormFiller/Components/Fields/TextField'
import EssayField from 'src/Views/FormFiller/Components/Fields/EssayField'
import SelectField from 'src/Views/FormFiller/Components/Fields/SelectField'
import MultiSelectField from 'src/Views/FormFiller/Components/Fields/MultiSelectField'
import { BaseFieldType, Field } from 'src/Types/Field'
import { StoreState } from 'src/Services/Store/reducers'
import CheckboxesField from 'src/Views/FormFiller/Components/Fields/CheckboxesField'
import InstanceId from 'src/Views/FormFiller/Components/Fields/InstanceId'
import PhoneField from 'src/Views/FormFiller/Components/Fields/PhoneField'
import DateTimeField from './Fields/DateTimeField'
import ExtensibleField from './Fields/ExtensibleField'
import SentenceField from './Fields/SentenceField'
import RadioField from './Fields/RadioField'
import ReferenceField from './Fields/ReferenceField/ReferenceField'
import FileField from './Fields/FileField'
import ButtonField from './Fields/ButtonField/ButtonField'
import UsernameField from './Fields/UsernameField'
import EmailField from './Fields/EmailField'
import DocumentField from './Fields/DocumentField'
import SignatureField from './Fields/SignatureField'
import CalculationField from './Fields/CalculationField'
import AddressField from './Fields/AddressField'
import HtmlContentField from './Fields/HtmlContentField'
import ConsentField from './Fields/ConsentField'
import JsonField from './Fields/JsonField'

export interface Props {
  field: Field
  isDisabled: boolean
  isLoading: boolean
  isRequired: boolean
}

const FieldComponentResolver = ({ field, isDisabled = false, isLoading = false, isRequired = false }: Props) => {
  const dispatch = useDispatch()

  const { language } = useSelector((state: StoreState) => state.Root.user)

  const trans = translate(translation)(language)

  if (!field?.id) {
    dispatch(handleError(new Error('FieldComponentResolver: Field incorrect'), null, { field }))
    return <div>{ trans('unknownField') }</div>
  }

  switch (field?.type?.baseFieldType) {
    case BaseFieldType.CHECKBOX:
      return <CheckboxesField field={ field } isDisabled={ isDisabled } isRequired={ isRequired }/>
    case BaseFieldType.DATE_TIME:
      return <DateTimeField id={ field.id }
        field={ field }
        options={ field.options }
        value={ field.value }
        disabled={ isDisabled }
        isRequired={ isRequired }
      />
    case BaseFieldType.DROPDOWN:
      return <SelectField field={ field } isDisabled={ isDisabled } isRequired={ isRequired } />
    case BaseFieldType.MULTISELECT:
      return <MultiSelectField field={ field } isDisabled={ isDisabled } isRequired={ isRequired } />
    case BaseFieldType.EXTENSIBLE:
      return <ExtensibleField field={ field } isDisabled={ isDisabled } />
    case BaseFieldType.SENTENCE:
      return <SentenceField field={ field }/>
    case BaseFieldType.FILE:
      return <FileField field={ field } isRequired={ isRequired } isDisabled={ isDisabled }/>
    case BaseFieldType.RADIO:
      return <RadioField field={ field } isDisabled={ isDisabled } isRequired={ isRequired }/>
    case BaseFieldType.REFERENCE:
      return <ReferenceField field={ field } isDisabled={ isDisabled } isRequired={ isRequired }/>
    case BaseFieldType.TEXT:
      return <TextField field={ field } isDisabled={ isDisabled } isRequired={ isRequired }/>
    case BaseFieldType.SIGNATURE:
      return <SignatureField id={ field.id }
        field={ field }
        trans={ trans }
        value={ field.value }
        disabled={ isDisabled }
        isRequired={ isRequired }
      />
    case BaseFieldType.INSTANCE_ID:
      return <InstanceId field={ field }/>
    case BaseFieldType.PHONE:
      return <PhoneField field={ field } isDisabled={ isDisabled } isRequired={ isRequired } />
    case BaseFieldType.USERNAME:
      return <UsernameField id={ field.id }
        field={ field }
        value={ field.value }
        disabled={ isDisabled }
        isRequired={ isRequired }
      />
    case BaseFieldType.EMAIL:
      return <EmailField id={ field.id }
        field={ field }
        value={ field.value }
        disabled={ isDisabled }
        isRequired={ isRequired }
      />
    case BaseFieldType.ESSAY:
      return <EssayField field={ field } isDisabled={ isDisabled } isRequired={ isRequired }/>
    case BaseFieldType.JSON:
      return <JsonField field={ field } isDisabled={ isDisabled } isRequired={ isRequired }/>
    case BaseFieldType.DOCUMENT:
      return <DocumentField field={ field } isDisable={ isDisabled } isRequired={ isRequired }/>
    case BaseFieldType.BUTTON:
      return <ButtonField field={ field } isLoading={ isLoading } isDisable={ isDisabled }/>
    case BaseFieldType.ADDRESS:
      return <AddressField field={ field } isDisable={ isDisabled } isRequired={ isRequired }/>
    case BaseFieldType.CALCULATION:
      return <CalculationField field={ field }/>
    case BaseFieldType.HTML_CONTENT:
      return <HtmlContentField id={ field.id } field={ field } value={ field.value }/>
    case BaseFieldType.CONSENT:
      return <ConsentField field={ field } id={ field.id } isDisabled={ isDisabled } isRequired={ field.options?.necessary }/>
    default:
      return <div>{ trans('unknownField') }</div>
  }
}

export default FieldComponentResolver
