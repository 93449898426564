import React, { ReactNode } from 'react'
import { Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { StoreState } from 'src/Services/Store/reducers'
import { translate } from 'src/Services/translation'
import Modal from '../BaseModal'

interface Props {
    title: string
    isOpen: boolean
    onClose: () => void
    onDelete: () => void
    children?: ReactNode
    props?: any
}

const DeleteModal = ({
                          title = '',
                          isOpen = false,
                          onClose = () => null,
                          onDelete = () => null,
                          children = null,
                          ...props
                      }: Props) => {
    const { language } = useSelector((state: StoreState) => state.Root?.user)
    const trans = translate({})(language)

    const onDeleteClick = () => {
        onDelete()
        onClose()
    }

    const onCancelClick = () => {
        onClose()
    }

    return (
        <Modal
            show={ isOpen }
            onClose={ onClose }
            title={ title }
            iconClass={ 'bi bi-exclamation-triangle' }
            iconVariant={ 'danger' }
            footer={
                <>
                    <Button variant={ 'outline-primary' } onClick={ onCancelClick }>
                        { trans('cancel') }
                    </Button>
                    <Button variant={ 'danger' } onClick={ onDeleteClick } style={ { float: 'right' } }>
                        { trans('delete') }
                    </Button>
                </>
            }
            { ...props }
        >
            { children }
        </Modal>
    )
}

export default DeleteModal
