import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { Container, Card, Row, Col, Badge } from 'react-bootstrap'
import { useActions } from 'src/Components/Util'
import { formatDateTime, isDate } from 'src/Utils/Date'
import { translate } from 'src/Services/translation'
import Modal from 'src/Components/Modals/BaseModal'
import { setOptions } from 'src/Layouts/View/state/actions'
import { fetchData } from './state/actions'
import translation from './translations'

const DEPLOYMENT_VERSION_PLACEHOLDER = '###FRONT_DEPLOYMENT_VERSION###'
const DEPLOYMENT_DATE_PLACEHOLDER = '###FRONT_DEPLOYMENT_DATE###'

const About = () => {
  const params = useParams()
  const dispatch = useDispatch()

  const { config, user: { language, locale, portalTimezone } } = useSelector(store => store.Root)
  const { versions, legalPages, contactTemplate } = useSelector(store => store.About)

  const trans = translate(translation)(language)
  const formatLocaleDateTime = formatDateTime(locale, portalTimezone)

  const [ isLegalModalOpen, setIsLegalModalOpen ] = useState(false)

  React.useEffect(() => {
    dispatch(setOptions({
      hasPrimaryButton: false,
      isLoading: true
    }))
  }, [ dispatch ])

  React.useEffect(() => {
    if (params?.legalPage && getLegalPage() && !isLegalModalOpen)
      setIsLegalModalOpen(true)
  }, [ params, legalPages ])

  useActions([ fetchData() ])([])([])
  //const formatLocaleDateTime = formatDateTime(locale)

  const handleLegalModalClose = () => {
    setIsLegalModalOpen(false)
  }

  const getLegalPage = () => params?.legalPage ? legalPages.find(({ name }) => name === params?.legalPage) : null

  const formatVersionValue = value => {
    if (!value)
      return trans('undefined')
    else if (value === DEPLOYMENT_VERSION_PLACEHOLDER)
      return config.FRONTEND_VERSION || trans('undefined')
    else if (value === DEPLOYMENT_DATE_PLACEHOLDER)
      return formatLocaleDateTime(config.DEPLOYMENT_DATE) || trans('undefined')
    else if (isDate(value))
      return formatLocaleDateTime(value)
    else
      return value
  }

  return <Container className={ 'mt-3' }>
    <Row className={ 'mb-3' }>
      <Col>
        <Card>
          <Card.Header>
            <Card.Title className={ 'm-0 py-2' }>
              { trans('contact') }
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Card.Text dangerouslySetInnerHTML={ { __html: contactTemplate } }/>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Card>
          <Card.Header>
            <Card.Title className={ 'm-0 py-2' }>
              { trans('version') }
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Card.Text>
              { versions.map(({ name, value }, i) =>
                <span key={ i }>{ trans(name) } : <Badge bg={ 'secondary' }>{ formatVersionValue(value) }</Badge><br/></span>) }
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    </Row>
    <Row>
      <Col>
        <Card>
          <Card.Header>
            <Card.Title className={ 'm-0 py-2' }>
              { trans('legalNotices') }
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Card.Text>
              { legalPages.map(({ name, value }, i) =>
                <span key={ i }><Link to={ `/about/${ name }` }>{ trans(name) }</Link><br/></span>) }
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    </Row>
    <Modal title={ getLegalPage() ? trans(getLegalPage().name) : '' } isOpen={ isLegalModalOpen } onClose={ handleLegalModalClose }>
      <span>{ getLegalPage()?.value }</span>
    </Modal>
  </Container>
}

export default About
