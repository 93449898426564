import * as type from 'src/Components/SectionNavigator/state/actionTypes'

export const setSections = (sectionNames: string[]) => ({
  type: type.SET_SECTIONS,
  payload: { sectionNames }
})
export const setActiveSection = (sectionIndex: number) => ({
  type: type.SET_ACTIVE_SECTION,
  payload: { sectionIndex }
})
