import {
  WORKFLOW_CREATED,
  WORKFLOW_DELETED,
  WORKFLOW_UPDATED,
  CREATE_WORKFLOW,
  SET_DELETING_WORKFLOW,
  SET_UPDATING_WORKFLOW,
  DELETE_WORKFLOW,
  UPDATE_WORKFLOW,
  TOGGLE_CREATE_WORKFLOW_MODAL
} from 'src/Views/Workflows/Modals/Workflows/state/actionTypes'
import WorkflowItem, { WorkflowItemForApi } from 'src/Types/WorkflowItem'
import { UuidV4 } from 'src/Types/Uuid'

export const createItem = (item: WorkflowItemForApi) => ({
  type: CREATE_WORKFLOW,
  payload: { item }
})

export const deleteItem = () => ({
  type: DELETE_WORKFLOW
})

export const updateItem = (item: WorkflowItemForApi) => ({
  type: UPDATE_WORKFLOW,
  payload: { item }
})

export const itemCreated = (item: WorkflowItem) => ({
  type: WORKFLOW_CREATED,
  payload: { item }
})

export const itemDeleted = (id: UuidV4) => ({
  type: WORKFLOW_DELETED,
  payload: { id }
})

export const itemUpdated = (item: WorkflowItem) => ({
  type: WORKFLOW_UPDATED,
  payload: { item }
})

export const setDeletingItem = (item: WorkflowItem) => ({
  type: SET_DELETING_WORKFLOW,
  payload: { item }
})

export const setUpdatingItem = (item: WorkflowItem) => ({
  type: SET_UPDATING_WORKFLOW,
  payload: { item }
})

export const toggleCreateWorkflowModal = () => ({
  type: TOGGLE_CREATE_WORKFLOW_MODAL
})
