import { call, put, takeLatest } from 'redux-saga/effects'
import * as types from 'src/Views/FormFiller/state/actionTypes'
import apiMethods from 'src/Services/api/apiMethods'
import { showSuccess } from 'src/Services/notifier/actions'
import {
  setMsSanteContentsToSend, setMsSanteRecipients, setMsSanteTrackingData, toggleMsSanteContentsFetching, toggleMsSanteModal, toggleMsSanteModalLoading,
  toggleMsSanteRecipientsFetching
} from 'src/Views/FormFiller/state/actions'

export function* sendMsSanteDocuments(props, { payload: { instanceId, recipients, files } }) {
  const request = {
    recipients: recipients,
    files: files
  }

  try {
    yield call(apiMethods.post, `/middlewares/ms_sante/send_documents/${ instanceId }`, { data: request })
    yield put(showSuccess('msSanteSendSuccess'))

  } catch (e) {
    yield put(props.globalActions.handleError(e, 'msSanteSendFailed'))
  }
  yield put(toggleMsSanteModal(false))
  yield put(toggleMsSanteModalLoading(false))
}

export function* fetchRecipients(props, { payload: { instanceId } }) {
  const { data } = yield call(apiMethods.get, `/middlewares/ms_sante/get_recipients/${ instanceId }`)
  yield put(setMsSanteRecipients(data))
  yield put(toggleMsSanteRecipientsFetching(false))
}

export function* fetchContentsToSend(props, { payload: { instanceId } }) {
  const { data } = yield call(apiMethods.get, `/middlewares/ms_sante/get_contents/${ instanceId }`)
  yield put(setMsSanteContentsToSend(data))
  yield put(toggleMsSanteContentsFetching(false))
}

export function* sendMsSanteTrackingRequest(props, { payload: { instanceId } }) {
  const { data } = yield call(apiMethods.get, `/middlewares/ms_sante/update/track_documents/${ instanceId }`)
  yield put(setMsSanteTrackingData(data))
}

export function* fetchMsSanteTrackingDatas(props, { payload: { instanceId } }) {
  const { data } = yield call(apiMethods.get, `/middlewares/ms_sante/fetch/track_documents/${ instanceId }`)
  yield put(setMsSanteTrackingData(data))
}

export default function* formFillerMsSanteSagaWatcher(props) {
  yield takeLatest(types.SEND_MS_SANTE_DOCUMENTS, sendMsSanteDocuments, props)
  yield takeLatest(types.FETCH_MS_SANTE_RECIPIENTS, fetchRecipients, props)
  yield takeLatest(types.FETCH_MS_SANTE_CONTENTS_TO_SEND, fetchContentsToSend, props)
  yield takeLatest(types.SEND_MS_SANTE_TRACKING_REQUEST, sendMsSanteTrackingRequest, props)
  yield takeLatest(types.FETCH_MS_SANTE_TRACKING_DATA, fetchMsSanteTrackingDatas, props)
}
