import React from 'react'
import SystemField from 'src/Types/SystemField'
import EntitySelect from '../EntitySelect'

interface SystemFieldSelectProps {
  formId?: string
  id?: string
  name?: string
  isMulti?: boolean
  required?: boolean
  disabled?: boolean
  clearable?: boolean
  defaultValue?: SystemField | string
  value?: SystemField | string
  onChange: (newValue: SystemField) => void
  placeholder?: string
}

const SystemFieldSelect = ({
  formId = null,
  id = 'entitySelect',
  name = 'entitySelect',
  isMulti = false,
  defaultValue = null,
  value = null,
  required = false,
  disabled = false,
  clearable = false,
  onChange = () => null,
  placeholder = null,
}: SystemFieldSelectProps) => {

  const getFetchUrl = () => formId ? `/forms/${ formId }/system_fields` : '/system_fields'

  const formatValue = (value: SystemField | string): SystemField => typeof value === 'string' ? { name: value, id: null, field: null } : value

  return <EntitySelect
    id={ id }
    name={ name }
    isMulti={ isMulti }
    defaultValue={ defaultValue ? formatValue(defaultValue) : null }
    value={ value ? formatValue(value) : null }
    required={ required }
    disabled={ disabled }
    clearable={ clearable }
    fetchUrl={ getFetchUrl() }
    onChange={ onChange }
    valueKey={ 'name' }
    labelKey={ 'name' }
    placeholder={ placeholder }
  />
}

export default SystemFieldSelect
