import { UuidV4 } from 'src/Types/Uuid'
import WorkflowAction from 'src/Types/WorkflowAction'
import { User } from 'src/Types/User'
import Role from 'src/Types/Role'
import { Field } from 'src/Types/Field'

interface WorkflowTrigger {
  id: UuidV4
  name: string | null
  type: WorkflowTriggerType | null
  field: Field | null
  form: Field | null
  oldValue: string | null
  newValue: string | null
  formEvent: WorkflowTriggerFormEvent | null
}

export enum WorkflowTriggerFormEvent {
  CREATE = 'CREATE',
  READ = 'READ',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export enum WorkflowTriggerType {
  FORM_EVENT = 'FORM_EVENT',
  FIELD_VALUE_CHANGE = 'FIELD_VALUE_CHANGE',
  EVENT_STATUS_CHANGE = 'EVENT_STATUS_CHANGE',
}

export default WorkflowTrigger
