import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'src/Components/Modals/BaseModal'
import SlotSerieForm from 'src/Components/Calendar/Components/SlotSerieForm'
import { setSlotFormModal } from 'src/Components/Calendar/state/actions'
import { StoreState } from 'src/Services/Store/reducers'

const SlotSerieFormModal = () => {
  const dispatch = useDispatch()

  const { isCreatingASlotSerie } = useSelector((state: StoreState) => state.Calendar)
  const { slotType, isModalOpen: isOpen } = useSelector((state: StoreState) => state.Calendar.slotForm)

  const handleClose = () => dispatch(setSlotFormModal(false, slotType))

  return <Modal title={ `Create Slot ${ isCreatingASlotSerie ? 'Series' : '' } ` }
    isOpen={ isOpen }
    onClose={ handleClose }
    size={ 'lg' }
  >
    <SlotSerieForm/>
  </Modal>
}

export default SlotSerieFormModal
