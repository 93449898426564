import { TOGGLE_CONFIRM_MODAL, SUBMIT_CONFIRM_MODAL } from './actionTypes'

export const toggleConfirmModal = payload => ({
  type: TOGGLE_CONFIRM_MODAL,
  payload: payload
})

export const submitConfirmModal = payload => ({
  type: SUBMIT_CONFIRM_MODAL,
  payload: payload
})
