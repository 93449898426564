import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'src/Components/Select'
import { Button, Form, OverlayTrigger, Popover } from 'react-bootstrap'
import LocalizedDatepicker from 'src/Components/LocalizedDatepicker'
import { NAME } from 'src/Services/Constants/Config/DocumentTemplate'
import { COLORS, SIZES } from 'src/Services/Constants'
import { FieldOption, FieldOptionSaveTypes } from 'src/Types/FieldOption'
import SystemFieldSelect from 'src/Components/SystemFieldSelect'
import { toggleCalculationFieldModal, toggleFieldValuesModal } from 'src/Views/FormEditor/state/actions'
import { BaseFieldType, EditableField, FieldOptions } from 'src/Types/Field'
import { StoreState } from 'src/Services/Store/reducers'
import { translate } from 'src/Services/translation'
import translations from 'src/Views/FormEditor/translations'
import FileTypeSettings from './FileTypeSettings'

interface Props {
  field: EditableField
  onChange: (field: EditableField) => void
  option: keyof FieldOptions
}

const FieldSetting = ({ field, option, onChange }: Props) => {
  const dispatch = useDispatch()

  const { language } = useSelector((state: StoreState) => state.Root.user)
  const { documentTypes, documentTemplates: templatesList } = useSelector((state: StoreState) => state.Dictionary)

  const trans = translate(translations)(language)

  const getValue = () => field.options[option]

  const updateValue = (value: any) => onChange({ ...field, options: { ...field.options, [option]: value } })

  switch (option) {
    case FieldOption.READ_ONLY:
      return <Form.Check checked={ getValue() }
        name={ 'readOnly' }
        label={ trans('editField.readOnly') }
        onChange={ e => updateValue(e.target.checked) }
      />
    case FieldOption.REQUIRED:
      return <Form.Check checked={ getValue() }
        name={ 'required' }
        label={ trans('required') }
        onChange={ e => updateValue(e.target.checked) }
      />
    case FieldOption.NECESSARY:
      return <Form.Check checked={ getValue() }
        name={ 'necessary' }
        label={ trans('editField.necessary') }
        onChange={ e => updateValue(e.target.checked) }
      />
    case FieldOption.EXCLUDE_FROM_CLONE:
      return <Form.Check checked={ getValue() }
        name={ 'excludeFromClone' }
        label={ trans('editField.excludeFromClone') }
        onChange={ e => updateValue(e.target.checked) }
      />
    case FieldOption.VALIDATE_USER_IDENTITY:
      return <Form.Check
        checked={ getValue() }
        name={ 'validateUserIdentity' }
        label={ trans('editField.validateUserIdentity') }
        onChange={ e => updateValue(e.target.checked) }
      />
    case FieldOption.SHOW_DATE:
      return <Form.Check checked={ getValue() }
        name={ 'showDate' }
        label={ trans('editField.showDate') }
        onChange={ e => updateValue(e.target.checked) }
      />
    case FieldOption.SHOW_TIME:
      return <Form.Check checked={ getValue() }
        name={ 'showTime' }
        label={ trans('editField.showTime') }
        onChange={ e => updateValue(e.target.checked) }
      />
    case FieldOption.IS_SENSITIVE:
      return <Form.Check checked={ getValue() }
        name={ 'isSensitive' }
        label={ trans('editField.isSensitive') }
        onChange={ e => updateValue(e.target.checked) }
      />
    case FieldOption.SORT_ALPHA:
      return <Form.Check checked={ getValue() }
        name={ 'sortAlpha' }
        label={ trans('editField.sortAlpha') }
        onChange={ e => updateValue(e.target.checked) }
      />
    case FieldOption.SELF_POPULATION:
      return <Form.Check checked={ getValue() }
        name={ 'selfPopulation' }
        label={ trans('editField.selfPopulation') }
        onChange={ e => updateValue(e.target.checked) }
      />
    case FieldOption.USER_TEXT_RESPONSE:
      return <Form.Check checked={ getValue() }
        name={ 'userTextResponse' }
        label={ trans('editField.userTextResponse') }
        onChange={ e => updateValue(e.target.checked) }
      />
    case FieldOption.SET_NOW_AT_CREATION:
      return <Form.Check checked={ getValue() }
        name={ 'setNowAtCreation' }
        label={ trans('editField.setNowAtCreation') }
        onChange={ e => updateValue(e.target.checked) }
      />
    case FieldOption.SET_NOW_AT_MODIFICATION:
      return <Form.Check checked={ getValue() }
        name={ 'setNowAtModification' }
        label={ trans('editField.setNowAtModification') }
        onChange={ e => updateValue(e.target.checked) }
      />
    case FieldOption.SET_AT_CREATION:
      return <Form.Check checked={ getValue() }
        name={ 'setAtCreation' }
        label={ trans('editField.setAtCreation') }
        onChange={ e => updateValue(e.target.checked) }
      />
    case FieldOption.SET_AT_MODIFICATION:
      return <Form.Check checked={ getValue() }
        name={ 'setAtModification' }
        label={ trans('editField.setAtModification') }
        onChange={ e => updateValue(e.target.checked) }
      />
    case FieldOption.ALIGN_OPTIONS_VERTICALLY:
      return <Form.Check checked={ getValue() }
        name={ 'alignOptionsVertically' }
        label={ trans('editField.alignOptionsVertically') }
        onChange={ e => updateValue(e.target.checked) }
      />
    case FieldOption.SHOW_CLEAR_BUTTON:
      return <Form.Check checked={ getValue() }
        name={ 'showClearButton' }
        label={ trans('editField.showClearButton') }
        onChange={ e => updateValue(e.target.checked) }
      />
    case FieldOption.SHOW_ADD_BUTTON:
      return <Form.Check checked={ getValue() }
        name={ 'showAddButton' }
        label={ trans('editField.showAddButton') }
        onChange={ e => updateValue(e.target.checked) }
      />
    case FieldOption.SHOW_EDIT_BUTTON:
      return <Form.Check checked={ getValue() }
        name={ 'showEditButton' }
        label={ trans('editField.showEditButton') }
        onChange={ e => updateValue(e.target.checked) }
      />
    case FieldOption.AUTOCOMPLETE_AT_CREATION:
      return <div>
        <Form.Check checked={ getValue() }
          name={ 'autocompleteAtCreation' }
          label={ trans('editField.autocompleteAtCreation') }
          onChange={ e => updateValue(e.target.checked) }
        />
        <small className={ 'text-muted' }>{ trans('editField.autocompleteAtCreationInfo') }</small>
      </div>
    case FieldOption.FILE_TYPES:
      return <FileTypeSettings value={ getValue() } onChange={ v => updateValue(v) }/>
    case FieldOption.LABEL_HOVER:
      return <Form.Group className={ 'mb-1' }>
        <Form.Label>{ trans('editField.labelHover') }</Form.Label>
        <Form.Control
          type={ 'text' }
          size={ 'sm' }
          id={ 'label-hover' }
          value={ getValue() || '' }
          name={ 'labelHover' }
          onChange={ e => updateValue(e.target.value) }
        />
      </Form.Group>
    case FieldOption.DEFAULT_TEMPLATE:
      return <Form.Group className={ 'mb-1' }>
        <Form.Label>{ trans('editField.defaultTemplate') } { getValue() }</Form.Label>
        { templatesList && <Select
          id={ 'default-template' }
          name={ 'defaultTemplate' }
          isClearable={ true }
          options={ templatesList }
          getOptionValue={ o => o.values[NAME] as string }
          getOptionLabel={ o => o.values[NAME] as string }
          value={ templatesList.find(row => row.values[NAME] === getValue()) }
          placeholder={ trans('editField.defaultTemplatePlaceholder') }
          onChange={ selected => updateValue(selected?.values[NAME]) }
        /> }
      </Form.Group>
    case FieldOption.FILE_FIELD:
      return <Form.Group className={ 'mb-1' }>
        <Form.Label>{ trans('editField.fileField') }</Form.Label>
        <SystemFieldSelect
          placeholder={ trans('editField.fileFieldPlaceholder') }
          id={ 'file-field' }
          value={ getValue() || null }
          name={ 'fileField' }
          clearable
          onChange={ systemField => updateValue(systemField?.name || null) }
        />
      </Form.Group>
    case FieldOption.DOCUMENT_TYPE:
      return <Form.Group className={ 'mb-1' }>
        <Form.Label>{ trans('editField.documentType') }</Form.Label>
        <Form.Select
          size={ 'sm' }
          id={ 'documentType' }
          value={ getValue() || '' }
          name={ 'fontSize' }
          onChange={ e => updateValue(e.target.value || null) }
        >
          <option value={ null }>{ trans('none') }</option>
          { documentTypes.map(type => <option key={ type.code } value={ type.code }>{ type.label }</option>) }
        </Form.Select>
      </Form.Group>
    // Use src/Components/FieldValueSetter/index.tsx instead
    case FieldOption.DEFAULT_VALUE:
      return <Form.Group className={ 'mb-1' }>
        <Form.Label>{ trans('editField.defaultValue') }</Form.Label>
        { field?.type?.baseFieldType === BaseFieldType.DATE_TIME ? <LocalizedDatepicker
          id={ 'defaultValue' }
          name={ 'defaultValue' }
          showDateOnly={ field.options[FieldOption.SHOW_DATE] && !field.options[FieldOption.SHOW_TIME] }
          showTimeOnly={ !field.options[FieldOption.SHOW_DATE] && field.options[FieldOption.SHOW_TIME] }
          selected={ getValue() }
          isClearable
          onChange={ newDate => updateValue(newDate) }
        /> : <Form.Control
          type={ 'text' }
          size={ 'sm' }
          placeholder={ trans('editField.defaultValuePlaceholder') }
          id={ 'defaultValue' }
          value={ getValue() || '' }
          name={ 'defaultValue' }
          onChange={ e => updateValue(e.target.value) }
        /> }
      </Form.Group>
    case FieldOption.IS_TEMPLATE_CREATOR:
      return <Form.Check
        checked={ getValue() }
        name={ 'isTemplateCreator' }
        label={ trans('editField.isTemplateCreator') }
        onChange={ e => updateValue(e.target.checked) }
      />
    case FieldOption.VALUES:
      return <>
        { /*<Form.Label>{ trans('editField.values') }</Form.Label>*/ }
        <div>
          <Button
            variant={ 'outline-primary' }
            size={ 'sm' }
            onClick={ e => dispatch(toggleFieldValuesModal()) }
          >
            { trans('editField.openValuesModalButton') }
          </Button>
        </div>
      </>
    case FieldOption.CONTENT:
      return <Form.Group className={ 'mb-1' }>
        <Form.Label>{ trans('editField.content') }</Form.Label>
        <Form.Control
          type={ 'text' } size={ 'sm' }
          placeholder={ trans('editField.contentPlaceholder') }
          id={ 'content' }
          value={ getValue() || '' }
          name={ 'content' }
          onChange={ e => updateValue(e.target.value) }
        />
      </Form.Group>
    case FieldOption.CSS_CLASS:
      return <Form.Group className={ 'mb-1' }>
        <Form.Label>{ trans('editField.cssClass') }</Form.Label>
        <Form.Control
          type={ 'text' }
          size={ 'sm' }
          placeholder={ trans('editField.cssClassPlaceholder') }
          id={ 'cssClass' }
          value={ getValue() || '' }
          name={ 'cssClass' }
          className={ 'mb-1' }
          onChange={ e => updateValue(e.target.value) }
        />
        <OverlayTrigger trigger={ 'click' }
          placement={ 'auto' }
          overlay={ <Popover>
            <Popover.Header>{ trans('editField.cssClassInfoHeader') }</Popover.Header>
            <Popover.Body>{ trans('editField.cssClassInfoBody') }</Popover.Body>
          </Popover> }
        >
          <Button size={ 'sm' }>{ trans('editField.cssClassInfoButton') }</Button>
        </OverlayTrigger>
      </Form.Group>
    case FieldOption.FONT_SIZE:
      return <Form.Group className={ 'mb-1' }>
        <Form.Label>{ trans('editField.fontSize') }</Form.Label>
        <Form.Select
          size={ 'sm' }
          id={ 'fontSize' }
          value={ getValue() || '' }
          name={ 'fontSize' }
          onChange={ e => updateValue(e.target.value || null) }
        >
          <option value={ null }>{ trans('none') }</option>
          { SIZES.map(size => <option key={ size } value={ size }>{ size }</option>) }
        </Form.Select>
      </Form.Group>
    case FieldOption.FONT_COLOR:
      return <Form.Group className={ 'mb-1' }>
        <Form.Label>{ trans('editField.fontColor') }</Form.Label>
        <Form.Select
          size={ 'sm' }
          id={ 'fontColor' }
          value={ getValue() || '' }
          name={ 'fontColor' }
          onChange={ e => updateValue(e.target.value || null) }
        >
          <option value={ null }>{ trans('none') }</option>
          { COLORS.map(color => <option key={ color } value={ color }>{ color }</option>) }
        </Form.Select>
      </Form.Group>
    case FieldOption.CALCULATION:
      return <>
        <div>
          <Button variant={ 'outline-primary' } size={ 'sm' }
            onClick={ e => dispatch(toggleCalculationFieldModal()) }>
            { trans('editField.calculationField') }
          </Button>
        </div>
      </>
    case FieldOption.CONSENT_DESCRIPTION:
      return <Form.Group className={ 'mb-1' }>
        <Form.Label>{ trans('editField.description') }</Form.Label>
        <Form.Control
          as={ 'textarea' }
          name={ 'consentDescription' }
          size={ 'sm' }
          value={ getValue() || '' }
          rows={ 10 }
          onChange={ e => updateValue(e.target.value) }
        />
      </Form.Group>
    case FieldOption.SAVE_TYPE:
      return <Form.Group className={ 'mb-1' }>
        <Form.Label>{ trans('editField.saveTypeLabel') }</Form.Label>
        <Form.Select
          size={ 'sm' }
          id={ 'saveType' }
          value={ getValue() || '' }
          name={ 'saveType' }
          onChange={ e => updateValue(e.target.value || null) }
        >
          <option value={ '' }>{ trans('none') }</option>
          { Object.values(FieldOptionSaveTypes).map(saveType => <option key={ saveType } value={ saveType }>{ trans(`editField.saveType.${ saveType }`) }</option>) }
        </Form.Select>
      </Form.Group>
    default:
      return <></>
  }
}

export default FieldSetting
