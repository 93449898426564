import { assoc, prop, sortBy } from 'ramda'
import * as types from 'src/Views/FormEditor/state/actionTypes'
import { EditableField, FieldType } from 'src/Types/Field'
import { EditableRow } from 'src/Types/Row'
import { EditableForm } from 'src/Types/Form'
import { UuidV4 } from 'src/Types/Uuid'
import { EditableSection } from 'src/Types/Section'
import { Action as ActionState } from 'src/Services/Store/reducers'
import ExtensibleScrollButton from 'src/Views/FormEditor/Types/ExtensibleScrollButton'

export interface FormEditorFormState {
  addingFieldError: string,
  deleteFieldError: string,
  deleteSectionError: string,
  isDetailsModalOpen: boolean,
  isSwpConfigModalOpen: boolean,
  isMsSanteConfigModalOpen: boolean,
  isRdConfigModalOpen: boolean,
  detailsError: string,
  draggedFieldType: FieldType | null,
  draggedExistingField: unknown,
  draggedRow: unknown,
  dragging: boolean,
  editedField: null | (EditableField & { loading: boolean }),
  isFieldValuesModalOpen: boolean,
  isFieldConditionsModalOpen: boolean,
  isAdvancedFieldConfigModalOpen: boolean,
  isCalculationFieldModalOpen: boolean,
  currentDeletedOption: unknown,
  editFieldError: string,
  editedSectionId: string,
  editedSection: unknown,
  form: EditableForm,
  loading: boolean,
  newField: EditableField|null,
  targetField: unknown,
  resizedRow: unknown,
  savingForm: boolean,
  savingFormError: string,
  targetElementId: unknown,
  updatingDetails: boolean,
  updatingField: boolean,
  updatingSection: boolean,
  updateSectionError: unknown[],
  systemFields: unknown[],
  refFieldModalOpened: boolean,
  savingRefField: boolean,
  fullRowModalOpened: boolean,
  isFieldDetailsModalOpen: boolean,
  newFullRowFieldDetails: unknown,
  extensibleScrollButtons: { [extensibleId: UuidV4]: ExtensibleScrollButton },
  isEditingTitleTemplate: boolean,
  scriptDialogOpened: boolean,
  isCustomizationOrderLoading: boolean
}

export const initialState: FormEditorFormState = {
  addingFieldError: '',
  deleteFieldError: '',
  deleteSectionError: '',
  isDetailsModalOpen: false,
  isSwpConfigModalOpen: false,
  isMsSanteConfigModalOpen: false,
  isRdConfigModalOpen: false,
  detailsError: '',
  draggedFieldType: null,
  draggedExistingField: null,
  draggedRow: null,
  dragging: false,
  editedField: { loading: false },
  isFieldValuesModalOpen: false,
  isFieldConditionsModalOpen: false,
  isAdvancedFieldConfigModalOpen: false,
  isCalculationFieldModalOpen: false,
  currentDeletedOption: {},
  editFieldError: '',
  editedSectionId: '',
  editedSection: {},
  form: {},
  loading: true,
  newField: null,
  targetField: null,
  resizedRow: {},
  savingForm: false,
  savingFormError: '',
  targetElementId: null,
  updatingDetails: false,
  updatingField: false,
  updatingSection: false,
  updateSectionError: [],
  systemFields: [],
  refFieldModalOpened: false,
  savingRefField: false,
  fullRowModalOpened: false,
  isFieldDetailsModalOpen: false,
  newFullRowFieldDetails: {},
  extensibleScrollButtons: {},
  isEditingTitleTemplate: false,
  scriptDialogOpened: false,
  isCustomizationOrderLoading: false
}

export const newRow = (row: EditableRow, i: number): EditableRow => ({
  id: row.id || null,
  fields: [],
  sortOrder: row.sortOrder || i || 1
})

export const getFields = (form: EditableForm): EditableField[] =>
  form.sections.reduce((fields, section) =>
    [ ...fields, ...section.rows.reduce((_fields, row) =>
      [ ..._fields, ...row.fields ]
    , []) ]
  , [])

export const formatForm = (raw: EditableForm): EditableForm => ({
  ...raw,
  sections: formatSections(raw),
  titleTemplate: raw.titleTemplate ? JSON.parse(raw.titleTemplate as string) : []
})

export const formatSections = (form: EditableForm): EditableSection[] => {
  const sections = form.sections.map(section => ({
    ...section,
    rows: section.rows.map(r => formatRow(r, section.id))
  }))

  return sortBy(prop('sortOrder'))(sections)
}

export const formatRow = (row: EditableRow, sectionId: UuidV4) =>
  ({
    id: row.id || null,
    sortOrder: row.sortOrder || 1,
    fields: row.fields.map(field => ({
      ...assoc('id', field.id)(field),
      type: field.type,
      javaScriptCode: field.javaScriptCode || null,
      options: field.options,
      rowColumn: field.rowColumn,
      row: field.row,
      rowSize: field.rowSize,
      section: sectionId,
      sectionId: sectionId
    }))
  })

export default (state = initialState, { type, payload }: ActionState) => {
  switch (type) {
    case types.LOAD_FORM:
      return {
        ...state,
        loading: true,
        editedField: !state.form.id || payload != state.form.id
          ? initialState.editedField
          : state.editedField
      }
    case types.RECEIVE_FORM:
      return {
        ...state,
        loading: false,
        form: formatForm(payload.form)
      }
    case types.SET_SYSTEM_FIELDS:
      return {
        ...state,
        systemFields: payload.systemFields
      }
    case types.TOGGLE_DETAILS_MODAL:
      return {
        ...state,
        isDetailsModalOpen: !state.isDetailsModalOpen,
        detailsError: ''
      }
    case types.TOGGLE_SWP_CONFIG_MODAL:
      return {
        ...state,
        isSwpConfigModalOpen: !state.isSwpConfigModalOpen,
      }
    case types.TOGGLE_MS_SANTE_CONFIG_MODAL:
      return {
        ...state,
        isMsSanteConfigModalOpen: !state.isMsSanteConfigModalOpen,
      }
    case types.TOGGLE_RD_CONFIG_MODAL:
      return {
        ...state,
        isRdConfigModalOpen: !state.isRdConfigModalOpen,
      }
    case types.UPDATE_DETAILS:
      return {
        ...state,
        updatingDetails: true
      }
    case types.DETAILS_UPDATED:
      return {
        ...state,
        updatingDetails: false,
        isDetailsModalOpen: false
      }
    case types.DETAILS_NOT_UPDATED:
      return {
        ...state,
        updatingDetails: false,
        detailsError: payload
      }
    case types.TOGGLE_SCRIPT_DIALOG:
      return {
        ...state,
        scriptDialogOpened: !state.scriptDialogOpened,
        scriptError: ''
      }
    case types.SET_DRAGGED_FIELD_TYPE:
      return {
        ...state,
        draggedFieldType: payload,
        dragging: true
      }
    case types.SET_DRAGGED_EXISTING_FIELD:
      return {
        ...state,
        draggedExistingField: payload,
        dragging: true
      }
    case types.SET_IS_EDITING_TITLE_TEMPLATE:
      return {
        ...state,
        isEditingTitleTemplate: payload
      }
    case types.SET_CURRENT_DELETED_OPTION:
      return {
        ...state,
        currentDeletedOption: payload
      }
    case types.MOVE_FIELD_TO_ANOTHER_PLACE:
      return {
        ...state,
        dragging: false
      }
    case types.DRAGGING_STOPPED:
      return {
        ...state,
        dragging: false
      }
    case types.FIELD_MOVED:
      return {
        ...state,
        draggedExistingField: null,
        targetField: initialState.targetField
      }
    case types.SET_TARGET_FIELD:
      return {
        ...state,
        targetField: payload.field
      }
    case types.SET_DRAGGED_ROW:

      const draggedRow = payload?.row ? {
        ...payload.row,
        section: payload.section
      } : null

      draggedRow.sortOrder = payload.rowSortOrder

      return {
        ...state,
        draggedRow,
        draggedFieldType: null
      }
    case types.REQUEST_ADD_FIELD:
      return {
        ...state,
        targetField: payload.targetField,
        newField: { ...state.newField, ...payload.newField },
        isFieldDetailsModalOpen: true
      }
    case types.REQUEST_ADD_REFERENCE_FIELD:
      return {
        ...state,
        targetField: payload.targetField,
        newField: { ...state.newField, ...payload.newField },
        refFieldModalOpened: true
      }
    case types.ADD_FIELD:
      return {
        ...state,
        form: computeFields(state, state.draggedFieldType, state.targetField),
        newField: { ...state.newField, ...payload.newField },
        resizedRow: {}
      }
    case types.ADD_FIELD_IN_EXTENSIBLE:
      return {
        ...state,
        newField: { ...state.newField, ...payload.newField }
      }
    case types.ADD_REFERENCE_FIELD:
      return {
        ...state,
        newField: { ...state.newField, ...payload.newField }
      }
    case types.FIELD_ADDED:
      return {
        ...state,
        dragging: false,
        draggedFieldType: initialState.draggedFieldType,
        targetField: initialState.targetField,
        isFieldDetailsModalOpen: false,
        newField: initialState.newField,
      }
    case types.ERROR_ADDING_FIELD:
      return {
        ...state,
        addingFieldError: 'An error occurred',
        newField: initialState.newField,
      }
    case types.ERROR_ADDING_ROW:
      return {
        ...state,
        addingRowError: 'An error occurred',
        newField: initialState.newField,
      }
    /** ROW */
    case types.ROW_ADDED:
      return {
        ...state,
        form: addNewRow(state, payload, payload.section),
        loading: false
      }
    case types.DELETE_ROW:
      return {
        ...state,
        deleteRowError: null
      }
    case types.ERROR_DELETING_ROW:
      return {
        ...state,
        deleteRowError: payload
      }
    case types.ROW_DELETED:
      return {
        ...state,
        deleteRowError: null
      }
    case types.ROW_REORDERED:
      return {
        ...state,
        draggedRow: null
      }
    /** DELETE FIELD */
    case types.DELETE_FIELD:
      return {
        ...state,
        deleteFieldError: ''
      }
    case types.ERROR_DELETING_FIELD:
      return {
        ...state,
        deleteFieldError: 'An error occurred'
      }
    case types.FIELD_DELETED:
      return {
        ...state,
        deleteFieldError: '',
        editedField: { loading: false }
      }
    /** EDIT FIELD */
    case types.OPEN_EDIT_FIELD:
      return {
        ...state,
        editedField: {
          id: payload,
          loading: true
        }
      }
    case types.REPLACE_FIELD_OPTION_VALUES:
      return {
        ...state,
        currentDeletedOption: {}
      }
    case types.RECEIVE_EDIT_FIELD:
      return {
        ...state,
        editedField: {
          ...payload,
          loading: false,
          javaScriptCode: payload.javaScriptCode || null
        }
      }
    case types.UPDATE_FIELD_OPTION:
      return {
        ...state,
        editedField: {
          ...state.editedField,
          options: {
            ...state.editedField.options,
            [payload.option]: payload.value
          }
        }
      }
    case types.CLOSE_EDIT_FIELD:
      return {
        ...state,
        editedField: { loading: false }
      }
    case types.UPDATE_FIELD:
      return {
        ...state,
        editFieldError: '',
        updatingField: true,
        editedField: payload
      }
    case types.FIELD_UPDATED:
      return {
        ...state,
        editFieldError: '',
        updatingField: false
      }
    case types.ERROR_UPDATING_FIELD:
      return {
        ...state,
        editFieldError: 'An error occurred',
        updatingField: false
      }
    /** SECTION */
    case types.DELETE_SECTION:
      return {
        ...state,
        deleteSectionError: null
      }
    case types.ERROR_DELETING_SECTION:
      return {
        ...state,
        deleteSectionError: payload
      }
    case types.SECTION_DELETED:
      return {
        ...state,
        deleteSectionError: null
      }
    case types.UPDATE_SECTION:
      return {
        ...state,
        editedSectionId: payload.id,
        editedSection: payload.details,
        updatingSection: true,
        updateSectionError: initialState.updateSectionError,
      }
    case types.ERROR_UPDATING_SECTION:
      return {
        ...state,
        editedSectionId: '',
        editedSection: {},
        updatingSection: false,
        updateSectionError: payload
      }
    case types.SECTION_UPDATED:
      return {
        ...state,
        editedSectionId: '',
        editedSection: {},
        updatingSection: false,
        updateSectionError: initialState.updateSectionError,
      }
    /** FORM */
    case types.SAVE_FORM:
      return {
        ...state,
        savingForm: true
      }
    case types.ERROR_SAVING_FORM:
      return {
        ...state,
        savingForm: false,
        savingFormError: 'An error occurred'
      }
    case types.FORM_SAVED:
      return {
        ...state,
        savingForm: false,
        savingFormError: ''
      }
    case types.RESET_FORM_SAVE:
      return {
        ...state,
        savingForm: false,
        savingFormError: ''
      }
    case types.OPEN_FULL_ROW_MODAL:
      return {
        ...state,
        fullRowModalOpened: true,
        newFullRowFieldDetails: {
          afterRow: payload.afterRow,
          type: payload.fieldType,
          sectionId: payload.sectionId
        }
      }
    case types.CLOSE_FULL_ROW_MODAL:
      return {
        ...state,
        fullRowModalOpened: false,
        newFullRowFieldDetails: {}
      }
    case types.SET_EXTENSIBLE_SCROLL_BUTTONS:
      return {
        ...state,
        extensibleScrollButtons: {
          ...state.extensibleScrollButtons,
          [payload.id]: {
            showLeft: payload.showLeft,
            showRight: payload.showRight
          }
        }
      }
    case types.UPDATE_EXTENSIBLE_FIELDS_ORDER:
      const { formId, extensibleId, fields } = payload
      return {
        ...state,
        form: updateExtensibleFieldsOrder(state, formId, extensibleId, fields)
      }
    case types.SET_REF_FIELD_MODAL_OPENED:
      return {
        ...state,
        refFieldModalOpened: payload
      }
    case types.TOGGLE_FIELD_DETAILS_MODAL:
      return {
        ...state,
        isFieldDetailsModalOpen: !state.isFieldDetailsModalOpen
      }
    case types.TOGGLE_ADVANCED_FIELD_CONFIG_MODAL:
      return {
        ...state,
        isAdvancedFieldConfigModalOpen: !state.isAdvancedFieldConfigModalOpen
      }
    case types.TOGGLE_CALCULATION_FIELD_MODAL:
      return {
        ...state,
        isCalculationFieldModalOpen: !state.isCalculationFieldModalOpen
      }
    case types.TOGGLE_FIELD_VALUES_MODAL:
      return {
        ...state,
        isFieldValuesModalOpen: !state.isFieldValuesModalOpen
      }
    case types.TOGGLE_CUSTOMIZATION_ORDERS_MODAL:
      return {
        ...state,
        isFieldConditionsModalOpen: !state.isFieldConditionsModalOpen
      }
    case types.SET_SAVING_REF_FIELD:
      return {
        ...state,
        savingRefField: payload
      }
    case types.SET_IS_CUSTOMIZATION_ORDER_LOADING:
      return {
        ...state,
        isCustomizationOrderLoading: payload
      }
    default:
      return state
  }
}

export const getSectionById = (state: FormEditorFormState) => (sectionId: UuidV4): EditableSection => {
  const sections = state.form?.sections || []

  if (sections.length === 0)
    return {}

  return sections.find(section => section.id === sectionId)
}

export const getHighestSortOrder = (sections: EditableSection[]) => (fieldId: UuidV4): number => {
  if (!sections || sections.length === 0)
    return 0

  const fields: EditableField[] = sections
    .reduce((acc, section) => [ ...acc, ...section.rows ], [])
    .reduce((acc, row) => [ ...acc, ...row.fields ], [])

  const field = fields.find(f => f.id === fieldId)

  if (!field?.fields || field.fields.length === 0)
    return 0

  const sortedFields = (field.fields as EditableField[]).sort((a, b) => b.options.sortOrder - a.options.sortOrder)

  return sortedFields[0]?.options?.sortOrder
}

const addNewRow = (state: FormEditorFormState, row: EditableRow, sectionId: UuidV4): EditableForm => ({
  ...state.form,
  sections: state.form.sections.map(
    section => section.id === sectionId
      ? section
      : {
        ...section,
        rows: [
          ...section.rows, newRow(row, section.rows.length + 1)
        ]
      }
  )
})

/** Change type of field matching position */
const computeFields = (state: FormEditorFormState, type: FieldType, newField: EditableField): EditableForm => ({
  ...state.form,
  sections: state.form.sections.map(section => ({
    ...section,
    rows: section.rows.map(row => ({
      ...row,
      fields: row.fields.map(field =>
        field.rowColumn === newField.rowColumn
        && field.row.id === newField.row.id
          ? { ...field, type } : field
      )
    }))
  }))
})

const updateExtensibleFieldsOrder = (state: FormEditorFormState, formId: UuidV4, extensibleId: UuidV4, fields: EditableField[]): EditableForm => ({
  ...state.form,
  sections: state.form.sections.map(section => ({
    ...section,
    rows: section.rows.map(row => ({
      ...row,
      fields: row.fields.map(field =>
        field.id === extensibleId
          ? { ...field, fields } : field
      )
    }))
  }))
})
