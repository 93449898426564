export enum Locale {
  US = 'en_US',
  FR = 'fr_FR',
}

export enum SimpleLocale {
  us = 'us',
  fr = 'fr',
  es = 'es',
  de = 'de',
  pt = 'pt',
  it = 'it',
  be = 'be',
  ch = 'ch',
  li = 'li',
  lu = 'lu',
  mc = 'mc',
}

export const localeToSimpleLocale = {
  [Locale.US]: SimpleLocale.us,
  [Locale.FR]: SimpleLocale.fr,
}

export const localeToNearSimpleLocales = {
  [Locale.US]: [ SimpleLocale.us ],
  [Locale.FR]: [
    SimpleLocale.fr, SimpleLocale.es, SimpleLocale.de, SimpleLocale.pt, SimpleLocale.it, SimpleLocale.be, SimpleLocale.ch, SimpleLocale.li, SimpleLocale.lu, SimpleLocale.mc,
  ],
}
