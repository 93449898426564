import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { translate } from 'src/Services/translation'
import { EditableField, EditableFieldOptionAutocompleteField, FieldOptionAutocompleteField } from 'src/Types/Field'
import { StoreState } from 'src/Services/Store/reducers'
import AutocompleteField from 'src/Views/FormEditor/Components/AutocompleteField'
import { TargetValueType } from 'src/Types/FormCustomizationOrder'
import translations from '../translations'

const DEFAULT_ITEM: FieldOptionAutocompleteField = {
  field: null, value: null, type: TargetValueType.CONSTANT
}

interface Props {
  field: EditableField
  onChange: (field: EditableField) => void
}

const AutocompleteFields = ({ field, onChange }: Props) => {
  const { language } = useSelector((state: StoreState) => state.Root.user)

  const trans = translate(translations)(language)

  useEffect(() => {
    if (field.options.autocompleteFields?.length === 0)
      addField()
  }, [ field.options.autocompleteFields ])

  const addField = () =>
    onChange({ ...field, options: { ...field.options, autocompleteFields: [ ...field.options.autocompleteFields, DEFAULT_ITEM ] } })

  const onFieldChange = (i: number) => (item: EditableFieldOptionAutocompleteField) =>
    onChange({ ...field, options: { ...field.options, autocompleteFields: field.options.autocompleteFields.map((f, j) => i === j ? item : f) } })

  const onFieldDelete = (i: number) => () =>
    onChange({ ...field, options: { ...field.options, autocompleteFields: field.options.autocompleteFields.filter((f, j) => i !== j) } })

  return <div>
    <Form>
      <Row>
        <Col sm={ 5 }>
          <Form.Label className={ 'fw-bold' }>{ trans('modal.autocompleteFields.targetField') }</Form.Label>
        </Col>
        <Col sm={ 7 }>
          <Form.Label className={ 'fw-bold' }>{ trans('modal.autocompleteFields.value') }</Form.Label>
        </Col>
      </Row>
      <Row className={ 'mb-1' }>
        <Col>
          { field.options.autocompleteFields.length ? field.options.autocompleteFields.map((item, i) =>
            <AutocompleteField key={ i } item={ item } onChange={ onFieldChange(i) } onDelete={ onFieldDelete(i) } />)
            : <i>{ trans('none') }</i> }
        </Col>
      </Row>
      <Button variant={ 'outline-primary' } onClick={ addField } size={ 'sm' }>
        { trans('editField.addValue') }
      </Button>
    </Form>
  </div>
}

export default AutocompleteFields
