import React from 'react'
import { useNavigate } from 'react-router-dom'
import { translate } from 'src/Services/translation'
import { Button } from 'react-bootstrap'
import { setOptions } from 'src/Layouts/View/state/actions'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from 'src/Services/Store/reducers'
import UserListTable from './Components/UserListTable'
import translation from './translations'
import { load } from './state/actions'

const UserList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { language } = useSelector((state: StoreState) => state.Root.user)

  const trans = translate(translation)(language)

  React.useEffect(() => {
    dispatch(setOptions({
      hasPrimaryButton: true,
      onPrimaryButtonClick: () => navigate('/user/new'),
      leftHeaderTemplate: <Button onClick={ onRolesClick }>{ trans('rolePermissions') }</Button>,
    }))

    dispatch(load())
  }, [])

  const onRolesClick = () => navigate('/permissions')

  return (
    <UserListTable/>
  )
}

export default UserList
