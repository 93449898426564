import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container } from 'react-bootstrap'
import { translate } from 'src/Services/translation'
import DeleteModal from 'src/Components/Modals/DeleteModal'
import Modal from 'src/Components/Modals/BaseModal'
import { setOptions } from 'src/Layouts/View/state/actions'
import { AdminTool } from 'src/Types/AdminTool'
import { StoreState } from 'src/Services/Store/reducers'
import translation from './translations'
import AdminToolsTable from './Components/AdminToolsTable'
import AdminToolsForm from './Components/AdminToolsForm'
import { toggleAddAdminTool, toggleUpdateAdminTool, toggleDeleteAdminTool, addAdminTool,
  updateAdminTool, deleteAdminTool, fetchAdminTools } from './state/actions'

const AdminTools = () => {
  const dispatch = useDispatch()

  const { language } = useSelector((state: StoreState) => state.Root.user)
  const {
    isAddingAdminTool,
    isUpdatingAdminTool,
    isDeletingAdminTool,
    adminToolOnUpdate,
  } = useSelector((state: StoreState) => state.AdminTools)

  const trans = translate(translation)(language)

  const onAddClick = () => dispatch(toggleAddAdminTool())

  const onModalAddClose = () => dispatch(toggleAddAdminTool())
  const onModalAddFormSubmit = (data: AdminTool) => dispatch(addAdminTool(data))

  const onModalUpdateClose = () => dispatch(toggleUpdateAdminTool(null))
  const onModalUpdateFormSubmit = (data: AdminTool) => dispatch(updateAdminTool(data))

  const onConfirmDeleteModalClose = () => dispatch(toggleDeleteAdminTool(null))
  const onConfirmDeleteModalConfirm = () => dispatch(deleteAdminTool())

  useEffect(() => {
    dispatch(setOptions({
                          hasPrimaryButton: true,
                          onPrimaryButtonClick: onAddClick,
                        }))
    dispatch(fetchAdminTools())
  }, [ dispatch ])

  return (
      <Container>
        <AdminToolsTable/>
        <Modal isOpen={ isAddingAdminTool } title={ trans('modal.add.title') } onClose={ onModalAddClose }>
          <AdminToolsForm trans={ trans } onSubmit={ onModalAddFormSubmit } />
        </Modal>

        <Modal isOpen={ isUpdatingAdminTool }
               title={ trans('modal.update.title') }
               onClose={ onModalUpdateClose }
        >
          <AdminToolsForm
              trans={ trans }
              onSubmit={ onModalUpdateFormSubmit }
              adminToolUpdate={ adminToolOnUpdate }
          />
        </Modal>

        <DeleteModal
            title={ trans('confirmDeleteModal.title') }
            isOpen={ isDeletingAdminTool }
            onClose={ onConfirmDeleteModalClose }
            onDelete={ onConfirmDeleteModalConfirm }
        >
          { trans('confirmDeleteModal.description') }
        </DeleteModal>
      </Container>
  )
}

export default AdminTools
