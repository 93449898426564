import { Action } from 'src/Services/Store/reducers'
import * as types from './actionTypes'

export interface ViewState {
  showTitle: string | undefined,
  title: string | undefined,
  isHeaderSticky: boolean,
  isLoading: boolean,
  rightHeaderTemplate: null,
  leftHeaderTemplate: null,
  hasPrimaryButton: boolean,
  primaryButtonType: string,
  isPrimaryButtonDisable: boolean,
  onPrimaryButtonClick: null,
}

export const initialState: ViewState = {
  showTitle: undefined,
  title: undefined,
  isHeaderSticky: false,
  isLoading: false,
  rightHeaderTemplate: null,
  leftHeaderTemplate: null,
  hasPrimaryButton: false,
  primaryButtonType: 'ADD',
  isPrimaryButtonDisable: false,
  onPrimaryButtonClick: null,
}

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case types.SET_OPTIONS:
      return {
        ...state,
        showTitle: payload.showTitle !== false,
        title: payload.title,
        isLoading: payload.isLoading || false,
        isHeaderSticky: payload.isHeaderSticky || false,
        hasPrimaryButton: payload.hasPrimaryButton || false,
        rightHeaderTemplate: payload.rightHeaderTemplate || null,
        leftHeaderTemplate: payload.leftHeaderTemplate || null,
        onPrimaryButtonClick: payload.onPrimaryButtonClick || null,
        isPrimaryButtonDisable: payload.isPrimaryButtonDisable || false,
        primaryButtonType: payload.primaryButtonType || 'ADD',
      }
    case types.SET_RIGHT_TEMPLATE:
      return {
        ...state,
        rightHeaderTemplate: payload
      }
    case types.SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload
      }
    default:
      return state
  }
}
