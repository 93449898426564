import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import Table from 'src/Components/NavTable/Table'
import { formatDateTime } from 'src/Utils/Date.ts'
import { translate } from 'src/Services/translation'
import { setOptions } from 'src/Layouts/View/state/actions'
import Actions from './Actions'
import translations from './translations'
import { fetchDocuments } from '../../state/actions'

const PatientDocuments = () => {
  const dispatch = useDispatch()
  const { id } = useParams()

  const { language, locale, portalTimezone } = useSelector(state => state.Root.user)
  const { documents } = useSelector(state => state.Patient)
  const { isLoading } = useSelector(state => state.View)

  const trans = translate(translations)(language)
  const formatLocaleDateTime = formatDateTime(locale, portalTimezone)

  const headers = [
    { label: trans('name'), id: 1 },
    { label: trans('description'), id: 2 },
    { label: trans('uploadedBy'), id: 3 },
    { label: trans('uploadedAt'), id: 4 },
    { label: trans('lastModified'), id: 5 },
    { label: trans('lastModifiedBy'), id: 6 },
    { label: trans('actions'), id: 7 }
  ]

  useEffect(() => {
    dispatch(setOptions({
      title: trans('patientDocuments'),
      hasPrimaryButton: false,
      isLoading: true,
      isHeaderSticky: false,
      rightHeaderTemplate: null
    }))

    dispatch(fetchDocuments(id))
  }, [])

  const rows = documents.map(doc => [
    doc.name,
    doc.description,
    doc.uploadedBy,
    formatLocaleDateTime(doc.createdAt),
    doc.createdAt === doc.updatedAt ? '' : formatLocaleDateTime(doc.updatedAt),
    doc.createdAt === doc.updatedAt ? '' : doc.updatedBy,
    <Actions key={ doc.id } document={ doc } />
  ])

  return <Container>
    <Table columnHeaders={ headers }
           rows={ rows }
           isLoading={ isLoading }
    />
  </Container>
}

export default PatientDocuments
