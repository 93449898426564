import { Button, Col, Form as BForm, Row, Spinner } from 'react-bootstrap'
import React, { FormEvent, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import Select from 'src/Components/Select'
import { EditableFormCustomizationCondition, FormCustomizationConditionType } from 'src/Types/FormCustomizationCondition'
import { getFields } from 'src/Views/FormEditor/state/formReducer'
import CustomizationConditionForm from 'src/Views/FormEditor/Components/CustomizationConditionForm'
import { StoreState } from 'src/Services/Store/reducers'
import { translate } from 'src/Services/translation'
import translation from 'src/Views/FormEditor/translations'
import { EditableFormCustomizationOrder, TargetValueType } from 'src/Types/FormCustomizationOrder'
import { BaseFieldType, EditableField } from 'src/Types/Field'
import { Action, NonScriptAction } from 'src/Views/FormFiller/Types/Field'
import { SlotType } from 'src/Types/Slot'
import TargetValueField from './TargetValueField'
import { AlertMessage } from '../../../Components/Alert'
import SystemField from '../../../Types/SystemField'


const DEFAULT_CONDITION: EditableFormCustomizationCondition = {
  field: null,
  type: FormCustomizationConditionType.VALUE,
  value: null,
  operator: null,
  listColumn: null,
}

interface Props {
  item: EditableFormCustomizationOrder
  onSubmit: (item: EditableFormCustomizationOrder) => void
}

const CustomizationOrderForm = ({ item, onSubmit }: Props) => {

  const { language } = useSelector((state: StoreState) => state.Root.user)
  const { slotTypes: availableSlotTypes } = useSelector((state: StoreState) => state.Dictionary)
  const { form, isCustomizationOrderLoading, systemFields } = useSelector((state: StoreState) => state.FormEditor.formReducer)

  const [ label, setLabel ] = useState<string>(item.label || null)
  const [ action, setAction ] = useState<Action>(item.action || null)
  const [ slotTypes, setSlotTypes ] = useState<SlotType[]>(item.slotTypes)
  const [ targetValueType, setTargetValueType ] = useState<TargetValueType>(item.targetValueType)
  const [ targetValue, setTargetValue ] = useState<string>(item.targetValue)
  const [ targetFields, setTargetFields ] = useState<EditableField[]>(item.targetFields || [])
  const [ conditions, setConditions ] = useState<EditableFormCustomizationCondition[]>(item.conditions || [])

  const trans = translate(translation)(language)

  useEffect(() => {
    setLabel(item.label || null)
    setAction(item.action || null)
    setTargetFields(item.targetFields || [])
    setConditions(item.conditions || [])
    setSlotTypes(item.slotTypes)
    setTargetValueType(item.options?.type)
    setTargetValue(
        item.options?.type === TargetValueType.CONSTANT
          ? item.options?.value
          :  systemFields.find((systemField: SystemField) => systemField.name === item.options?.value)
    )
  }, [ item ])

  useEffect(() => {
    if (action === Action.SET_VALUE && !item.options?.type) {
      item.options.type = TargetValueType.CONSTANT
      setTargetValueType(TargetValueType.CONSTANT)
    }
  }, [ action ])

  const fieldsForSelect = useMemo((): EditableField[] =>
    getFields(form)
      .filter(f => {
        if (!f.id || f.isFromCache) return false

        if (action === Action.SET_VALUE_BY_AVAILABILITY)
          return f.type.baseFieldType === BaseFieldType.REFERENCE && f.list?.refForm?.id
              && slotTypes.some(st => st.eventType.resources.some(r => r.id === f.list.refForm.id))
        else return true
      })
      .map(f => ({ id: f.id, systemName: f.systemName, label: f.label }))
      .sort((a, b) => a.systemName.localeCompare(b.systemName))
  , [ form, action, slotTypes ])

  useEffect(() => {
    setTargetFields(prevState =>
      prevState.filter(tf => fieldsForSelect.some(f => f.id === tf.id)),
    )
  }, [ fieldsForSelect ])

  const onAddConditionButtonClick = () =>
    setConditions([ ...conditions, DEFAULT_CONDITION ])

  const onConditionDelete = (index: number) => () =>
    setConditions(conditions.filter((c, i) => i !== index))

  const onConditionChange = (index: number) => (condition: EditableFormCustomizationCondition) =>
    setConditions(conditions.map((c, i) => i === index ? condition : c))

  const onFormSubmit = (e: FormEvent) => {
    e.preventDefault()
    let options = {}
    if (action === Action.SET_VALUE) {
      options = {
        value: targetValue,
        type: targetValueType
      }
    }

    onSubmit({ ...item, form, label, action, slotTypes, targetFields, conditions, options })
  }

  const addTargetField = (editableFields: EditableField[]) => {
    setTargetFields(
      getFields(form).filter(field => editableFields.map(editableField => editableField.id).includes(field.id))
    )
  }

  return <div className={ 'blue-card' }>
    <BForm onSubmit={ onFormSubmit }>
      <Row>
        <Col>
          <BForm.Group>
            <BForm.Label id={ 'formCustomizationOrderLabelLabel' }>
              { trans('form.customizationOrder.label') } <span className={ 'text-danger' }>*</span>
            </BForm.Label>
            <BForm.Control id={ 'formCustomizationOrderLabelInput' } size={ 'sm' } name={ 'label' } value={ label || '' }
              required onChange={ e => setLabel(e.target.value) }/>
          </BForm.Group>
        </Col>
        <Col>
          <BForm.Group>
            <BForm.Label id={ 'formCustomizationOrderActionLabel' }>
              { trans('form.customizationOrder.action') } <span className={ 'text-danger' }>*</span>
            </BForm.Label>
            <BForm.Select id={ 'formCustomizationOrderActionInput' }
              size={ 'sm' } name={ 'action' } value={ action || '' }
              required onChange={ e => setAction((e.target.value || null) as Action) }>
              <option value={ '' }>{ trans('none') }</option>
              { Object.values(NonScriptAction).map(action => <option key={ action } value={ action }>{ action }</option>) }
            </BForm.Select>
          </BForm.Group>
        </Col>
      </Row>
      <Row className={ 'mt-2 mb-2' }>
        {
          action === Action.SET_VALUE &&
                <Col md={ '12' } className={ 'mt-2' }>
                  <AlertMessage message={ trans('form.customizationOrder.targetValueMessage')  } type={ 'warning' } />
                </Col>
        }
        { action === Action.SET_VALUE_BY_AVAILABILITY && <Col>
          <BForm.Group>
            <BForm.Label id={ 'formCustomizationOrderSlotTypesLabel' }>
              { trans('form.customizationOrder.slotTypes') } <span className={ 'text-danger' }>*</span>
            </BForm.Label>
            <Select id={ 'formCustomizationOrderSlotTypesInput' }
              isMulti name={ 'slotTypes' } options={ availableSlotTypes }
              getOptionValue={ f => f.id }
              getOptionLabel={ f => f.label }
              value={ slotTypes }
              onChange={ (slotTypes: SlotType[]) => setSlotTypes(slotTypes) }
              required
            />
          </BForm.Group>
        </Col> }
        <Col>
          <BForm.Group>
            <BForm.Label id={ 'formCustomizationOrderTargetFieldsLabel' }>
              { trans('form.customizationOrder.targetFields') } <span className={ 'text-danger' }>*</span>
            </BForm.Label>
            <Select id={ 'formCustomizationOrderTargetFieldsInput' }
              isMulti name={ 'targetFields' } options={ fieldsForSelect }
              getOptionValue={ f => f.id }
              getOptionLabel={ f => f.systemName }
              value={ targetFields }
              onChange={ (fields: EditableField[]) => addTargetField(fields) }
              isDisabled={ action === Action.SET_VALUE_BY_AVAILABILITY && !slotTypes.length }
              required
            />
          </BForm.Group>
        </Col>
        { action === Action.SET_VALUE &&
              <TargetValueField
                type={ targetValueType }
                value={ targetValue }
                targetField={ targetFields[0] }
                onTypeChange={ setTargetValueType }
                onValueChange={ setTargetValue }
                withLabel
              />
        }
      </Row>
      <Row>
        <Col>
          <BForm.Label id={ 'formCustomizationOrderConditions' } className={ 'mb-0' }>
            { trans('form.customizationOrder.conditions') }
          </BForm.Label>
          <div>
            <BForm.Text>{ trans('form.customizationOrder.conditionsInfo') }</BForm.Text><br/>
            <BForm.Text>{ trans('form.customizationOrder.conditionsInfo2') }</BForm.Text><br/>
            <strong className={ 'cursor-pointer text-primary' } onClick={ onAddConditionButtonClick }>
              <i className={ 'fas fa-lg fa-plus-circle me-1' }/>
              { trans('form.customizationOrder.addCondition') }
            </strong>
          </div>
          { conditions.map((c, i) =>
            <CustomizationConditionForm key={ i } condition={ c }
              onChange={ onConditionChange(i) } onDelete={ onConditionDelete(i) }/>) }
        </Col>
      </Row>
      <Row className={ 'mt-2' }>
        <Col>
          <Button id={ 'formCustomizationOrderSubmitButton' } type={ 'submit' } disabled={ isCustomizationOrderLoading }>
            { isCustomizationOrderLoading &&
              <Spinner className={ 'me-1' } animation={ 'border' } size={ 'sm' }>
                <span className={ 'visually-hidden' }>Loading...</span>
              </Spinner> }
            { trans('form.customizationOrder.submit') }
          </Button>
        </Col>
      </Row>
    </BForm>
  </div>
}

export default CustomizationOrderForm
