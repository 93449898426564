import moment from 'moment'
import React, { useRef, useState, useEffect, MutableRefObject } from 'react'
import { useSelector } from 'react-redux'
import day from 'dayjs'
import {
  Button, ButtonGroup, Dropdown, DropdownButton,
  Popover, OverlayTrigger, ListGroup, Stack
} from 'react-bootstrap'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import momentPlugin from '@fullcalendar/moment'
import bootstrap5Plugin from '@fullcalendar/bootstrap5'
import { translate } from 'src/Services/translation'
import { formatTime } from 'src/Utils/Date'
import { months, years } from 'src/Components/Calendar/Constants/datePickersConfig'
import translation from 'src/Components/Calendar/translations'
import Filters from 'src/Components/Calendar/Components/Sidebar/Tabs/Events/Filters'
import { Options, SidebarTab } from 'src/Components/Calendar/Types/Calendar'
import { StoreState } from 'src/Services/Store/reducers'
import CalendarEvent from 'src/Components/Calendar/Types/CalendarEvent'
import { DayCellContentArg } from '@fullcalendar/core'
import { DateMarker } from '@fullcalendar/core/internal'

interface Props {
  activeTab: SidebarTab
  calendarOptions: Options
  events: CalendarEvent[]
  mainCalendarRef: MutableRefObject<any>
}

export const EventsTab = ({ events, mainCalendarRef, calendarOptions, activeTab }: Props) => {
  const calendarRef = useRef()

  const { language, locale, portalTimezone } = useSelector((state: StoreState) => state.Root.user)

  const trans = translate(translation)(language)
  const eventsPerDay = 11

  const [ currentMonth, setCurrentMonth ] = useState(() =>
    months.find(({ value }) => value === moment().month()))

  const [ currentYear, setCurrentYear ] = useState(() => years.find(year => year === moment().year()))

  // @ts-ignore
  const getCalendarComponent = () => calendarRef.current.getApi()

  const handleDateClick = (dateInfo: DateClickArg) => {
    mainCalendarRef.current.getApi()
      .changeView('timeGridDay', dateInfo.dateStr)
  }
  useEffect(() =>{
    if (activeTab === SidebarTab.EVENTS) {
      getCalendarComponent().render()
    }
  },[ activeTab ])
  const navigateInMonths = (direction: string) => () => {
    getCalendarComponent()[direction]()
    setCurrentMonth(months.find(({ value }) => moment(getCalendarComponent().getDate()).month() === value))
  }

  const getEventsByDate = (date: DateMarker, events: CalendarEvent[]): CalendarEvent[] =>
      events.filter(e => day(date).isSame(e.extendedProps.start, 'day'))

  const renderDotEvent = (event: CalendarEvent) => (
    <div key={ event.id } className={ 'fc-event-container' }>
      <div
        style={ { backgroundColor: event.extendedProps.color || '#A7C7E7' } }
        className={ 'fc-dot-event' }
      ></div>
    </div>
  )

  const renderEventsAsDots = (events: CalendarEvent[]) => {
    const areEventsOverflow = events.length > eventsPerDay

    if (areEventsOverflow)
      return <>
          { events.slice(0, eventsPerDay).map(renderDotEvent) }
          <i className={ 'fa-solid fa-plus fc-more' }></i>
        </>

    return events.map(renderDotEvent)
  }

  const renderMoreLink = () => <i className={ 'fa-solid fa-plus fc-more' }></i>

  const renderPopoverEvent = ({ id, title, extendedProps: { start } }: CalendarEvent) =>
    <ListGroup.Item key={ id }>
      { `${ formatTime(locale, portalTimezone)(start) } ${ title }` }
    </ListGroup.Item>

  const renderDayCell = (dayInfo: DayCellContentArg) => {
    const { dayNumberText, date } = dayInfo
    const dayEvents = getEventsByDate(date, events)

    const eventsPopover =
      <Popover id={ 'popover-basic' }>
        <Popover.Body>
          <ListGroup>
            { dayEvents.map(renderPopoverEvent) }
          </ListGroup>
        </Popover.Body>
      </Popover>

    return <>
        <div title={ trans('dayCellTitle') }>{ dayNumberText }</div>
        { !!dayEvents.length &&
            <OverlayTrigger placement={ 'right' } overlay={ eventsPopover }>
              <div className={ 'day-events' }>
                { renderEventsAsDots(dayEvents) }
              </div>
            </OverlayTrigger>
          }
      </>
  }

  return <>
    <div>
      <Stack direction={ 'horizontal' } gap={ 0 }>
        <ButtonGroup>
          <Button onClick={ navigateInMonths('prev') }>
            <i className={ 'fas fa-xs fa-chevron-left' }/>
          </Button>
          <Button onClick={ navigateInMonths('next') }>
            <i className={ 'fas fa-xs fa-chevron-right' }/>
          </Button>
        </ButtonGroup>

        <DropdownButton className={ 'ms-auto' } title={ trans(currentMonth.translation) }>
          { months.map(month => {
            const itemProps = {
              title: month.title,
              onClick: () => {
                setCurrentMonth(month)
                getCalendarComponent().gotoDate(moment({
                  month: month.value,
                  year: currentYear
                }).format())
              },
              active: currentMonth.value === month.value,
              key: month.value
            }

            return <Dropdown.Item key={ month.title } { ...itemProps }>{ month.title }</Dropdown.Item>
          }) }
        </DropdownButton>

        <DropdownButton className={ 'ms-1' } title={ currentYear }>
          { years.map(year => {
            const itemProps = {
              title: `${ year }`,
              onClick: () => {
                setCurrentYear(year)
                getCalendarComponent().gotoDate(moment({
                  year,
                  month: currentMonth.value
                }).format())
              },
              active: currentYear === year,
              key: year
            }

            return <Dropdown.Item key={ year } { ...itemProps }>{ year }</Dropdown.Item>
          }) }
        </DropdownButton>
      </Stack>

      <FullCalendar
        ref={ calendarRef }
        fixedWeekCount={ false }
        events={ events }
        dayMaxEvents={ eventsPerDay }
        timeZone={ portalTimezone }
        weekends={ calendarOptions.weekends }
        firstDay={ calendarOptions.startWeek }
        dateClick={ handleDateClick }
        themeSystem={ 'bootstrap5' }
        viewClassNames={ 'calendarSmall' }
        plugins={ [
          dayGridPlugin,
          interactionPlugin,
          timeGridPlugin,
          momentPlugin,
          bootstrap5Plugin
        ] }
        aspectRatio={ 1 }
        headerToolbar={ {
          left: '',
          center: '',
          right: ''
        } }
        displayEventTime={ false }
        eventDisplay={ 'none' }
        moreLinkContent={ renderMoreLink }
        dayCellContent={ renderDayCell }
      />
    </div>
    <Filters />
  </>
}
