import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form } from 'react-bootstrap'
import { translate } from 'src/Services/translation'
import Modal from 'src/Components/Modals/BaseModal'
import SystemNameInput from 'src/Components/SystemNameInput'
import { FIELD_BASE_TYPE_EXTENSIBLE } from 'src/Services/Constants'
import { addField, addFieldInExtensible, toggleFieldDetailsModal } from 'src/Views/FormEditor/state/actions.ts'
import translations from '../translations'

const FieldDetailsModal = () => {
  const dispatch = useDispatch()

  const { language } = useSelector(state => state.Root.user)
  const { form, isFieldDetailsModalOpen, targetField, newField } = useSelector(state => state.FormEditor.formReducer)

  const trans = translate(translations)(language)

  const [ label, setLabel ] = React.useState(null)
  const [ isAvailable, setIsAvailable ] = useState(null)

  const closeRefFieldModal = () => {
    dispatch(toggleFieldDetailsModal())
  }

  const onSubmit = e => {
    e.preventDefault()

    const field = {
      type: newField.type || null,
      label: e.target.label.value,
      systemName: e.target.systemName.value
    }

    if (targetField.type?.baseFieldType === FIELD_BASE_TYPE_EXTENSIBLE)
      dispatch(addFieldInExtensible(field))
    else
      dispatch(addField(field))
  }

  const onSystemNameChange = (systemName, isAvailable) => {
    setIsAvailable(isAvailable)
  }

  return <Modal title={ trans('modal.fieldDetails.title') }
                onClose={ closeRefFieldModal }
                isOpen={ isFieldDetailsModalOpen }
                size={ 'md' }
  >
    <Form onSubmit={ onSubmit }>
      <Form.Group className={ 'mb-2' }>
        <Form.Label className={ 'fw-bold' }>{ trans('editField.headingField') }</Form.Label>
        <Form.Control size={ 'sm' } name={ 'label' } onChange={ e => setLabel(e.target.value) } required />
      </Form.Group>
      <Form.Group className={ 'mb-2' }>
        <Form.Label className={ 'fw-bold' }>{ trans('editField.systemName') }</Form.Label>
        <SystemNameInput size={ 'sm' } label={ label } parent={ form.systemName } model={ 'Field' }
                         required onChange={ onSystemNameChange } />
      </Form.Group>
      <Button type={ 'submit' } className={ 'float-right' } disabled={ !isAvailable }>{ trans('submit') }</Button>
    </Form>
  </Modal>
}

export default FieldDetailsModal
