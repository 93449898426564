import React from 'react'
import { Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import NavbarStart from './Components/NavbarStart'
import NavbarEnd from './Components/NavbarEnd'
// @ts-ignore
import biosLogo from '../../logo.png'

const TopNavbar = () =>
  <Navbar id={ 'appNavbar' } className={ 'py-1' } sticky={ 'top' } collapseOnSelect bg={ 'primary' } variant={ 'dark' }>
    <Navbar.Brand as={ Link } to={ '/' }>
      <img className={ 'logo' } alt={ 'logo' } src={ biosLogo }/>
    </Navbar.Brand>
    <NavbarStart />
    <NavbarEnd />
  </Navbar>

export default TopNavbar
