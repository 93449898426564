export const FETCH_RECENT_LISTS = '[DICTIONARY] FETCH_RECENT_LISTS'
export const RECENT_LISTS_FETCHED = '[DICTIONARY] RECENT_LISTS_FETCHED'
export const RECENT_LISTS_FETCH_ERROR = '[DICTIONARY] RECENT_LISTS_FETCH_ERROR'
export const CLEAR_RECENT_LISTS = '[DICTIONARY] CLEAR_RECENT_LISTS'

export const FETCH_RECENT_VIEWED = '[DICTIONARY] FETCH_RECENT_VIEWED'
export const RECENT_VIEWED_FETCHED = '[DICTIONARY] RECENT_VIEWED_FETCHED'
export const RECENT_VIEWED_FETCH_ERROR = '[DICTIONARY] RECENT_VIEWED_FETCH_ERROR'
export const CLEAR_RECENT_VIEWED = '[DICTIONARY] CLEAR_RECENT_VIEWED'

export const FETCH_LISTS = '[DICTIONARY] FETCH_LISTS'
export const LISTS_FETCHED = '[DICTIONARY] LISTS_FETCHED'
export const CHANGE_LISTS_CURRENT_PAGE = '[DICTIONARY] CHANGE_LISTS_CURRENT_PAGE'
export const CHANGE_LISTS_PAGE_SIZE = '[DICTIONARY] CHANGE_LISTS_PAGE_SIZE'
export const CHANGE_LISTS_SEARCH = '[DICTIONARY] CHANGE_LISTS_SEARCH'
export const LISTS_FETCH_ERROR = '[DICTIONARY] LISTS_FETCH_ERROR'
export const CLEAR_LISTS = '[DICTIONARY] CLEAR_LISTS'
