import { call, put, takeLatest } from 'redux-saga/effects'
import { GlobalActions } from 'src/Types/GlobalActions'
import { Action } from 'src/Services/Store/reducers'
import * as types from './actionTypes'
import { setData, setIsLoading } from './actions'
import apiMethods from '../../../Services/api/apiMethods'

function* doFetchData(props: GlobalActions, { payload: { filter } }: Action) {
  try {
    const { data } = yield call(apiMethods.post, '/audit_trails', { data: filter })

    yield put(setData(data))
    yield put(setIsLoading(false))
  } catch (error) {
    yield put(setIsLoading(false))
    yield put(props.globalActions.handleError(error, 'actionFailed'))
  }
}

function* doFetchInstanceData(props: GlobalActions, { payload: { instance, filter } }: Action) {
  try {
    const { data } = yield call(apiMethods.post, `/audit_trails/instances/${ instance.id }`, { data: filter || {} })

    yield put(setData(data))
    yield put(setIsLoading(false))
  } catch (error) {
    yield put(setIsLoading(false))
    yield put(props.globalActions.handleError(error, 'actionFailed'))
  }
}

function* doFetchSlotData(props: GlobalActions, { payload: { slot, filter } }: Action) {
  try {
    const { data } = yield call(apiMethods.post, `/audit_trails/slots/${ slot.id }`, { data: filter || {} })

    yield put(setData(data))
    yield put(setIsLoading(false))
  } catch (error) {
    yield put(setIsLoading(false))
    yield put(props.globalActions.handleError(error, 'actionFailed'))
  }
}

export default function* zmrSagaWatcher(props: GlobalActions) {
  yield takeLatest(types.FETCH_DATA, doFetchData, props)
  yield takeLatest(types.FETCH_INSTANCE_DATA, doFetchInstanceData, props)
  yield takeLatest(types.FETCH_SLOT_DATA, doFetchSlotData, props)
}
