import React, { FC, MouseEvent } from 'react'

interface Props {
  onClick: (event: MouseEvent<HTMLDivElement>) => void;
}

const CloseIcon: FC<Props> = ({ onClick }) => {
  return (
    <div className={ 'close-icon' } onClick={ onClick }>
      <div className={ 'line' }></div>
      <div className={ 'line' }></div>
    </div>
  )
}

export default CloseIcon
