import React, { useState } from 'react'
import { Form as BForm, Button, Row, Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import SystemNameInput from '../../../Components/SystemNameInput'
import { translateConf } from '../../../Services/translation/index'

const Form = ({ trans, onSubmit = () => {}, item = null }) => {

  const { configTranslations } = useSelector(state => state.Root)

  const [ label, setLabel ] = React.useState(null)
  const [ isAvailable, setIsAvailable ] = useState(null)

  const transConf = translateConf(configTranslations)

  const onFormSubmit = e => {
    e.preventDefault()

    const eventStatus = {
      ...item,
      label: e.target.label.value,
      color: e.target.color.value,
    }

    if (!item)
      eventStatus.systemName = e.target.systemName.value

    onSubmit(eventStatus)
    setLabel(null)
    setIsAvailable(null)
  }

  const onSystemNameChange = (systemName, isAvailable) => {
    setIsAvailable(isAvailable)
  }

  return (
    <BForm onSubmit={ onFormSubmit }>
      { item && <small className={ 'text-muted' }>{ trans('systemName') } : { item.systemName }</small> }
      <Row className={ 'mb-2' }>
        <Col>
          <BForm.Group className={ 'mb-2' }>
            <BForm.Label>{ trans('form.label') }</BForm.Label>
            <BForm.Control name={ 'label' } defaultValue={ item?.label || '' }
              onChange={ e => setLabel(e.target.value) } required
              placeholder={ trans('form.label') }
            />
          </BForm.Group>
        </Col>
        { !item &&
          <Col>
            <BForm.Group className={ 'mb-2' }>
              <BForm.Label>{ trans('form.systemName') }</BForm.Label>
              <SystemNameInput label={ label } model={ 'EventStatus' }
                required onChange={ onSystemNameChange } name={ 'systemName' }
                placeholder={ trans('form.systemName') }
              />
            </BForm.Group>
          </Col> }
      </Row>
      <Row className={ 'mb-2' }>
        <Col>
          <BForm.Group>
            <BForm.Label>{ trans('form.color') }</BForm.Label>
            <BForm.Control defaultValue={ item?.color || '' } name={ 'color' } type={ 'color' }
              placeholder={ trans('form.color') } title={ trans('form.color') } />
          </BForm.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Button variant={ 'success' } disabled={ !item && !isAvailable } style={ { float: 'right' } }
            type={ 'submit' }>
            { trans('submit') }
          </Button>
        </Col>
      </Row>
    </BForm>
  )
}

export default Form
