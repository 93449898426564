
export default {
  english: {
    title: 'Cancellation reason manager',
    addNew: 'Add a cancellation reason',
    entries: 'of {total} entries',
    isActive: 'Show in navbar',
    form: {
      label: 'Label',
      systemName: 'System name',
      systemNameInfo: 'Use only caps and underscore for spacing.',
      color: 'Color',
      isSystem: 'Is system',
    },
    modal: {
      create: {
        title: 'Create a new event cancellation reason'
      },
      update: {
        title: 'Update an event cancellation reason'
      }
    },
    confirmDeleteModal: {
      title: 'Delete cancellation reason',
      description: 'Are you sure you want to delete this event cancellation reason ?'
    }
  },
  french: {
    title: 'Gestionnaire de raison d\'annulation',
    addNew: 'Créer un élément de navigation',
    entries: 'sur {total} entrées',
    isActive: 'Afficher dans la barre de navigation',
    form: {
      label: 'Label',
      systemName: 'Nom système',
      systemNameInfo: 'Utilisez seulement majuscules et tiret bas pour les espaces.',
      color: 'Couleur',
      isSystem: 'Est système',
    },
    modal: {
      create: {
        title: 'Créer une nouvelle raison d\'annulation'
      },
      update: {
        title: 'Modifier une raison d\'annulation'
      }
    },
    confirmDeleteModal: {
      title: 'Supprimer la raison d\'annulation',
      description: 'Êtes-vous sûr de vouloir supprimer cette raison d\'annulation ?'
    }
  }
}
