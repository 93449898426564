import React, { useState } from 'react'
import { Button, Col, Form as BForm, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { translate } from 'src/Services/translation'
import { StoreState } from 'src/Services/Store/reducers'
import { EventCancellationReason } from 'src/Types/EventCancellationReason'
import translation from '../translations'
import SystemNameInput from '../../../Components/SystemNameInput'

interface Props {
  onSubmit: (item: EventCancellationReason) => void
  item?: EventCancellationReason | null
}

interface EventCancellationReasonFormElements extends HTMLFormControlsCollection {
  label: HTMLInputElement
  systemName: HTMLInputElement
}

interface EventCancellationReasonForm extends HTMLFormElement {
  readonly elements: EventCancellationReasonFormElements
}

const Form = ({ onSubmit, item = null }: Props) => {

  const { language } = useSelector((state: StoreState) => state.Root.user)

  const trans = translate(translation)(language)

  const [ label, setLabel ] = React.useState(null)
  const [ isAvailable, setIsAvailable ] = useState(null)

  const onFormSubmit = (e: React.FormEvent<EventCancellationReasonForm>) => {
    e.preventDefault()

    const eventCancellationReason = {
      ...item,
      label: e.currentTarget.elements.label.value,
    }

    if (!item)
      eventCancellationReason.systemName = e.currentTarget.elements.systemName.value

    onSubmit(eventCancellationReason)
    setLabel(null)
    setIsAvailable(null)
  }

  const onSystemNameChange = (value: string, isAvailable: boolean) => {
    setIsAvailable(isAvailable)
  }

  return <BForm onSubmit={ onFormSubmit }>
    { item && <small className={ 'text-muted' }>{ trans('systemName') } : { item.systemName }</small> }
    <Row className={ 'mb-2' }>
      <Col>
        <BForm.Group className={ 'mb-2' }>
          <BForm.Label>{ trans('form.label') }</BForm.Label>
          <BForm.Control name={ 'label' } defaultValue={ item?.label || '' }
            onChange={ e => setLabel(e.target.value) } required
            placeholder={ trans('form.label') }
          />
        </BForm.Group>
      </Col>
      { !item &&
                <Col>
                  <BForm.Group className={ 'mb-2' }>
                    <BForm.Label>{ trans('form.systemName') }</BForm.Label>
                    <SystemNameInput label={ label }
                      model={ 'EventReason' }
                      required
                      onFieldBlur={ null }
                      onChange={ (val: string, e: boolean) => onSystemNameChange(val, e) }
                      name={ 'systemName' }
                      placeholder={ trans('form.systemName') }
                      systemName={ item?.systemName }
                    />
                  </BForm.Group>
                </Col> }
    </Row>

    <Row>
      <Col>
        <Button variant={ 'success' } disabled={ !item && !isAvailable } style={ { float: 'right' } }
          type={ 'submit' }>
          { trans('submit') }
        </Button>
      </Col>
    </Row>
  </BForm>
}

export default Form
