export default {
  english: {
    title: 'Workflow',
    addNew: 'Add',
    entries: 'of {total} entries',
    form: {
      email: 'Email',
      emailHelp: 'Can be an actual email or a system field.',
      sortOrder: 'Sort order',
      trigger: 'Trigger',
      actions: 'Actions',
      role: 'Role',
      user: 'User',
    },
    column: {
      email: 'Email',
      sortOrder: 'Sort order',
      trigger: 'Trigger',
      actions: 'Actions',
      role: 'Role',
      user: 'User',
    },
    modal: {
      create: {
        title: 'Create a new workflow',
      },
      update: {
        title: 'Update a workflow'
      }
    },
    confirmDeleteModal: {
      title: 'Delete workflow',
      description: 'Are you sure you want to delete this workflow ?'
    }
  },
  french: {
    title: 'Workflows',
    addNew: 'Créer',
    entries: 'sur {total} entrées',
    form: {
      email: 'Email',
      emailHelp: 'Peu être un email ou un champ système.',
      sortOrder: 'Ordre',
      trigger: 'Déclencheur',
      actions: 'Actions',
      role: 'Role',
      user: 'Utilisateur',
    },
    column: {
      email: 'Email',
      sortOrder: 'Ordre',
      trigger: 'Déclencheur',
      actions: 'Actions',
      role: 'Role',
      user: 'Utilisateur',
    },
    modal: {
      create: {
        title: 'Créer un nouveau workflow',
      },
      update: {
        title: 'Modifier un workflow'
      }
    },
    confirmDeleteModal: {
      title: 'Supprimer le workflow',
      description: 'Êtes-vous sûr de vouloir supprimer ce workflow ?'
    }
  }
}
