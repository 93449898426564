import { SET_DICTIONARIES, SET_LISTS_DICTIONARY } from './actionTypes'
import { Action } from 'src/Services/Store/reducers'
import { NavigationItem } from 'src/Types/NavigationItem'
import Role from 'src/Types/Role'
import { User } from 'src/Types/User'
import { Form } from 'src/Types/Form'
import { List } from 'src/Types/List'
import { EventReason, EventStatus, EventType } from 'src/Types/Event'
import { Instance } from 'src/Types/Instance'
import { SlotType } from 'src/Types/Slot'
import { FieldType } from 'src/Types/Field'
import Icon from 'src/Types/Icon'
import DocumentType from 'src/Types/DocumentType'

// TODO : types
export interface DictionaryState {
  isReady: boolean,
  formTypes: [],
  listTypes: [],
  fieldTypes: FieldType[],
  documentTypes: DocumentType[],
  roles: Role[],
  users: User[],
  forms: Form[],
  lists: List[],
  eventTypes: EventType[],
  locations: Instance[],
  slotTypes: SlotType[],
  icons: Icon[],
  eventStatuses: EventStatus[],
  eventReasons: EventReason[],
  workflowActionTypes: [],
  workflowTriggerTypes: [],
  workflowTriggerFormEvents: [],
  modules: [],
  navigationItems: NavigationItem[],
  sensitiveFields: [],
  allowedFileFormats: string[],
  taskTypes: [],
  taskCategories: [],
  taskStatuses: [],
  messageTemplates: Instance[],
  documentTemplates: Instance[]
}

export const initialState: DictionaryState = {
  isReady: false,
  formTypes: [],
  listTypes: [],
  fieldTypes: [],
  documentTypes: [],
  roles: [],
  users: [],
  forms: [],
  lists: [],
  eventTypes: [],
  locations: [],
  slotTypes: [],
  icons: [],
  eventStatuses: [],
  eventReasons: [],
  workflowActionTypes: [],
  workflowTriggerTypes: [],
  workflowTriggerFormEvents: [],
  modules: [],
  navigationItems: [],
  sensitiveFields: [],
  allowedFileFormats: [],
  taskTypes: [],
  taskCategories: [],
  taskStatuses: [],
  messageTemplates: [],
  documentTemplates: []
}

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
      case SET_DICTIONARIES:
      return {
        ...state,
        formTypes: payload.formTypes || [],
        listTypes: payload.listTypes || [],
        fieldTypes: payload.fieldTypes || [],
        documentTypes: payload.documentTypes || [],
        roles: payload.roles || [],
        users: payload.users || [],
        forms: payload.forms || [],
        lists: payload.lists || [],
        eventTypes: payload.eventTypes || [],
        locations: payload.locations || [],
        slotTypes: payload.slotTypes || [],
        icons: payload.icons || [],
        eventStatuses: payload.eventStatuses || [],
        eventReasons: payload.eventReasons || [],
        workflowActionTypes: payload.workflowActionTypes || [],
        workflowTriggerTypes: payload.workflowTriggerTypes || [],
        workflowTriggerFormEvents: payload.workflowTriggerFormEvents || [],
        modules: payload.modules || [],
        navigationItems: payload.navigationItems || [],
        sensitiveFields: payload.sensitiveFields || [],
        allowedFileFormats: payload.allowedFileFormats || [],
        taskTypes: payload.taskTypes || [],
        taskCategories: payload.taskCategories || [],
        taskStatuses: payload.taskStatuses || [],
        messageTemplates: payload.messageTemplates || [],
        documentTemplates: payload.documentTemplates || [],
        isReady: true
      }
    case SET_LISTS_DICTIONARY:
      return {
        ...state,
        lists: payload.lists || []
      }
    default:
      return state
  }
}
