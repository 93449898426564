import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { translate } from 'src/Services/translation'
import Modal from 'src/Components/Modals/BaseModal'
import DeleteModal from 'src/Components/Modals/DeleteModal'
import Form from 'src/Views/Workflows/Modals/Workflows/Components/Form'
import translation from 'src/Views/Workflows/Modals/Workflows/translations'
import { StoreState } from 'src/Services/Store/reducers'
import { WorkflowItemForApi } from 'src/Types/WorkflowItem'
import { createItem, deleteItem, setDeletingItem, setUpdatingItem, toggleCreateWorkflowModal, updateItem } from 'src/Views/Workflows/Modals/Workflows/state/actions'

const WorkflowModals = () => {
  const dispatch = useDispatch()
  const { language } = useSelector((state: StoreState) => state.Root.user)
  const { creatingItem, updatingItem, deletingItem } = useSelector((state: StoreState) => state.WorkflowItems)

  const trans = translate(translation)(language)

  const onModalCreateFormSubmit = (data: WorkflowItemForApi) => dispatch(createItem(data))
  const onModalUpdateClose = () => dispatch(setUpdatingItem(null))
  const onModalUpdateFormSubmit = (data: WorkflowItemForApi) => dispatch(updateItem(data))
  const onDeleteModalClose = () => dispatch(setDeletingItem(null))
  const onDeleteModalConfirm = () => dispatch(deleteItem())

  return <>
    <Modal
      isOpen={ !!creatingItem }
      title={ trans('modal.create.title') }
      onClose={ () => dispatch(toggleCreateWorkflowModal()) }
      size={ 'xl' }
    >
      <Form onSubmit={ onModalCreateFormSubmit }/>
    </Modal>

    <Modal isOpen={ !!updatingItem } title={ trans('modal.update.title') } onClose={ onModalUpdateClose } size={ 'xl' }>
      <Form onSubmit={ onModalUpdateFormSubmit } item={ updatingItem }/>
    </Modal>

    <DeleteModal title={ trans('confirmDeleteModal.title') }
                 isOpen={ !!deletingItem }
                 onClose={ onDeleteModalClose }
                 onDelete={ onDeleteModalConfirm }
    >
      { trans('confirmDeleteModal.description') }
    </DeleteModal>
  </>
}

export default WorkflowModals
