import { combineReducers, Reducer } from 'redux'
import formReducer, { FormEditorFormState } from 'src/Views/FormEditor/state/formReducer'
import sectionReducer from '../Components/Section/state/reducer'

export interface FormEditorState {
  formReducer: FormEditorFormState,
  sectionReducer: unknown,
}

const formEditor = combineReducers<FormEditorState>({
  formReducer: formReducer as Reducer<FormEditorFormState>,
  sectionReducer: sectionReducer as Reducer<unknown>,
})

export default formEditor
