export default {
  english: {
    title: 'Message configurator',
    addNew: 'Add',
    entries: 'of {total} entries',
    form: {
      server: 'Server',
      port: 'Port',
      fromEmail: 'From (email)',
      fromName: 'From (name)',
      username: 'Username',
      password: 'Password',
    },
    column: {
      server: 'Server',
      port: 'Port',
      fromEmail: 'From (email)',
      fromName: 'From (name)',
      username: 'Username',
      password: 'Password',
    },
    modal: {
      create: {
        title: 'Create a new config',
      },
      update: {
        title: 'Update a config',
      }
    },
    confirmDeleteModal: {
      title: 'Delete config',
      description: 'Are you sure you want to delete this config ?'
    }
  },
  french: {
    title: 'Configurations de message',
    addNew: 'Créer',
    entries: 'sur {total} entrées',
    form: {
      server: 'Serveur',
      port: 'Port',
      fromEmail: 'Depuis (email)',
      fromName: 'Depuis (nom)',
      username: 'Nom d\'utilisateur',
      password: 'Mot de passe',
    },
    column: {
      server: 'Serveur',
      port: 'Port',
      fromEmail: 'Depuis (email)',
      fromName: 'Depuis (nom)',
      username: 'Nom d\'utilisateur',
      password: 'Mot de passe',
    },
    modal: {
      create: {
        title: 'Créer uns nouvelle configuration'
      },
      update: {
        title: 'Modifier une configuration'
      }
    },
    confirmDeleteModal: {
      title: 'Supprimer la configuration',
      description: 'Êtes-vous sûr de vouloir supprimer cette configuration ?'
    }
  }
}
