import * as types from 'src/Views/UserForm/state/actionTypes'
import { User } from 'src/Types/User'
import { NavigateFunction } from 'react-router/dist/lib/hooks'
import Patient from 'src/Types/Patient'
import { UuidV4 } from 'src/Types/Uuid'

export const reset = () => ({
  type: types.RESET
})

export const fetchUser = (id: UuidV4) => ({
  type: types.FETCH_USER,
  payload: { id }
})

export const setUser = (user: User|null) => ({
  type: types.SET_USER,
  payload: { user }
})

export const setNewUser = (user: User|null) => ({
  type: types.SET_NEW_USER,
  payload: { user }
})

export const createUser = (user: User, navigate: NavigateFunction) => ({
  type: types.CREATE_USER,
  payload: { user, navigate }
})

export const updateUser = (id: UuidV4, user: User, navigate: NavigateFunction) => ({
  type: types.UPDATE_USER,
  payload: { id, user, navigate }
})

export const deleteUser = (id: UuidV4, navigate: NavigateFunction) => ({
  type: types.DELETE_USER,
  payload: { id, navigate }
})

export const blockUser = (id: UuidV4) => ({
  type: types.BLOCK_USER,
  payload: { id }
})

export const unblockUser = (id: UuidV4) => ({
  type: types.UNBLOCK_USER,
  payload: { id }
})

export const createPatientUser = (
  id: Patient['id'],
  firstName: Patient['FIRST_NAME#PATIENT'],
  lastName: Patient['LAST_NAME#PATIENT'],
  email: Patient['EMAIL#PATIENT'],
  roleId: string,
  mobilePhone?: Patient['MOBILE_PHONE#PATIENT'],
) => ({
  type: types.CREATE_PATIENT_USER,
  payload: { id, firstName, lastName, email, roleId, mobilePhone }
})
