import React from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row } from 'react-bootstrap'
import { translate } from 'src/Services/translation'
import { handleError } from 'src/Services/Store/Root/actions'
import translation from './translations'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
    this.trans = translate(translation)(this.props.language)
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true })
    const { dispatch } = this.props
    dispatch(handleError(error, null, info))
    // Not sure why object was broke as string. Keep in case there is an issue with this
    // dispatch(handleError(JSON.stringify(error?.message || error?.toString()), null, info))
  }

  render() {
   if (this.state.hasError)
      return <Container className={ 'mt-5' }>
        <Row>
          <Col>
            <h1>{ this.trans('title') }</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              { this.trans('body.try') }
              <a href={ '#' } onClick={ () => window.location.reload() }>{ this.trans('body.reloadThePage') }</a>
              { this.trans('body.or') }
              <a href={ '/' }>{ this.trans('body.goToHomepage') }</a>
              { this.trans('body.otherWays') }
            </p>
          </Col>
        </Row>
      </Container>

    return this.props.children
  }
}

export default connect(null)(ErrorBoundary)
