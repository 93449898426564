import { Translation } from 'src/Types/Translation'

const translation: Translation = {
  english: {
    title: 'Admin Tools manager',
    AddNewTool: 'Add a new tool',
    searchList: 'Search a list',
    addNewList: 'Add a list',
    entries: 'of {total} entries',
    exportFields: 'Configure Export Fields',
    systemFields: 'Manage System Fields',
    form: {
      name: 'Name',
      icon: 'Icon',
      internalLink: 'Internal link',
      externalLink: 'External link',
      baseAdminTool: 'Base admin tool',
      defaultBaseAdminToolOption: 'Select a base tool',
    },
    modal: {
      newList: {
        title: 'Add a new tool',
        label: 'Role',
        description: 'Text',
        systemName: 'System name',
        listType: 'Start Date',
        formType: 'End Date',
      },
      add: {
        title: 'Create a new tool',
      },
      update: {
        title: 'Update an admin tool',
      },
    },
    confirmDeleteModal: {
      title: 'Delete tool',
      description: 'Are you sure you want to delete this tool ?'
    }
  },
  french: {
    title: 'Gestionnaire d\'outil admin',
    AddNewTool: 'Ajouter un nouvel outil',
    searchList: 'Chercher une liste',
    addNewList: 'Créer une liste',
    entries: 'sur {total} entrées',
    exportFields: 'Configurer les champs d\'exportation',
    systemFields: 'Gérer les champs système',
    form: {
      name: 'Nom',
      icon: 'Icône',
      internalLink: 'Lien interne',
      externalLink: 'Lien externe',
      baseAdminTool: 'Outil admin de base',
      defaultBaseAdminToolOption: 'Sélectionnez un outil admin de base',
    },
    modal: {
      newList: {
        title: 'Ajouter un nouvel outil',
        description: 'Description',
        systemName: 'Nom système',
        listType: 'Type de liste',
        formType: 'Formulaire',
      },
      add: {
        title: 'Créer un nouvel outil',
      },
      update: {
        title: 'Modifier un outil',
      },
    },
    confirmDeleteModal: {
      title: 'Supprimer l\'outil',
      description: 'Êtes-vous sûr de vouloir supprimer cet outil ?'
    }
  },
}

export default translation
