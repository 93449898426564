import { Action } from 'src/Services/Store/reducers'
import { AuditTrailLog } from 'src/Types/AuditTrailLog'
import * as types from './actionTypes'

export interface AuditTrailsSimpleTableState {
  rows: AuditTrailLog[],
  isLoading: boolean,
}

const initialState: AuditTrailsSimpleTableState = {
  rows: [],
  isLoading: false,
}

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case types.FETCH_DATA:
      return {
        ...state,
        isLoading: true,
      }
    case types.FETCH_INSTANCE_DATA:
      return {
        ...state,
        isLoading: true,
      }
      case types.FETCH_SLOT_DATA:
      return {
        ...state,
        isLoading: true,
      }
    case types.SET_DATA:
      return {
        ...state,
        rows: payload.rows || [],
      }
    case types.SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload.isLoading,
      }
    default:
      return state
  }
}
