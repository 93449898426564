import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { translate } from 'src/Services/translation'
import { submitConfirmModal, toggleConfirmModal } from 'src/Services/Store/GlobalControls/actions'
import translation from './translations'
import ConfirmModal from '../ConfirmModal'

/**
 * ConfirmModal but managed by State and usable by every component
 */
const SharedConfirmModal = () => {
  const dispatch = useDispatch()

  const { language } = useSelector(state => state?.Root?.user)
  const { isConfirmModalOpen } = useSelector(state => state?.GlobalControls)

  const trans = translate(translation)(language)

  const onConfirm = () => dispatch(submitConfirmModal())
  const onCancel = () => dispatch(toggleConfirmModal())

  return (
    <ConfirmModal isOpen={ isConfirmModalOpen }
                  onConfirm={ onConfirm }
                  onClose={ onCancel }
                  title={ trans('areYouSure') }
    />
  )
}

export default SharedConfirmModal
