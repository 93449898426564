import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'src/Components/Modals/BaseModal'
import { translate } from 'src/Services/translation'
import translation from 'src/Views/FormFiller/translations'
import {
  fetchMsSanteTrackingData,
  sendMsSanteTrackingRequest,
  toggleMsSanteTrackingModal
} from 'src/Views/FormFiller/state/actions'
import { Badge, OverlayTrigger, Table, Tooltip } from 'react-bootstrap'
import { useEffect } from 'react'
import { StoreState } from 'src/Services/Store/reducers'
import { MS_SANTE_CAN_REFRESH_STATUS } from 'src/Services/Constants/FeatureSystemFields'
import { computeTrackingGlobalDetailedStatus, getRecipientDetailedStatus } from '../utils/MSSante'

const MsSanteTrackingModal = () => {
  const dispatch = useDispatch()
  const { language } = useSelector((state: StoreState) => state.Root.user)
  const { openedInstance } = useSelector((state: StoreState) => state.FormFiller)
  const { isMsSanteTrackingModalOpen, tracking } = useSelector((state: StoreState) => state.FormFiller.msSante)


  const trans = translate(translation)(language)

  useEffect(() => {
    dispatch(fetchMsSanteTrackingData(openedInstance.id))
  }, [])

  return (
    <Modal isOpen={ isMsSanteTrackingModalOpen }
      title={ null }
      onClose={ () => dispatch(toggleMsSanteTrackingModal(false)) }
      size={ 'lg' }
      modalClass={ 'text-primary border border-primary border-1' }
      animation={ 'false' }
    >
      <h5 className={ 'mb-4' }>
        { trans('modal.msSanteTrackingModal.title') }
        {
                    tracking?.recipients && MS_SANTE_CAN_REFRESH_STATUS.includes(computeTrackingGlobalDetailedStatus(tracking)?.value) &&
                    <OverlayTrigger
                      key={ 'top' }
                      placement={ 'top' }
                      overlay={
                        <Tooltip id={ 'field_tooltip' }>
                          { trans('modal.msSanteTrackingModal.refresh') }
                        </Tooltip>
                      }
                    >
                      <a
                        href={ '#' }
                        className={ 'ms-2 text-dark' }
                        onClick={ e => dispatch(sendMsSanteTrackingRequest(openedInstance.id)) }
                      >
                        <i className={ 'fa-solid fa-rotate' }></i>
                      </a>
                    </OverlayTrigger>
        }
      </h5>
      <Table striped bordered hover>
        <thead>

        </thead>
        <tbody>
          {
                    tracking?.recipients && tracking.recipients.map((recipient, index) =>
              <tr key={ index }>
                <td>{ recipient.email }</td>
                <td>
                  <Badge
                    bg={ getRecipientDetailedStatus(recipient)?.color }
                  >
                    { trans(`modal.msSanteStatus.${ getRecipientDetailedStatus(recipient)?.label }`) }
                  </Badge>

                </td>
                <td></td>
              </tr>
            )
          }
        </tbody>
      </Table>
      <h5>{ trans('modal.msSanteTrackingModal.files.title') }</h5>
      <Table striped bordered hover>
        <tbody>
          {
                    tracking?.files && tracking.files.map((file, index) =>
              <tr key={ index }>
                <td>{ file }</td>
              </tr>
            )
          }
        </tbody>
      </Table>
    </Modal>
  )
}

export default MsSanteTrackingModal
