import Config from 'src/Types/Config'

export const getApiBaseUrl = (config: Config): string => config.API_BASE_URL
export const getFullApiBaseUrl = (config: Config): string => `${ getApiBaseUrl(config) }/${ config.PORTAL_NAME }`

export const logout = (config: Config, useSoftLogout = true): void => {
  const route = process.env.NODE_ENV === 'development' ? 'authenticate/logout' : (useSoftLogout ? 'soft_logout' : 'logout')

  window.location.href = `${ getFullApiBaseUrl(config) }/${ route }?redirectUrl=${ window.location.origin }`
}
