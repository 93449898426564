import React from 'react'
import Select from 'src/Components/Select'
import { useDispatch } from 'react-redux'
import { FieldEvent } from 'src/Views/FormFiller/Types/Field'
import { Field } from 'src/Types/Field'
import getFieldOptionValues from 'src/Views/FormFiller/utils/getFieldOptionValues'
import { forSingleOptionValue } from 'src/Views/FormFiller/utils/getFieldSelectedOptionValue'
import { fieldEvent } from '../../state/actions'

export interface Props {
  field: Field
  isDisabled: boolean
  isRequired: boolean
}

const SelectField = ({ field, isDisabled = false, isRequired = false }: Props) => {
  const dispatch = useDispatch()

  const getValue = () => forSingleOptionValue(field)
  const onChange = (value: string|null) => dispatch(fieldEvent(field, FieldEvent.VALUE_CHANGE, { value }))

  const options = React.useMemo(() => getFieldOptionValues(field), [ field, field.options ])

  return <Select id={ `field${ field.id }` }
                 isClearable
                 value={ getValue() }
                 options={ options }
                 onChange={ selected => onChange(selected?.systemName || null) }
                 getOptionValue={ o => o.systemName }
                 getOptionLabel={ o => o.label }
                 isDisabled={ isDisabled }
                 required={ isRequired }
  />
}

export default SelectField
