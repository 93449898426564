import { call, put, select, takeLatest } from 'redux-saga/effects'
import apiMethods from 'src/Services/api/apiMethods'
import { CREATE_TRIGGER, DELETE_TRIGGER, UPDATE_TRIGGER } from './actionTypes'
import { itemUpdated, itemDeleted, itemCreated } from './actions'

const STORE = 'WorkflowTriggers'

function* updateItem(props, { payload }) {
  try {
    const item = (yield select())[STORE].updatingItem
    const { data } = yield call(apiMethods.update, `/workflow/triggers/${ item.id }`, { data: payload })
    yield put(itemUpdated(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'updateFailed'))
  }
}

function* deleteItem(props) {
  try {
    const { id } = (yield select())[STORE].deletingItem
    yield call(apiMethods.delete, `/workflow/triggers/${ id }`)
    yield put(itemDeleted(id))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'deleteFailed'))
  }
}

function* createItem(props, { payload }) {
  try {
    const { data } = yield call(apiMethods.create, '/workflow/triggers', { data: payload })
    yield put(itemCreated(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'createFailed'))
  }
}

export default function* workflowTriggersSagaWatcher(props) {
  yield takeLatest(CREATE_TRIGGER, createItem, props)
  yield takeLatest(DELETE_TRIGGER, deleteItem, props)
  yield takeLatest(UPDATE_TRIGGER, updateItem, props)
}
