import React, { MouseEvent } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { translateConf } from 'src/Services/translation'
import { StoreState } from 'src/Services/Store/reducers'
import { FieldEvent } from 'src/Views/FormFiller/Types/Field'
import { Field } from 'src/Types/Field'
import { ButtonSize } from 'src/Views/FormFiller/Types/Size'
import { useNavigate } from 'react-router-dom'
import { fieldEvent } from '../../../state/actions'

export interface SimpleHTMLEventTarget {
  id: string
  className: string
}

export interface SimpleHTMLEvent {
  target?: SimpleHTMLEventTarget
}

interface Props {
  field: Field,
  isLoading?: boolean
  isDisable?: boolean
  size?: ButtonSize
}

const GenericButtonField = ({
  field = null,
  isLoading = false,
  isDisable = false,
  size = ButtonSize.sm,
}: Props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { configTranslations } = useSelector((state: StoreState) => state.Root)

  const transConf = translateConf(configTranslations)

  const onButtonClick = async (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()

    const target = (e.target as HTMLInputElement)

    // Minimize payload size by picking necessary event data
    const event: SimpleHTMLEvent = {
      target: {
        id: target.id,
        className: target.className,
      },
    }

    dispatch(fieldEvent(field, FieldEvent.CLICK, event, navigate))
  }

  return <Button
    size={ size }
    variant={ 'info' }
    id={ `field${ field.id }` }
    onClick={ onButtonClick }
    disabled={ isDisable }
  >
    { isLoading && <Spinner
      as={ 'span' }
      animation={ 'border' }
      size={ 'sm' }
      role={ 'status' }
      aria-hidden={ 'true' }
      className={ 'me-2' }
    /> }
    { transConf('FIELD')(field) }
  </Button>
}

export default GenericButtonField
