import React from 'react'
import Calendar from 'src/Components/Calendar'
import { Row, Col, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { setOptions } from 'src/Layouts/View/state/actions'
import { setIsListsOpen } from './state/actions'

const Scheduling = () => {
  const dispatch = useDispatch()

  const { isListsOpen } = useSelector(state => state.Scheduling)

  const renderLeftHeaderTemplate = () => <>
    { /*<Col sm={'auto'}>
        <Button onClick={() => dispatch(toggleSidebar())} size={'sm'}>
          <i className={`fas fa-${!isSidebarOpen ? 'plus' : 'minus'}`}/>{' '}
          Sidebar
        </Button>
      </Col>*/ }
    <Button
      variant={ 'secondary' }
      size={ 'sm' }
      onClick={ () => dispatch(setIsListsOpen(!isListsOpen)) }
      className={ 'me-2' }
    >
      <i className={ `fas fa-${ !isListsOpen ? 'plus' : 'minus' }` }/> List
    </Button>
    <Button variant={ 'secondary' } disabled size={ 'sm' }>
      <i className={ 'fas fa-print' }/> List
    </Button>
  </>

  React.useEffect(() => {
    dispatch(setOptions({
      showTitle: false,
      hasPrimaryButton: false,
      //leftHeaderTemplate: renderLeftHeaderTemplate()
    }))
  }, [ dispatch ])

  return <>
    { /*<Row className={ 'mb-3' }>
      <Col>
        <SchedulingLists/>
      </Col>
    </Row> */ }
    <Row>
      <Col>
        <Calendar/>
      </Col>
    </Row>
  </>
}

export default Scheduling
