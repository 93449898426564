import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container } from 'react-bootstrap'
import { translate } from 'src/Services/translation'
import Modal from 'src/Components/Modals/BaseModal'
import DeleteModal from 'src/Components/Modals/DeleteModal'
import { setOptions } from 'src/Layouts/View/state/actions'
import translation from './translations'
import FieldTypesTable from './Components/FieldTypesTable'
import FieldTypeForm from './Components/FieldTypeForm'
import { fetchFieldTypes, fetchBaseFieldTypes, toggleCreateFieldType, createFieldType, toggleDeleteFieldType,
  updateFieldType, deleteFieldType, toggleUpdateFieldType } from './state/actions'

const FieldTypes = () => {
  const dispatch = useDispatch()
  const { language } = useSelector(state => state.Root?.user)
  const {
    isCreatingFieldType,
    isDeletingFieldType,
    baseFieldTypes,
    isUpdatingFieldType,
    fieldTypeOnUpdate,
    fieldTypes
  } = useSelector(state => state.FieldTypes)

  const onCreateClick = () => dispatch(toggleCreateFieldType())
  const onModalCreateClose = () => dispatch(toggleCreateFieldType())
  const onModalCreateFormSubmit = data => dispatch(createFieldType(data))
  const onModalUpdateClose = () => dispatch(toggleUpdateFieldType(null))
  const onModalUpdateFormSubmit = data => dispatch(updateFieldType(data))
  const onConfirmDeleteModalClose = () => dispatch(toggleDeleteFieldType(null))
  const onConfirmDeleteModalConfirm = () => dispatch(deleteFieldType())

  useEffect(() => {
    dispatch(setOptions({
      hasPrimaryButton: true,
      onPrimaryButtonClick: onCreateClick
    }))
    if (!fieldTypes?.length)
      dispatch(fetchFieldTypes())

    if (!baseFieldTypes?.length)
      dispatch(fetchBaseFieldTypes())
  }, [ dispatch ])

  const trans = translate(translation)(language)

  return (
    <Container>
      <FieldTypesTable/>

      <Modal isOpen={ isCreatingFieldType } title={ trans('modal.create.title') } onClose={ onModalCreateClose }>
        <FieldTypeForm trans={ trans }
                       baseFieldTypes={ baseFieldTypes }
                       onSubmit={ onModalCreateFormSubmit }
        />
      </Modal>

      <Modal isOpen={ isUpdatingFieldType } title={ trans('modal.update.title') } onClose={ onModalUpdateClose }>
        <FieldTypeForm trans={ trans }
                       baseFieldTypes={ baseFieldTypes }
                       onSubmit={ onModalUpdateFormSubmit }
                       fieldType={ fieldTypeOnUpdate }
        />
      </Modal>

      <DeleteModal title={ trans('confirmDeleteModal.title') }
                   isOpen={ isDeletingFieldType }
                   onClose={ onConfirmDeleteModalClose }
                   onDelete={ onConfirmDeleteModalConfirm }
      >
        { trans('confirmDeleteModal.description') }
      </DeleteModal>
    </Container>
  )
}

export default FieldTypes
