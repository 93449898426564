import React from 'react'
import { useSelector } from 'react-redux'
import { Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { translate } from 'src/Services/translation'
import { formatDateTime } from 'src/Utils/Date'
import { boldifySearchResults } from 'src/Services/UserHelpers'
import { StoreState } from 'src/Services/Store/reducers'
import { EditablePatient } from 'src/Types/Patient'
import translations from '../translations'

interface Props {
  patient: EditablePatient
  onClick: (patient: EditablePatient) => void
}

const PatientSearchResultRow = ({ patient, onClick }: Props) => {
  const { language, locale, portalTimezone } = useSelector((state: StoreState) => state.Root.user)
  const { searchInputValue } = useSelector((state: StoreState) => state.Header)
  const trans = translate(translations)(language)

  const formatSearchedItem = (item: string | null) => {
    if (searchInputValue && item) {
      const formattedItem = boldifySearchResults(searchInputValue, item)
      return <span dangerouslySetInnerHTML={ { __html: formattedItem } }/>
    }
  }

  const isNotEmptyString = (content: string) => content !== null && content !== '' && content !== ' '

  return (
    <Dropdown.Item as={ Link }
      className={ 'text-primary text-wrap' }
      to={ `/patient/${ patient.id }/instance/${ patient.id }` }
      onClick={ () => onClick(patient) }
      tabIndex={ 0 }
      data-is={ 'resultItem' }
    >
      { isNotEmptyString(patient['USE_NAME#PATIENT'])
        ? formatSearchedItem(patient['USE_NAME#PATIENT'])
        : isNotEmptyString(patient['LAST_NAME#PATIENT'])
          ? formatSearchedItem(patient['LAST_NAME#PATIENT'])
          : <i>{ trans('noLastName') }</i>
      },&nbsp;
      { isNotEmptyString(patient['USE_NAME#PATIENT']) && isNotEmptyString(patient['LAST_NAME#PATIENT'])
        ? <>{ trans('born') } { formatSearchedItem(patient['LAST_NAME#PATIENT']) }, </>
        : ''
      }
      { isNotEmptyString(patient['FIRST_NAME#PATIENT']) ? formatSearchedItem(patient['FIRST_NAME#PATIENT']) : <i>{ trans('noFirstName') }</i> } -&nbsp;
      { isNotEmptyString(patient['DOB#PATIENT'])
        ? formatDateTime(locale, portalTimezone)(patient['DOB#PATIENT'], true, false)
        : <i>{ trans('noDateOfBirth') }</i>
      }
      { isNotEmptyString(patient['MOBILE_PHONE#PATIENT']) ? <> - { formatSearchedItem(patient['MOBILE_PHONE#PATIENT']) }</> : '' }
      { isNotEmptyString(patient['HOME_PHONE#PATIENT']) ? <> - { formatSearchedItem(patient['HOME_PHONE#PATIENT']) }</> : '' }
      { isNotEmptyString(patient['EXTERNAL_ID#PATIENT']) ? <> - { formatSearchedItem(patient['EXTERNAL_ID#PATIENT']) }</> : '' }
    </Dropdown.Item>
  )
}

export default PatientSearchResultRow
