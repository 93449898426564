import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { translate } from 'src/Services/translation'
import FastListCreator from 'src/Components/FastListCreator/index'
import Modal from 'src/Components/Modals/BaseModal'
import translation from '../translations'
import { setRefFieldModalOpened, addReferenceField, setSavingRefField } from '../state/actions'

const AddRefFieldModal = () => {
  const dispatch = useDispatch()

  const { form, targetField, refFieldModalOpened, savingRefField }
    = useSelector(state => state.FormEditor.formReducer)
  const { language } = useSelector(state => state.Root.user)

  const trans = translate(translation)(language)

  const closeRefFieldModal = () => {
    dispatch(setRefFieldModalOpened(false))
  }

  const onSubmit = list => {
    dispatch(addReferenceField({ targetField }, list))
    dispatch(setSavingRefField(true))
  }

  return <Modal title={ trans('refModal.title') }
                onClose={ closeRefFieldModal }
                isOpen={ refFieldModalOpened }
  >
    <FastListCreator form={ form } isSaving={ savingRefField } onSubmit={ onSubmit } />
  </Modal>
}

export default AddRefFieldModal
