import React, { FC, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Badge } from 'react-bootstrap'
import { setDraggedFieldType } from 'src/Views/FormEditor/state/actions'
import { FieldType as FieldTypeType } from 'src/Types/Field'

interface FieldTypeProps {
  fieldType: FieldTypeType
}

export const FieldType: FC<FieldTypeProps> = ({ fieldType }) => {
  const dispatch = useDispatch()

  const dispatchDraggedFieldType = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.dataTransfer.setData('field-type', fieldType.id.toString())
    dispatch(setDraggedFieldType(fieldType))
  }, [ dispatch, fieldType ])

  return (
    <Badge
      title={ fieldType.name }
      bg={ 'primary' }
      className={ 'draggable cursor-drag px-2 pt-2 d-flex align-items-center flex-column mb-2' }
      draggable={ true }
      onDragStart={ dispatchDraggedFieldType }
    >
      <div>
        <i className={ `mb-1 ${ fieldType.icon }` } />
      </div>
      <div>
        <small>{ fieldType.name }</small>
      </div>
    </Badge>
  )
}
