import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Button, ButtonGroup, Card, Col, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { Spinner, StickyMenu } from 'src/Components/General'
import { translate } from 'src/Services/translation'
import Sections from 'src/Components/Sections'
import { setOptions } from 'src/Layouts/View/state/actions'
import AuditTrailsModal from 'src/Components/AuditTrailsModal/index.tsx'
import Modal from 'src/Components/Modals/BaseModal'
import SwpConfigModal from 'src/Views/FormEditor/Components/FeatureConfig/SwpConfigModal.tsx'
import { AuditTrailLogFields } from 'src/Types/AuditTrailLog'
import { ColumnValueType, FilterOperator } from 'src/Types/RequestFilter'
import { SortItemDirection } from 'src/Types/Pagination'
import { hasUserModulePermission } from 'src/Utils/index.ts'
import { MODULE_AUDIT_TRAILS } from 'src/Services/Constants/Config/Modules'
import { PermissionAccessLevel } from 'src/Types/Permission'
import MsSanteConfigModal from 'src/Views/FormEditor/Components/FeatureConfig/MsSanteConfigModal.tsx'
import CustomizationConfigurator from 'src/Views/FormEditor/Components/CustomizationConfigurator.tsx'
import { closeEditField, loadForm, saveForm, toggleCustomizationOrdersModal, toggleDetailsModal, toggleScriptDialog, undoChanges } from 'src/Views/FormEditor/state/actions.ts'
import RdConfigModal from 'src/Views/FormEditor/Components/FeatureConfig/RdConfigModal'
import FieldSettings from 'src/Views/FormEditor/Components/FieldSettings'
import CalculationFieldModal from './Components/CalculationFieldModal.tsx'
import EditableSection from './Components/Section'
import translation from './translations'
import FieldList from './Components/FieldList'
import DetailsModal from './Components/DetailsModal'
import ScriptModal from './Components/ScriptModal'
import FieldDetailsModal from './Components/FieldDetailsModal'
import AddRefFieldModal from './Components/AddRefFieldModal'
import FullRowModal from './Components/FullRowModal'
import TitleTemplateEditor from './Components/TitleTemplateEditor'

const FormEditor = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { user } = useSelector(state => state.Root)
  const { editedField, loading, isFieldConditionsModalOpen } = useSelector(state => state.FormEditor.formReducer)
  const { lockedBy, titleTemplate, sections } = useSelector(state => state.FormEditor.formReducer.form)

  const changeLogFilter = React.useMemo(() => ({
    offset: 0,
    limit: 100,
    sortItems: [ { column: AuditTrailLogFields.TIMESTAMP, direction: SortItemDirection.DESC } ],
    filters: [
      {
        column: AuditTrailLogFields.CONTEXT,
        type: ColumnValueType.STRING,
        operator: FilterOperator.EQUAL,
        value: 'FORM'
      },
      {
        column: AuditTrailLogFields.CONTEXT_IDENTIFIER,
        type: ColumnValueType.STRING,
        operator: FilterOperator.EQUAL,
        value: id
      }
    ]
  }), [ id ])

  useEffect(() => {
    dispatch(loadForm(id))
  }, [ dispatch, id ])

  const [ isAuditTrailsModalOpen, setIsAuditTrailsModalOpen ] = useState(false)

  const trans = translate(translation)(user.language)

  const openDetailsDialog = e => dispatch(toggleDetailsModal())
  const openScriptDialog = e => dispatch(toggleScriptDialog())
  const onCancelClick = () => dispatch(undoChanges(id, navigate))
  const saveThisForm = withExit => dispatch(saveForm(id, navigate, withExit))

  const renderLeftHeaderTemplate = () => <>
    <i title={ trans('lockedTooltip') }
      className={ `me-2 fas fa-${ lockedBy ? 'lock locked' : 'unlock' }` }
      style={ { fontSize: '1.5em' } }
    />
    <Button variant={ 'primary' } size={ 'sm' } onClick={ openDetailsDialog } className={ 'me-2' }>
      { trans('viewDetails') }
    </Button>
    { hasUserModulePermission(user, MODULE_AUDIT_TRAILS, PermissionAccessLevel.READ) &&
      <Button variant={ 'outline-primary' } size={ 'sm' } onClick={ onChangelogClick } className={ 'me-2' }>
        <i className={ 'icon fas fa-history' }/>
      </Button> }
    <ButtonGroup>
      <Button variant={ 'outline-secondary' } size={ 'sm' } onClick={ openScriptDialog }>
        <i className={ 'far fa-file me-1' }/>
        <span>{ trans('scripts') }</span>
      </Button>
      <Button variant={ 'outline-secondary' } size={ 'sm' } as={ Link }
        to={ `/system_fields/manage_system_fields/${ id }` }>
        <i className={ 'fas fa-align-justify me-1' }/>
        <span>{ trans('systemFields') }</span>
      </Button>
      <Button size={ 'sm' } variant={ 'outline-secondary' } title={ trans('configureConditionalActions') }
        onClick={ e => dispatch(toggleCustomizationOrdersModal()) }>
        <i className={ 'fa-solid fa-microchip me-1' }/>
        { trans('configureConditionalActionsButton') }
      </Button>
    </ButtonGroup>
  </>

  const renderRightHeaderTemplate = () => <>
    <div>
      <Button variant={ 'primary' } className={ 'me-2' } onClick={ () => saveThisForm(true) }>
        <i className={ 'far fa-save me-1' }/>
        <span>{ trans('saveExit') }</span>
      </Button>
      <Button variant={ 'primary' } className={ 'me-2' } onClick={ () => saveThisForm(false) }>
        <i className={ 'far fa-save me-1' }/>
        <span>{ trans('save') }</span>
      </Button>
      <Button variant={ 'danger' } onClick={ onCancelClick }>
        { trans('cancel') }
      </Button>
    </div>
  </>

  const onChangelogClick = e => setIsAuditTrailsModalOpen(true)
  const onModalChangelogClose = () => setIsAuditTrailsModalOpen(false)

  useEffect(() => {
    dispatch(setOptions({
      hasPrimaryButton: false,
      leftHeaderTemplate: renderLeftHeaderTemplate(),
      rightHeaderTemplate: renderRightHeaderTemplate()
    }))
  }, [ dispatch, id ])

  return <>
    { loading ? <Spinner/> :
      <div>
        <FullRowModal/>

        <div>
          <Row>
            <Col md={ 2 }>
              <StickyMenu>
                <Sections/>
              </StickyMenu>
            </Col>

            <Col md={ 8 }>
              <div className={ 'm-0' }>
                <TitleTemplateEditor titleTemplate={ titleTemplate }/>
                { sections.map(section =>
                  <EditableSection section={ section } key={ section.id }/>
                ) }
              </div>
            </Col>

            <Col md={ 2 }>
              <RightColumn>
                { editedField.id ?
                  <Card>
                    <Card.Header className={ 'cursor-pointer' } onClick={ e => dispatch(closeEditField()) }>
                      { trans('backToFields') }
                    </Card.Header>

                    <FieldSettings/>
                  </Card>
                  : <FieldList/> }
              </RightColumn>
            </Col>
          </Row>
        </div>

        <AuditTrailsModal isOpen={ isAuditTrailsModalOpen }
          onClose={ onModalChangelogClose }
          filter={ changeLogFilter }
        />
        <DetailsModal/>
        <SwpConfigModal/>
        <MsSanteConfigModal/>
        <RdConfigModal/>
        <ScriptModal/>
        <AddRefFieldModal/>
        <FieldDetailsModal/>
        <CalculationFieldModal/>
      </div>
    }
    <Modal title={ trans('modal.conditions.title') }
      show={ isFieldConditionsModalOpen }
      onClose={ e => dispatch(toggleCustomizationOrdersModal()) }
      size={ 'xl' }
    >
      <CustomizationConfigurator/>
    </Modal>
  </>
}

export default FormEditor

const RightColumn = styled.div`
  position: sticky;
  top: ${ props => props.theme.sizes.stickyMenus.top };
`
