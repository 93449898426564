import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { path } from 'ramda'
import { Button, Form, Card } from 'react-bootstrap'
import { withState } from 'src/Components/Util'
import { translate } from 'src/Services/translation'
import AutoResizeInput from 'src/Components/AutoResizeInput'
import { setIsEditingTitleTemplate, updateDetails } from 'src/Views/FormEditor/state/actions.ts'
import translation from '../translations'

const propsFromState = {
  isEditingTitleTemplate: path([ 'FormEditor', 'formReducer', 'isEditingTitleTemplate' ]),
  language: path([ 'Root', 'user', 'language' ])
}

const TitleTemplateEditor = ({
  titleTemplate,
  isEditingTitleTemplate,
  language
}) => {
  const dispatch = useDispatch()
  const trans = translate(translation)(language)

  const [ titleTemplateValue, setTitleTemplateValue ] = useState([ ...titleTemplate ])

  const submitTitle = e => {
    e.preventDefault()
    dispatch(updateDetails({
      titleTemplate: JSON.stringify(titleTemplateValue.filter(_ => _.trim()))
    }))
    dispatch(setIsEditingTitleTemplate(false))
  }

  const onEditClick = e => dispatch(setIsEditingTitleTemplate(true))
  const onCancelClick = e => {
    setTitleTemplateValue([ ...titleTemplate ])
    dispatch(setIsEditingTitleTemplate(false))
  }

  const addTitleTemplateItem = e => {
    e.preventDefault()
    setTitleTemplateValue([ ...titleTemplateValue, '-' ])
  }

  const editTitleTemplateItem = index => value => {
    const newTitleTemplate = [ ...titleTemplateValue ]
    newTitleTemplate[index] = value
    setTitleTemplateValue(newTitleTemplate)
  }

  const removeTitleTemplateItem = index => {
    const newTitleTemplate = [ ...titleTemplateValue ]
    newTitleTemplate.splice(index, 1)
    setTitleTemplateValue(newTitleTemplate)
  }

  return <Card className={ 'mb-3' }>
    <Card.Header>{ trans('titleTemplate.viewTitle') }</Card.Header>
    <Card.Body>
      <Form onSubmit={ submitTitle }>
        <Form.Group>
          <div className={ 'd-flex mb-2 flex-wrap' } style={ { rowGap: '4px' } }>
            { titleTemplateValue.map((el, i) =>
              <span key={ i } className={ 'me-2 overflow-auto' }>
                <AutoResizeInput size={ 'sm' }
                                 onChange={ editTitleTemplateItem(i) }
                                 value={ el }
                                 disabled={ !isEditingTitleTemplate }
                />
              </span>
            ) }
            { isEditingTitleTemplate &&
              <Button size={ 'sm' } variant={ 'secondary' } onClick={ addTitleTemplateItem }>
                <i className={ 'fa fa-plus' }/>
              </Button> }
            { isEditingTitleTemplate && titleTemplateValue.length > 1 &&
              <Button size={ 'sm' }
                      variant={ 'danger' }
                      className={ 'ms-1' }
                      title={ trans('titleTemplate.removeButtonTitle') }
                      onClick={ () => removeTitleTemplateItem(--titleTemplateValue.length) }
              >
                <i className={ 'fas fa-trash' }/>
              </Button> }
          </div>
          { !isEditingTitleTemplate &&
            <Button size={ 'sm' } variant={ 'secondary' } onClick={ onEditClick }>{ trans('edit') }</Button> }
          { isEditingTitleTemplate && <Button size={ 'sm' } variant={ 'danger' } className={ 'me-1' }
                                              onClick={ onCancelClick }>{ trans('cancel') }</Button> }
          { isEditingTitleTemplate && <Button size={ 'sm' } type={ 'submit' }>{ trans('submit') }</Button> }
        </Form.Group>
      </Form>
    </Card.Body>
  </Card>
}

export default withState(propsFromState)(TitleTemplateEditor)
