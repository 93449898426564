import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Container, Dropdown } from 'react-bootstrap'
import { Xwrapper } from 'react-xarrows'
import { translate } from 'src/Services/translation'
import { setOptions } from 'src/Layouts/View/state/actions'
import Arrow from 'src/Components/Arrow'
import translation from 'src/Views/Workflows/translations'
import { StoreState } from 'src/Services/Store/reducers'
import { toggleCreateWorkflowModal } from 'src/Views/Workflows/Modals/Workflows/state/actions'
import WorkflowFilters from 'src/Views/Workflows/Filters'
import WorkflowColumns from './Columns'
import WorkflowModals from './Modals'
import { toggleCreateActionModal } from './Modals/Actions/state/actions'
import { toggleCreateTriggerModal } from './Modals/Triggers/state/actions'
import { fetchWorkflowManager } from './state/actions'

const Workflows = () => {
  const dispatch = useDispatch()

  const { language } = useSelector((state: StoreState) => state.Root.user)
  const { selectedWorkflow } = useSelector((state: StoreState) => state.WorkflowManager)

  const trans = translate(translation)(language)

  const renderRightHeaderTemplate = () => <>
    <Dropdown className={ 'workflow-create' }>
      <Dropdown.Toggle variant={ 'primary' } size={ 'lg' }>
        <i className={ 'fas fa-plus-circle me-1' }/>
        <span>{ trans('add') }</span>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={ () => dispatch(toggleCreateWorkflowModal()) }>
          { trans('workflow') }
        </Dropdown.Item>
        <Dropdown.Item onClick={ () => dispatch(toggleCreateActionModal()) }>
          { trans('action') }
        </Dropdown.Item>
        <Dropdown.Item onClick={ () => dispatch(toggleCreateTriggerModal()) }>
          { trans('trigger') }
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  </>

  useEffect(() => {
    dispatch(setOptions({
      hasPrimaryButton: false,
      rightHeaderTemplate: renderRightHeaderTemplate(),
    }))
  }, [])

  useEffect(() => {
    dispatch(fetchWorkflowManager())
  }, [])

  return <Container>
    <Card className={ 'workflow-manager' }>
      <Card.Body>
        <WorkflowFilters/>
        <WorkflowModals/>
        <Xwrapper>
          <WorkflowColumns/>
          { selectedWorkflow?.actions?.map(action => <Arrow key={ action.id } start={ selectedWorkflow?.id } end={ action.id }/>) }
          { selectedWorkflow?.trigger && <Arrow start={ selectedWorkflow?.trigger.id } end={ selectedWorkflow?.id }/> }
        </Xwrapper>
      </Card.Body>
    </Card>
  </Container>
}

export default Workflows
