export default {
  english: {
    title: 'Tasks Types',
    addNew: 'add a new type',
    form: {
      name: 'Name',
      form: 'Form',
    },
    modal: {
      create: {
        title: 'Create a new type'
      },
      update: {
        title: 'Update a type'
      }
    },
    confirmDeleteModal: {
      title: 'Delete type',
      description: 'Are you sure you want to delete this type ?'
    }
  },
  french: {
    title: 'Types de Tâche',
    addNew: 'Ajouter un nouveau type',
    form: {
      name: 'Nom',
      form: 'Formulaire',
    },
    modal: {
      create: {
        title: 'Créer un nouveau type'
      },
      update: {
        title: 'Éditer un type'
      }
    },
    confirmDeleteModal: {
      title: 'Supprimer le type',
      description: 'Êtes-vous sûr de supprimer ce type ?'
    }
  }
}
