import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import translation from 'src/Views/FormFiller/translations.ts'
import { translate } from 'src/Services/translation'
import { NAME as PATIENT_REFERENCE } from 'src/Services/Constants/Config/Patient'
import { FetchStatus } from 'src/Types/FetchStatus'
import ListCell from 'src/Components/ListCell'
import { EXTERNAL_PROVIDER_MS_SANTE, FIELD_MS_SANTE_RECIPIENTS_PREFIX } from 'src/Services/Constants/FeatureSystemFields.ts'
import { FieldOption } from 'src/Types/FieldOption'
import Modal from 'src/Components/Modals/BaseModal'
import Table from 'src/Components/NavTable/Table'
import { hideReferenceSearchModal, changeReference, fillPatientToInstance, searchReference } from '../state/actions'
import { getIsReferenceSearchModalOpen, getReferenceSearchQuery } from '../selectors'

const ReferenceResultsModal = () => {
  const dispatch = useDispatch()

  const { user } = useSelector(state => state.Root)
  const isOpen = useSelector(state => getIsReferenceSearchModalOpen(state.FormFiller))
  const getSearchQuery = useSelector(state => getReferenceSearchQuery(state.FormFiller))
  const {
    searchReferenceStatus,
    searchForRefField,
    referenceSearchResults,
    openedInstance,
    openedInstanceForm,
    referenceSearchFilters,
    openedInstanceSystemFields,
  } = useSelector(state => state.FormFiller)

  const [ patientRefFields, setPatientRefFields ] = useState([])
  const [ referenceField, setReferenceField ] = useState({})

  const trans = translate(translation)(user.language)

  useEffect(() => {
    setPatientRefFields(getPatientRefFieldsIds())
  }, [ searchForRefField ])

  const getColumnHeaders = () => {
    if (!openedInstance.fields)
      return []

    return Object.values(openedInstance.fields)
      .filter(field => field?.referenceFieldId === searchForRefField)
      .map(field => ({
        label: field.listColumn.label,
        id: field.id,
        isSortable: true,
        field
      }))
  }

  const renderRow = row => (
    <tr key={ `${ row.id }` }
      onClick={ () => handleChangeReference(row.id, row.values) }
      className={ 'cursor-pointer' }
    >
      {
        getColumnHeaders().map(h => (
          <td key={ `${ row.id } ${ h.field.listColumn.systemName }` }>
            <ListCell value={ row.values[h.field.listColumn.systemName] } field={ h.field }/>
          </td>
        ))
      }
    </tr>
  )

  const handleChangeReference = (rowId, restRow) => {
    if (patientRefFields?.length > 0) {
      dispatch(fillPatientToInstance(searchForRefField, rowId, restRow, patientRefFields))

    } else {
      const row = referenceSearchResults.find(result => result.id === rowId)
      dispatch(changeReference(searchForRefField, rowId, restRow, row?.isFromExternalSource))
    }
  }
  const searchQuery = getSearchQuery(searchForRefField)

  useEffect(() => {
    searchForRefField &&
        setReferenceField(Object.values(openedInstance.fields).find(field => field.id === searchForRefField))

  }, [ searchForRefField ])


  const getPatientRefFieldsIds = () => {
    if (!openedInstance.fields)
      return []

    if (
      !searchForRefField ||
            openedInstance.fields[searchForRefField].list?.refForm?.systemName !== PATIENT_REFERENCE
    )
      return []

    const patientRefFieldsIds = Object.values(openedInstance.fields)
      .filter(field => field?.list?.refForm?.systemName === PATIENT_REFERENCE && field.id !== searchForRefField)
      .map(field => {
        return { id: field.id, listId: field.list?.id }
      })

    return patientRefFieldsIds?.length > 0 ? patientRefFieldsIds : []
  }

  const getAssociatedSystemFields = () => openedInstanceSystemFields.filter(sf => sf.field.id === referenceField.id) || []

  const isFromMsSanteRecipientReference = () => {
    return referenceField.id && getAssociatedSystemFields().some(sf => sf.name.startsWith(FIELD_MS_SANTE_RECIPIENTS_PREFIX))
  }


  const getEmptyBodyComponent = () => {
    if (openedInstanceForm?.isMsSanteEnabled && isFromMsSanteRecipientReference()) {
      return (
        <Button
          onClick={
            () => dispatch(
              searchReference(
                                referenceField?.list?.id,
                                referenceField.id,
                                referenceSearchFilters,
                                referenceField?.options[FieldOption.SHOW_ADD_BUTTON],
                                EXTERNAL_PROVIDER_MS_SANTE,
                                openedInstance.id
              )
            )
          }
          className={ 'mt-2' }
        >
          { trans('msSanteSearchButton') }
        </Button>
      )
    }
    return null
  }

  return <Modal title={ trans('selectAReference') }
    size={ 'xl' }
    onClose={ () => dispatch(hideReferenceSearchModal()) }
    isOpen={ isOpen }
  >
    <Table columnHeaders={ getColumnHeaders() }
      rows={ referenceSearchResults }
      renderRow={ renderRow }
      isLoading={ searchReferenceStatus === FetchStatus.LOADING }
      onEmptyBodyComponent={ getEmptyBodyComponent() }
    />
  </Modal>
}

export default ReferenceResultsModal
