import React from 'react'
import { translate } from 'src/Services/translation'
import { Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { StoreState } from 'src/Services/Store/reducers'
import translations from './translations'

export enum SaveStatus {
  ONGOING = 'ONGOING',
  DISABLED = 'DISABLE',
  VALID = 'VALID',
  INVALID = 'INVALID',
  FAIL = 'FAIL',
  SUCCESS = 'SUCCESS',
  LOADING = 'LOADING',
  WARNING = 'WARNING'
}

interface SaveButtonProps {
  saveStatus: string
  onClick: () => void
  className?: string
}

const SaveButton = ({
  saveStatus = '',
  onClick = () => false,
  className = '',
  ...props
}: SaveButtonProps) => {

  const { language } = useSelector((state: StoreState) => state.Root.user)

  const trans = translate(translations)(language)

  const getSaveButtonVariant = (saveState: string) => {
    switch (saveState) {
      case SaveStatus.SUCCESS:
        return 'success'
      case SaveStatus.FAIL:
        return 'danger'
      case SaveStatus.WARNING:
        return 'warning'
      default:
        return 'primary'
    }
  }

  const getSaveButtonLabel = (saveState: string) => {
    switch (saveState) {
      case SaveStatus.SUCCESS:
        return trans('saved')
      case SaveStatus.FAIL:
        return trans('failed')
      case SaveStatus.ONGOING:
        return trans('loading') + '...'
      default:
        return trans('save')
    }
  }

  return <Button variant={ getSaveButtonVariant(saveStatus) }
                 size={ 'sm' }
                 className={ ` ${ className }` }
                 onClick={ onClick }
                 disabled={ saveStatus === SaveStatus.ONGOING || saveStatus === SaveStatus.DISABLED
                     || saveStatus === SaveStatus.INVALID }
                 { ...props }
  >
    <i className={ `me-1 fas ${ saveStatus === SaveStatus.FAIL ? 'fa-sync' : 'fa-save' }` }/>
    <span>{ getSaveButtonLabel(saveStatus) }</span>
  </Button>
}


export default SaveButton
