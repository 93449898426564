import React from 'react'
import { useSelector } from 'react-redux'
import { translate } from 'src/Services/translation'
import translations from 'src/Views/FormFiller/translations'
import { StoreState } from 'src/Services/Store/reducers'
import { Field } from 'src/Types/Field'

export interface Props {
  field: Field
}

const CalculationField = ({ field = null }: Props) => {

  const { language } = useSelector((state: StoreState) => state.Root.user)

  const trans = translate(translations)(language)

  return <div>{ field?.value !== false ? field?.value : trans('none') }</div>
}

export default CalculationField
