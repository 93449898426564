import { call, put, select, takeLatest } from 'redux-saga/effects'
import { PermissionSubject } from 'src/Views/Permissions/Types/Subject'
import { ROLE_ADMIN } from 'src/Services/Constants/Config/Role'
import * as types from './actionTypes'
import {
  fetchForms,
  formPermissionDeleted,
  newPermissionsSaved,
  setForms, setLandingPages,
  setLists, setLogAsRoles,
  setNewSubjectData,
  setNumericReports,
  setSubjectData,
  setUser
} from './actions.ts'
import apiMethods from '../../../Services/api/apiMethods'

function* fetchSubjectData(props, { payload }) {
  try {
    const { role, user, subject, forms } = (yield select()).Permissions

    if (!subject || (!user && !role))
      return

    const target = user ? 'users' : 'roles'
    const targetId = user ? user.id : role.id
    let permissions

    const { data } = yield call(apiMethods.get, `/${ target }/${ targetId }/permissions`, { subject })

    // Special case for form that contains sections and fields permissions
    if (subject === PermissionSubject.FORM) {
      const { data: sectionPermissions } = yield call(apiMethods.get, `/${ target }/${ targetId }/permissions`,
        { subject: PermissionSubject.SECTION })
      const { data: fieldPermissions } = yield call(apiMethods.get, `/${ target }/${ targetId }/permissions`,
        { subject: PermissionSubject.FIELD })

      if (forms.length === 0)
        yield put(fetchForms())

      permissions = {
        [PermissionSubject.FORM]: data,
        [PermissionSubject.SECTION]: sectionPermissions,
        [PermissionSubject.FIELD]: fieldPermissions
      }
    } else
      permissions = data

    yield put(setSubjectData(permissions))
    yield put(setNewSubjectData(permissions))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'fetchFailed'))
  }
}

function* fetchUser(props, { payload }) {
  try {
    const { data } = yield call(apiMethods.get, `/users/${ payload }`)
    yield put(setUser(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'fetchFailed'))
  }
}

/*function* fetchAccessLevels(props, { payload }) {
  try {
    const { data } = yield call(apiMethods.get, '/permissions/access_levels')
    yield put(setAccessLevels(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'fetchFailed'))
  }
}*/

function* fetchLandingPages(props, { payload }) {
  try {
    const { data } = yield call(apiMethods.get, '/landing_pages', { all: true })
    yield put(setLandingPages(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'fetchFailed'))
  }
}

function* fetchLogAsRoles(props, { payload }) {
  try {
    const { data } = yield call(apiMethods.get, '/roles', { all: true })

    // Role admin is not eligible to a log as role
    yield put(setLogAsRoles(data.filter(r => r.systemName !== ROLE_ADMIN)))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'fetchFailed'))
  }
}

function* getAllForms(props, { payload }) {
  try {
    const { data } = yield call(apiMethods.get, '/forms', { all: true, formDetails: true })
    yield put(setForms(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'fetchFailed'))
  }
}

function* fetchLists(props, { payload }) {
  try {
    const { listTypes } = (yield select()).Dictionary
    const types = listTypes.filter(_ => ![ 'Reference' ].includes(_))

    const { data } = yield call(apiMethods.get, '/lists', { all: true, types })
    yield put(setLists(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'fetchFailed'))
  }
}

function* fetchNumericReports(props, { payload }) {
  try {
    const { data } = yield call(apiMethods.get, '/reports', { all: true })
    yield put(setNumericReports(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'fetchFailed'))
  }
}

function* deleteFormPermission(props, { payload: { id } }) {
  try {
    yield call(apiMethods.delete, `/form_permissions/${ id }`)
    yield put(formPermissionDeleted())
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'removeFailed'))
  }
}

function* formatFormPermissions(subject) {
  const { role, user, newSubjectData } = (yield select()).Permissions
  const target = user ? 'user' : 'role'

  const formatSubjectData = subjectData => {
    let result = { accessLevel: subjectData.accessLevel }

    if (subjectData.id && !subjectData.id.startsWith('_'))
      result.id = subjectData.id

    result[target] = subjectData[target]?.id || (target === 'user' ? user.id : role.id)

    if (subject === PermissionSubject.FIELD)
      result.field = subjectData.field.id
    else if (subject === PermissionSubject.SECTION)
      result.section = subjectData.section.id
    else if (subject === PermissionSubject.FORM)
      result = {
        ...result,
        grantAccessToInstancesThatRefersToMe: subjectData.grantAccessToInstancesThatRefersToMe || false,
        grantAccessToInstancesCreatedByMe: subjectData.grantAccessToInstancesCreatedByMe || false,
        grantAccessToAllLocations: subjectData.grantAccessToAllLocations || false,
        form: subjectData.form.id,
        defaultSection: subjectData.defaultSection?.id
      }

    return result
  }

  return newSubjectData[subject]?.map(formatSubjectData) || []
}

function* saveFormPermission(props, { payload }) {
  try {
    const { role, user, subject } = (yield select()).Permissions

    if (subject !== PermissionSubject.FORM)
      return

    const target = user ? 'users' : 'roles'
    const targetId = user ? user.id : role.id

    const formPermissions = yield call(formatFormPermissions, PermissionSubject.FORM)
    yield call(apiMethods.update, `/${ target }/${ targetId }/form_permissions`, { data: formPermissions })

    let sectionPermissions = yield call(formatFormPermissions, PermissionSubject.SECTION)
    yield call(apiMethods.update, `/${ target }/${ targetId }/section_permissions`, { data: sectionPermissions })

    let fieldPermissions = yield call(formatFormPermissions, PermissionSubject.FIELD)
    yield call(apiMethods.update, `/${ target }/${ targetId }/field_permissions`, { data: fieldPermissions })

    yield put(newPermissionsSaved(subject))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'saveFailed'))
  }
}

/** Excluding form related permissions */
function* formatNewSubjectDataBySubject() {
  const { role, user, subject, newSubjectData } = (yield select()).Permissions
  const target = user ? 'user' : 'role'

  const formatSubjectData = subjectData => {
    let result = { accessLevel: subjectData.accessLevel }

    if (subjectData.id && !subjectData.id.startsWith('_'))
      result.id = subjectData.id

    result[target] = subjectData[target]?.id || (target === 'user' ? user.id : role.id)

    if (subject === PermissionSubject.MODULE)
      result.module = subjectData.module.id
    else if (subject === PermissionSubject.LANDING_PAGE)
      result.landingPage = subjectData.landingPage.id
    else if (subject === PermissionSubject.NAVIGATION_ITEM)
      result.navigationItem = subjectData.navigationItem.id
    else if (subject === PermissionSubject.LIST)
      result.allowable = subjectData.list.id
    else if (subject === PermissionSubject.NUMERIC_REPORT)
      result.allowable = subjectData.numericReport.id
    else if (subject === PermissionSubject.LOG_AS_ROLE)
      result.logAsRole = subjectData.logAsRole.id

    return result
  }

  return newSubjectData.map(formatSubjectData)
}

function* saveNewSubjectData(props, { payload }) {
  try {
    const { role, user, subject } = (yield select()).Permissions

    if (subject === PermissionSubject.FORM)
      return

    const target = user ? 'users' : 'roles'
    const targetId = user ? user.id : role.id

    const data = yield call(formatNewSubjectDataBySubject)

    yield call(apiMethods.update, `/${ target }/${ targetId }/permissions`, { data })
    yield put(newPermissionsSaved(subject))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'fetchFailed'))
  }
}

export default function* sagaWatcher(props) {
  //yield takeLatest(types.FETCH_ACCESS_LEVELS, fetchAccessLevels, props)
  yield takeLatest(types.FETCH_LISTS, fetchLists, props)
  yield takeLatest(types.FETCH_LANDING_PAGES, fetchLandingPages, props)
  yield takeLatest(types.FETCH_LOG_AS_ROLES, fetchLogAsRoles, props)
  yield takeLatest(types.FETCH_FORMS, getAllForms, props)
  yield takeLatest(types.FETCH_USER, fetchUser, props)
  yield takeLatest(types.FETCH_NUMERIC_REPORTS, fetchNumericReports, props)
  yield takeLatest(types.SET_SUBJECT, fetchSubjectData, props)
  yield takeLatest(types.SET_ROLE, fetchSubjectData, props)
  yield takeLatest(types.NEW_PERMISSIONS_SAVED, fetchSubjectData, props)
  yield takeLatest(types.FORM_PERMISSION_DELETED, fetchSubjectData, props)
  yield takeLatest(types.SAVE_NEW_SUBJECT_DATA, saveNewSubjectData, props)
  yield takeLatest(types.SAVE_NEW_SUBJECT_DATA, saveFormPermission, props)
  yield takeLatest(types.DELETE_FORM_PERMISSION, deleteFormPermission, props)
}
