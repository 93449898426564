import React from 'react'
import { Form } from 'react-bootstrap'
import { Field } from 'src/Types/Field'
import { UuidV4 } from 'src/Types/Uuid'

export interface Props {
  field: Field
}

const InstanceId = ({ field }: Props) => {
  return <Form.Control id={ `field${ field.id }` } disabled value={ (field.value as UuidV4|null) || '' }  />
}

export default InstanceId
