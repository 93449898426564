import { Option } from 'src/Types/Components/Select'
import WorkflowItem from 'src/Types/WorkflowItem'
import {
  FETCH_WORKFLOW_MANAGER, SET_ACTION_FILTER, SET_FORM_FILTER, SET_ROLE_FILTER, SET_TYPE_FILTER,
  RESET_FILTERS, WORKFLOW_MANAGER_RECEIVED, SELECT_WORKFLOW
} from './actionTypes'

export const fetchWorkflowManager = () => ({
  type: FETCH_WORKFLOW_MANAGER
})

export const workflowManagerReceived = (workflowManager: any) => ({
  type: WORKFLOW_MANAGER_RECEIVED,
  payload: workflowManager
})

export const setActionFilter = (filter: Option) => ({
  type: SET_ACTION_FILTER,
  payload: filter
})

export const setFormFilter = (filter: Option) => ({
  type: SET_FORM_FILTER,
  payload: filter
})

export const setRoleFilter = (filter: Option) => ({
  type: SET_ROLE_FILTER,
  payload: filter
})

export const setTypeFilter = (filter: Option) => ({
  type: SET_TYPE_FILTER,
  payload: filter
})

export const resetFilters = () => ({
  type: RESET_FILTERS
})

export const selectWorkflow = (item: WorkflowItem) => ({
  type: SELECT_WORKFLOW,
  payload: item
})
