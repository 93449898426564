import { call, put, select, takeLatest } from 'redux-saga/effects'
import { NavigateFunction } from 'react-router/dist/lib/hooks'
import apiMethods from 'src/Services/api/apiMethods'
import { fetchUser, setNewUser, setUser } from 'src/Views/UserForm/state/actions'
import * as types from 'src/Views/UserForm/state/actionTypes'
import { showSuccess, showWarning } from 'src/Services/notifier/actions'
import { checkAndExtractDiffBtwTwoObj } from 'src/Services/UserHelpers'
import { User, UserApiInput } from 'src/Types/User'
import { GlobalActions } from 'src/Types/GlobalActions'
import { Action, StoreState } from 'src/Services/Store/reducers'
import { Instance } from 'src/Types/Instance'
import { FAKE_PIN, FAKE_PASSWORD } from '../constants'

const formatUserToApiInput = (user: User): UserApiInput => {
  const userApi: UserApiInput = {
    firstName: user.firstName,
    lastName: user.lastName,
    username: user.username,
    email: user.email,
    isProvider: user.isProvider,
    mobilePhone: user.mobilePhone,
    primaryRole: user.primaryRole?.id || null,
    secondaryRoles: user.secondaryRoles ? user.secondaryRoles.map(r => r.id) : [],
    locations: user.locations ? (user.locations as Instance[]).map(r => r.id) : [],
  }

  if (user.password && user.password !== FAKE_PASSWORD)
    userApi.password = btoa(user.password)

  if (user.pin && user.pin !== FAKE_PIN)
    userApi.pin = user.pin

  if (user.id)
    userApi.primaryRoleInstance = user.primaryRoleInstance?.id || null

  return userApi
}

function* loadUser(props: GlobalActions, { payload: { id } }: Action) {
  try {
    const { data } = yield call(apiMethods.get, `/users/${ id }`, { withAuthInformations: true })

    yield put(setUser({
      ...data,
      pin: FAKE_PIN,
      password: FAKE_PASSWORD,
      confirmPassword: FAKE_PASSWORD,
    }))

    yield put(setNewUser({
      ...data,
      pin: FAKE_PIN,
      password: FAKE_PASSWORD,
      confirmPassword: FAKE_PASSWORD,
    }))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'loadUserFailed'))
  }
}

function* addUser(props: GlobalActions, { payload: { user, navigate } }: Action) {
  try {
    yield call(apiMethods.create, '/users', { data: formatUserToApiInput(user) })
    yield call(redirect, navigate)
  } catch (error) {
    const translationKey = error.response?.data?.error
      ? `${ error.response.data.error.code }Error`
      : 'addNewUserFailed'

    yield put(props.globalActions.handleError(error, translationKey))
  }
}

function* createPatientUser(
  props: GlobalActions,
  { payload: { id, firstName, lastName, email, roleId, mobilePhone } }: Action,
) {
  try {
    if (!id || !firstName || !lastName || !email || !roleId) {
      yield put(showWarning('createPatientUserDataIncomplete'))
      return
    }

    const user: UserApiInput = {
      firstName, lastName, email, username: email, mobilePhone,
      primaryRole: roleId, secondaryRoles: [ roleId ],
      primaryRoleInstance: id, instances: [ id ],
      password: btoa(Math.random().toString(36)),
    }

    const { data } = yield call(
      // @ts-ignore
      apiMethods.create,
      '/users',
      { emailTemplate: 'USER_PATIENT_CREATED', data: user },
    )
    window.location.replace(`/user/${ data.id }`)
  } catch (error) {
    const translationKey = error.response?.data?.error ? `${ error.response.data.error.code }Error` : 'addNewUserFailed'
    yield put(props.globalActions.handleError(error, translationKey))
  }
}

function* updateUser(props: GlobalActions, { payload: { id, user: updatedUser, navigate } }: Action) {
  try {
    const state = (yield select()) as StoreState
    const { user } = state.UserForm

    const data = checkAndExtractDiffBtwTwoObj(formatUserToApiInput(user), formatUserToApiInput(updatedUser))

    yield call(apiMethods.update, `/users/${ id }`, { data })
    yield call(redirect, navigate)
    yield put(showSuccess('updateSucceeded'))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'updateUserFailed'))
  }
}

function* deleteUser(props: GlobalActions, { payload: { id, navigate } }: Action) {
  try {
    yield call(apiMethods.delete, `/users/${ id }`)
    yield call(redirect, navigate)
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'deleteUserFailed'))
  }
}

function* blockUser(props: GlobalActions, { payload: { id } }: Action) {
  try {
    yield call(apiMethods.get, `/users/${ id }/block`)
    yield put(showSuccess('userBlocked'))
    yield put(fetchUser(id))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'actionFailed'))
  }
}

function* unblockUser(props: GlobalActions, { payload: { id } }: Action) {
  try {
    yield call(apiMethods.get, `/users/${ id }/unblock`)
    yield put(showSuccess('userUnblocked'))
    yield put(fetchUser(id))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'actionFailed'))
  }
}

function* redirect(navigate: NavigateFunction) {
  yield put(setUser(null))
  yield put(setNewUser(null))
  /** @ts-ignore */
  yield call(navigate, '/users')
}

export default function* userListSagaWatcher(props: GlobalActions) {
  yield takeLatest(types.FETCH_USER, loadUser, props)
  yield takeLatest(types.CREATE_USER, addUser, props)
  yield takeLatest(types.CREATE_PATIENT_USER, createPatientUser, props)
  yield takeLatest(types.UPDATE_USER, updateUser, props)
  yield takeLatest(types.DELETE_USER, deleteUser, props)
  yield takeLatest(types.BLOCK_USER, blockUser, props)
  yield takeLatest(types.UNBLOCK_USER, unblockUser, props)
}
