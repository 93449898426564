export default {
  english: {
    checking: 'Checking availability...',
    isAvailable: 'System name available',
    isNotAvailable: 'System name not available',
    contentIsCopied: 'Content copied to clipboard'
  },
  french: {
    checking: 'Vérification de la disponibilité...',
    isAvailable: 'Nom système disponible',
    isNotAvailable: 'Nom système indisponible',
    contentIsCopied: 'Le contenu a été copié dans le presse-papier'
  }
}
