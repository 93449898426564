import FormCustomizationCondition, { EditableFormCustomizationCondition } from 'src/Types/FormCustomizationCondition'
import { Form } from 'src/Types/Form'
import { Action, ActionOptions } from 'src/Views/FormFiller/Types/Field'
import { EditableField, Field } from 'src/Types/Field'
import { UuidV4 } from 'src/Types/Uuid'
import { SlotType } from 'src/Types/Slot'

export enum TargetValueType {
  DYNAMIC = 'DYNAMIC',
  CONSTANT = 'CONSTANT'
}

export default interface FormCustomizationOrder {
  id: UuidV4
  label: string
  form: Form
  action: Action
  slotTypes: SlotType[]
  options: ActionOptions
  targetFields: Field[]
  conditions: FormCustomizationCondition[]
}

export type EditableFormCustomizationOrder = Partial<Omit<FormCustomizationOrder, 'conditions' | 'targetFields'> & {
  conditions: EditableFormCustomizationCondition[],
  targetFields: EditableField[]
  // Field string value or system field name
  targetValue?: string
  targetValueType?: TargetValueType
}>
