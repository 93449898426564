export default {
  english: {
    backToList: 'Back to lists',
    backToForm: 'Back to form',
    basedOff: 'Based off',
    referencing: 'Referencing',
    fixedFilters: 'Fixed filters',
    systemName: 'System name',
    listType: 'List type',
    editColumn: 'Edit column',
    addColumn: 'Add column',
    addFixedFilter: 'Add fixed filter',
    formField: 'Form field',
    alias: 'Alias',
    label: 'Label',
    deleteFilter: 'Delete filter ?',
    operator: 'Operator',
    value: 'Value',
    operatorPlaceholder: 'Please select a form field first.',
    columnForm: {
      class: 'CSS classes',
      isPartOfEventTitle: 'Is part of event title'
    },
    fieldTooltipTitle: 'Available Variables: ',
    fieldTooltipItem1: '###me### connected user',
    confirmDeleteModal: {
      title: 'Delete column',
      description: 'Are you sure you want to delete this column ?',
    },
  },
  french: {
    backToList: 'Retour aux listes',
    backToForm: 'Retour au formulaire',
    basedOff: 'Basé sur',
    referencing: 'Fait référence à',
    fixedFilters: 'Filtres fixes',
    systemName: 'Nom du système',
    listType: 'Type de liste',
    editColumn: 'Modifier colonne',
    addColumn: 'Ajouter colonne',
    addFixedFilter: 'Ajouter filtre fixe',
    formField: 'Champ du formulaire',
    alias: 'Alias',
    label: 'Label',
    deleteFilter: 'Supprimer le filtre ?',
    operator: 'Opérateur',
    value: 'Valeur',
    operatorPlaceholder: 'Veuillez choisir un champ de formulaire.',
    columnForm: {
      class: 'CSS classes',
      isPartOfEventTitle: 'Is part of event title'
    },
    fieldTooltipTitle: 'Variables disponibles : ',
    fieldTooltipItem1: '###me### utilisateur connecté',
    confirmDeleteModal: {
      title: 'Supprimer la colonne',
      description: 'Supprimer la colonne ?',
    },
  }
}
