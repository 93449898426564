export enum PermissionSubject {
  NAVIGATION_ITEM = 'navigationItem',
  LANDING_PAGE = 'landingPage',
  MODULE = 'module',
  FORM = 'form',
  SECTION = 'section',
  FIELD = 'field',
  LIST = 'list',
  NUMERIC_REPORT = 'numericReport',
  LOG_AS_ROLE = 'logAsRole',
  EFAX = 'efax',
}

export enum FormPermissionSubject {
  FORM = 'form',
  SECTION = 'section',
  FIELD = 'field',
}
