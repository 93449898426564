import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import _ from 'lodash'
import { translate } from 'src/Services/translation'
import translation from 'src/Views/Workflows/Filters/translations'
import { StoreState } from 'src/Services/Store/reducers'
import { Option } from 'src/Types/Components/Select'
import WorkflowFilter from './Filter'
import { resetFilters, setActionFilter, setFormFilter, setRoleFilter, setTypeFilter } from '../state/actions'

const WorkflowFilters = () => {
  const dispatch = useDispatch()

  const { language } = useSelector((state: StoreState) => state.Root.user)
  const { actionFilter, formFilter, typeFilter, roleFilter } = useSelector((state: StoreState) => state.WorkflowManager)
  const { eventStatuses, forms, roles, workflowActionTypes, workflowTriggerFormEvents } = useSelector((state: StoreState) => state.Dictionary)

  const trans = translate(translation)(language)

  const formEventList = [
    { label: trans('plainFormEvent'), value: 'formEvent' },
    ...workflowTriggerFormEvents.map(event => ({
      label: trans('formEvent', { event: _.capitalize(event) }),
      value: event,
    })),
  ]

  const eventStatusList = [
    { label: trans('plainEventStatus'), value: 'eventStatus' },
    ...eventStatuses.map(({ label, systemName }) => ({
      label: trans('eventStatus', { status: label }),
      value: systemName,
    })),
  ]

  const actionOptions = workflowActionTypes.map(action => ({ label: action, value: action }))
  const formOptions = forms.map(form => ({ label: form.label, value: form.systemName }))
  const roleOptions = roles.map(role => ({ label: role.label, value: role.systemName }))
  const typeOptions = [
    { label: trans('fieldEvent'), value: 'fieldEvent' },
    ...formEventList,
    ...eventStatusList,
  ]

  return (
    <div className={ 'workflow-filters' }>
      <Row>
        <Col>
          <WorkflowFilter label={ trans('action') }
                          options={ actionOptions }
                          value={ actionFilter }
                          onChange={ (option: Option | null) => option ? dispatch(setActionFilter(option)) : dispatch(resetFilters()) }
          />
        </Col>

        <Col>
          <WorkflowFilter label={ trans('form') }
                          options={ formOptions }
                          value={ formFilter }
                          onChange={ (option: Option | null) => option ? dispatch(setFormFilter(option)) : dispatch(resetFilters()) }
          />
        </Col>

        <Col>
          <WorkflowFilter label={ trans('type') }
                          options={ typeOptions }
                          value={ typeFilter }
                          onChange={ (option: Option | null) => option ? dispatch(setTypeFilter(option)) : dispatch(resetFilters()) }
          />
        </Col>

        <Col>
          <WorkflowFilter label={ trans('role') }
                          options={ roleOptions }
                          value={ roleFilter }
                          onChange={ (option: Option | null) => option ? dispatch(setRoleFilter(option)) : dispatch(resetFilters()) }
          />
        </Col>
      </Row>
    </div>
  )
}

export default WorkflowFilters
