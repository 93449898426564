import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ValidationTrigger from 'src/Components/ValidationTrigger'
import { FieldEvent } from 'src/Views/FormFiller/Types/Field'
import { CountryCode, PhoneNumber } from 'libphonenumber-js/types'
import { localeToNearSimpleLocales, localeToSimpleLocale } from 'src/Types/Locale'
import IntlTelInput, { CountryData } from 'react-intl-tel-input'
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js'
import { StoreState } from 'src/Services/Store/reducers'
import { Field } from 'src/Types/Field'
import { fieldEvent } from '../../state/actions'
import 'react-intl-tel-input/dist/main.css'

interface Props {
  field: Field
  isDisabled: boolean
  isRequired: boolean
}

const PhoneField = ({
  field = null,
  isDisabled = false,
  isRequired = false,
}: Props) => {
  const dispatch = useDispatch()
  const { portalLocale } = useSelector((state: StoreState) => state.Root.user)
  const { user } = useSelector((state: StoreState) => state.Root)
  const [ invalid, setIsInvalid ] = useState(false)
  const [ formattedPhoneNumber, setFormattedPhoneNumber ] = useState('')
  const [ phoneNumber, setPhoneNumber ] = useState<PhoneNumber>()


  const onChange = (isValid: boolean,value: string,selectedCountryData: CountryData,fullNumber:string) => {
    formatValidPhoneNumber(value)
  }

  const onBlur = (isValid: boolean,value: string,selectedCountryData: CountryData,fullNumber:string) => {
    if ('' === value) {
      dispatch(fieldEvent(field, FieldEvent.VALUE_CHANGE, { value: value }))
      return
    }

    if (isValid) {
      try {
        const parsedPhoneNumber = parsePhoneNumber(value, (selectedCountryData.iso2.toUpperCase() as CountryCode))
        setFormattedPhoneNumber(value)
        setPhoneNumber(parsedPhoneNumber)
        dispatch(fieldEvent(field, FieldEvent.VALUE_CHANGE, { value: parsedPhoneNumber.number }))
      } catch (err) {
        setIsInvalid(true)
      }

    }
    setIsInvalid(!isValid)
  }

  const formatValidPhoneNumber = (value: string) => {

    try {
      if (isValidPhoneNumber(value)) {
        setFormattedPhoneNumber(value)
        return
      }

      if (phoneNumber) {
        setFormattedPhoneNumber(phoneNumber.formatNational())
      }

      const parsedPhoneNumber = parsePhoneNumber(value as string)
      setPhoneNumber(parsedPhoneNumber)
      setFormattedPhoneNumber(parsedPhoneNumber.formatNational())
    } catch (err) {
      setFormattedPhoneNumber(value as string)
    }
  }

  useEffect(() => {
    formatValidPhoneNumber(field.value as string)
  }, [ field ])


  return <>
    <ValidationTrigger
      isRequired={ isRequired || invalid }
      isValid={ !invalid }
    />
    <IntlTelInput
      preferredCountries={ localeToNearSimpleLocales[portalLocale]  }
      defaultCountry={ localeToSimpleLocale[portalLocale] }
      value={  formattedPhoneNumber || '' }
      disabled={ isDisabled }
      onPhoneNumberChange={ (isValid,value,selectedCountryData,fullNumber) => onChange(isValid,value,selectedCountryData,fullNumber) }
      onPhoneNumberBlur={ (isValid,value,selectedCountryData,fullNumber) => onBlur(isValid,value,selectedCountryData,fullNumber)  }
    />
  </>

}

export default PhoneField
