import { ADD_STORE_DATA, CLEAR_STORE, EXECUTE_SCRIPT, RESET, UPDATE_STORE_DATA } from 'src/Services/ScriptExecutor/state/actionTypes'
import { SimpleHTMLEvent } from 'src/Views/FormFiller/Components/Fields/ButtonField/ButtonField'
import { StoreItem } from 'src/Services/ScriptExecutor/Types'
import { Todo } from 'src/Utils/Typing/Todo'

export const executeScript = (script: Todo, e: SimpleHTMLEvent | null) => ({
  type: EXECUTE_SCRIPT,
  payload: { script, e }
})
export const addStoreData = (id: string, data: any) => ({
  type: ADD_STORE_DATA,
  payload: { id, data }
})
export const updateStoreData = (id: StoreItem['id'], data: StoreItem['data']) => ({
  type: UPDATE_STORE_DATA,
  payload: { id, data }
})
export const clearStore = () => ({
  type: CLEAR_STORE
})
export const reset = () => ({
  type: RESET
})
