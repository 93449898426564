import React from 'react'
import { Row, Col } from 'react-bootstrap'
import LandingPageContent from './LandingPageContent'

const LandingPage = ({ landingPage }) => {
  const getRows = () => landingPage.contents.reduce((rows, content) => {
    if (!rows[content.row - 1])
      rows[content.row - 1] = { id: content.row, contents: [] }

    rows[content.row - 1].contents.push(content)
    return rows
  }, [])

  return <>
    { getRows().map(row => <Row key={ row.id } className={ 'mb-3' }>
      { row.contents.map(content => <Col key={ content.id } lg={ content.size }>
        <LandingPageContent content={ content } />
      </Col>) }
    </Row>) }
  </>
}

export default LandingPage
