import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import { translate } from 'src/Services/translation'
import Modal from 'src/Components/Modals/BaseModal'
import { StoreState } from 'src/Services/Store/reducers'
import Filter from 'src/Views/ListEdit/Components/Filter'
import ListFixedFilter from 'src/Types/ListFixedFilter'
import FilterForm from 'src/Views/ListEdit/Components/FilterForm'
import { AddRowButton } from '../../FormEditor/Components/Section'
import { ButtonsWrapper, ColumnsWrapper } from '../shared'
import translation from '../translations'
import { removeFilter, setFilterBeingRemoved, setIsAddFilterModalOpened, setSelectedReferenceField, setSelectedReferenceFieldDetails } from '../state/actions'

const Filters = () => {
  const dispatch = useDispatch()

  const { language } = useSelector((state: StoreState) => state.Root.user)
  const { id: listId, isAddFilterModalOpened, filterBeingRemoved, filters } = useSelector((state: StoreState) => state.ListEdit)

  const trans = translate(translation)(language)

  const onAddFilterClick = () => {
    dispatch(setIsAddFilterModalOpened(true))
    resetForm()
  }

  const onCloseAddFilterModal = () => {
    dispatch(setIsAddFilterModalOpened(false))
    resetForm()
  }
  const onDeleteConfirm = () => dispatch(removeFilter(listId, filterBeingRemoved))
  const onDeleteCancel = () => dispatch(setFilterBeingRemoved(''))

  const resetForm = () => {
    dispatch(setSelectedReferenceField(null))
    dispatch(setSelectedReferenceFieldDetails(null))
  }

  return <div>
    { filters.length ? <ColumnsWrapper>
      { filters.map((filter: ListFixedFilter) => <Filter key={ filter.id } filter={ filter }/>) }
    </ColumnsWrapper> : '' }

    <AddRowButton onClick={ onAddFilterClick }>
      <span>{ trans('addFixedFilter') }</span>
      <span className={ 'icon' }>
        <i className={ 'icon fas fa-lg fa-plus-circle' }/>
      </span>
    </AddRowButton>
    <Modal title={ trans('addFixedFilter') }
      isOpen={ isAddFilterModalOpened }
      onClose={ onCloseAddFilterModal }
    >
      <FilterForm />
    </Modal>
    <Modal title={ trans('deleteFilter') }
      isOpen={ Boolean(filterBeingRemoved) }
      onClose={ onDeleteCancel }
    >
      <ButtonsWrapper>
        <Button variant={ 'danger' } onClick={ onDeleteCancel }>{ trans('cancel') }</Button>
        <Button onClick={ onDeleteConfirm }>{ trans('confirm') }</Button>
      </ButtonsWrapper>
    </Modal>
  </div>
}

export default Filters
