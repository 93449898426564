import React, { useEffect }  from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container } from 'react-bootstrap'
import { translate } from 'src/Services/translation'
import Modal from 'src/Components/Modals/BaseModal'
import DeleteModal from 'src/Components/Modals/DeleteModal'
import { setOptions } from 'src/Layouts/View/state/actions'
import translation from './translations'
import Table from './Components/Table'
import Form from './Components/Form'
import { setDeletingItem, deleteItem, setUpdatingItem, updateItem, setCreatingItem,
  createItem, fetchItems } from './state/actions'

const NavigationItems = () => {
  const dispatch = useDispatch()
  const { language } = useSelector(state => state.Root.user)
  const trans = translate(translation)(language)

  useEffect(() => {
    dispatch(setOptions({
      hasPrimaryButton: true,
      onPrimaryButtonClick: onCreateClick
    }))
    dispatch(fetchItems())
  }, [ dispatch ])

  const { creatingItem, updatingItem, deletingItem  } = useSelector(state => state.NavigationItems)

  const onCreateClick = () => dispatch(setCreatingItem({}))
  const onModalCreateClose = () => dispatch(setCreatingItem(null))
  const onModalCreateFormSubmit = data => {
    dispatch(setCreatingItem(data))
    dispatch(createItem())
  }

  const onUpdateClick = item => dispatch(setUpdatingItem(item))
  const onModalUpdateClose = () => dispatch(setUpdatingItem(null))
  const onModalUpdateFormSubmit = data => {
    dispatch(setUpdatingItem(data))
    dispatch(updateItem())
  }

  const onDeleteClick = item => dispatch(setDeletingItem(item))
  const onConfirmDeleteModalClose = () => dispatch(setDeletingItem(null))
  const onConfirmDeleteModalConfirm = () => dispatch(deleteItem())

  return (
    <Container>
      <Table onDeleteClick={ onDeleteClick } onUpdateClick={ onUpdateClick } />

      <Modal isOpen={ !!creatingItem } title={ trans('modal.create.title') } onClose={ onModalCreateClose } size={ 'md' }>
        <Form trans={ trans } onSubmit={ onModalCreateFormSubmit } />
      </Modal>

      <Modal isOpen={ !!updatingItem } title={ trans('modal.update.title') } onClose={ onModalUpdateClose } size={ 'md' }>
        <Form trans={ trans }
              onSubmit={ onModalUpdateFormSubmit }
              item={ updatingItem }
        />
      </Modal>

      <DeleteModal title={ trans('confirmDeleteModal.title') }
                   isOpen={ !!deletingItem }
                   onClose={ onConfirmDeleteModalClose }
                   onDelete={ onConfirmDeleteModalConfirm }
      >
        { trans('confirmDeleteModal.description') }
      </DeleteModal>
    </Container>
  )
}

export default NavigationItems
