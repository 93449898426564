import React, { useRef } from 'react'
import { Modal as BModal } from 'react-bootstrap'
import CloseIcon from './CloseIcon'

const Modal = ({
  title = 'Dialog',
  iconClass = '',
  iconVariant = '',
  isOpen = false,
  onClose = () => {},
  children,
  footer = null,
  modalClass = null,
  ...props
}) => {
  const wrapperRef = useRef()

  return (
    <BModal
      className={ `base-modal ${ modalClass }` }
      show={ isOpen }
      onHide={ onClose }
      ref={ wrapperRef } { ...props }
    >
      <BModal.Header>
        <BModal.Title>
          {iconClass && <i className={ `icon ${ iconClass } ${ iconVariant }` }></i>}
          {title}
        </BModal.Title>
        <CloseIcon onClick={ onClose }/>
      </BModal.Header>

      <BModal.Body>
        { children }
      </BModal.Body>

      { footer &&
        <BModal.Footer>
          { footer }
        </BModal.Footer>
      }
    </BModal>
  )
}

export default Modal
