export default {
  english: {
    title: 'Reports Manager',
    addNew: 'Add',
    entries: 'of {total} entries',
    quarter1: '1st quarter',
    quarter2: '2nd quarter',
    quarter3: '3rd quarter',
    quarter4: '4th quarter',
    titleRun: 'Run Report',
    backToReports: 'Back to reports',
    selectLocations: 'Select location',
    selectYear: 'Select year',
    previousMonths: 'Previous 12 months',
    chartYAxis: 'Number of studies',
    form: {
      name: 'Name',
      label: 'Label',
      description: 'Description',
      form: 'Form'
    },
    column: {
      name: 'Name',
      label: 'Label',
      description: 'Description',
      form: 'Form'
    },
    modal: {
      create: {
        title: 'Create a new report'
      },
      update: {
        title: 'Update a report'
      }
    },
    confirmDeleteModal: {
      title: 'Delete report',
      description: 'Are you sure you want to delete this report ?'
    }
  },
  french: {
    title: 'Gestionnaire de rapports',
    addNew: 'Créer',
    entries: 'sur {total} entrées',
    quarter1: '1er trimestre',
    quarter2: '2e trimestre',
    quarter3: '3e trimestre',
    quarter4: '4ème trimestre',
    titleRun: 'Rapport d\'exécution',
    backToReports: 'Retour aux rapports',
    selectLocations: 'Sélectionnez l\'emplacement',
    selectYear: 'Sélectionnez l\'année',
    previousMonths: '12 mois précédents',
    chartYAxis: 'Nombre d\'études',
    form: {
      name: 'Nom',
      label: 'Label',
      description: 'Description',
      form: 'Formulaire'
    },
    column: {
      name: 'Nom',
      label: 'Label',
      description: 'Description',
      form: 'Formulaire'
    },
    modal: {
      create: {
        title: 'Créer un nouveau rapport'
      },
      update: {
        title: 'Modifier un rapport'
      }
    },
    confirmDeleteModal: {
      title: 'Supprimer le rapport',
      description: 'Êtes-vous sûr de vouloir supprimer ce rapport ?'
    }
  }
}
