import React from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { translateConf } from 'src/Services/translation'
import { Route } from 'src/Services/Router/Routes'
import { StoreState } from 'src/Services/Store/reducers'
import ViewHeader from './Components/Header'

interface ViewProps {
  route: Route
}

const View = ({ route }: ViewProps) => {
  const params = useParams()

  const { navigationItems } = useSelector((state: StoreState) => state.Dictionary)
  const { configTranslations } = useSelector((state: StoreState) => state.Root)

  const transConf = translateConf(configTranslations)
  const Component = route.component

  const getNavigationItem = (navigationItem: string) =>
      navigationItems?.find(i => navigationItem === i.systemName) || route.name

  const getViewTitle = (navigationItem: string) =>
    transConf('NAVIGATION_ITEM')(getNavigationItem(navigationItem))

  return <div id={ 'appView' }>
    <ViewHeader title={ getViewTitle(route.navigationItem) } />

    <div id={ 'viewBody' }>
      { /* @ts-ignore */ }
      <Component path={ route.path } params={ params } />
    </div>
  </div>
}

export default View
