export const NAME = 'PATIENT'
export const ID = 'id'
export const SCORE = 'score'
export const FIRST_NAME = `FIRST_NAME#${ NAME }`
export const LAST_NAME = `LAST_NAME#${ NAME }`
export const GENDER = `GENDER#${ NAME }`
export const USE_NAME = `USE_NAME#${ NAME }`
export const DATE_OF_BIRTH = `DOB#${ NAME }`
export const MOBILE_PHONE = `MOBILE_PHONE#${ NAME }`
export const HOME_PHONE = `HOME_PHONE#${ NAME }`
export const EXTERNAL_ID = `EXTERNAL_ID#${ NAME }`
export const EMAIL = `EMAIL#${ NAME }`

