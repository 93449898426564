import { ListGroup } from 'react-bootstrap'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomizationOrderDisplay from 'src/Views/FormEditor/Components/CustomizationOrderDisplay'
import { translate } from 'src/Services/translation'
import translation from 'src/Views/FormEditor/translations'
import { StoreState } from 'src/Services/Store/reducers'
import { createCustomizationOrder, updateCustomizationOrder } from 'src/Views/FormEditor/state/actions'
import { EditableFormCustomizationOrder, TargetValueType } from 'src/Types/FormCustomizationOrder'
import CustomizationOrderForm from 'src/Views/FormEditor/Components/CustomizationOrderForm'

const DEFAULT_ORDER: EditableFormCustomizationOrder = {
  form: null,
  action: null,
  slotTypes: [],
  targetFields: [],
  conditions: [],
  options: { type: TargetValueType.CONSTANT }
}

const CustomizationConfigurator = () => {
  const dispatch = useDispatch()

  const { language } = useSelector((state: StoreState) => state.Root.user)
  const { form, isCustomizationOrderLoading } = useSelector((state: StoreState) => state.FormEditor.formReducer)

  const [ formItem, setFormItem ] = React.useState<EditableFormCustomizationOrder>(null)

  const trans = translate(translation)(language)

  useEffect(() => {
    if (isCustomizationOrderLoading === false)
      setFormItem(null)
  }, [ isCustomizationOrderLoading ])

  const onNewOrderClick = () => {
    setFormItem(DEFAULT_ORDER)
  }

  const onSubmit = (item: EditableFormCustomizationOrder) =>
    dispatch(item.id ? updateCustomizationOrder(item) : createCustomizationOrder(item))

  return <>
    { formItem && <CustomizationOrderForm item={ formItem } onSubmit={ onSubmit } /> }

    <ListGroup as={ 'ol' } className={ 'my-2' }>
      { !formItem && <ListGroup.Item as={ 'li' } onClick={ onNewOrderClick }
        className={ 'd-flex justify-content-between cursor-pointer bg-primary text-white' }>
        <strong>{ trans('add') }</strong>
        <i className={ 'fas fa-lg fa-plus-circle' }/>
      </ListGroup.Item> }
      { form.customizationOrders.map((customizationOrder: EditableFormCustomizationOrder, i: number) => (
        <ListGroup.Item as={ 'li' } key={ i }>
          <CustomizationOrderDisplay order={ customizationOrder } onUpdateRequest={ setFormItem } />
        </ListGroup.Item>
      )) }
    </ListGroup>
  </>
}

export default CustomizationConfigurator
