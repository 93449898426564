import { ID_SEPARATOR } from 'src/Services/Constants'
import { FieldOption } from 'src/Types/FieldOption'
import { BaseFieldType, Field } from 'src/Types/Field'
import FormFillerField from 'src/Views/FormFiller/Types/FormFillerField'
import Row from 'src/Types/Row'
import Section from 'src/Types/Section'
import OpenedInstance from 'src/Views/FormFiller/Types/OpenedInstance'
import { Instance } from 'src/Types/Instance'

export const sortFieldsByOrder = (fields: Field[]) => {
  return fields.map((field, index) => {
    field.options[FieldOption.SORT_ORDER] = index + 1
    return field
  })
}

// Create children fields with their own values
const parseExtensibleChildren = (extensible: FormFillerField<BaseFieldType.EXTENSIBLE>) => {

  if (!extensible.value?.length || !extensible.fields?.length)
    return extensible.fields

  const findChild = (systemName: string) => (extensible.fields as Field[]).find(f => f.systemName === systemName)

  const filledChildren = extensible.value
    .map(extensibleRow =>
      Object.keys(extensibleRow.values)
        .filter(key => key.includes('#') && (!extensible.fields?.length || findChild(key)))
        .map(key => {
          if (extensible.fields?.length) {
            const child = findChild(key)

            return {
              ...child,
              id: child.id + ID_SEPARATOR + extensibleRow.id,
              value: extensibleRow.values[key],
            }
          }
        }))
    .reduce((acc, val) => acc.concat(val), [])

  const emptyChildren = (extensible.fields as Field[]).map(field => ({
    ...field,
    value: null,
  }))

  return [ ...filledChildren, ...emptyChildren ]
}

const flattenAndParseExtensibles = (fields: FormFillerField<BaseFieldType.EXTENSIBLE>[]): FormFillerField[] =>
  fields.reduce((results, field) => {
    if (field.type?.baseFieldType !== BaseFieldType.EXTENSIBLE)
      return [ ...results, field ]

    const parsedExtensible: FormFillerField = {
      ...field,
      fields: (field.fields as Field[]).map(f => f.id),
      // Real value is split across all fields
      value: null,
    }

    return [ ...results, parsedExtensible, ...parseExtensibleChildren(field) ]
  }, [])

const flattenAndParseReferences = (fields: FormFillerField<BaseFieldType.REFERENCE>[]): FormFillerField[] =>
  fields.reduce((results, field) => {

    if (field.type?.baseFieldType !== BaseFieldType.REFERENCE || !field.referenceFields)
      return [ ...results, field ]

    const parsedReference = {
      ...field,
      referenceFields: field.referenceFields.map(f => f.listColumn.id),
    }

    return [ ...results, parsedReference, ...parseReferencedFields(field) ]
  }, [])

const parseReferencedFields = (field: FormFillerField<BaseFieldType.REFERENCE>): Field[] =>
  field.referenceFields.map(f => ({
    ...f,
    // Field id is not unique, so we use associated listColumn id here
    id: f.listColumn.id,
    referenceField: field.id,
    value: getReferenceChildFieldValue(field, f)
  }))

const getReferenceChildFieldValue = (referenceField: FormFillerField<BaseFieldType.REFERENCE>, childField: Field) => {
  if (!referenceField.value?.values)
    return null

  return referenceField.value.values[childField.listColumn.systemName]
}

export const getAllFields = (sections: Section[], instance: Instance = null): FormFillerField[] => {
  const rows: Row[] = sections.reduce((rows, section) => [ ...rows, ...section.rows ], [])

  if (instance)
    return rows.reduce((fields, row) =>
      [ ...fields, ...row.fields.map(f => ({ ...f, value: instance.values[f.systemName] })) ],
    [])
  else
    return rows.reduce((fields, row) => [ ...fields, ...row.fields ], [])
}

export const parseFields = (instance: Instance, sections: Section[]) => {

  let fields = getAllFields(sections, instance)

  fields = flattenAndParseExtensibles(fields)
  fields = flattenAndParseReferences(fields)

  return fields.reduce((acc, field) => ({ ...acc, [field.id]: field }), {})
}


export const getSpecificFields = <T extends BaseFieldType = null>(fields: OpenedInstance['fields'], fieldType: T): Field<T>[] => {
  const specificFields: Field<T>[] = []
  for (const [ key, field ] of Object.entries(fields)) {
    if (field?.type?.baseFieldType === fieldType) {
      specificFields.push(field)
    }
  }
  return specificFields
}
