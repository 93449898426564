import { FIELD_MS_SANTE_PREFIX_TRACKING_LIST, MS_SANTE_TRACKING_STATUS } from 'src/Services/Constants/FeatureSystemFields'
import { getOpenedInstanceFieldById, getSystemFieldsStartingByName } from 'src/Services/Selectors'
import { MsSanteTracking, MsSanteTrackingRecipient } from '../Types/MsSante'
import OpenedInstance from '../Types/OpenedInstance'
import SystemField from '../../../Types/SystemField'

interface detailedStatus {
  label: string
  value: string
  color: string
}

export const computeTrackingGlobalDetailedStatus = (tracking: MsSanteTracking): detailedStatus | null => {
  for (const status of MS_SANTE_TRACKING_STATUS) {
    if (tracking?.recipients.filter(recipient => recipient.trackingStatus === status.value).length) {
      return status
    }
  }

  return null
}

export const getRecipientDetailedStatus = (recipient: MsSanteTrackingRecipient): detailedStatus | null => {
  return MS_SANTE_TRACKING_STATUS.find(status => status.value === recipient.trackingStatus)
}

export const hasTrackingId = (openedInstance: OpenedInstance, openedInstanceSystemFields: SystemField[]) => {
  if (openedInstanceSystemFields) {
    const trackingSystemFields = getSystemFieldsStartingByName(openedInstanceSystemFields, FIELD_MS_SANTE_PREFIX_TRACKING_LIST)
    return trackingSystemFields.length &&
      getOpenedInstanceFieldById(openedInstance, trackingSystemFields[0].field.id)
  }
}

export const hasTrackingStatus = (openedInstance: OpenedInstance, openedInstanceSystemFields: SystemField[], tracking: MsSanteTracking) => {
  if (!hasTrackingId(openedInstance, openedInstanceSystemFields)) {
    return false
  }

  return tracking?.recipients?.filter(recipient => recipient.trackingStatus !== null).length > 0
}
