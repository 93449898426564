import React from 'react'
import WorkflowModals from 'src/Views/Workflows/Modals/Workflows'
import TriggerModals from './Triggers'
import ActionModals from './Actions'

const WorkflowManagerModals = () => (
  <>
    <TriggerModals />
    <WorkflowModals />
    <ActionModals />
  </>
)

export default WorkflowManagerModals
