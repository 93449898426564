import { FIELD_BASE_TYPES_WITH_OPTIONS } from 'src/Services/Constants'
import { Link } from 'react-router-dom'
import React from 'react'
import { formatDateTime } from 'src/Utils/Date'
import { useSelector } from 'react-redux'
import { translate } from 'src/Services/translation'
import { Instance } from 'src/Types/Instance'
import { BaseFieldType, ExtensibleRow, Field, FieldOption, FieldValue } from 'src/Types/Field'
import { ConsentFieldValue } from 'src/Types/FieldType/ConsentField'
import { FieldOption as Options } from 'src/Types/FieldOption'
import File from 'src/Types/File'
import { StoreState } from 'src/Services/Store/reducers'
import translations from './translations'

interface IncorrectInstanceValue {
  value: FieldValue
}

interface Props {
  value: FieldValue | ExtensibleRow
  // Field should be required as values will not render properly
  field?: Field
  patientId?: string | number
  instanceId?: string | number
  renderAsLink?: boolean
}

/**
 * patientId and instanceId only needed if renderAsLink is true
 * field is required to allow the display of button fields
 *
 * TODO: rename it to renderFieldValue or so since it's not use only in list
 */
const ListCell = ({ value, field, patientId, instanceId, renderAsLink = false }: Props) => {

  const { user } = useSelector((state: StoreState) => state.Root)

  const trans = translate(translations)(user.language)
  const formatLocaleDateTime = formatDateTime(user.locale, user.portalTimezone)
  const getFormattedValue = (value: FieldValue | ExtensibleRow | IncorrectInstanceValue): JSX.Element => {

    if ((value as IncorrectInstanceValue)?.value)
      value = (value as IncorrectInstanceValue)?.value as FieldValue

    if (value === '' || value === null || value === undefined)
      return <i>{ trans('none') }</i>
    // Extensible row or checkbox values
    else if (Array.isArray(value))
      return getFormattedArrayValue(value)
    // Instance (Reference field)
    else if ((value as Instance).id && 'identityString' in (value as Instance))
      return <i>{ (value as Instance).identityString || Object.values((value as Instance).values).join(', ') }</i>
    // File
    else if ((value as File).id && 'extension' in (value as File))
      return <span>{ (value as File).name }</span>
    // Date field
    else if (field?.type?.baseFieldType === BaseFieldType.DATE_TIME)
      return <span>{ formatLocaleDateTime((value as string), field.options.showDate, field.options.showTime) }</span>
    // Date field without field information
    else if (field?.type?.baseFieldType === BaseFieldType.PHONE)
      return <span>{ (value as string) }</span>
    else if (field?.type?.baseFieldType === BaseFieldType.TEXT)
      return <span>{ (value as string) }</span>
    else if (field?.type?.baseFieldType === BaseFieldType.ESSAY)
      return <span style={ { whiteSpace:'pre-wrap' } } >{ (value as string) }</span>
    // Date field without field information
    // else if (typeof value === 'string' && isDate(value))
    //   return <span>{formatLocaleDateTime(value)}</span>
    // Option field type with full information in value
    else if ((value as FieldOption).systemName && (value as FieldOption).label)
      return <span>{ (value as FieldOption).label || (value as FieldOption).systemName }</span>
    // Option field type with systemName as a value
    else if (field && FIELD_BASE_TYPES_WITH_OPTIONS.includes(field.type?.baseFieldType) && field.options.values) {
      const fieldOption: FieldOption = field.options[Options.VALUES].find((o: FieldOption) => o.systemName === value) || null
      return <span>{ fieldOption?.label || (value as string) }</span>
    }
    // Boolean field (true)
    else if (value === true)
      return <span>{ trans('true') }</span>
    // Boolean field (false)
    else if (value === false)
      return <span>{ trans('false') }</span>
    else if (typeof value === 'object' && field.type?.baseFieldType === BaseFieldType.CONSENT)
      return <span>{ (value as ConsentFieldValue).value ?? 'null' }</span>
    else if (typeof value === 'object' && field?.type?.baseFieldType === BaseFieldType.BUTTON)
      return <span></span>
    else if (typeof value === 'object')
      return getFormattedObject(value)
    else
      return <span>{ (value as string) }</span>
  }

  const getFormattedObject = (value: object): JSX.Element => {
    return <>
      { Object.entries(value).map(([ key, val ] ) =>  <div><span className={ 'fw-bold' }>{ key }</span>: { val }</div>) }
    </>
  }

  const getFormattedArrayValue = (value: FieldValue[] | File[] | FieldOption[] | ExtensibleRow[]): JSX.Element => {
    return <>
      { value.map((fieldValue, index) => <span key={ `${ field?.id }.${ index }` }>
        <ListCell value={ fieldValue } field={ field }/>{ ++index < value.length ? ', ' : '' }
      </span>) }
    </>
  }

  const getRowLink = () =>
    patientId && instanceId
      ? `/patient/${ patientId }/instance/${ instanceId }`
      : `/instance/${ instanceId }`

  return renderAsLink
    ? <Link to={ getRowLink() }>{ getFormattedValue(value) }</Link>
    : getFormattedValue(value)
}

export default ListCell
