import { put, select, takeLatest } from 'redux-saga/effects'
import { SUBMIT_CONFIRM_MODAL } from './actionTypes'

function* callConfirmModalCallback(props) {
  try {
    const state = yield select()
    const confirmCallback = state.GlobalControls.confirmModalCallback

    if (confirmCallback) confirmCallback()

  } catch (error) {
    yield put(props.globalActions.handleError(error))
  }
}

export default function* globalControlsSagaWatcher(props) {
  yield takeLatest(SUBMIT_CONFIRM_MODAL, callConfirmModalCallback, props)
}
