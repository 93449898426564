import { call, cancel, delay, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import { fromEvent } from 'rxjs'
import { ignoreElements } from 'rxjs/operators'
import { showError, showSuccess, showWarning } from 'src/Services/notifier/actions'
import { executeScript, reset } from 'src/Services/ScriptExecutor/state/actions'
import { FIELD_ACTION_OPTION_VALUE } from 'src/Services/Constants'
import { Action as ReducerAction, StoreState } from 'src/Services/Store/reducers'
import { Action, FieldEvent, FieldEventData } from 'src/Views/FormFiller/Types/Field'
import { setIsLoading } from 'src/Layouts/View/state/actions'
import { SaveStatus } from 'src/Components/SaveButton'
import * as featureSystemFields from 'src/Services/Constants/FeatureSystemFields'
import { getExtensibleFieldsToSaveByRow, getExtensibleNewRowFieldsToSave, getFieldBySystemName, getFieldValueBySystemName } from 'src/Views/FormFiller/state/selectors'
import { SwpFeatureStatus } from 'src/Views/FormFiller/Types/Swp'
import { fetchInstanciatedForms, init } from 'src/Views/Patient/state/actions'
import * as patientTypes from 'src/Views/Patient/state/actionTypes'
import apiMethods from 'src/Services/api/apiMethods'
import { formatDateToIso8601, isIso8601DateBeforeThan } from 'src/Utils/Date'
import { translate } from 'src/Services/translation'
import { FormCustomizationConditionTypeOneTime } from 'src/Types/FormCustomizationCondition'
import { handleError, logMessage } from 'src/Services/Store/Root/actions'
import { getSpecificFields } from 'src/Views/FormFiller/utils/Fields'
import { GlobalActions } from 'src/Types/GlobalActions'
import { FormElementType } from 'src/Views/FormFiller/Types/ScriptFormCustomizationOrder'
import { SlotType } from 'src/Types/Slot'
import { FormFillerState } from 'src/Views/FormFiller/state/reducer'
import { TargetValueType } from 'src/Types/FormCustomizationOrder'
import { BaseFieldType, CalculationField, ExtensibleRow, Field, FieldOptionAutocompleteField, FieldOptionAutocompleteFieldDynamicValue } from 'src/Types/Field'
import { InstanceListValues } from 'src/Types/Instance'
import {
  addExtensibleRow, addExtensibleRowLoading, askConsent, changeFieldValue, changeReference, changeReferenceFromId, createInstanceForReference, createRdSession, DeleteFileAction,
  deleteFileSucceed, editFileSucceed, editInstanceForReference, executeAutoCompleteAction, executeButtonFieldActions, executeConsentAction, fetchInstance, fetchInstanceFailed,
  fieldEvent, initSwp, lockFileSucceed, openRdSession, openSwpMonitor, openSwpPlayback, openSwpRecord, pushCustomizationOrder, referenceInstanceCreated, referenceSearchSucceeded,
  removeCustomizationConditionTriggered, removeExtensibleRow, removeExtensibleRowLoading, resetCustomizations, saveInstance, saveInstanceAborted, saveInstanceFailed,
  saveInstanceFieldValue, saveInstanceSucceeded, setCalculationFields, setDocumentData, setFormCustomizationsConditionsTriggered, setInstance, setInstanceField,
  setInstanceSystemFields, setIsGenerateTemplateLoading, setIsOpenedInstanceReady, setPendingProtectedAction, setReferenceSearchFilters, setSaveInstanceStatus, setSwpFeatureStatus,
  setUserCode, setValueByAvailability, setValueToTargetFields, toggleValidateUserIdentityModal, unlockFileSucceed,
} from './actions'
import * as types from './actionTypes'
import { SAVE_INSTANCE_ABORTED, SAVE_INSTANCE_FAILED, SAVE_INSTANCE_SUCCEEDED } from './actionTypes'
import {
  getDocumentFieldTypeValue, getFormCustomizationConditionsTriggered, getFormCustomizationConditionsTriggeredByEvent, getInstanceDataToSave, isValidateUserIdentityNeeded,
} from '../utils'
import translation from '../translations'

function* fetchInstanceHandler(props: GlobalActions, { payload: id }: ReducerAction) {
  try {
    /** Get instance */
    const { data: instance } = yield call(apiMethods.get, `/instances/${ id }`)
    /* */

    /** Get form */
    const { data: form } = yield call(apiMethods.get, `/forms/${ instance.form.id }`, { withAccessLevel: true, instance: id })
    /* */

    yield put(setInstance(instance, form))
  } catch (error) {
    yield put(fetchInstanceFailed(error.response?.status || null))
    yield put(props.globalActions.handleError(error))
  }
}

function* fetchInstanceFieldHandler(props: GlobalActions, { payload: { instanceId, fieldId, onSuccess } }: ReducerAction) {
  try {
    const { data } = yield call(apiMethods.get, `/instances/${ instanceId }/field/${ fieldId }`)
    yield put(setInstanceField(fieldId, data))
    yield put(setDocumentData(getDocumentFieldTypeValue(data)))

    if (onSuccess)
      onSuccess()
    yield put(setIsGenerateTemplateLoading(false))
  } catch (error) {
    yield put(fetchInstanceFailed(error.response?.status || null))
    yield put(props.globalActions.handleError(error))
  }
}

function* triggerCalculation(formFiller: FormFillerState, field: Field, data: FieldEventData) {
  const { calculationFields } = formFiller

  let isFieldPartOfCalculation = false
  let calculationFieldId = null
  const tempValues: { [key: Field['id']]: CalculationField['value'] } = {}
  for (const calculationField in calculationFields) {
    if (calculationFields[calculationField] && calculationFields[calculationField].some((fieldSystemName: string) => fieldSystemName === field.systemName)) {
      isFieldPartOfCalculation = true
      calculationFieldId = calculationField
    }
    if (isFieldPartOfCalculation) {
      for (const systemName in calculationFields[calculationField]) {
        if (calculationFields[calculationField][systemName] === field.systemName)
          tempValues[calculationFields[calculationField][systemName]] = data.value
        else
          tempValues[calculationFields[calculationField][systemName]] =
            getFieldValueBySystemName(formFiller, calculationFields[calculationField][systemName])
      }
      break
    }
  }

  if (isFieldPartOfCalculation) {
    const { data } = yield call(apiMethods.post, `/instances/${ formFiller.openedInstance.id }/calculate/${ calculationFieldId }`, { data: tempValues })

    if (data !== false) {
      yield put(setInstanceField(calculationFieldId, data))
    }
  }
}

function* onFieldEvent(props: GlobalActions, { payload: { field, event, data, navigate } }: ReducerAction) {
  try {
    yield put(setIsLoading(true))
    const formFiller = ((yield select()) as StoreState).FormFiller

    if (event === FieldEvent.VALUE_CHANGE) {
      yield triggerCalculation(formFiller, field, data)

      yield put(changeFieldValue(field, data[FIELD_ACTION_OPTION_VALUE]))
    }
    else if (field.type.baseFieldType === BaseFieldType.BUTTON && event === FieldEvent.CLICK) {
      yield put(saveInstanceFieldValue(
        formFiller.openedInstance.id,
        field.id,
        { clickedAt: new Date() },
        true,
      ))
      yield put(executeButtonFieldActions(field, data, navigate))
    }

    if (event === FieldEvent.VALUE_CHANGE && isValidateUserIdentityNeeded(formFiller, field.id))
      yield put(toggleValidateUserIdentityModal())

    /** Process form customizations */
    const conditionsTriggered = formFiller.formCustomizationConditionsTriggered
    const conditionsTriggeredForField = conditionsTriggered.filter(c => c.field.id === field.id)

    const conditionsTriggeredByEvent = getFormCustomizationConditionsTriggeredByEvent(
      formFiller.openedInstanceForm?.customizationOrders || [],
      event, field, data
    )

    const results = [
      // if cond has been trigger by same field but is not in those recently triggered then remove it
      ...conditionsTriggered.filter(condition =>
        conditionsTriggeredForField.some(c => c.id === condition.id)
          ? conditionsTriggeredByEvent.some(c => c.id === condition.id)
          : true
      ),
      ...conditionsTriggeredByEvent
    ]

    yield put(setFormCustomizationsConditionsTriggered(results))
    /** */
  } catch (error) {
    yield put(props.globalActions.handleError(error))
  } finally {
    yield put(setIsLoading(false))
  }
}

function* saveInstanceFieldValueHandler(props: GlobalActions, { payload: { instanceId, fieldId, value, isSilent } }: ReducerAction) {
  try {
    const { data } = yield call(apiMethods.update, `/instances/${ instanceId }/field/${ fieldId }/value`, {
      data: { value }
    })
    yield put(setInstanceField(fieldId, data))

    if (!isSilent)
      yield put(showSuccess('updateSucceeded'))

  } catch (error) {
    yield put(props.globalActions.handleError(error, 'editFailed'))
  }
}


function* saveInstanceHandler(props: GlobalActions, { payload: { id, isSilent, onSuccess } }: ReducerAction) {
  const state = ((yield select()) as StoreState).FormFiller
  const { openedInstance } = state
  const data = getInstanceDataToSave(state)

  if (!data) {
    if (!isSilent)
      yield put(showWarning('noDataToSave'))

    yield put(saveInstanceAborted())
    yield put(setSaveInstanceStatus(SaveStatus.VALID))
    yield cancel()
  }

  id = id || openedInstance.id

  try {
    yield call(apiMethods.update, `/instances/${ id }`, { data: data.form })

    if (!isSilent)
      yield put(showSuccess('saveInstanceSucceeded'))

    yield put(saveInstanceSucceeded())

    if (onSuccess)
      onSuccess()

  } catch (e) {
    const errorCode = e.response?.data?.error?.code

    const translationKey = 'saveInstanceFailed'

    if (errorCode === 'userPinIncorrect') {
      yield put(setUserCode(null))

      yield put(saveInstanceFailed())
      yield put(setPendingProtectedAction({ type: types.SAVE_INSTANCE, payload: { id, isSilent, onSuccess } }))
      yield put(toggleValidateUserIdentityModal())
      yield cancel()
    } else if (errorCode === 'workflowFailed') {
      if (!isSilent)
        yield put(showWarning('workflowFailedError'))

      yield put(saveInstanceSucceeded())

      if (onSuccess)
        onSuccess()

      yield cancel()
    }

    if (!isSilent)
      yield put(showError(translationKey))

    yield put(props.globalActions.handleError(e, translationKey))
    yield put(saveInstanceFailed())
  }
}

function* setDocumentAsFinal(props: GlobalActions, { payload: { instanceId, field, file } }: ReducerAction) {
  const state = ((yield select()) as StoreState).FormFiller

  try {
    if (isValidateUserIdentityNeeded(state, field.id, field?.options?.saveType)) {
      yield put(setPendingProtectedAction({
        type: types.SET_DOCUMENT_AS_FINAL,
        payload: { instanceId, field, file }
      }))
      yield put(toggleValidateUserIdentityModal())
      yield cancel()
    }

    const { data } = yield call(
      apiMethods.sendFiles,
      `/instances/${ instanceId }/fields/${ field.id }/document_as_final`,
      {
        files: [ file ],
        data: {
          userCode: state.userCode
        }
      }
    )

    yield put(showSuccess('saveAsFinalSucceeded'))

    yield put(fieldEvent(
      data.field,
      FieldEvent.VALUE_CHANGE,
      { value: data.value[`field_${ data.field.id }`] }
    ))
  } catch (e) {
    const errorCode = e.response?.data?.error?.code

    if (errorCode === 'userPinIncorrect') {
      yield put(setUserCode(null))

      yield put(setPendingProtectedAction({
        type: types.SET_DOCUMENT_AS_FINAL,
        payload: { instanceId, field, file }
      }))
      yield put(toggleValidateUserIdentityModal())
      yield cancel()
    }

    yield put(props.globalActions.handleError(e, e.toString()))
  }
}

function* deleteInstanceHandler(props: GlobalActions, { payload: { id, navigate } }: ReducerAction) {
  try {
    yield call(apiMethods.delete, `/instances/${ id }`)
    yield put(showSuccess('deleteSucceeded'))

    yield call(navigate, '/')
  } catch (error) {
    yield put(props.globalActions.handleError(error, error.toString()))
  }
}

function* addInstance(props: GlobalActions, { payload: { patientId, formId, navigate } }: ReducerAction) {
  try {
    const { data } = yield call(apiMethods.post, '/instances', {
      data: { form: formId, patientId }
    })
    yield put(fetchInstanciatedForms(patientId))
    if (patientId) {
      yield call(navigate, `/patient/${ patientId }/instance/${ data.id }`)
    } else {
      yield call(navigate, `/instance/${ data.id }`)
    }
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'createFailed'))
  }
}

function* requestInstanceCreationForReference(props: GlobalActions, { payload: { referenceField } }: ReducerAction) {
  try {
    const { referenceFields: referenceFieldIds } = referenceField
    const state = ((yield select()) as StoreState).FormFiller
    const { editedFields, openedInstance: { fields } } = state

    const values: InstanceListValues = {}
    let isEmpty = true

    for (const referenceFieldId of referenceFieldIds) {

      if (!editedFields.includes(referenceFieldId))
        continue

      const field = fields[referenceFieldId]

      values[field.listColumn.systemName] = field.value

      if (field.value !== null)
        isEmpty = false
    }

    if (isEmpty)
      yield put(showWarning('emptyForm'))
    else
      yield put(createInstanceForReference(referenceField, values))
  } catch (e) {

    const errorCode = e.response?.data?.error?.code

    if (errorCode === 'userPinIncorrect') {
      yield put(setUserCode(null))

      yield put(saveInstanceFailed())
      yield put(
        setPendingProtectedAction({
          type: types.REQUEST_INSTANCE_CREATION_FOR_REFERENCE,
          payload: { referenceField }
        })
      )
      yield put(toggleValidateUserIdentityModal())
      yield cancel()
    }

    yield put(props.globalActions.handleError(e, 'referenceInstanceCreationFailed'))
  }
}

function* doCreateInstanceForReference(props: GlobalActions, { payload: { referenceField, values } }: ReducerAction) {
  const state = ((yield select()) as StoreState).FormFiller
  const { id } = state.openedInstance

  try {
    const { data } = yield call(apiMethods.post, `/instances/${ id }/field/${ referenceField.id }`, {
      data: {
        data: values,
        userCode: state.userCode
      }
    })

    yield put(referenceInstanceCreated())
    yield put(changeReference(referenceField.id, data.value, values))

    yield put(showSuccess('createSucceeded'))
  } catch (e) {

    const errorCode = e.response?.data?.error?.code

    if (errorCode === 'userPinIncorrect') {
      yield put(setUserCode(null))

      yield put(saveInstanceFailed())
      yield put(
        setPendingProtectedAction({
          type: types.CREATE_INSTANCE_FOR_REFERENCE,
          payload: { referenceField, values }
        }))
      yield put(toggleValidateUserIdentityModal())
      yield cancel()
    }

    yield put(props.globalActions.handleError(e, 'referenceInstanceCreationFailed'))
  }
}

function* requestInstanceEditForReference(props: GlobalActions, { payload: { referenceField } }: ReducerAction) {
  try {
    const { referenceFields: referenceFieldIds, value } = referenceField
    const state = ((yield select()) as StoreState).FormFiller
    const { editedFields, openedInstance: { fields } } = state

    const values: InstanceListValues = {}
    let isEmpty = true

    for (const referenceFieldId of referenceFieldIds) {

      if (!editedFields.includes(referenceFieldId))
        continue

      const field = fields[referenceFieldId]

      values[field.listColumn.systemName] = field.value

      if (field.value !== null)
        isEmpty = false
    }

    if (isEmpty)
      yield put(showWarning('emptyForm'))
    else
      yield put(editInstanceForReference(referenceField, values, value?.id || value))
  } catch (e) {
    const errorCode = e.response?.data?.error?.code

    if (errorCode === 'userPinIncorrect') {
      yield put(setUserCode(null))

      yield put(setPendingProtectedAction(
        { type: types.REQUEST_INSTANCE_EDIT_FOR_REFERENCE, payload: { referenceField } }))
      yield put(toggleValidateUserIdentityModal())
      yield cancel()
    }

    yield put(props.globalActions.handleError(e, 'referenceInstanceCreationFailed'))
  }
}

function* doEditInstanceForReference(props: GlobalActions, { payload: { referenceField, values, instanceId } }: ReducerAction) {
  const state = ((yield select()) as StoreState).FormFiller

  try {
    yield call(apiMethods.update, `/instances/${ instanceId }/field/${ referenceField.id }`, {
      data: {
        data: values,
        userCode: state.userCode
      }
    })

    // yield put(referenceInstanceCreated())
    // yield put(changeReference(referenceField.id, instanceId, values))
    yield put(showSuccess('updateSucceeded'))
  } catch (e) {
    const errorCode = e.response?.data?.error?.code

    if (errorCode === 'userPinIncorrect') {
      yield put(setUserCode(null))

      yield put(setPendingProtectedAction(
        { type: types.EDIT_INSTANCE_FOR_REFERENCE, payload: { referenceField, values, instanceId } }))
      yield put(toggleValidateUserIdentityModal())
      yield cancel()
    }

    yield put(props.globalActions.handleError(e, 'referenceInstanceCreationFailed'))
  }
}

function* editFile(props: GlobalActions, { payload }: ReducerAction) {
  const state = ((yield select()) as StoreState).FormFiller

  try {
    yield call(apiMethods.update, `/files/${ payload.fileId }`, {
      data: {
        data: { name: payload.name, description: payload.description },
        userCode: state.userCode
      }
    })

    yield put(showSuccess('updateSucceeded'))
    yield put(editFileSucceed(payload.fieldId, payload.fileId, payload.name, payload.description))
  } catch (e) {
    const errorCode = e.response?.data?.error?.code

    if (errorCode === 'userPinIncorrect') {
      yield put(setUserCode(null))

      yield put(setPendingProtectedAction({ type: types.EDIT_FILE, payload }))
      yield put(toggleValidateUserIdentityModal())
      yield cancel()
    }

    yield put(props.globalActions.handleError(e, 'editFileFailed'))
  }
}

function* lockFile(props: GlobalActions, { payload }: ReducerAction) {
  try {
    const { id: instanceId } = ((yield select()) as StoreState).FormFiller.openedInstance

    yield call(apiMethods.get, `/instances/${ instanceId }/files/${ payload.fileId }/lock`)
    yield put(showSuccess('updateSucceeded'))
    yield put(lockFileSucceed(payload.fieldId, payload.fileId))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'lockFileFailed'))
  }
}

function* printContent(props: GlobalActions) {
  try {
    document.querySelector('body')
      .classList
      .add('print-content')
    window.print()

    // todo remove it to get rid of rxjs
    fromEvent(document, 'afterprint')

    document.querySelector('body')
      .classList
      .remove('print-content')

    // todo remove it to get rid of rxjs
    ignoreElements()

  } catch (error) {
    yield put(props.globalActions.handleError(error, error.toString()))
  }
}

function* restoreInstance(props: GlobalActions, { payload: { instanceId } }: ReducerAction) {
  try {
    yield call(apiMethods.update, `/instances/${ instanceId }/restore`)

    const state = ((yield select()) as StoreState).Patient
    const { id: patientId } = state

    if (patientId === instanceId)
      yield put(init(patientId))

    yield put(fetchInstance(instanceId))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'restoreInstanceFailed'))
  }
}

function* createExtensibleRow(props: GlobalActions, { payload }: ReducerAction) {
  const state = ((yield select()) as StoreState).FormFiller

  try {
    const { id: instanceId, fields } = state.openedInstance

    const extensibleData = getExtensibleNewRowFieldsToSave(fields, payload.fieldId)

    if (!Object.keys(extensibleData).length) {
      yield put(showWarning('editExtensibleNoChange'))
      yield cancel()
    }

    yield call(apiMethods.post, `/instances/${ instanceId }/table/${ payload.fieldId }/row`, {
      data: {
        data: extensibleData,
        userCode: state.userCode,
        useSystemNameAsKey: true
      }
    })

    const { data } = yield call(apiMethods.get, `/instances/${ instanceId }/table/${ payload.fieldId }`)

    let lastRow = (data as ExtensibleRow[])[0]
    for (const row of (data as ExtensibleRow[]))
      if (isIso8601DateBeforeThan(lastRow.createdAt, row.createdAt))
        lastRow = row

    yield put(addExtensibleRow(payload.fieldId, lastRow.id, extensibleData))
    yield put(showSuccess('saveExtensibleSucceeded'))
  } catch (e) {
    const errorCode = e.response?.data?.error?.code

    if (errorCode === 'userPinIncorrect') {
      yield put(setUserCode(null))

      yield put(setPendingProtectedAction({ type: types.CREATE_EXTENSIBLE_ROW, payload: { payload } }))
      yield put(toggleValidateUserIdentityModal())
      yield cancel()
    }

    yield put(props.globalActions.handleError(e, 'saveExtensibleFailed'))
  }
}

function* editExtensibleRow(props: GlobalActions, { payload: { extensibleFieldId, rowId } }: ReducerAction) {
  const state = ((yield select()) as StoreState).FormFiller
  const { id: instanceId, fields } = state.openedInstance

  try {
    yield put(addExtensibleRowLoading(extensibleFieldId, rowId))
    const extensibleData = getExtensibleFieldsToSaveByRow(fields, extensibleFieldId, rowId)

    if (!Object.keys(extensibleData).length) {
      yield put(showWarning('editExtensibleNoChange'))
      yield put(removeExtensibleRowLoading(extensibleFieldId, rowId))
      return
    }

    yield call(apiMethods.update, `/instances/${ instanceId }/table/${ extensibleFieldId }/row/${ rowId }`, {
      data: {
        data: extensibleData,
        userCode: state.userCode,
        useSystemNameAsKey: true
      }
    })

    yield put(showSuccess('saveExtensibleSucceeded'))
  } catch (e) {
    const errorCode = e.response?.data?.error?.code

    if (errorCode === 'userPinIncorrect') {
      yield put(setUserCode(null))

      yield put(setPendingProtectedAction({
        type: types.EDIT_EXTENSIBLE_ROW,
        payload: { extensibleFieldId, rowId }
      }))
      yield put(toggleValidateUserIdentityModal())
      yield cancel()
    }

    yield put(props.globalActions.handleError(e, 'editExtensibleFailed'))
  }
  yield put(removeExtensibleRowLoading(extensibleFieldId, rowId))
}

function* deleteExtensibleRow(props: GlobalActions, { payload: { extensibleFieldId, rowId } }: ReducerAction) {
  const state = ((yield select()) as StoreState).FormFiller
  const { id: instanceId } = state.openedInstance

  try {
    yield put(addExtensibleRowLoading(extensibleFieldId, rowId))
    yield call(apiMethods.delete, `/instances/${ instanceId }/table/${ extensibleFieldId }/row/${ rowId }`)

    yield put(removeExtensibleRow(extensibleFieldId, rowId))
    yield put(showSuccess('deleteSucceeded'))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'deleteExtensibleFailed'))
  }
  yield put(removeExtensibleRowLoading(extensibleFieldId, rowId))
}

function* searchReference(props: GlobalActions, { payload }: ReducerAction) {
  try {
    const { data } = yield call(apiMethods.post, `/lists/${ payload.listId }/run`, {
      data: {
        filters: payload.filters,
        limit: 100,
        offset: 0,
        externalProvider: payload?.externalProvider,
        contextInstance: payload?.contextInstance
      }
    })
    yield put(setReferenceSearchFilters(payload.filters))
    yield put(referenceSearchSucceeded(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'searchReferenceFailed'))
  }
}

function* changeReferenceToSelf(props: GlobalActions, { payload: { refId, instanceId, listId, contextInstance } }: ReducerAction) {
  try {
    const { data } = yield call(apiMethods.post, `/lists/${ listId }/run/${ instanceId }`, { data: { contextInstance } })
    const userDataFromList = data[0]

    yield put(changeReference(refId, instanceId, userDataFromList?.values || []))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'createFailed'))
  }
}

function* downloadFile(props: GlobalActions, { payload }: ReducerAction) {
  try {
    yield call(apiMethods.downloadBlobFile, 'get', `/download/${ payload.fileId }`)
  } catch (error) {
    yield put(props.globalActions.handleError(error))
  }
}

function* downloadFileFromUrl(props: GlobalActions, { payload }: ReducerAction) {
  try {
    yield call(apiMethods.downloadFileFromUrl, 'get', `/generate_url_file/${ payload.fileId }`)
  } catch (error) {
    yield put(props.globalActions.handleError(error))
  }
}

function* unlockFile(props: GlobalActions, { payload }: ReducerAction) {
  try {
    const { id: instanceId } = ((yield select()) as StoreState).FormFiller.openedInstance

    yield call(apiMethods.get, `/instances/${ instanceId }/files/${ payload.fileId }/unlock`)
    yield put(showSuccess('updateSucceeded'))
    yield put(unlockFileSucceed(payload.fieldId, payload.fileId))
  } catch (error) {
    yield put(props.globalActions.handleError(error, error.toString()))
  }
}

function* deleteFile(props: GlobalActions, { payload: { field, fileId } }: DeleteFileAction) {
  const state = ((yield select()) as StoreState).FormFiller

  try {
    if (isValidateUserIdentityNeeded(state, field.id)) {
      yield put(setPendingProtectedAction({ type: types.DELETE_FILE, payload: { field, fileId } }))
      yield put(toggleValidateUserIdentityModal())
      yield cancel()
    }

    const { userCode, openedInstance } = state
    const newValue = field.value.reduce((value, f) =>
      f.id !== fileId ? [ ...value, f.id ] : value, [])

    yield call(apiMethods.update, `/instances/${ openedInstance.id }`, {
      data: {
        data: {
          [field.systemName]: newValue
        },
        userCode,
        useSystemNameAsKey: true,
      }
    })
    yield call(apiMethods.post, `/files/${ fileId }/delete`, { data: { userCode } })
    yield put(deleteFileSucceed(field.id, fileId))
  } catch (e) {
    const errorCode = e.response?.data?.error?.code

    if (errorCode === 'userPinIncorrect') {
      yield put(setUserCode(null))

      yield put(setPendingProtectedAction({ type: types.DELETE_FILE, payload: { field, fileId } }))
      yield put(toggleValidateUserIdentityModal())
      yield cancel()
    }

    yield put(props.globalActions.handleError(e, 'deleteFailed'))
  }
}

function* doChangeReference(props: GlobalActions, { payload: { refId, instanceId, values, isFromExternalSource } }: ReducerAction) {
  const state = ((yield select()) as StoreState).FormFiller
  const field = state.openedInstance.fields[refId]

  if (isFromExternalSource) {
    yield put(createInstanceForReference(field, values))
  } else {
    yield put(fieldEvent(field, FieldEvent.VALUE_CHANGE, { value: instanceId }))
  }

  if (isValidateUserIdentityNeeded(state))
    yield put(toggleValidateUserIdentityModal())
}

function* doClearReference(props: GlobalActions, { payload }: ReducerAction) {
  const state = ((yield select()) as StoreState).FormFiller

  yield put(fieldEvent(payload.field, FieldEvent.VALUE_CHANGE, { value: null }))

  if (isValidateUserIdentityNeeded(state))
    yield put(toggleValidateUserIdentityModal())
}

function* onInstanceSet(props: GlobalActions, { payload }: ReducerAction) {
  yield put(setIsLoading(true))

  /** Reset last instance custom script and customization orders applied */
  yield put(reset())
  yield put(resetCustomizations())

  try {
    /** Get system fields */
    const { data: systemFields } = yield call(
      apiMethods.get,
      `/forms/${ payload.instance.form.id }/system_fields`,
      { all: true }
    )
    yield put(setInstanceSystemFields(systemFields))
    /** */

    /** Trigger default form customization orders */
    const { openedInstance, openedInstanceForm: form } = ((yield select()) as StoreState).FormFiller

    const conditionsTriggered = getFormCustomizationConditionsTriggered(
      form.customizationOrders || [],
      openedInstance
    )

    yield put(setFormCustomizationsConditionsTriggered(conditionsTriggered))
    /** */

    /** Trigger page load script */
    const onPageLoad = form.javaScriptCode?.onPageLoad || null

    if (onPageLoad)
      yield put(executeScript(onPageLoad, null))
    /** */

    /** Calculation */
    const values: FormFillerState['calculationFields'] = {}
    for (const field in openedInstance.fields) {
      if (openedInstance.fields[field].type.baseFieldType === BaseFieldType.CALCULATION) {
        values[openedInstance.fields[field].id] = openedInstance.fields[field].options.targetedFields
      }
    }
    yield put(setCalculationFields(values))
    /** */

    yield put(setIsOpenedInstanceReady(true))
  } catch (error) {
    yield put(props.globalActions.handleError(error))
  } finally {
    yield put(setIsLoading(false))
  }
}

function* sendTestPreviewEmail(props: GlobalActions, { payload }: ReducerAction) {
  try {
    const { instanceId } = payload

    yield call(apiMethods.get, `/message_templates/${ instanceId }/preview`)
    yield put(showSuccess('fetchSucceeded'))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'fetchFailed'))
  }
}


function* onSystemFieldsSet(props: GlobalActions, { payload }: ReducerAction) {
  const { openedInstanceForm } = ((yield select()) as StoreState).FormFiller

  if (openedInstanceForm?.isSwpEnabled) {
    yield put(setSwpFeatureStatus(SwpFeatureStatus.LOADING))
    yield put(initSwp())
  } else {
    yield put(setSwpFeatureStatus(SwpFeatureStatus.DISABLE))
  }
}

function* setUserPinHandler(props: GlobalActions, { payload: { userCode } }: ReducerAction) {
  if (userCode) {
    // Reset user code after 10 seconds (it needs time to be sent to requests before being deleted)
    yield delay(10000)
    yield put(setUserCode(null))
  }
}

function* onFormCustomizationsConditionsTriggered(props: GlobalActions, { payload: { conditions } }: ReducerAction) {
  const rootState = ((yield select()) as StoreState).Root
  const { portalTimezone } = rootState.user
  const state = ((yield select()) as StoreState).FormFiller
  const { formCustomizationOrdersTriggered } = state

  // Process non-customization actions
  for (const order of formCustomizationOrdersTriggered) {
    if (order.action === Action.SET_VALUE_BY_AVAILABILITY) {
      // As this action has to be triggered only one time we have to remove all on click conditions
      for (const condition of order.conditions)
        if (condition.type in FormCustomizationConditionTypeOneTime)
          yield put(removeCustomizationConditionTriggered(condition))

      yield put(setValueByAvailability(formatDateToIso8601(new Date(), portalTimezone), order.targetFields, order.slotTypes))
    } else if (order.action === Action.SET_VALUE) {
      // As this action has to be triggered only one time we have to remove all on click conditions
      for (const condition of order.conditions)
        if (condition.type in FormCustomizationConditionTypeOneTime)
          yield put(removeCustomizationConditionTriggered(condition))

      yield put(setValueToTargetFields(order.targetFields, order.options))
    }
  }
}

function* doSetValueToTargetFields(props: GlobalActions, { payload: { targetFields, options } }: ReducerAction) {
  const formFillerState = ((yield select()) as StoreState).FormFiller
  const { openedInstanceSystemFields, openedInstance } = formFillerState
  const rootState = ((yield select()) as StoreState).Root
  const { language } = rootState.user
  const trans = translate(translation)(language)

  for (const targetField of targetFields) {
    if (options?.type === TargetValueType.CONSTANT) {
      yield put(changeFieldValue(targetField, options?.value))
    } else if (options?.type === TargetValueType.DYNAMIC) {
      const targetSystemField = openedInstanceSystemFields.find(systemField => systemField.name === options?.value)

      if (targetSystemField) {
        const { data } = yield call(apiMethods.post, `/instances/${ openedInstance.id }/system_fields/value/get`, {
          data: {
            name: targetSystemField.name
          }
        })

        if (!data.value || !data.value.length) {
          yield put(showWarning(trans('noTargetSystemFielValuedFound')))
        } else {
          if (targetField.type.baseFieldType === BaseFieldType.REFERENCE) {
            yield put(changeReferenceFromId(targetField, data.value))
          } else {
            yield put(changeFieldValue(targetField, data.value))
          }
        }
      } else {
        yield put(showWarning(trans('noTargetSystemFieldFound')))
      }
    }
  }
}

function* doSetValueByAvailability(props: GlobalActions, { payload: { date, fieldsToPopulate, slotTypes } }: ReducerAction) {
  const rootState = ((yield select()) as StoreState).Root
  const { language } = rootState.user
  const trans = translate(translation)(language)

  const { data } = yield call(apiMethods.get, '/slots/find_next_available',
    { date, slotTypes: slotTypes.map((rt: SlotType) => rt.id) }
  )

  if (!data || data.length === 0)
    yield put(showWarning(trans('noResourcesAddSlots')))
  else if (data.length > 1)
    yield put(showWarning(trans('tooMuchResourcesVerifySlots')))
  else if (!data[0].resource?.id)
    yield put(showWarning(trans('resourcesContainsNoResource')))
  else
    for (const field of fieldsToPopulate)
      yield put(changeReferenceFromId(field, data[0].resource.id))
}

function* fillPatientDataInInstance(props: GlobalActions, { payload: { refId, rowId, values, patientReferenceFields } }: ReducerAction) {
  try {
    yield put(changeReference(refId, rowId, values))

    if (!patientReferenceFields?.length)
      yield cancel()

    for (const refPatient of patientReferenceFields) {
      yield put(changeReferenceFromId(refPatient, rowId))
    }
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'referenceInstanceCreationFailed'))
  }
}

function* doChangeReferenceFromId(props: GlobalActions, { payload: { referenceField, instanceId, contextInstance } }: ReducerAction) {
  try {
    const listId = referenceField.list?.id || referenceField.listId
    const { data } = yield call(apiMethods.post, `/lists/${ listId }/run/${ instanceId }`, { data: { contextInstance } } )
    const instance = data[0]

    yield put(changeReference(referenceField.id, instanceId, instance?.values || []))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'referenceChangeValueFailed'))
  }
}

function* doExecuteButtonFieldActions(props: GlobalActions, { payload: { field, event, navigate, skipConsent } }: ReducerAction): Generator<any, void, any> {
  yield put(pushCustomizationOrder(FormElementType.FIELD, field.id, Action.DISABLE))
  yield put(pushCustomizationOrder(FormElementType.FIELD, field.id, Action.START_LOADING))

  if (event && field.javaScriptCode?.onClick) {
    yield put(executeScript(field.javaScriptCode.onClick, event))
  } else {
    const { swpFeatureStatus, openedInstanceForm, openedInstanceSystemFields } = ((yield select()) as StoreState).FormFiller

    if (field.options.autocompleteFields)
      yield put(executeAutoCompleteAction(field))

    if (!skipConsent && field.options.consentFields?.length > 0) {
      yield put(executeConsentAction(field, navigate))
      yield cancel()
    }

    if (field.options.saveInstance) {
      yield put(saveInstance())

      // Wait for saveInstance result action
      const action = yield take([ SAVE_INSTANCE_SUCCEEDED, SAVE_INSTANCE_FAILED, SAVE_INSTANCE_ABORTED ])

      if (action.type === SAVE_INSTANCE_FAILED) {
        yield cancel()
      }
    }

    /** SWP + RD process */
    const getAssociatedSystemFields = () => openedInstanceSystemFields.filter(sf => sf.field.id === field.id)
    const isButtonSystemFieldNameStartsWith = (startsWith: string) => getAssociatedSystemFields().some(sf => sf.name.startsWith(startsWith))

    if (swpFeatureStatus === SwpFeatureStatus.READY) {
      if (isButtonSystemFieldNameStartsWith(featureSystemFields.SWP_OPEN_RECORD_FIELD_SYSTEM_NAME))
        yield put(openSwpRecord())
      else if (isButtonSystemFieldNameStartsWith(featureSystemFields.SWP_OPEN_PLAYBACK_FIELD_SYSTEM_NAME))
        yield put(openSwpPlayback())
      else if (isButtonSystemFieldNameStartsWith(featureSystemFields.SWP_OPEN_MONITOR_FIELD_SYSTEM_NAME))
        yield put(openSwpMonitor())
    }

    if (openedInstanceForm.isRdEnabled) {
      if (isButtonSystemFieldNameStartsWith(featureSystemFields.RD_SYSTEM_FIELD_PREFIX_CREATE_SESSION))
        yield put(createRdSession())
      else if (isButtonSystemFieldNameStartsWith(featureSystemFields.RD_SYSTEM_FIELD_PREFIX_OPEN_SESSION))
        yield put(openRdSession())
    }
    /** */

    if (field.options.redirectToHomePage)
      yield call(navigate, '/')
  }

  yield put(pushCustomizationOrder(FormElementType.FIELD, field.id, Action.STOP_LOADING))
  yield put(pushCustomizationOrder(FormElementType.FIELD, field.id, Action.ENABLE))
}

function* doExecuteConsentAction(props: GlobalActions, { payload: { field, navigate } }: ReducerAction) {

  if (field.options.consentFields?.length < 1)
    yield cancel()

  const { openedInstance } = ((yield select()) as StoreState).FormFiller

  const consentFields = getSpecificFields(openedInstance.fields, BaseFieldType.CONSENT).filter(f => field.options.consentFields.includes(f.systemName))

  // compare required consent fields count with required consent fields that are checked but not yet accepted
  if (
    consentFields.filter(item => item.options.necessary).length ===
    consentFields.filter(item =>
      item.options.necessary && (item.value !== null && item.value?.value !== null && !item.value?.accepted)
    ).length
  ) {
    // if all required are all already checked acceptation is silent
    for (const field of consentFields) {
      yield put(saveInstanceFieldValue(
        openedInstance.id,
        field.id,
        { accepted: true, value: field.value?.value ?? null },
      ))
      yield put(fieldEvent(field, FieldEvent.VALUE_CHANGE, {
        value: { accepted: true, value: field.value?.value ?? null },
      }))
    }

    // Retry actions ignoring consent this time
    yield put(executeButtonFieldActions(field, null, navigate, true))

    // filter all required consent fields that are not checked (value is null)
    // or that are checked but not yet accepted
  } else if (
    consentFields.some(item => item.options.necessary && (item.value === null || item.value?.value === null || !item.value?.accepted))
  ) {
    // if there are required fields not checked, modal is open
    yield put(askConsent(field))
  } else {
    // Retry actions ignoring consent this time
    yield put(executeButtonFieldActions(field, null, navigate, true))
  }
}

function* doExecuteAutoCompleteAction(props: GlobalActions, { payload: { field } }: ReducerAction) {
  const formFillerState = ((yield select()) as StoreState).FormFiller
  const { openedInstance } = formFillerState

  const autocompleteFields: FieldOptionAutocompleteField[] = field.options?.autocompleteFields || []

  if (autocompleteFields.length < 1)
    yield cancel()

  const containsDynamicValues = autocompleteFields.some(af => af.type === TargetValueType.DYNAMIC)
  let dynamicValues: FieldOptionAutocompleteFieldDynamicValue[] = []

  if (containsDynamicValues) {
    try {
      const { data } = yield call(apiMethods.get, `/fields/${ field.id }/options/autocomplete/dynamic_values`, { instanceId: openedInstance.id })

      dynamicValues = data
    } catch (error) {
      yield put(handleError(error))
    }
  }

  for (const autocompleteField of autocompleteFields) {
    const { field: fieldSystemName, type, value } = autocompleteField

    const field: Field<any> = getFieldBySystemName(formFillerState, fieldSystemName, false)

    if (!field?.id) {
      yield put(logMessage(`Autocomplete field : can't find field with system name '${ fieldSystemName }'`, 'warning'))
      continue
    }

    if (type === TargetValueType.CONSTANT) {
      yield put(changeFieldValue(field, value))
      continue
    }

    // Value is a system field in this case
    const systemFieldName = value
    const dynamicValue = dynamicValues.find(dv => dv.systemFieldName === systemFieldName)

    if (!dynamicValue) {
      yield put(logMessage(`Autocomplete field : can't find target dynamic value with system field '${ systemFieldName }'`, 'warning'))
      continue
    }

    yield put(changeFieldValue(field, dynamicValue.value))
  }
}

function* onConsentAborted(props: GlobalActions, { payload: { field } }: ReducerAction) {
  yield put(pushCustomizationOrder(FormElementType.FIELD, field.id, Action.STOP_LOADING))
  yield put(pushCustomizationOrder(FormElementType.FIELD, field.id, Action.ENABLE))
}

export default function* formFillerSagaWatcher(props: GlobalActions) {
  yield takeLatest(types.FETCH_INSTANCE, fetchInstanceHandler, props)
  yield takeLatest(types.FETCH_INSTANCE_FIELD, fetchInstanceFieldHandler, props)
  yield takeLatest(types.SAVE_INSTANCE, saveInstanceHandler, props)
  yield takeLatest(types.SAVE_INSTANCE_FIELD_VALUE, saveInstanceFieldValueHandler, props)
  yield takeLatest(types.DELETE_INSTANCE, deleteInstanceHandler, props)
  yield takeLatest(patientTypes.ADD_INSTANCE, addInstance, props)
  yield takeLatest(types.REQUEST_INSTANCE_CREATION_FOR_REFERENCE, requestInstanceCreationForReference, props)
  yield takeLatest(types.CREATE_INSTANCE_FOR_REFERENCE, doCreateInstanceForReference, props)
  yield takeLatest(types.REQUEST_INSTANCE_EDIT_FOR_REFERENCE, requestInstanceEditForReference, props)
  yield takeLatest(types.EDIT_INSTANCE_FOR_REFERENCE, doEditInstanceForReference, props)
  yield takeLatest(types.EDIT_FILE, editFile, props)
  yield takeLatest(types.LOCK_FILE, lockFile, props)
  yield takeLatest(types.PRINT_CONTENT, printContent, props)
  yield takeLatest(types.RESTORE_INSTANCE, restoreInstance, props)
  yield takeLatest(types.CREATE_EXTENSIBLE_ROW, createExtensibleRow, props)
  yield takeLatest(types.EDIT_EXTENSIBLE_ROW, editExtensibleRow, props)
  yield takeLatest(types.DELETE_EXTENSIBLE_ROW, deleteExtensibleRow, props)
  yield takeLatest(types.SEARCH_REFERENCE, searchReference, props)
  yield takeLatest(types.UNLOCK_FILE, unlockFile, props)
  yield takeLatest(types.DOWNLOAD_FILE, downloadFile, props)
  yield takeLatest(types.DOWNLOAD_FILE_FROM_URL, downloadFileFromUrl, props)
  yield takeLatest(types.DELETE_FILE, deleteFile, props)
  yield takeLatest(types.CHANGE_REFERENCE, doChangeReference, props)
  yield takeLatest(types.CLEAR_REFERENCE, doClearReference, props)
  yield takeLatest(types.CHANGE_REFERENCE_TO_SELF, changeReferenceToSelf, props)
  yield takeLatest(types.SET_INSTANCE, onInstanceSet, props)
  yield takeLatest(types.SET_INSTANCE_SYSTEM_FIELDS, onSystemFieldsSet, props)
  yield takeLatest(types.FIELD_EVENT, onFieldEvent, props)
  yield takeLatest(types.SEND_EMAIl_PREVIEW, sendTestPreviewEmail, props)
  yield takeLatest(types.FILL_PATIENT_INSTANCE, fillPatientDataInInstance, props)
  yield takeEvery(types.CHANGE_REFERENCE_FROM_ID, doChangeReferenceFromId, props)
  yield takeLatest(types.SET_DOCUMENT_AS_FINAL, setDocumentAsFinal, props)
  yield takeLatest(types.SET_USER_PIN, setUserPinHandler, props)
  yield takeLatest(types.SET_FORM_CUSTOMIZATIONS_CONDITIONS_TRIGGERED, onFormCustomizationsConditionsTriggered, props)
  yield takeLatest(types.SET_VALUE_BY_AVAILABILITY, doSetValueByAvailability, props)
  yield takeEvery(types.SET_VALUE_TO_TARGET_FIELDS, doSetValueToTargetFields, props)
  yield takeEvery(types.EXECUTE_BUTTON_FIELD_ACTIONS, doExecuteButtonFieldActions, props)
  yield takeEvery(types.EXECUTE_AUTO_COMPLETE_ACTION, doExecuteAutoCompleteAction, props)
  yield takeEvery(types.EXECUTE_CONSENT_ACTION, doExecuteConsentAction, props)
  yield takeEvery(types.ABORT_CONSENT, onConsentAborted, props)
}
