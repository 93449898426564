import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col } from 'react-bootstrap'
import { FIELD_BASE_TYPE_EXTENSIBLE, FIELD_BASE_TYPE_REFERENCE } from 'src/Services/Constants'
import {
  draggingStopped, moveFieldToAnotherPlace, openEditField, requestAddField, requestAddReferenceField,
  setDraggedExistingField, setTargetField } from 'src/Views/FormEditor/state/actions.ts'
import { FieldWrapper, TargetBody, Name } from './shared'
import EmptyField from './EmptyField'
import ExtensibleField from './ExtensibleField'

const Field = ({ field, trans, isDragging = false, }) => {
  const dispatch = useDispatch()

  const { systemName, label, type, fields } = field
  const id = field.id

  const {
    draggedFieldType, form, draggedExistingField, targetField
  } = useSelector(state => state.FormEditor.formReducer)

  const baseFieldType = type?.baseFieldType

  const isFieldEmpty = field.type === null

  const getColSize = () => {
    switch (field.rowSize) {
      case 3:
        return 12
      case 2:
        return 8
      case 1:
        return 4
      default:
        return 'auto'
    }
  }

  const handleFieldDrop = e => {

    e.preventDefault()

    const dataTransferType = e.dataTransfer.types[0]

    if (dataTransferType === 'row')
      return dispatch(draggingStopped())

    if (dataTransferType === 'field')
      return onFieldDropOnField()

    if (dataTransferType === 'field-type')
      return onFieldTypeDropOnField()

    dispatch(draggingStopped())
  }

  const onFieldDropOnField = () => {
    if (field.id === draggedExistingField.id)
      return dispatch(draggingStopped())

    if (field.type)
      dispatch(moveFieldToAnotherPlace(
          field.id, draggedExistingField.row.id, draggedExistingField.rowSize, draggedExistingField.rowColumn
      ))

    dispatch(moveFieldToAnotherPlace(draggedExistingField.id))
  }

  const onFieldTypeDropOnField = () => {
    if (draggedFieldType.baseFieldType === FIELD_BASE_TYPE_REFERENCE)
      dispatch(requestAddReferenceField(field, { type: draggedFieldType }))
    else
      dispatch(requestAddField(field, { type: draggedFieldType }))
  }

  const handleFieldDragOver = e => {

    const dataTransferType = e.dataTransfer.types[0]

    const isFieldType = dataTransferType === 'field-type'
    const isField = dataTransferType === 'field'

    if (isFieldType && !isFieldEmpty && baseFieldType !== FIELD_BASE_TYPE_EXTENSIBLE)
      return

    // Accept drop
    e.preventDefault()

    if (!targetField)
      return dispatch(setTargetField(field))

    const isSamePosition =
      targetField.rowColumn === field.rowColumn && targetField.row?.id === field.row?.id
      && targetField.sectionId === field.sectionId && targetField.rowSize === field.rowSize

    if (isSamePosition || !(isFieldType || isField))
      return

    dispatch(setTargetField(field))
  }

  const handleFieldDragStart = e => {
    e.stopPropagation()
    e.dataTransfer.setData('field', id)
    dispatch(setDraggedExistingField({ ...field, id }))
  }

  const computeCurrentTargetClassName = () =>
    field?.rowColumn === targetField?.rowColumn
    && field?.row?.id === targetField?.row?.id
      ? 'current-drag-and-drop-target'
      : ''

  return <Col className={ `px-2 ${ isFieldEmpty ? '' : 'cursor-pointer' } ${ computeCurrentTargetClassName() }` }
              onDrop={ handleFieldDrop }
              onDragOver={ handleFieldDragOver }
              onDragStart={ handleFieldDragStart }
              draggable={ !isFieldEmpty && !isDragging }
              xs={ getColSize() }
  >
    { isFieldEmpty ?
      <EmptyField isDragging={ isDragging } trans={ trans } /> :
      baseFieldType === FIELD_BASE_TYPE_EXTENSIBLE ?
        <ExtensibleField id={ id }
                         label={ label }
                         systemName={ systemName }
                         fields={ fields }
                         isDragging={ isDragging }
                         trans={ trans }
                         dispatch={ dispatch }
        /> :
        <FieldWrapper onClick={ e => dispatch(openEditField(id)) }>
          <TargetBody className={ isDragging ? 'undroppable-target' : '' }>
            <span className={ 'fa-stack' }>
              <i className={ 'fas fa-circle fa-stack-2x' }/>
              <i className={ `${ type?.icon } fa-stack-1x` }/>
            </span>
            <Name className={ label === null ? 'empty' : '' }>
              { label }
            </Name>
          </TargetBody>
        </FieldWrapper>
    }
  </Col>
}

export default Field
