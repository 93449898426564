import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { getUUID } from 'rc-select/lib/utils/commonUtil'
import FormFillerColumn from './Column'

const FormFillerRow = ({ row }) => {
  const getFieldByPosition = (fields, position) => fields.find(f => f.rowColumn === position)
  const getColSizeByField = field => field.rowSize === 1 ? 4 : field.rowSize === 2 ? 8 : 12
  const getColByField = field => field
    ? <Col xs={ getColSizeByField(field) }
      key={ `${ row.id }.${ field.id }` }
      className={ 'd-grid align-self-baseline' }
    >
      <FormFillerColumn cell={ field }/>
    </Col>
    : <Col xs={ 4 } key={ getUUID() }></Col>

  const getCols = () => {
    const cols = []

    const field1 = getFieldByPosition(row.fields, 1)
    const field2 = getFieldByPosition(row.fields, 2)
    const field3 = getFieldByPosition(row.fields, 3)

    for (let pos = 1; pos <= 3; pos++) {
      if (pos === 1)
        cols.push(getColByField(field1))
      else if (pos === 2)
        (!field1 || field1.rowSize < 2) && cols.push(getColByField(field2))
      else if (pos === 3)
        (!field1 || field1.rowSize < 3) && (!field2 || field2.rowSize < 2) && cols.push(getColByField(field3))
    }

    return cols
  }

  return <Row className={ 'mb-2' }>
    { getCols() }
  </Row>
}

export default FormFillerRow
