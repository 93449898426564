import React, { MouseEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Form } from 'react-bootstrap'
import Modal from 'src/Components/Modals/BaseModal'
import { translate } from 'src/Services/translation'
import { AppLoader } from 'src/Components/AppLoader'
import ValueSettings from 'src/Views/FormEditor/Components/FieldSettings/Components/ValueSettings'
import { deleteField, toggleAdvancedFieldConfigModal, toggleFieldValuesModal, updateField } from 'src/Views/FormEditor/state/actions'
import { BaseFieldType, EditableField, FieldOptions } from 'src/Types/Field'
import { getRowFieldByColumn } from 'src/Views/FormEditor/state/selectors'
import FieldSetting from 'src/Views/FormEditor/Components/FieldSettings/Components/FieldSetting'
import { StoreState } from 'src/Services/Store/reducers'
import AdvancedFieldSettings from './Components/AdvancedFieldSettings'
import translation from '../../translations'

const FieldSettings = () => {
  const dispatch = useDispatch()

  const { language } = useSelector((state: StoreState) => state.Root.user)
  const state = useSelector((state: StoreState) => state.FormEditor)
  const { form, editedField, isFieldValuesModalOpen, isAdvancedFieldConfigModalOpen } = useSelector((state: StoreState) => state.FormEditor.formReducer)

  const [ localEditedField, setLocalEditedField ] = useState<EditableField>(null)

  const trans = translate(translation)(language)
  const isReference = editedField && editedField.type?.baseFieldType === BaseFieldType.REFERENCE

  useEffect(() => {
    editedField && setLocalEditedField(editedField)
  }, [ editedField ])

  const removeField = (e: MouseEvent) => {
    e.preventDefault()
    dispatch(deleteField(editedField.id))
  }

  const onRowSizeChange = (newRowSize: number) => {
    if (newRowSize > getMaxRowSize())
      newRowSize = getMaxRowSize()

    setLocalEditedField(f => ({ ...f, rowSize: newRowSize as 1 | 2 | 3 }))
  }

  const getMaxRowSize = (): 1 | 2 | 3 => {
    const maxRowSize = 3

    // Check next cols if there is empty space
    for (let col = editedField.rowColumn + 1; col < 4; col++) {
      const field = getRowFieldByColumn(state, editedField.row, col)

      if (field && field.id !== editedField.id)
        return col
    }

    return maxRowSize
  }

  const submitField = () => {
    dispatch(updateField(localEditedField))
  }

  return <div className={ 'card-content' }>
    <Form>
      { editedField.loading && <AppLoader/> }
      { localEditedField && !editedField.loading ? <React.Fragment>
        <small className={ 'text-muted' }>
          { trans('editField.fieldId') }: { editedField.id } |&nbsp;
          { trans('editField.rowId') }: { editedField.row?.id }<br/>
          { trans('editField.systemName') }: { editedField.systemName }
        </small>

        <hr className={ 'my-2' }/>

        <Form.Group className={ 'mb-2' }>
          <Form.Label className={ 'fw-bold' }>{ trans('editField.headingField') }</Form.Label>
          <Form.Control
            size={ 'sm' }
            name={ 'label' }
            value={ localEditedField.label || '' }
            onChange={ e => setLocalEditedField(f => ({ ...f, label: e.target.value })) }
          />
        </Form.Group>

        { !localEditedField.extensibleFieldId &&
            <Form.Group className={ 'mb-2' }>
              <Form.Label className={ 'fw-bold' } htmlFor={ 'rowSize' }>{ trans('editField.rowSize') }</Form.Label>
              <Form.Control type={ 'number' }
                size={ 'sm' }
                id={ 'rowSize' }
                name={ 'rowSize' }
                value={ localEditedField.rowSize || '' }
                onChange={ e => onRowSizeChange(parseInt(e.target.value, 10)) }
                min={ 1 }
                max={ getMaxRowSize() }
                step={ 1 }
              />
            </Form.Group>
        }

        { localEditedField.options?.supportOnChangeCode &&
            <Form.Group className={ 'mb-1' }>
              <Form.Label>{ trans('editField.onChangeCode') }</Form.Label>
              <Form.Control
                as={ 'textarea' }
                name={ 'onChangeCode' }
                size={ 'sm' }
                value={ localEditedField.javaScriptCode?.onChange || '' }
                onChange={ e => setLocalEditedField(f => ({ ...f, javaScriptCode: { ...f.javaScriptCode, onChange: e.target.value || null } })) }
              />
              <Form.Text className={ 'text-muted' }>{ trans('editField.supportOnChangeCode') }</Form.Text>
            </Form.Group>
        }

        { localEditedField.options?.supportOnBlurCode &&
            <Form.Group className={ 'mb-1' }>
              <Form.Label>{ trans('editField.onBlurCode') }</Form.Label>
              <Form.Control
                as={ 'textarea' }
                name={ 'onBlurCode' }
                size={ 'sm' }
                value={ localEditedField.javaScriptCode?.onBlur || '' }
                onChange={ e => setLocalEditedField(f => ({ ...f, javaScriptCode: { ...f.javaScriptCode, onBlur: e.target.value || null } })) }
              />
              <Form.Text className={ 'text-muted' }>{ trans('editField.onBlurCodeInfo') }</Form.Text>
            </Form.Group>
        }

        <Form.Group className={ 'mb-2' }>
          <Form.Label className={ 'fw-bold' }>Options</Form.Label>
          { localEditedField.options && Object.keys(localEditedField.options).map((option: keyof FieldOptions) =>
            <FieldSetting
              key={ localEditedField.id + option }
              field={ localEditedField }
              option={ option }
              onChange={ setLocalEditedField }
            />)
          }
        </Form.Group>

        { editedField.type?.baseFieldType === BaseFieldType.BUTTON &&
            <div>
              <Button
                variant={ 'outline-primary' }
                size={ 'sm' }
                onClick={ () => dispatch(toggleAdvancedFieldConfigModal()) }
                title={ trans('modal.advancedFieldConfig.openModalButtonTitle') }
              >
                <i className={ 'fas fa-cog' }/>
              </Button>
            </div>
        }

        { isReference &&
            <Link to={ `/list/${ editedField.list.id }?fromForm=${ form.id }` } className={ 'button' }>
              <i className={ 'me-1 fa fa-edit' }/>
              <span>{ trans('editField.editList') }</span>
            </Link>
        }
      </React.Fragment> : <span>{ trans('modal.advancedFieldConfig.noFieldSelected') }</span>
      }

      <hr className={ 'my-2' }/>

      <div>
        <Button className={ 'me-1' } onClick={ submitField }>
          <i className={ 'me-1 fas fa-save' }/>{ trans('save') }
        </Button>
        <Button variant={ 'danger' } size={ 'sm' } onClick={ removeField }>
          <i className={ 'me-1 fas fa-trash-alt' }/>{ trans('delete') }
        </Button>
      </div>
    </Form>
    <Modal title={ trans('modal.fieldValues.title') }
      show={ isFieldValuesModalOpen }
      onClose={ () => dispatch(toggleFieldValuesModal()) }
      size={ 'xl' }
    >
      <ValueSettings field={ editedField } />
    </Modal>
    <Modal title={ `${ localEditedField?.type?.name } ${ trans('modal.advancedFieldConfig.title') }` }
      show={ isAdvancedFieldConfigModalOpen }
      onClose={ () => dispatch(toggleAdvancedFieldConfigModal()) }
      size={ 'xl' }
      iconClass={ 'fas fa-cog' }
    >
      <AdvancedFieldSettings field={ localEditedField } onChange={ setLocalEditedField } onSubmit={ submitField } />
    </Modal>
  </div>
}

export default FieldSettings
