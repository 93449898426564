import React, { useState } from 'react'
import { Form as BForm, Button, Row, Col } from 'react-bootstrap'
import { ICON_TYPES } from '../../../Services/Constants'
import SystemNameInput from '../../../Components/SystemNameInput'

const Form = ({ trans, onSubmit = () => {}, item = null }) => {

  const [ label, setLabel ] = React.useState(item?.label || null)
  const [ isAvailable, setIsAvailable ] = useState(null)

  const onFormSubmit = e => {
    e.preventDefault()
    const icon = {
      ...item,
      label,
      type: e.target.type.value.toUpperCase(),
      content: e.target.content.value || null,
      hostName: e.target.hostName.value || null
    }

    if (!item)
      icon.systemName = e.target.systemName.value

    onSubmit(icon)

    setLabel(null)
    setIsAvailable(null)
  }

  const onSystemNameChange = (systemName, isAvailable) => {
    setIsAvailable(isAvailable)
  }

  return (
    <BForm onSubmit={ onFormSubmit }>
      { item && <small className={ 'text-muted' }>{ trans('form.systemName') } : { item.systemName }</small> }
      <Row className={ 'mb-2' }>
        <Col>
          <BForm.Group>
            <BForm.Label>{ trans('form.label') }</BForm.Label>
            <BForm.Control size={ 'sm' } name={ 'label' } value={ label || '' }
              onChange={ e => setLabel(e.target.value) } required
              placeholder={ trans('form.label') }/>
          </BForm.Group>
        </Col>
        { !item &&
          <Col>
            <BForm.Group>
              <BForm.Label>{ trans('form.systemName') }</BForm.Label>
              <SystemNameInput size={ 'sm' } label={ label } model={ 'Icon' } required onChange={ onSystemNameChange }
                placeholder={ trans('form.systemName') }/>
            </BForm.Group>
          </Col> }
      </Row>
      <Row>
        <Col>
          <BForm.Group>
            <BForm.Label>{ trans('form.type') }</BForm.Label>
            <BForm.Select defaultValue={ item?.type } name={ 'type' } required aria-label={ trans('form.type') }>
              <option value={ null }></option>
              { ICON_TYPES.map(type => <option value={ type } key={ type }>{ type }</option>) }
            </BForm.Select>
          </BForm.Group>
        </Col>
      </Row>
      <Row className={ 'mb-2' }>
        <Col>
          <BForm.Group>
            <BForm.Label>{ trans('form.content') }</BForm.Label>
            <BForm.Control as={ 'textarea' } defaultValue={ item?.content } name={ 'content' }
              placeholder={ trans('form.content') }/>
          </BForm.Group>
        </Col>
      </Row>

      <Row className={ 'mb-2' }>
        <Col>
          <BForm.Group>
            <BForm.Label>{ trans('form.hostName') }</BForm.Label>
            <BForm.Control defaultValue={ item?.hostName } name={ 'hostName' } type={ 'text' }
              placeholder={ trans('form.hostName') }/>
          </BForm.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Button variant={ 'success' } style={ { float: 'right' } } type={ 'submit' } disabled={ !item && !isAvailable }>
            { trans('submit') }
          </Button>
        </Col>
      </Row>
    </BForm>
  )
}

export default Form
