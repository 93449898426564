import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Form, Row } from 'react-bootstrap'
import { addPermission, getFieldPermissions } from 'src/Views/Permissions/selectors'
import Select from 'src/Components/Select'
import { PermissionSubject } from 'src/Views/Permissions/Types/Subject'
import { FieldPermissionAccessLevel } from 'src/Types/FieldPermission'
import { setNewSubjectData } from '../state/actions.ts'
import translation from '../translations'
import { translate } from '../../../Services/translation/index'

const fieldPermissionForm = ({ permission = null, field }) => {
  const dispatch = useDispatch()

  const { language } = useSelector(state => state.Root.user)
  const state = useSelector(state => state.Permissions)

  const fieldPermissions = getFieldPermissions(state)
  const { user } = state

  const trans = translate(translation)(language)

  const getPermission = () => permission || { field, accessLevel: FieldPermissionAccessLevel.DEFAULT }

  const onCreate = accessLevel =>
    dispatch(setNewSubjectData(
      addPermission(state, PermissionSubject.FIELD, { field, accessLevel }),
      PermissionSubject.FIELD)
    )
  const savePermission = permission =>
    dispatch(setNewSubjectData(
      [ ...fieldPermissions.map(p => p.field.id === field.id ? permission : p) ], PermissionSubject.FIELD))
  const onDelete = () =>
    dispatch(setNewSubjectData(fieldPermissions.filter(p => p.id !== permission.id), PermissionSubject.FIELD))

  const onAccessLevelSelect = accessLevel => {

    if (accessLevel === FieldPermissionAccessLevel.DEFAULT && getPermission().id)
      return onDelete()
    if (accessLevel !== FieldPermissionAccessLevel.DEFAULT && !getPermission().id)
      return onCreate(accessLevel)

    savePermission({ ...permission, accessLevel })
  }

  const getAccessLevelLabelByValue = value =>
    Object.keys(FieldPermissionAccessLevel).find(key => FieldPermissionAccessLevel[key] === value)

  return <Row>
    <Col sm={ 8 } className={ 'd-flex align-items-center justify-content-start text-break' }>
        <span className={ `${ permission?.id ? 'text-bold' : '' }` }>
          { field?.systemName }
        </span>
    </Col>
    <Col sm={ 4 }>
      <Form.Group>
        <Select
          options={
            Object.values(FieldPermissionAccessLevel)
              .filter(o => o === null || !isNaN(Number(o)))
              .map(o => ({ value: o }))
          }
          value={ ({ value: getPermission().accessLevel }) }
          onChange={ o => onAccessLevelSelect(o.value) }
          getOptionLabel={ o => trans(`accessLevels.${ getAccessLevelLabelByValue(o.value) }`) }
          title={ trans('accessLevel') }
          isDisabled={ user }
        />
      </Form.Group>
    </Col>
  </Row>
}

export default fieldPermissionForm
