import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container } from 'react-bootstrap'
import { setDeletingItem, deleteItem, setUpdatingItem, updateItem, setCreatingItem, createItem,
  fetchItems, sendTestEmail } from 'src/Views/MessageConfigs/state/actions'
import { translate } from 'src/Services/translation'
import Table from 'src/Views/MessageConfigs/Components/Table'
import Form from 'src/Views/MessageConfigs/Components/Form'
import Modal from 'src/Components/Modals/BaseModal'
import DeleteModal from 'src/Components/Modals/DeleteModal'
import { setOptions } from 'src/Layouts/View/state/actions'
import MessageConfig from 'src/Types/MessageConfig'
import { StoreState } from 'src/Services/Store/reducers'
import translation from './translations'

export const STORE = 'MessageConfigs'

const MessageConfigs = () => {
  const dispatch = useDispatch()

  const { language } = useSelector((state: StoreState) => state.Root.user)
  const { creatingItem, updatingItem, deletingItem }
      = useSelector((state: StoreState) => state[STORE])

  const trans = translate(translation)(language)

  useEffect(() => {
    dispatch(setOptions({
      hasPrimaryButton: true,
      onPrimaryButtonClick: onCreateClick
    }))
    dispatch(fetchItems())
  }, [ dispatch ])

  const onCreateClick = () => dispatch(setCreatingItem({ }))
  const onModalCreateClose = () => dispatch(setCreatingItem(null))
  const onModalCreateFormSubmit = (item: MessageConfig) => {
    dispatch(setCreatingItem(item))
    dispatch(createItem())
  }

  const onUpdateClick = (item: MessageConfig) => dispatch(setUpdatingItem(item))
  const onModalUpdateClose = () => dispatch(setUpdatingItem(null))
  const onModalUpdateFormSubmit = (item: MessageConfig) => {
    dispatch(setUpdatingItem(item))
    dispatch(updateItem())
  }

  const onDeleteClick = (item: MessageConfig) => dispatch(setDeletingItem(item))
  const onConfirmDeleteModalClose = () => dispatch(setDeletingItem(null))
  const onConfirmDeleteModalConfirm = () => dispatch(deleteItem())

  const onTestClick = (item: MessageConfig) => dispatch(sendTestEmail(item))

  return (
    <Container>
      <Table onDeleteClick={ onDeleteClick } onUpdateClick={ onUpdateClick } onTestClick={ onTestClick } />

      <Modal isOpen={ !!creatingItem } title={ trans('modal.create.title') } onClose={ onModalCreateClose }
             size={ 'md' }>
        <Form trans={ trans } onSubmit={ onModalCreateFormSubmit } />
      </Modal>

      <Modal isOpen={ !!updatingItem } title={ trans('modal.update.title') } onClose={ onModalUpdateClose }
             size={ 'md' }>
        <Form trans={ trans } onSubmit={ onModalUpdateFormSubmit } item={ updatingItem } />
      </Modal>

      <DeleteModal title={ trans('confirmDeleteModal.title') }
                   isOpen={ !!deletingItem }
                   onClose={ onConfirmDeleteModalClose }
                   onDelete={ onConfirmDeleteModalConfirm }
      >
        { trans('confirmDeleteModal.description') }
      </DeleteModal>
    </Container>
  )
}

export default MessageConfigs
