import Section from 'src/Types/Section'
import { GenericPermission } from 'src/Types/Permission'

export default interface SectionPermission extends GenericPermission {
    accessLevel: SectionPermissionAccessLevel | null
    section: Section | null
}

export enum SectionPermissionAccessLevel {
    DEFAULT = null,
    NONE = 0,
    READ = 1,
    UPDATE = 2,
    CREATE = 3,
    DELETE = 4,
}
