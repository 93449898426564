import { Form } from 'src/Types/Form'
import Section from 'src/Types/Section'
import { GenericPermission } from 'src/Types/Permission'

export default interface FormPermission extends GenericPermission {
    accessLevel: FormPermissionAccessLevel | null
    form: Form | null
    defaultSection: Section | null
    grantAccessToInstancesCreatedByMe: boolean
    grantAccessToInstancesThatRefersToMe: boolean
    grantAccessToAllLocations: boolean
}

export enum FormPermissionAccessLevel {
    NONE = 0,
    READ = 1,
    READ_DOCUMENT = 2,
    UPDATE = 3,
    DELETE_DOCUMENT = 4,
    CREATE = 5,
    DELETE = 6,
    UNLOCK_DOCUMENT = 7,
    RESTORE_DOCUMENT = 8,
    RESTORE = 9,
}
