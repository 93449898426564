import { always, cond, defaultTo, isEmpty, path, pipe, reduce, replace, split, T, test, toPairs, unless } from 'ramda'
import AppSentry from 'src/Services/Sentry/index.ts'
import main from './main'

const replaceParams = params => trans =>
  reduce(
    (trans, [ name, value ]) => replace(`{${ name }}`, value, trans),
    trans,
    toPairs(params)
  )

const translate = dictionary => {
  const translations = {
    english: { ...main.english, ...dictionary.english },
    french: { ...main.french, ...dictionary.french },
  }

  return locale =>
    (jsonPath, params = {}) =>
      pipe(
        cond([
          [ test(/^fr/iu), always(translations.french || translations.fr) ],
          [ T, always(translations.english || translations.en) ]
        ]),
        path(split('.', jsonPath)),
        defaultTo(`${ jsonPath }`),
        unless(always(isEmpty(params)), replaceParams(params))
      )(locale)
}

/**
 * Works with a trans code or with an object containing { systemName, label },
 * in which case systemName is used as a code
 */
const translateConf = configTranslations => (model = null) => param => {

  if (!configTranslations)
    return AppSentry.captureMessage('No conf translations loaded.', 'warning')

  const code = model ? `${ model }#` : ''

  if (typeof param === 'string')
    return configTranslations[code+param] || param
  else if (!param)
    return ''
  else if (param.systemName)
    return configTranslations[code+param.systemName] || param.label || param.systemName
  else if (param.label)
    return param.label || ''
  else
    return ''
}

export { translate, translateConf }
