import AppSentry, { Levels } from 'src/Services/Sentry'
import { ID_SEPARATOR } from 'src/Services/Constants'
import { Field } from 'src/Types/Field'
import { ListColumn, ListColumnForApi } from 'src/Types/ListColumn'
import { UuidV4 } from 'src/Types/Uuid'
import { User } from 'src/Types/User'
import { PermissionAccessLevel } from 'src/Types/Permission'
import { Instance } from 'src/Types/Instance'
import { Form } from 'src/Types/Form'
import { FormPermissionAccessLevel } from 'src/Types/FormPermission'
import Config from 'src/Types/Config'
import { AxiosResponse } from 'axios'
import { getFullApiBaseUrl } from 'src/Services/Firewall/utils'

export const concatExceptLast = (separator: string, array: string[]): string =>
  array.reduce((acc, item, index) => {
    const isFirst = index === 0
    const isLast = index === array.length - 1

    if (isFirst)
      return acc + item

    if (isLast)
      return acc

    return acc + separator + item
  }, '')

export const formatColumnBody = (field: Field, column: ListColumn): ListColumnForApi => {
  const result: ListColumnForApi = {
    label: column.label,
    systemName: column.systemName,
    fieldId: field.id,
    referenceFieldLabel: field.label,
    referenceFieldId: null
  }

  if (!result.fieldId.includes(ID_SEPARATOR))
    return result

  const ids = result.fieldId.split(ID_SEPARATOR)
  const lastIdIndex = ids.length - 1

  return {
    ...result,
    fieldId: ids[lastIdIndex],
    referenceFieldId: concatExceptLast(ID_SEPARATOR, ids)
  }
}

export const getFieldIdFromReferenceFieldId = (id: string): UuidV4 => {

  if (!id.includes(ID_SEPARATOR))
    return id

  const ids = id.split(ID_SEPARATOR)
  const lastIdIndex = ids.length - 1

  return ids[lastIdIndex]
}

export const toSnakeCase = (value: string, inUpper = true): string => {
  if (typeof value !== 'string')
    return ''

  const matches = value.match(/[A-Za-z0-9_]+/g)

  if (!matches || matches.length === 0)
    return ''

  return matches
    .map(s => inUpper ? s.toUpperCase() : s.toLowerCase())
    .join('_')
}

export const hasUserModulePermission = (user: User, moduleName: string, accessLevel: PermissionAccessLevel): boolean => {

  if (!user?.modules) {
    AppSentry.captureMessage(`User provided has no modules : ${ user?.id }`, Levels.LEVEL_WARN)
    return false
  }

  if (user.isAdmin)
    return true

  const module = user?.modules.find(m => m.systemName === moduleName)

  return module?.isActive && module.permission?.accessLevel >= accessLevel
}

export const hasUserInstancePermission = (user: User, instance: Instance, accessLevel: FormPermissionAccessLevel) => {
  if (user.isAdmin)
    return true

  return instance?.permission?.accessLevel >= accessLevel
}

export const hasUserFormPermission = (user: User, form: Form, accessLevel: FormPermissionAccessLevel) => {
  if (user.isAdmin)
    return true

  return form?.accessLevel >= accessLevel
}

export const getNavbarHeight = () =>
  document.getElementById('appNavbar')?.offsetHeight || 0

export const getViewHeaderHeight = (isHeaderSticky: boolean) =>
  isHeaderSticky ? document.getElementById('viewHeader')?.offsetHeight : 0

const getPatientToolbarHeight = (isPatientToolbar: boolean) =>
  isPatientToolbar ? document.getElementById('patientToolbar')?.offsetHeight : 0


export const getHeadersHeight = (isHeaderSticky: boolean, isPatientToolbar: boolean) =>
  getNavbarHeight() + getViewHeaderHeight(isHeaderSticky) + getPatientToolbarHeight(isPatientToolbar)

export const scrollToView = (element: HTMLElement, isHeaderSticky: boolean = null, isPatientToolbar: boolean = null) => {
  const elementTop = element.getBoundingClientRect().top
  const headersHeight = isHeaderSticky && isPatientToolbar
    ? getHeadersHeight(isHeaderSticky, isPatientToolbar)
    : getHeadersHeight(true, false)
  const y = window.scrollY + elementTop - headersHeight
  window.scrollTo({ top: y, behavior: 'smooth' })
}

export const getCookie = (name: string): void|string => {
  const allParts = document.cookie.split(';')
  const targetedCookie = allParts.find(part => RegExp(new RegExp(name)).exec(part))

  if (targetedCookie) {
    const cookieParts = targetedCookie.split(`${ name }=`)
    return cookieParts.pop().split(';').at(-1)
  }
}

export const isLocalhost = (url: string): boolean => url.includes('localhost') || url.includes('127.0.0.1')

export const isJsonString = (string: string): boolean => {
  try {
    JSON.parse(string)
  } catch (e) {
    return false
  }
  return true
}

export const isOwnApiRequest = (config: Config, response: AxiosResponse): boolean =>
  response.config
    ? response.config.url.startsWith('/') && response.config.baseURL === getFullApiBaseUrl(config) || response.config.url.startsWith(getFullApiBaseUrl(config))
    : true
