import { call, put, select, takeLatest } from 'redux-saga/effects'
import { itemCreated, itemDeleted, itemUpdated } from 'src/Views/Workflows/Modals/Workflows/state/actions'
import apiMethods from 'src/Services/api/apiMethods'
import { CREATE_WORKFLOW, DELETE_WORKFLOW, UPDATE_WORKFLOW } from 'src/Views/Workflows/Modals/Workflows/state/actionTypes'
import { GlobalActions } from 'src/Types/GlobalActions'
import { Action, StoreState } from 'src/Services/Store/reducers'

const STORE = 'WorkflowItems'

function* updateItem(props: GlobalActions, { payload: { item } }: Action) {
  try {
    const id = item.id
    delete item.id
    const { data } = yield call(apiMethods.update, `/workflow/items/${ id }`, { data: item })
    yield put(itemUpdated(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'updateFailed'))
  }
}

function* deleteItem(props: GlobalActions) {
  try {
    const { id } = ((yield select()) as StoreState)[STORE].deletingItem
    yield call(apiMethods.delete, `/workflow/items/${ id }`)
    yield put(itemDeleted(id))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'deleteFailed'))
  }
}

function* createItem(props: GlobalActions, { payload: { item } }: Action) {
  try {
    const { data } = yield call(apiMethods.create, '/workflow/items', { data: item })
    yield put(itemCreated(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'createFailed'))
  }
}

export default function* workflowItemsSagaWatcher(props: GlobalActions) {
  yield takeLatest(CREATE_WORKFLOW, createItem, props)
  yield takeLatest(DELETE_WORKFLOW, deleteItem, props)
  yield takeLatest(UPDATE_WORKFLOW, updateItem, props)
}
