import { call, put, select, takeLatest } from 'redux-saga/effects'
import * as actions from 'src/Components/Calendar/state/actionTypes'
import apiMethods from 'src/Services/api/apiMethods'
import { pushNotification, showSuccess, VARIANT_SUCCESS } from 'src/Services/notifier/actions'
import { fetchSidebarEvents } from 'src/Views/Patient/state/actions'
import {
  eventCreated, eventDeleted, getEvents, setDeletingEvent, setDeletingSlot, setEditingEvent, setEventResources, setEvents, setFilter, setFilterValues, setSlotResources,
  slotCreated,
  slotDeleted, slotSerieCreated,
} from 'src/Components/Calendar/state/actions'
import { GlobalActions } from 'src/Types/GlobalActions'
import { Action, StoreState } from 'src/Services/Store/reducers'
import { CalendarFilterName } from 'src/Types/CalendarFilter'
import { getDefaultActiveFilters } from 'src/Components/Calendar/Utils'

function* createSlotSerie(props: GlobalActions, { payload: { slotSerie } }: Action) {
  try {
    const { data } = yield call(apiMethods.post, '/slot_series', { data: slotSerie })
    yield put(slotSerieCreated(data))
    yield put(getEvents(data.startDate, data.endDate))
    yield put(pushNotification(
      VARIANT_SUCCESS,
      'createSucceeded',
    ))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'createFailed'))
  }
}

function* createSlot(props: GlobalActions, { payload: { slot } }: Action) {
  try {
    const { data } = yield call(apiMethods.post, '/slots', { data: slot })
    yield put(slotCreated(data))
    yield put(getEvents(data.startDate, data.endDate))
    yield put(pushNotification(
      VARIANT_SUCCESS,
      'createSucceeded',
    ))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'createFailed'))
  }
}

function* deleteSlot(props: GlobalActions, { payload }: Action) {
  try {
    const state = ((yield select()) as StoreState).Calendar
    const deletingSlot = state.deletingSlot
    yield call(apiMethods.delete, `/slots/${ deletingSlot.id }`)
    yield put(slotDeleted(deletingSlot))
    yield put(setDeletingSlot(null))

    yield put(showSuccess('deleteSucceeded'))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'deleteFailed'))
  }
}

function* deleteEvent(props: GlobalActions, { payload }: Action) {
  try {
    const state = ((yield select()) as StoreState).Calendar
    const deletingEvent = state.deletingEvent

    yield call(apiMethods.delete, `/events/${ deletingEvent.id }`)
    yield put(eventDeleted(deletingEvent))
    yield put(setDeletingEvent(null))

    yield put(showSuccess('deleteSucceeded'))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'deleteFailed'))
  }
}

function* handleSlotSerieCreated(props: GlobalActions, { payload: { slotSerie } }: Action) {
  const { startDate, endDate } = slotSerie
  yield put(getEvents(startDate, endDate))
}

function* handleSlotCreated(props: GlobalActions, { payload: { slot } }: Action) {
  const { startAt, endAt } = slot
  yield put(getEvents(startAt, endAt))
}

function* getResources(props: GlobalActions, { payload: { target, eventTypeId, locationId } }: Action) {
  const params = locationId ? { locationId } : {}

  try {
    const { data } = yield call(apiMethods.get, `/instances/resources/${ eventTypeId }`, params)
    yield put(target === 'slot' ? setSlotResources(data) : setEventResources(data))
  } catch (error) {
    yield put(props.globalActions.handleError(error))
  }
}

function* onFiltersSet(props: GlobalActions, { payload }: Action) {
  yield put(setFilterValues(getDefaultActiveFilters()))
}

function* getResourceFilter(props: GlobalActions, { payload }: Action) {
  const params = payload.locationId ? { locationId: payload.locationId } : {}
  const { data } = yield call(apiMethods.get, '/calendar/filters/resource', params)

  yield put(setFilter(data))
}

function* fetchEvents(props: GlobalActions, { payload: { start, end } }: Action) {
  try {
    const state = ((yield select()) as StoreState).Calendar
    const { filterValues, currentStartDate, currentEndDate } = state
    const startDate = start || currentStartDate
    const endDate = end || currentEndDate

    const filters =
      Object.keys(filterValues).map((name: CalendarFilterName) => ({ name, values: filterValues[name] }))

    const { data } = yield call(apiMethods.get, '/calendar/events', { filters, startDate, endDate })

    yield put(setEvents(data, startDate, endDate))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'fetchFailed'))
  }
}

function* createEvent(props: GlobalActions, { payload: { event, patientId } }: Action) {
  try {
    const { data } = yield call(apiMethods.post, '/events', { data: event })
    yield put(getEvents(data.start, data.end))
    yield put(eventCreated(data))
    yield put(showSuccess('createSucceeded'))

    if (patientId)
      yield put(fetchSidebarEvents(patientId))
  } catch (error) {
    yield put(props.globalActions.handleError(
        error,
        error.response?.data?.error?.code === 'mandatoryFieldMissing' ? 'mandatoryFieldMissing' : 'createEventsFailed',
      ),
    )
  }
}

function* editEvent(props: GlobalActions, { payload: { event, eventId, patientId } }: Action) {
  try {
    const state = ((yield select()) as StoreState).Calendar
    const { currentStartDate, currentEndDate } = state

    yield call(apiMethods.update, `/events/${ eventId }`, { data: event })

    yield put(getEvents(currentStartDate, currentEndDate))
    yield put(setEditingEvent(null))
    yield put(showSuccess('updateSucceeded'))

    if (patientId)
      yield put(fetchSidebarEvents(patientId))
  } catch (error) {
    yield put(props.globalActions.handleError(
        error,
        error.response?.data?.error?.code === 'MandatoryField Missing' ? 'mandatoryFieldMissing' : 'updateFailed',
      ),
    )
  }
}

export default function* calendarSagaWatcher(props: GlobalActions) {
  yield takeLatest(actions.CREATE_SLOT_SERIE, createSlotSerie, props)
  yield takeLatest(actions.CREATE_SLOT, createSlot, props)
  yield takeLatest(actions.GET_EVENTS, fetchEvents, props)
  yield takeLatest(actions.FETCH_RESOURCES, getResources, props)
  yield takeLatest(actions.CREATE_EVENT, createEvent, props)
  yield takeLatest(actions.SLOT_SERIE_CREATED, handleSlotSerieCreated, props)
  yield takeLatest(actions.SLOT_CREATED, handleSlotCreated, props)
  yield takeLatest(actions.DELETE_SLOT, deleteSlot, props)
  yield takeLatest(actions.DELETE_EVENT, deleteEvent, props)
  yield takeLatest(actions.EDIT_EVENT, editEvent, props)
  yield takeLatest(actions.SET_FILTERS, onFiltersSet, props)
  yield takeLatest(actions.GET_RESOURCE_FILTER, getResourceFilter, props)
}
