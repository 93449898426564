import { StoreState } from 'src/Services/Store/reducers'
import Row from 'src/Types/Row'
import { UuidV4 } from 'src/Types/Uuid'
import { Field } from 'src/Types/Field'

export const getFieldById = (state: StoreState['FormEditor'], id: UuidV4): Field => {
  if (!state.formReducer?.form?.sections)
    return null

  for (const section of state.formReducer.form.sections)
    for (const row of section.rows)
      for (const field of row.fields)
        if (field.id === id)
          return field

  return null
}

export const getRowById = (state: StoreState['FormEditor'], id: UuidV4): Row => {
  if (!state.formReducer?.form?.sections)
    return null

  for (const section of state.formReducer.form.sections)
    for (const row of section.rows)
      if (row.id === id)
        return row

  return null
}

export const getRowFieldByColumn = (state: StoreState['FormEditor'], rowId: UuidV4, columnIndex: number) => {
  const row = getRowById(state, rowId)

  return row?.fields?.find(field =>
    field.rowColumn === columnIndex || field.rowColumn - 1 + field.rowSize === columnIndex
  ) || null
}
