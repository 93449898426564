import { Button, Col, Form, Row } from 'react-bootstrap'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import prettyBytes from 'pretty-bytes'
import { showWarning } from 'src/Services/notifier/actions'
import { Field } from 'src/Types/Field'
import File from 'src/Types/File'
import { translate } from 'src/Services/translation'
import translation from 'src/Components/FilesUploader/translations'
import { StoreState } from 'src/Services/Store/reducers'
import { deleteFile, downloadFileFromUrl, editFile, lockFile, unlockFile } from '../../../../state/actions'

interface Props {
  field: Field
  file: File
  canUpdateFile: boolean
  canDeleteFile: boolean
  isDisabled: boolean
}

const FileDetails = ({ field, file, canUpdateFile, canDeleteFile, isDisabled }: Props) => {
  const dispatch = useDispatch()

  const { savingFiles } = useSelector((state: StoreState) => state.FormFiller)
  const { user } = useSelector((state: StoreState) => state.Root)

  const trans = translate(translation)(user.language)

  const { id, extension, fileType, size } = file

  const [ name, setName ] = useState(file.name)
  const [ description, setDescription ] = useState(file.description)

  React.useEffect(() => {
    if (!file)
      return

    setName(file.name)
    setDescription(file.description)
  }, [ file ])

  const isSaving = () => !!savingFiles[id]
  const isUploaded = () => !!file.createdAt
  const isLocked = () => isUploaded() === false ? false : file.isLocked

  const onDownloadClick = () => dispatch(downloadFileFromUrl(field.id, id))
  const onLockClick = () => dispatch(lockFile(field.id, id))
  const onUnlockClick = () => dispatch(unlockFile(field.id, id))
  const onDeleteClick = () => dispatch(deleteFile(field, id))
  const onSaveClick = () => {
    if (!name)
      return dispatch(showWarning(trans('nameRequired')))

    dispatch((editFile)(field.id, id, name, description))
  }

  const isSaveButtonDisabled = () => {
    if (isLocked() || isSaving())
      return true

    return file.name === name && file.description === description
  }

  return <>
    <Row className={ 'mt-2' }>
      { canUpdateFile && <><Col>
        <Form.Group>
          <Form.Label className={ 'm-0' }>{ trans('filename') }</Form.Label>
          <Form.Control name={ 'name' }
            size={ 'sm' }
            required
            disabled={ isDisabled || isLocked() || !file.id }
            value={ name || '' }
            onChange={ e => setName(e.target.value || null) }
          />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Label className={ 'm-0' }>{ trans('description') }</Form.Label>
          <Form.Control id={ 'description' }
            disabled={ isDisabled || isLocked() || !file.id }
            size={ 'sm' }
            value={ description || '' }
            onChange={ e => setDescription(e.target.value || null) }
          />
        </Form.Group>
      </Col></> }
      <Col className={ 'mt-auto' }>
        {  file.id && <Button variant={ 'primary' }
          size={ 'sm' }
          onClick={ onDownloadClick }
          className={ 'me-2' }
          title={ trans('download') }
        >
          <i className={ 'fas fa-download' }/>
        </Button> }
        { canUpdateFile && file.id && <Button variant={ 'secondary' }
          size={ 'sm' }
          onClick={ isLocked() ? onUnlockClick : onLockClick }
          className={ 'me-2' } disabled={ isDisabled }
          title={ isLocked() ? trans('unlock') : trans('lock') }
        >
          <i className={ `fas fa-${ isLocked() ? 'unlock' : 'lock' }` }/>
        </Button> }

        { canUpdateFile && file.id && <Button variant={ 'success' }
          size={ 'sm' }
          className={ 'me-2' }
          onClick={ onSaveClick }
          disabled={ isDisabled || isSaveButtonDisabled() }
          title={ trans('save') }
        >
          <i className={ 'fas fa-save' }/>
        </Button> }
        { canDeleteFile && file.id && <Button variant={ 'danger' }
          size={ 'sm' }
          onClick={ onDeleteClick }
          disabled={ isDisabled || isLocked() }
          title={ trans('delete') }
        >
          <i className={ 'fas fa-trash' }/>
        </Button> }
      </Col>
    </Row>
    <Row>
      <small className={ 'text-muted' }>
        <span className={ 'fw-bold' }>{ trans('type') } : </span>
        { extension } ({ fileType })
        &nbsp;|&nbsp;
        <span className={ 'font-weight-bold' }>{ trans('size') } : </span>
        { size ? prettyBytes(size) : trans('none') }
      </small>
    </Row>
  </>
}

export default FileDetails
