import { Instance, InstanceListValues } from 'src/Types/Instance'
import { BaseFieldType, Field, FieldValue } from 'src/Types/Field'
import { Form } from 'src/Types/Form'
import FormCustomizationCondition from 'src/Types/FormCustomizationCondition'
import { Action, ActionOptions, FieldEvent, FieldEventData } from 'src/Views/FormFiller/Types/Field'
import { FormElementType } from 'src/Views/FormFiller/Types/ScriptFormCustomizationOrder'
import { SaveStatus } from 'src/Components/SaveButton'
import { NavigateFunction } from 'react-router/dist/lib/hooks'
import OpenedInstance from 'src/Views/FormFiller/Types/OpenedInstance'
import { Filter } from 'src/Types/RequestFilter'
import SystemField from 'src/Types/SystemField'
import { ExtensibleRowValues } from 'src/Views/FormFiller/state/selectors'
import { SwpFeatureStatus } from 'src/Views/FormFiller/Types/Swp'
import { Iso8601 } from 'src/Types/Date'
import { UuidV4 } from 'src/Types/Uuid'
import { SlotType } from 'src/Types/Slot'
import { Action as ReducerAction } from 'src/Services/Store/reducers'
import { FormFillerState } from 'src/Views/FormFiller/state/reducer'
import * as type from './actionTypes'
import { MsSanteTracking } from '../Types/MsSante'

export const resetInstance = () => ({
  type: type.RESET_INSTANCE,
})


export const fetchInstance = (id: string) => ({
  type: type.FETCH_INSTANCE,
  payload: id,
})

export const fetchInstanceField = (instanceId: string, fieldId: string, onSuccess = (): any => null) => ({
  type: type.FETCH_INSTANCE_FIELD,
  payload: { instanceId, fieldId, onSuccess },
})

export const fetchInstanceFailed = (httpCode: number) => ({
  type: type.FETCH_INSTANCE_FAILED,
  payload: { httpCode },
})

export const setInstance = (instance: OpenedInstance, form: Form) => ({
  type: type.SET_INSTANCE,
  payload: { instance, form },
})
export const setInstanceField = (fieldId: string | number, value: any) => ({
  type: type.SET_INSTANCE_FIELD,
  payload: { fieldId, value },
})
export const setInstanceSystemFields = (systemFields: SystemField[]) => ({
  type: type.SET_INSTANCE_SYSTEM_FIELDS,
  payload: { systemFields },
})

export const saveInstance = (
  id: string = null,
  isSilent = false,
  onSuccess = (): any => null,
  onFailure = (): any => null,
) => ({
  type: type.SAVE_INSTANCE,
  payload: { id, isSilent, onSuccess, onFailure },
})

export const saveInstanceFieldValue = (
  instanceId: string,
  fieldId: string | number,
  value: any,
  isSilent = false,
) => ({
  type: type.SAVE_INSTANCE_FIELD_VALUE,
  payload: { instanceId, fieldId, value, isSilent },
})

export const saveInstanceSucceeded = () => ({
  type: type.SAVE_INSTANCE_SUCCEEDED,
})

export const saveInstanceAborted = () => ({
  type: type.SAVE_INSTANCE_ABORTED,
})

export const saveInstanceFailed = () => ({
  type: type.SAVE_INSTANCE_FAILED,
})

export const restoreInstance = (instanceId: string) => ({
  type: type.RESTORE_INSTANCE,
  payload: { instanceId },
})

export const deleteInstance = (id: string, navigate: NavigateFunction) => ({
  type: type.DELETE_INSTANCE,
  payload: { id, navigate },
})

export const setIsEditing = (isEditing: boolean, referenceField: Field) => ({
  type: type.IS_EDITING_REFERENCE,
  payload: { isEditing, referenceField },
})

export const changeReference = (refId: UuidV4, instanceId: UuidV4, values: InstanceListValues, isFromExternalSource = false) => ({
  type: type.CHANGE_REFERENCE,
  payload: { refId, instanceId, values, isFromExternalSource },
})

export const changeReferenceFromId = (referenceField: Field, instanceId: UuidV4) => ({
  type: type.CHANGE_REFERENCE_FROM_ID,
  payload: { referenceField, instanceId },
})

export const changeReferenceToSelf = (refId: UuidV4, instanceId: UuidV4, listId: UuidV4, contextInstance: UuidV4) => ({
  type: type.CHANGE_REFERENCE_TO_SELF,
  payload: { refId, instanceId, listId, contextInstance },
})

export const clearReference = (field: Field) => ({
  type: type.CLEAR_REFERENCE,
  payload: { field },
})

export const printContent = () => ({
  type: type.PRINT_CONTENT,
})

export const searchReference = (
  listId: string,
  fieldId: string | number,
  filters: Filter[],
  showAddButton: boolean,
  externalProvider?: string,
  contextInstance?: UuidV4
) => ({
  type: type.SEARCH_REFERENCE,
  payload: { listId, fieldId, filters, showAddButton, externalProvider, contextInstance },
})

export const referenceSearchSucceeded = (results: Instance[]) => ({
  type: type.REFERENCE_SEARCH_SUCCEEDED,
  payload: { results },
})
export const setReferenceSearchFilters = (filters: object[]) => ({
  type: type.SET_REFERENCE_SEARCH_FILTERS,
  payload: { filters },
})

export const referenceSearchFailed = () => ({
  type: type.REFERENCE_SEARCH_FAILED,
})

export const hideReferenceSearchModal = () => ({
  type: type.HIDE_REFERENCE_SEARCH_MODAL,
})

export const requestInstanceCreationForReference = (referenceField: Field) => ({
  type: type.REQUEST_INSTANCE_CREATION_FOR_REFERENCE,
  payload: { referenceField },
})

export const createInstanceForReference = (referenceField: Field, values: InstanceListValues) => ({
  type: type.CREATE_INSTANCE_FOR_REFERENCE,
  payload: { referenceField, values },
})

export const requestInstanceEditForReference = (referenceField: Field) => ({
  type: type.REQUEST_INSTANCE_EDIT_FOR_REFERENCE,
  payload: { referenceField },
})

export const editInstanceForReference = (referenceField: Field, values: InstanceListValues, instanceId: string) => ({
  type: type.EDIT_INSTANCE_FOR_REFERENCE,
  payload: { referenceField, values, instanceId },
})

export const referenceInstanceCreated = () => ({
  type: type.REFERENCE_INSTANCE_CREATED,
})

export const referenceInstanceCreationFailed = () => ({
  type: type.REFERENCE_INSTANCE_CREATION_FAILED,
})

export const addExtensibleRow = (extensibleId: string, rowId: UuidV4, data: ExtensibleRowValues) => ({
  type: type.ADD_EXTENSIBLE_ROW,
  payload: { extensibleId, rowId, data },
})

export const removeExtensibleRow = (extensibleId: string, rowId: UuidV4) => ({
  type: type.REMOVE_EXTENSIBLE_ROW,
  payload: { extensibleId, rowId },
})

export const createExtensibleRow = (fieldId: string | number) => ({
  type: type.CREATE_EXTENSIBLE_ROW,
  payload: { fieldId },
})

export const editExtensibleRow = (extensibleFieldId: string, rowId: UuidV4) => ({
  type: type.EDIT_EXTENSIBLE_ROW,
  payload: { extensibleFieldId, rowId },
})
export const addExtensibleRowLoading = (extensibleFieldId: string, rowId: UuidV4) => ({
  type: type.ADD_EXTENSIBLE_ROW_LOADING,
  payload: { extensibleFieldId, rowId },
})
export const removeExtensibleRowLoading = (extensibleFieldId: string, rowId: UuidV4) => ({
  type: type.REMOVE_EXTENSIBLE_ROW_LOADING,
  payload: { extensibleFieldId, rowId },
})

export const toggleValidateUserIdentityModal = (isOpen: boolean = null) => ({
  type: type.TOGGLE_VALIDATE_USER_AUTHENTICITY_MODAL,
  payload: { isOpen }
})

export const toggleMsSanteModal = (data: boolean) => ({
  type: type.TOGGLE_MS_SANTE_MODAL,
  payload: data
})

export const toggleMsSanteRecipientsFetching = (data: boolean) => ({
  type: type.TOGGLE_MS_SANTE_RECIPIENTS_FETCHING,
  payload: data
})

export const toggleMsSanteContentsFetching = (data: boolean) => ({
  type: type.TOGGLE_MS_SANTE_CONTENTS_FETCHING,
  payload: data
})

export const sendMsSanteTrackingRequest = (instanceId: UuidV4) => ({
  type: type.SEND_MS_SANTE_TRACKING_REQUEST,
  payload: { instanceId }
})

export const fetchMsSanteTrackingData = (instanceId: UuidV4) => ({
  type: type.FETCH_MS_SANTE_TRACKING_DATA,
  payload: { instanceId }
})

export const setMsSanteTrackingData = (tracking: MsSanteTracking) => ({
  type: type.SET_MS_SANTE_TRACKING_DATA,
  payload: { tracking }
})

export const toggleMsSanteTrackingModal = (data: boolean) => ({
  type: type.TOGGLE_MS_SANTE_TRACKING_MODAL,
  payload: data
})


export const setUserCode = (userCode: string | null) => ({
  type: type.SET_USER_PIN,
  payload: { userCode },
})

export const deleteExtensibleRow = (extensibleFieldId: UuidV4, rowId: UuidV4) => ({
  type: type.DELETE_EXTENSIBLE_ROW,
  payload: { extensibleFieldId, rowId },
})

// Files
export const downloadFile = (fieldId: UuidV4, fileId: UuidV4) => ({
  type: type.DOWNLOAD_FILE,
  payload: { fieldId, fileId },
})
export const downloadFileFromUrl = (fieldId: UuidV4, fileId: UuidV4) => ({
  type: type.DOWNLOAD_FILE_FROM_URL,
  payload: { fieldId, fileId },
})

export type DeleteFileAction = ReducerAction<{ field: Field<BaseFieldType.FILE>, fileId: UuidV4 }>
export const deleteFile = (field: Field<BaseFieldType.FILE>, fileId: UuidV4): DeleteFileAction => ({
  type: type.DELETE_FILE,
  payload: { field, fileId },
})
export const deleteFileSucceed = (fieldId: UuidV4, fileId: UuidV4) => ({
  type: type.DELETE_FILE_SUCCEED,
  payload: { fieldId, fileId },
})

export const lockFile = (fieldId: UuidV4, fileId: UuidV4) => ({
  type: type.LOCK_FILE,
  payload: { fieldId, fileId },
})
export const lockFileSucceed = (fieldId: UuidV4, fileId: UuidV4) => ({
  type: type.LOCK_FILE_SUCCEED,
  payload: { fieldId, fileId },
})
export const unlockFile = (fieldId: UuidV4, fileId: UuidV4) => ({
  type: type.UNLOCK_FILE,
  payload: { fieldId, fileId },
})
export const unlockFileSucceed = (fieldId: UuidV4, fileId: UuidV4) => ({
  type: type.UNLOCK_FILE_SUCCEED,
  payload: { fieldId, fileId },
})

export const editFile = (fieldId: UuidV4, fileId: UuidV4, name: string, description: string) => ({
  type: type.EDIT_FILE,
  payload: { fieldId, fileId, name, description },
})

export const editFileSucceed = (fieldId: UuidV4, fileId: UuidV4, name: string, description: string) => ({
  type: type.EDIT_FILE_SUCCEED,
  payload: { fieldId, fileId, name, description },
})
export const setSaveInstanceStatus = (status: SaveStatus) => ({
  type: type.SET_SAVE_INSTANCE_STATUS,
  payload: { status },
})

export const pushCustomizationOrder = (
  elementType: FormElementType,
  elementId: string | number,
  action: Action,
  options?: ActionOptions,
) => ({
  type: type.PUSH_CUSTOMIZATION_ORDER,
  payload: { elementType, elementId, action, options },
})
export const resetCustomizations = () => ({
  type: type.RESET_CUSTOMIZATIONS,
})
export const setFormCustomizationsConditionsTriggered = (conditions: FormCustomizationCondition[]) => ({
  type: type.SET_FORM_CUSTOMIZATIONS_CONDITIONS_TRIGGERED,
  payload: { conditions },
})
export const removeCustomizationConditionTriggered = (condition: FormCustomizationCondition) => ({
  type: type.REMOVE_CUSTOMIZATION_CONDITION_TRIGGERED,
  payload: { condition },
})

export const fieldEvent = (field: Field, event: FieldEvent, data: FieldEventData, navigate: NavigateFunction = null) => ({
  type: type.FIELD_EVENT,
  payload: { field, event, data, navigate },
})
export const changeFieldValue = <T extends BaseFieldType = null>(field: Field<T>, value: FieldValue<T>) => ({
  type: type.CHANGE_FIELD_VALUE,
  payload: { field, value },
})

export const generateDocumentFile = () => ({
  type: type.GENERATE_DOCUMENT_FILE,
})

export const setIsGenerateTemplateLoading = (isGenerateTemplateLoading: boolean) => ({
  type: type.IS_GENERATE_TEMPLATE_LOADING,
  payload: { isGenerateTemplateLoading },
})

export const setDocumentData = (data: any) => ({
  type: type.SET_DOCUMENT_DATA,
  payload: { data },
})

export const sendEmailPreview = (instanceId: string) => ({
  type: type.SEND_EMAIl_PREVIEW,
  payload: { instanceId },
})

/** SWP */
export const initSwp = () => ({
  type: type.INIT_SWP,
})
export const openSwpRecord = () => ({
  type: type.OPEN_SWP_RECORD,
})
export const openSwpPlayback = () => ({
  type: type.OPEN_SWP_PLAYBACK,
})
export const openSwpMonitor = () => ({
  type: type.OPEN_SWP_MONITOR,
})
export const initiateSwpAcquisition = () => ({
  type: type.INITIATE_SWP_ACQUISITION,
})
export const redirectToSwpDynamicLink = () => ({
  type: type.REDIRECT_TO_SWP_DYNAMIC_LINK,
})

export const createRdSession = () => ({
  type: type.CREATE_RD_SESSION,
})
export const openRdSession = () => ({
  type: type.OPEN_RD_SESSION,
})

export const fillPatientToInstance = (refId: UuidV4, rowId: UuidV4, values: InstanceListValues, patientReferenceFields: Field[] = []) => ({
  type: type.FILL_PATIENT_INSTANCE,
  payload: { refId, rowId, values, patientReferenceFields },
})

export const setCalculationFields = (data: FormFillerState['calculationFields']) => ({
  type: type.SET_CALCULATION_FIELDS,
  payload: data,
})

export const toggleSaveLoading = (isLoading: boolean) => ({
  type: type.TOGGLE_SAVE_LOADING,
  payload: { isLoading },
})

export const setIsOpenedInstanceReady = (isReady: boolean) => ({
  type: type.SET_IS_OPENED_INSTANCE_READY,
  payload: { isReady },
})

export const setSwpFeatureStatus = (status: SwpFeatureStatus) => ({
  type: type.SET_SWP_FEATURE_STATUS,
  payload: { status },
})

export const setDocumentAsFinal = (instanceId: string, field: Field, file: File) => ({
  type: type.SET_DOCUMENT_AS_FINAL,
  payload: { instanceId, field, file },
})
export const setPendingProtectedAction = (action: ReducerAction) => ({
  type: type.SET_PENDING_PROTECTED_ACTION,
  payload: { action },
})

export const sendMsSanteDocuments = (
  instanceId: string,
  recipients: string[],
  files: string[]
) => ({
  type: type.SEND_MS_SANTE_DOCUMENTS,
  payload: { instanceId, recipients, files }
})

export const toggleMsSanteModalLoading = (isLoading: boolean) => ({
  type: type.TOGGLE_MS_SANTE_MODAL_LOADING,
  payload: { isLoading },
})

export const fetchMsSanteRecipients = (instanceId: string) => ({
  type: type.FETCH_MS_SANTE_RECIPIENTS,
  payload: { instanceId }
})

export const setMsSanteRecipients = (recipients: object[]) => ({
  type: type.SET_MS_SANTE_RECIPIENTS,
  payload: { recipients }
})

export const fetchMsSanteContentsToSend = (instanceId: string) => ({
  type: type.FETCH_MS_SANTE_CONTENTS_TO_SEND,
  payload: { instanceId }
})

export const setMsSanteContentsToSend = (contentsToSend: object[]) => ({
  type: type.SET_MS_SANTE_CONTENTS_TO_SEND,
  payload: { contentsToSend }
})

export const setValueByAvailability = (date: Iso8601, fieldsToPopulate: Field[], slotTypes: SlotType[]) => ({
  type: type.SET_VALUE_BY_AVAILABILITY,
  payload: { date, fieldsToPopulate, slotTypes }
})


export const setValueToTargetFields = (targetFields: Field[], options: ActionOptions) => ({
  type: type.SET_VALUE_TO_TARGET_FIELDS,
  payload: { targetFields, options }
})

export const executeButtonFieldActions = (field: Field, event: FieldEventData = null, navigate: NavigateFunction, skipConsent = false) => ({
  type: type.EXECUTE_BUTTON_FIELD_ACTIONS,
  payload: { field, event, navigate, skipConsent }
})
export const executeAutoCompleteAction = (field: Field) => ({
  type: type.EXECUTE_AUTO_COMPLETE_ACTION,
  payload: { field }
})

export const executeConsentAction = (field: Field, navigate: NavigateFunction) => ({
  type: type.EXECUTE_CONSENT_ACTION,
  payload: { field, navigate }
})
export const askConsent = (field: Field) => ({
  type: type.ASK_CONSENT,
  payload: { field }
})
export const abortConsent = (field: Field) => ({
  type: type.ABORT_CONSENT,
  payload: { field }
})
