import { AuditTrailLog } from 'src/Types/AuditTrailLog'
import { RequestFilter } from 'src/Types/RequestFilter'
import { Instance } from 'src/Types/Instance'
import * as types from './actionTypes'
import Slot from '../../../Types/Slot'

export const fetchData = (filter: RequestFilter) => ({
  type: types.FETCH_DATA,
  payload: { filter }
})
export const fetchInstanceData = (instance: Instance, filter: RequestFilter|null = null) => ({
  type: types.FETCH_INSTANCE_DATA,
  payload: { instance, filter }
})
export const fetchSlotData = (slot: Slot, filter: RequestFilter|null = null) => ({
  type: types.FETCH_SLOT_DATA,
  payload: { slot, filter }
})
export const setData = (rows: AuditTrailLog[]) => ({
  type: types.SET_DATA,
  payload: { rows }
})

export const setIsLoading = (isLoading: boolean) => ({
  type: types.SET_IS_LOADING,
  payload: { isLoading }
})
