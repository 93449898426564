import React from 'react'
import { useSelector } from 'react-redux'
import { translate } from 'src/Services/translation'
import { RequestFilter } from 'src/Types/RequestFilter'
import { StoreState } from 'src/Services/Store/reducers'
import { Instance } from 'src/Types/Instance'
import Modal from 'src/Components/Modals/BaseModal'
import translations from './translations'
import AuditTrailsSimpleTable from '../AuditTrailsSimpleTable'

interface Props {
  isOpen: boolean
  onClose: () => void
  filter?: RequestFilter | null
  instance?: Instance | null
}

const AuditTrailsModal = ({ isOpen = false, onClose, filter, instance = null }: Props) => {
  const { language } = useSelector((state: StoreState) => state.Root.user)

  const trans = translate(translations)(language)

  return <Modal isOpen={ isOpen }
                title={ trans('title') }
                onClose={ onClose }
                size={ 'xl' }
  >
    <AuditTrailsSimpleTable filter={ filter } instance={ instance } />
  </Modal>
}

export default AuditTrailsModal
