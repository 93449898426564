import React from 'react'
import { useDispatch } from 'react-redux'
import Select from 'src/Components/Select'
import { FieldEvent } from 'src/Views/FormFiller/Types/Field'
import { Field, FieldOption } from 'src/Types/Field'
import { forMultiOptionValue } from 'src/Views/FormFiller/utils/getFieldSelectedOptionValue'
import { fieldEvent } from '../../state/actions'
import getFieldOptionValues from '../../utils/getFieldOptionValues'

export interface Props {
  field: Field
  isDisabled: boolean
  isRequired: boolean
}

const MultiSelectField = ({ field, isDisabled = false, isRequired = false }: Props) => {
  const dispatch = useDispatch()

  const getValue = () => forMultiOptionValue(field)

  const onChange = (value: string[]) => dispatch(fieldEvent(field, FieldEvent.VALUE_CHANGE, { value }))

  const options = React.useMemo(() => getFieldOptionValues(field), [ field, field.options ])

  return <>
    { field.options &&
      <Select id={ `field${ field.id }` }
        isClearable
        isMulti
        options={ options }
        value={ getValue() }
        getOptionValue={ o => o.systemName }
        getOptionLabel={ o => o.label }
        isDisabled={ isDisabled }
        required={ isRequired }
        onChange={ selected => onChange(selected.map((v: FieldOption) => v.systemName)) }
      />
    }
  </>
}

export default MultiSelectField
