import axios from 'axios'
import { call, cancelled, put, takeLatest } from 'redux-saga/effects'
import { GlobalActions } from 'src/Types/GlobalActions'
import { Action } from 'src/Services/Store/reducers'
import apiMethods from 'src/Services/api/apiMethods'
import * as types from './actionTypes'
import { setInstanceData, setSearchResults } from './actions'

function* searchPatients(props: GlobalActions, { payload: { query } }: Action) {
  const cancelSource = axios.CancelToken.source()

  try {
    const { data } = yield call(apiMethods.get, '/patients/search', { query: query }, { cancelToken: cancelSource.token })

    yield put(setSearchResults(data || []))
  } catch (error) {
    yield put(props.globalActions.handleError(error, 'searchPatientFailed'))
  } finally {
    // @ts-ignore
    if (yield cancelled())
      yield call(cancelSource.cancel)
  }
}

function* searchInstance(props: GlobalActions, { payload: { query } }: Action) {
  try {
    const { data } = yield call(apiMethods.get, `/instances/${ query }`, { withMinimalData: true })
    yield put(setInstanceData(data))
  } catch (error) {
    /* empty */
  }
}

export default function* headerSagaWatcher(props: GlobalActions) {
  yield takeLatest(types.SEARCH, searchPatients, props)
  yield takeLatest(types.SEARCH_INSTANCE, searchInstance, props)
}

