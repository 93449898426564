import React from 'react'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'
import { path } from 'ramda'
import { translate } from 'src/Services/translation'
import { withState } from 'src/Components/Util'
import Modal from 'src/Components/Modals/BaseModal'
import { closeFullRowModal, addFullRowField } from 'src/Views/FormEditor/state/actions.ts'
import translation from '../translations'

// propsFromState :: Map String (State -> a)
const propsFromState = {
  isOpen: path([ 'FormEditor', 'formReducer', 'fullRowModalOpened' ]),
  formId: path([ 'FormEditor', 'formReducer', 'form', 'id' ]),
  afterRow: path([
    'FormEditor',
    'formReducer',
    'newFullRowFieldDetails',
    'afterRow'
  ]),
  fieldType: path([
    'FormEditor',
    'formReducer',
    'newFullRowFieldDetails',
    'type'
  ]),
  sectionId: path([
    'FormEditor',
    'formReducer',
    'newFullRowFieldDetails',
    'sectionId'
  ]),
  language: path([ 'Root', 'user', 'language' ])
}

const FullRowModal = ({
  afterRow = -1,
  fieldType = null,
  sectionId = '',
  formId = '',
  isOpen = false,
  language,
  dispatch
}) => {
  const trans = translate(translation)(language)

  const onConfirm = () => dispatch(addFullRowField(fieldType, afterRow, sectionId, formId))
  const onClose = () => dispatch(closeFullRowModal(false))

  return <Modal title={ trans('fullRowModal.title') }
                size={ 'xl' }
                onClose={ onClose }
                isOpen={ isOpen }
  >
    <div>{ trans('fullRowModal.warning') }</div>
    <div>{ trans('fullRowModal.question') }</div>
    <ButtonsWrapper>
      <Button onClick={ onConfirm }>
        { trans('confirm') }
      </Button>
      <Button variant={ 'danger' } onClick={ onClose }>
        { trans('cancel') }
      </Button>
    </ButtonsWrapper>
  </Modal>
}

export default withState(propsFromState)(FullRowModal)

const ButtonsWrapper = styled.div`
  text-align: center;
  margin-top: 1em;

  button:first-of-type {
    background-color: ${ props => props.theme.styleguideColors.mainOrange };
    color: white;
    margin: 0 1em;
  }
`
