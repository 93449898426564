import GenericEntity from 'src/Types/GenericEntity'
import { Form } from 'src/Types/Form'
import { ListColumn } from 'src/Types/ListColumn'

export interface List extends GenericEntity {
  type: ListType
  form?: Form
  refForm?: Form
  listColumns?: ListColumn[]
}

export enum ListType {
  ENTITY = 'Entity',
  REFERENCE = 'Reference',
  PATIENT_SIDEBAR = 'Patient Sidebar',
  CALENDAR = 'Calendar',
  EXPANDED_SIDEBAR = 'Expanded Sidebar',
  SYSTEM = 'System',
  TREND = 'Trend',
  HOMEPAGE = 'Homepage',
}
