import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container } from 'react-bootstrap'
import { translate } from 'src/Services/translation'
import Modal from 'src/Components/Modals/BaseModal'
import { setOptions } from 'src/Layouts/View/state/actions'
import translation from './translations'
import TasksPageTable from './Components/TasksPageTable'
import { setDeletingItem, deleteItem, setUpdatingItem, updateItem, setCreatingItem,
  createItem, fetchItems } from '../Tasks/state/actions'
import { default as TaskForm } from '../Tasks/Components/TaskForm'

const Tasks = () => {
  const [ innerState, setInnerState ] = useState({
    isTasks: false,
    isHover: false,
    isDisplayed: false,
    listTasks: null,
    tasksCounter: 0,
    isFormOk: null
  })

  const dispatch = useDispatch()
  const { items, form } = useSelector(state => state.Tasks)

  const [ isModalOpened, setIsModalOpened ] = useState(false)
  const [ stateUpdatingItem, setStateUpdatingItem ] = useState(null)

  const { language } = useSelector(state => state.Root?.user)
  const trans = translate(translation)(language)

  React.useEffect(() => {
    dispatch(setOptions({
      hasPrimaryButton: true,
      onPrimaryButtonClick: addTask
    }))
  }, [ dispatch ])

  useEffect(() => {
    dispatch(fetchItems())

    setInnerState({
      ...innerState,
      listTasks: items,
      tasksCounter: items.length,
      isFormOk: form.isFormResponseOk
    })
  }, [ form ])

//Essai: useEffect to get back the data of formResponse to find the bool
  // modal behavior - if ok close, if not display errors

  const addTask = () => {
    setIsModalOpened(!isModalOpened)
  }

  const onModalUpdateFormSubmit = data => {
    if (data.isSetItem) {
      delete data.isSetItem
      delete data.isSetItem
      dispatch(setUpdatingItem(data))
      dispatch(updateItem())
    } else {
      dispatch(setCreatingItem(data))
      dispatch(createItem())
    }

    setIsModalOpened(!isModalOpened)
  }

  const resetUpdate = () => {
    setStateUpdatingItem(null)
    setUpdatingItem(null)
  }

  const onModalUpdateClose = () => {
    setIsModalOpened(false)
  }

  const goToUpdate = (e, item) => {
    e.preventDefault()
    setStateUpdatingItem(item)
    addTask()
  }
  const deleteItemTask = (e, item) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(setDeletingItem(item))
    dispatch(deleteItem())
    setIsModalOpened(false)
  }

  return (
    <Container>
      <TasksPageTable onUpdateClick={ goToUpdate }
                      onDeleteClick={ deleteItemTask }
                      language={ language }
      />

      <Modal size={ 'lg' } isOpen={ isModalOpened } title={ trans('modal.create.title') } onExited={ resetUpdate }
             onClose={ onModalUpdateClose }
      >
        { !stateUpdatingItem && <TaskForm onSubmit={ onModalUpdateFormSubmit }
                                          deleteTask={ deleteItemTask }
        /> }

        { stateUpdatingItem &&
          <TaskForm onSubmit={ onModalUpdateFormSubmit }
                    deleteTask={ deleteItemTask }
                    item={ stateUpdatingItem }
          /> }
      </Modal>
    </Container>
  )
}

export default Tasks

