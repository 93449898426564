import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { Form, Row, Col, Button } from 'react-bootstrap'
import day from 'dayjs'
import Accordion from 'react-bootstrap/Accordion'
import LocalizedDatepicker from 'src/Components/LocalizedDatepicker'
import { formatDateAsUtcToIso8601, formatDateTime } from 'src/Utils/Date'
import { translate } from 'src/Services/translation'
import translations from 'src/Components/Calendar/translations'
import { AuditTrailLogFields } from 'src/Types/AuditTrailLog'
import { ColumnValueType, FilterOperator, RequestFilter } from 'src/Types/RequestFilter'
import { SortItemDirection } from 'src/Types/Pagination'
import { Instance } from 'src/Types/Instance'
import { Event, EventForApi } from 'src/Types/Event'
import { Iso8601 } from 'src/Types/Date'
import { UuidV4, isUuidV4String } from 'src/Types/Uuid'
import { Size } from 'src/Types/Size'
import { StoreState } from 'src/Services/Store/reducers'
import AuditTrailsSimpleTable from 'src/Components/AuditTrailsSimpleTable'
import { CANCELLED } from 'src/Services/Constants/Config/Event'
import { createEvent, editEvent, setDeletingEvent, setEditingEvent } from '../state/actions'
import EntitySelect from '../../EntitySelect'

interface Props {
  onSubmit: (event: EventForApi) => void
}

interface EventForm extends HTMLFormElement {
  readonly elements: EventFormElements
}

interface EventFormElements extends HTMLFormControlsCollection {
  location: HTMLSelectElement
  status: HTMLSelectElement
  duration: HTMLInputElement
  reason: HTMLSelectElement
  note: HTMLInputElement,
  id: HTMLInputElement
}

const EventForm = ({ onSubmit }: Props) => {
  const dispatch = useDispatch()
  const { id: patientId } = useParams()

  const { locale, language, portalTimezone, isAdmin }
        = useSelector((state: StoreState) => state.Root.user)
  const { eventStatuses, eventReasons, locations }
        = useSelector((state: StoreState) => state.Dictionary)
  const { creatingEvent, editingEvent, eventForm }
        = useSelector((state: StoreState) => state.Calendar)
  const [ status, setStatus ] = useState(editingEvent?.status?.id)

  const trans = translate(translations)(language)

  const getEvent = (): Event | null => creatingEvent || editingEvent || null

  const getDefaultResource = (): Instance[] => {
    if (creatingEvent?.resource?.id)
      return [ creatingEvent.resource ]
    else if (editingEvent?.resources)
      return editingEvent.resources
    else return []
  }
  const getUserDefaultLocationId = (): UuidV4 | null => {
    const defaultLocationIsSet = locations.some(_ => _.id === getDefaultLocationId())
    return defaultLocationIsSet ? getDefaultLocationId() : null
  }

  const getDefaultLocationId = (): UuidV4 | null =>
    creatingEvent
      ? creatingEvent?.location?.id || locations[0]?.id
      : editingEvent?.location?.id || null


  const [ location, setLocation ] = useState<string>(getDefaultLocationId)
  const [ startAt, setStartAt ] = useState<Iso8601>(getEvent()?.start)
  const [ duration, setDuration ] = useState<number>(getEvent()?.duration)
  const [ resourcesSelected, setResourcesSelected ] = useState<Instance[]>(getDefaultResource())

  const changeLogFilter = React.useMemo((): RequestFilter => ({
    offset: 0,
    limit: 100,
    sortItems: [ { column: AuditTrailLogFields.TIMESTAMP, direction: SortItemDirection.DESC } ],
    filters: [
      {
        column: AuditTrailLogFields.CONTEXT,
        type: ColumnValueType.STRING,
        operator: FilterOperator.EQUAL,
        value: 'EVENT',
      },
      {
        column: AuditTrailLogFields.CONTEXT_IDENTIFIER,
        type: ColumnValueType.STRING,
        operator: FilterOperator.EQUAL,
        value: editingEvent?.id,
      },
    ],
  }), [ editingEvent?.id ])
  const handleLocationSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const locationId = isUuidV4String(e.target.value) ? e.target.value : null
    // requestResources(locationId)
    setLocation(locationId)
  }
  const handleInstancesChange = (resources: Instance[]) => setResourcesSelected(resources)

  const handleSubmit = (e: FormEvent<EventForm>) => {
    e.preventDefault()
    const data = editingEvent ? submitEdit(e) : submitCreate(e)
    onSubmit(data)
  }

  const submitCreate = (e: FormEvent<EventForm>): EventForApi => {
    const eventData: EventForApi = {
      instance: creatingEvent.instance.id,
      type: creatingEvent.type.id,
      location: (e.currentTarget.elements.location.value as UuidV4 | null),
      duration: parseInt(e.currentTarget.elements.duration.value, 10),
      resources: resourcesSelected.map(_ => _.id),
      note: e.currentTarget.elements.note.value,
      date: startAt,
    }

    if (creatingEvent.slot)
      eventData.slot = creatingEvent.slot.id

    dispatch(createEvent(eventData, (patientId as UuidV4 | null)))
    return eventData
  }

  const submitEdit = (e: FormEvent<EventForm>): EventForApi => {
    const eventId = e.currentTarget.elements.id.value

    const eventData: EventForApi = {
      status: (e.currentTarget.elements.status.value as UuidV4 | null),
      location: (e.currentTarget.elements.location.value as UuidV4 | null),
      duration: parseInt(e.currentTarget.elements.duration.value, 10),
      resources: resourcesSelected.map(_ => _.id),
      note: e.currentTarget.elements.note.value,
      date: startAt,
      reason: (e.currentTarget.elements.reason.value as UuidV4 | null),
    }

    dispatch(editEvent(eventData, (eventId as UuidV4 | null), (patientId as UuidV4 | null)))
    return eventData
  }

  const isInSlot = () => !!getEvent()?.slot?.id

  const onStartAtChange = (startAt: Iso8601) => {
    setStartAt(startAt)
  }

  const onDurationChange = (e: ChangeEvent<HTMLInputElement>) => {
    const duration = parseInt(e.target.value, 10)

    setDuration(duration)
  }

  const getEndAt = () => {
    const endAt = day(startAt).add(duration, 'minutes')
    return formatDateAsUtcToIso8601(endAt.toDate())
  }

  const handleDeleteClick = () => {
    dispatch(setEditingEvent(null))
    dispatch(setDeletingEvent(editingEvent))
  }

  return <>
    { editingEvent?.patient &&
            <Link to={ `/patient/${ editingEvent.patient.id }/instance/${ editingEvent.instance.id }` } className={ 'mb-2' }>
              { trans('form.event.goToInstance') }
            </Link>
    }

    <Form onSubmit={ handleSubmit }>
      { !getEvent()?.instance?.id &&
                <b className={ 'text-danger' }>Instance missing, event will not work.</b> }
      <Row>
        <Col md={ 6 }>
          <Form.Group>
            <Form.Label>{ trans('form.event.startAt') }</Form.Label>
            <LocalizedDatepicker selected={ startAt }
              onChange={ onStartAtChange }
              disabled={ isInSlot() }
              size={ Size.MEDIUM }
            />
          </Form.Group>
        </Col>
        <Col md={ 6 }>
          <Form.Group>
            <Form.Label>{ trans('form.event.duration') }</Form.Label>
            <Form.Control name={ 'duration' } type={ 'number' } min={ 1 } title={ 'min' }
              disabled={ isInSlot() }
              value={ duration }
              onChange={ onDurationChange }
            />
            <Form.Text>
              { trans('form.event.endAt') } : { formatDateTime(locale, portalTimezone)(getEndAt()) }
            </Form.Text>
          </Form.Group>
          { isInSlot() && <small className={ 'text-muted' }>{ trans('form.event.inASlot') }</small> }
        </Col>
      </Row>
      <Row className={ 'mb-2' }>
        <Col md={ 6 }>
          <Form.Group>
            <Form.Label>{ trans('form.event.location') }</Form.Label>
            <Form.Select name={ 'location' }
              defaultValue={ getUserDefaultLocationId() }
              onChange={ handleLocationSelect }
              disabled={ isInSlot() }
            >
              <option value={ '' }>{ trans('none') }</option>
              { locations.map(_ => <option key={ _.id } value={ _.id }>{ _.identityString }</option>) }
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={ 6 }>
          <Col>
            <Form.Group>
              <Form.Label>{ trans('form.event.resources') }</Form.Label>
              <EntitySelect fetchUrl={ `/instances/resources/${ getEvent()?.type?.id }` }
                isMulti
                getOptionLabel={ r => r.identityString || r.id }
                getOptionValue={ r => r.id }
                name={ 'resourceSelect' }
                placeholder={ 'Select a resource' }
                labelKey={ 'identityString' }
                value={ resourcesSelected ?? null }
                // key is dynamic to force a rerender of the resources when changing the location
                key={ location ?? 'resources' }
                additionalParams={ location ? { locationId: location } : null }
                isDefaultOptions
                onChange={ handleInstancesChange }
              />
            </Form.Group>
          </Col>
        </Col>
      </Row>
      <Row className={ 'mb-2' }>
        <Col md={ editingEvent ? '4' : '6' }>
          <Form.Group>
            <Form.Label>{ trans('form.event.type') }</Form.Label>
            <Form.Control defaultValue={ getEvent()?.type?.label || getEvent()?.type?.systemName } disabled/>
          </Form.Group>
        </Col>
        { editingEvent && <Col md={ '4' }>
          <Form.Group>
            <Form.Label>{ trans('form.event.status') }</Form.Label>
            <Form.Select
              name={ 'status' }
              defaultValue={ editingEvent.status?.id }
              onChange={ e => setStatus(e.target.value) }
            >
              { eventStatuses.map(status => <option key={ status.id } value={ status.id }>
                { status.label || status.systemName }
              </option>) }
            </Form.Select>
          </Form.Group>
        </Col> }
        { editingEvent && <Col md={ '4' }>
          <Form.Group>
            <Form.Label>{ trans('form.event.reason') }</Form.Label>
            <Form.Select
              name={ 'reason' }
              defaultValue={ editingEvent.reason?.id }
              disabled={ status !== eventStatuses.find(event => event.systemName === CANCELLED).id }
            >
              <option value={ '' }></option>
              { eventReasons.map(reason => <option key={ reason.id } value={ reason.id }>
                { reason.label || reason.systemName }
              </option>) }
            </Form.Select>
          </Form.Group>
        </Col> }
      </Row>

      <Row className={ 'mb-2' }>
        <Col>
          <Form.Group>
            <Form.Label>{ trans('form.event.notes') }</Form.Label>
            <Form.Control name={ 'note' } as={ 'textarea' } rows={ 4 } defaultValue={ getEvent()?.note }/>
          </Form.Group>
        </Col>
      </Row>

      { editingEvent && <>
        <Row className={ 'mb-2' }>
          <Col>
            <h6>{ trans('informations') }</h6>
          </Col>
        </Row>
        <Row>
          <Col md={ 4 }>
            <Form.Group>
              <Form.Label>{ trans('form.event.id') }</Form.Label>
              <Form.Control name={ 'id' } type={ 'id' } disabled defaultValue={ getEvent()?.id }/>
            </Form.Group>
          </Col>
          <Col md={ 4 }>
            <Form.Group>
              <Form.Label>{ trans('form.event.createdBy') }</Form.Label>
              <Form.Control name={ 'createdBy' } type={ 'createdBy' }
                disabled defaultValue={ getEvent()?.createdBy?.username }
              />
            </Form.Group>
          </Col>
          <Col md={ 4 }>
            <Form.Group>
              <Form.Label>{ trans('form.event.createdAt') }</Form.Label>
              <LocalizedDatepicker selected={ getEvent()?.createdAt } disabled size={ Size.MEDIUM }/>
            </Form.Group>
          </Col>
        </Row>
      </> }
      <Row className={ 'mt-5' }>
        <Col>
          <Button
            type={ 'submit' }
            variant={ 'primary' }
            className={ 'float-right ms-2' }
          >
            { trans('form.event.submit') }
          </Button>
          { editingEvent && <Button
            type={ 'button' }
            variant={ 'danger' }
            className={ 'float-right' }
            onClick={ handleDeleteClick }
          >
            <i className={ 'fa fa-trash' }></i>
          </Button> }
        </Col>
      </Row>
    </Form>

    { editingEvent?.id &&
            <div className={ 'container mt-3' }>
              <Accordion>
                <Accordion.Item eventKey={ '0' }>
                  <Accordion.Header>{ trans('form.event.changeLogs') }</Accordion.Header>
                  <Accordion.Body>
                    <AuditTrailsSimpleTable filter={ changeLogFilter }/>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
    }

  </>
}

export default EventForm
