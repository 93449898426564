export const FETCH_RESOURCES = '[CALENDAR] FETCH_RESOURCES'
export const SET_SLOT_RESOURCES = '[CALENDAR] SET_SLOT_RESOURCES'
export const SET_EVENT_RESOURCES = '[CALENDAR] SET_EVENT_RESOURCES'
export const CREATE_SLOT_SERIE = '[CALENDAR] CREATE_SLOT_SERIE'
export const CREATE_SLOT = '[CALENDAR] CREATE_SLOT'
export const SET_TOGGLE_IS_CREATING_SLOT_SERIE = '[CALENDAR] SET_TOGGLE_IS_CREATING_SLOT_SERIE'
export const SET_SLOT_FORM_MODAL = '[CALENDAR] SET_SLOT_FORM_MODAL'
export const SET_CREATING_EVENT = '[CALENDAR] SET_CREATING_EVENT'
export const CREATE_EVENT = '[CALENDAR] CREATE_EVENT'
export const EVENT_CREATED = '[CALENDAR] EVENT_CREATED'
export const SET_EDITING_EVENT = '[CALENDAR] SET_EDITING_EVENT'
export const EDIT_EVENT = '[CALENDAR] EDIT_EVENT'
export const SET_DELETING_EVENT = '[CALENDAR] SET_DELETING_EVENT'
export const DELETE_EVENT = '[CALENDAR] DELETE_EVENT'
export const EVENT_DELETED = '[CALENDAR] EVENT_DELETED'
export const EDIT_SLOT = '[CALENDAR] EDIT_SLOT'
export const SET_EDITING_SLOT = '[CALENDAR] SET_EDITING_SLOT'
export const SET_DELETING_SLOT = '[CALENDAR] SET_DELETING_SLOT'
export const DELETE_SLOT = '[CALENDAR] DELETE_SLOT'
export const SLOT_DELETED = '[CALENDAR] SLOT_DELETED'
export const SLOT_SERIE_CREATED = '[CALENDAR] SLOT_SERIE_CREATED'
export const SLOT_CREATED = '[CALENDAR] SLOT_CREATED'

export const SET_CURRENT_START_DATE = '[CALENDAR] SET_CURRENT_START_DATE'
export const SET_CURRENT_END_DATE = '[CALENDAR] SET_CURRENT_END_DATE'

export const GET_EVENTS = '[CALENDAR] GET_EVENTS'
export const SET_EVENTS = '[CALENDAR] SET_EVENTS'

/** SIDEBAR */
export const SET_ACTIVE_TAB = '[CALENDAR] SET_ACTIVE_TAB'

export const SET_FILTERS = '[CALENDAR] SET_FILTERS'
export const SET_FILTER = '[CALENDAR] SET_FILTER'

export const SET_FILTER_VALUES = '[CALENDAR] SET_FILTER_VALUES'
export const SET_FILTER_VALUE = '[CALENDAR] SET_FILTER_VALUE'

export const GET_RESOURCE_FILTER = '[CALENDAR] GET_RESOURCE_FILTER'
