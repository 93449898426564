import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'src/Components/Select'
import { Form as BForm, Button, Card } from 'react-bootstrap'
import { PAGE_CONTENT_TYPE_LIST, PAGE_CONTENT_TYPES } from 'src/Services/Constants'
import { fetchListsDictionary } from 'src/Services/Dictionary/state/actions'
import { addContent, updateContent, deleteContent } from '../state/actions'
import { getFilledColsCount } from '../utils/index'

const LandingPageContentEditor = ({ trans }) => {
  const dispatch = useDispatch()
  const { lists } = useSelector(state => state.Dictionary)
  const { editingContent } = useSelector(state => state.LandingPages.form)

  const [ label, setLabel ] = useState(null)
  const [ contentType, setContentType ] = useState(editingContent?.type || null)
  const [ contentList, setContentList ] = useState(editingContent?.list || null)

  useEffect(() => {
    dispatch(fetchListsDictionary())
  }, [])

  useEffect(() => {
    setLabel(editingContent?.label || null)
  }, [ editingContent ])

  const reset = () => {
    setContentType(null)
    setContentList(null)
    setLabel(null)
  }

  const onFormSubmit = e => {
    e.preventDefault()

    submitContent(e.target)

    e.target.reset()
    reset()
  }

  const submitContent = form => {
    const content = {
      label,
      type: form.type.value,
      size: parseInt(form.size.value, 10) || 1,
      list: contentList
    }

    if (editingContent.id) {
      content.id = editingContent.id
      dispatch(updateContent(editingContent.row, content))
    } else
      dispatch(addContent(editingContent.row, content))
  }

  const onDeleteClick = () => {
    if (editingContent?.id)
      dispatch(deleteContent(editingContent.row, editingContent))

    reset()
  }

  const getMaxSize = () => (12 - getFilledColsCount(editingContent.row)) + (editingContent.size || 0)

  return <>
    <Card.Header>{ trans('form.editor.editingContent') }</Card.Header>
    <Card.Body>
      <BForm onSubmit={ onFormSubmit }>
        <BForm.Group className="mb-1">
          <BForm.Label>{ trans('form.label') }</BForm.Label>
          <BForm.Control name="label" required type="text" size="sm"
                         value={ label || '' } onChange={e => setLabel(e.target.value)}
                         placeholder={ trans('form.label') }/>
        </BForm.Group>
          <BForm.Group className="mb-1">
            <BForm.Label>{ trans('form.size') }</BForm.Label>
            <BForm.Control name="size" required type="number" size="sm"
                           defaultValue={ editingContent.size || getMaxSize() } min={1}
                           max={getMaxSize()} placeholder={ trans('form.size') } />
            <BForm.Text>{ trans('form.content.sizeRemaining') }: {getMaxSize()}</BForm.Text>
          </BForm.Group>
          <BForm.Group className="mb-1">
            <BForm.Label>{ trans('form.type') }</BForm.Label>
            <BForm.Select defaultValue={ editingContent.type } name="type" size="sm"
                          required aria-label={ trans('form.content.type') }
                          onChange={ e => setContentType(e.target.value) }>
              { PAGE_CONTENT_TYPES.map(type => <option value={ type } key={ type }>{ type }</option>) }
            </BForm.Select>
          </BForm.Group>
          { contentType === PAGE_CONTENT_TYPE_LIST && <BForm.Group>
            <BForm.Label>{ trans('form.list') }</BForm.Label>
            <Select options={ lists } value={ contentList }
                    getOptionValue={ _ => _.id } size="sm"
                    name="list" required id="listSelect" onChange={ setContentList }
            />
          </BForm.Group> }
        <Button className="mt-2 me-1" type="submit">{ trans('save') }</Button>
        { editingContent?.id &&
          <Button className="mt-2" variant="danger" onClick={ onDeleteClick }>{ trans('delete') }</Button> }
      </BForm>
    </Card.Body>
  </>
}

export default LandingPageContentEditor
