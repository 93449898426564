import { Option } from 'src/Types/Components/Select'
import WorkflowItem from 'src/Types/WorkflowItem'
import { WORKFLOW_CREATED, WORKFLOW_DELETED, WORKFLOW_UPDATED } from 'src/Views/Workflows/Modals/Workflows/state/actionTypes'
import { Action } from 'src/Services/Store/reducers'
import WorkflowTrigger, { WorkflowTriggerType } from 'src/Types/WorkflowTrigger'
import WorkflowAction from 'src/Types/WorkflowAction'
import translations from 'src/Views/Workflows/Filters/translations'
import {
  FETCH_WORKFLOW_MANAGER, RESET_FILTERS, SELECT_WORKFLOW, SET_ACTION_FILTER, SET_FORM_FILTER, SET_ROLE_FILTER, SET_TYPE_FILTER, WORKFLOW_MANAGER_RECEIVED,
} from './actionTypes'
import { ACTION_CREATED, ACTION_DELETED, ACTION_UPDATED } from '../Modals/Actions/state/actionTypes'
import { TRIGGER_CREATED, TRIGGER_DELETED, TRIGGER_UPDATED } from '../Modals/Triggers/state/actionTypes'

export interface WorkflowManagerState {
  isLoading: boolean
  actionFilter: Option | null
  formFilter: Option | null
  roleFilter: Option | null
  typeFilter: Option | null
  isFilterApplied: boolean
  originalActions: WorkflowAction[]
  originalWorkflows: WorkflowItem[]
  originalTriggers: WorkflowTrigger[]
  filteredActions: WorkflowAction[]
  filteredWorkflows: WorkflowItem[]
  filteredTriggers: WorkflowTrigger[]
  selectedWorkflow: WorkflowItem | null,
}

const initialState: WorkflowManagerState = {
  isLoading: false,
  actionFilter: null,
  formFilter: null,
  roleFilter: null,
  typeFilter: null,
  isFilterApplied: false,
  originalActions: [],
  originalWorkflows: [],
  originalTriggers: [],
  filteredActions: [],
  filteredWorkflows: [],
  filteredTriggers: [],
  selectedWorkflow: null
}

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case FETCH_WORKFLOW_MANAGER:
      return {
        ...state,
        isLoading: true
      }
    case WORKFLOW_MANAGER_RECEIVED:
      return {
        ...state,
        isLoading: false,
        originalActions: payload.actions,
        originalWorkflows: payload.workflows,
        originalTriggers: payload.triggers
      }
    case SET_ACTION_FILTER: {
      const filteredActions = state.originalActions.filter(action => action?.type === payload.value)

      const filteredWorkflows = state.originalWorkflows.filter(w =>
        filteredActions.find(action => w.actions.some(a => a.id === action.id)))

      const filteredTriggers = state.originalTriggers.filter(trigger =>
        filteredWorkflows.find(workflow => trigger.id === workflow.trigger.id))


      return {
        ...state,
        actionFilter: payload,
        roleFilter: initialState.roleFilter,
        formFilter: initialState.formFilter,
        typeFilter: initialState.typeFilter,
        isFilterApplied: true,
        selectedWorkflow: initialState.selectedWorkflow,
        filteredActions,
        filteredWorkflows,
        filteredTriggers
      }
    }
    case SET_FORM_FILTER: {
      const filteredTriggers = state.originalTriggers.filter(trigger =>
        trigger?.form?.systemName === payload.value)

      const filteredWorkflows = state.originalWorkflows.filter(workflow =>
        filteredTriggers.find(trigger => trigger.id === workflow.trigger.id))

      const filteredActions = state.originalActions.filter(action =>
        filteredWorkflows.find(w => w.actions.some(a => a.id === action.id)))

      return {
        ...state,
        actionFilter: initialState.actionFilter,
        roleFilter: initialState.roleFilter,
        formFilter: payload,
        typeFilter: initialState.typeFilter,
        isFilterApplied: true,
        selectedWorkflow: initialState.selectedWorkflow,
        filteredActions,
        filteredWorkflows,
        filteredTriggers
      }
    }
    case SET_ROLE_FILTER: {
      const filteredWorkflows = state.originalWorkflows.filter(workflow =>
        workflow?.role?.systemName === payload.value)

      const filteredActions = state.originalActions.filter(action =>
        filteredWorkflows.find(w => w.actions.some(a => a.id === action.id)))

      const filteredTriggers = state.originalTriggers.filter(trigger =>
        filteredWorkflows.find(workflow => trigger.id === workflow.trigger.id))

      return {
        ...state,
        actionFilter: initialState.actionFilter,
        formFilter: initialState.formFilter,
        roleFilter: payload,
        typeFilter: initialState.typeFilter,
        isFilterApplied: true,
        selectedWorkflow: initialState.selectedWorkflow,
        filteredActions,
        filteredWorkflows,
        filteredTriggers
      }
    }
    case SET_TYPE_FILTER: {
      const label = payload.label
      const value = payload.value

      const isFieldEvent = value === 'fieldEvent'
      const isPlainFormEvent = value === 'formEvent'
      const isPlainEventStatus= value === 'fieldEvent'
      const isFormEvent = label.startsWith(translations.english.plainFormEvent) && value
      const isEventStatus= label.startsWith(translations.english.plainEventStatus) && value

      let filteredTriggers :any[] = []

      if (isFieldEvent) {
        filteredTriggers = state.originalTriggers.filter(trigger =>
          trigger.type === WorkflowTriggerType.FIELD_VALUE_CHANGE
        )
      }
      else if (isPlainFormEvent) {
        filteredTriggers = state.originalTriggers.filter(trigger =>
          trigger.type === WorkflowTriggerType.FORM_EVENT
        )
      }
      else if (isPlainEventStatus) {
        filteredTriggers = state.originalTriggers.filter(trigger =>
          trigger.type === WorkflowTriggerType.EVENT_STATUS_CHANGE
        )
      }
      else if (isFormEvent) {
        filteredTriggers = state.originalTriggers.filter(
          trigger => trigger.type === WorkflowTriggerType.FORM_EVENT && trigger.formEvent === value
        )
      }
      else if (isEventStatus) {
        filteredTriggers = state.originalTriggers.filter(trigger =>
          trigger.type === WorkflowTriggerType.EVENT_STATUS_CHANGE &&
            (trigger.oldValue === value || trigger.newValue === value)
        )
      }

      const filteredWorkflows = state.originalWorkflows.filter(workflow =>
        filteredTriggers.find(trigger => trigger.id === workflow.trigger.id))

      const filteredActions = state.originalActions.filter(action =>
        filteredWorkflows.find(w => w.actions.some(a => a.id === action.id)))

      return {
        ...state,
        actionFilter: initialState.actionFilter,
        formFilter: initialState.formFilter,
        roleFilter: initialState.roleFilter,
        typeFilter: payload,
        isFilterApplied: true,
        selectedWorkflow: initialState.selectedWorkflow,
        filteredActions,
        filteredWorkflows,
        filteredTriggers
      }
    }
    case RESET_FILTERS: {
      return {
        ...state,
        actionFilter: null,
        formFilter: null,
        roleFilter: null,
        typeFilter: null,
        isFilterApplied: false,
        selectedWorkflow: null,
        filteredActions: [],
        filteredWorkflows: [],
        filteredTriggers: []
      }
    }
    case SELECT_WORKFLOW:
      return {
        ...state,
        selectedWorkflow: payload
      }

    // ACTIONS
    case ACTION_CREATED:
      return {
        ...initialState,
        originalWorkflows: state.originalWorkflows,
        originalTriggers: state.originalTriggers,
        originalActions: [ ...state.originalActions, payload ]
      }
    case ACTION_UPDATED:
      return {
        ...initialState,
        originalWorkflows: state.originalWorkflows,
        originalTriggers: state.originalTriggers,
        originalActions: state.originalActions.map(_ => _.id === payload.id ? payload : _)
      }
    case ACTION_DELETED:
      return {
        ...initialState,
        originalWorkflows: state.originalWorkflows,
        originalTriggers: state.originalTriggers,
        originalActions: state.originalActions.filter(_ => _.id !== payload)
      }

    // WORKFLOWS
    case WORKFLOW_CREATED:
      return {
        ...initialState,
        originalActions: state.originalActions,
        originalTriggers: state.originalTriggers,
        originalWorkflows: [ ...state.originalWorkflows, payload.item ]
      }
    case WORKFLOW_UPDATED:
      return {
        ...initialState,
        originalActions: state.originalActions,
        originalTriggers: state.originalTriggers,
        originalWorkflows: state.originalWorkflows.map(_ => _.id === payload.item.id ? payload.item : _)
      }
    case WORKFLOW_DELETED:
      return {
        ...initialState,
        originalActions: state.originalActions,
        originalTriggers: state.originalTriggers,
        originalWorkflows: state.originalWorkflows.filter(_ => _.id !== payload.id)
      }

    // TRIGGERS
    case TRIGGER_CREATED:
      return {
        ...initialState,
        originalActions: state.originalActions,
        originalWorkflows: state.originalWorkflows,
        originalTriggers: [ ...state.originalTriggers, payload ]
      }
    case TRIGGER_UPDATED:
      return {
        ...initialState,
        originalActions: state.originalActions,
        originalWorkflows: state.originalWorkflows,
        originalTriggers: state.originalTriggers.map(_ => _.id === payload.id ? payload : _)
      }
    case TRIGGER_DELETED:
      return {
        ...initialState,
        originalActions: state.originalActions,
        originalWorkflows: state.originalWorkflows,
        originalTriggers: state.originalTriggers.filter(_ => _.id !== payload)
      }
    default:
      return state
    }
}
