import React, { ChangeEvent, useRef } from 'react'
import { Form } from 'react-bootstrap'
import { FieldEvent } from 'src/Views/FormFiller/Types/Field'
import { useDispatch, useSelector } from 'react-redux'
import { Field } from 'src/Types/Field'
import { fieldEvent } from '../../state/actions'
import { translate } from '../../../../Services/translation'
import translations from '../../translations'
import { StoreState } from '../../../../Services/Store/reducers'
import { isJsonString } from '../../../../Utils'

interface Props {
    field: Field,
    isRequired: boolean
    isDisabled: boolean
}

const JsonField = ({ field = null, isDisabled = false, isRequired = false }: Props) => {
    const dispatch = useDispatch()

  const [ changeTimeout, setChangeTimeout ] = React.useState(null)
  const [ localValue, setLocalValue ] = React.useState(field.value ? JSON.stringify(field.value) : null)

    const { language } = useSelector((state: StoreState) => state.Root.user)
    const trans = translate(translations)(language)
    const input = useRef(null)

    React.useEffect(() => {
        setLocalValue(JSON.stringify(field.value))
    }, [ field.value ])

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setLocalValue(e.target.value)
    }

    const onBlur = () => {
        if (localValue && !isJsonString(localValue)) {
            input.current.setCustomValidity(trans('invalidJSON'))
            input.current.reportValidity()
        } else {
            input.current.setCustomValidity('')
            clearTimeout(changeTimeout)
            setChangeTimeout(setTimeout(() => {
                dispatch(fieldEvent(field, FieldEvent.VALUE_CHANGE, { value: JSON.parse(localValue) }))
            }, 500))
        }
    }

    return <Form.Control ref={ input }
                         size={ 'sm' }
                         as={ 'textarea' }
                         id={ `field${ field.id }` }
                         value={ localValue || '' }
                         onChange={ onChange }
                         onBlur={ onBlur }
                         disabled={ isDisabled }
                         required={ isRequired }

    />
}

export default JsonField
