import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { path, compose } from 'ramda'
import { translate } from 'src/Services/translation'
import { withState } from 'src/Components/Util'
import Modal from 'src/Components/Modals/BaseModal'
import FieldType from 'src/Components/StyledComponents/FieldType'
import BlocItem from 'src/Components/StyledComponents/BlocItem'
import BlocItemContent from 'src/Components/StyledComponents/BlocItemContent'
import ActionsWrapper from 'src/Components/StyledComponents/ActionsWrapper'
import DeleteModal from 'src/Components/Modals/DeleteModal'
import { AddRowButton } from 'src/Views/FormEditor/Components/Section'
import ColumnForm from './ColumnForm'
import { getDraggedColumn } from '../state/selectors'
import { ColumnsWrapper, BarsIcon } from '../shared'
import translation from '../translations'
import {
  addColumn, sortColumn, setDraggedColumn, setColumnBeingRemoved, removeColumn,
  setColumnBeingEdited, editColumn, setSelectedReferenceField, setColumnBeingCreated,
  setSelectedReferenceFieldDetails
} from '../state/actions'

// onColumnDragStart :: dispatch -> DOMEvent -> *
const onColumnDragStart = (dispatch, columnId) => () => {
  dispatch(setDraggedColumn(columnId))
}

// onColumnDragOver :: DOMEvent -> *
const onColumnDragOver = e => {
  e.preventDefault()

  e.target.classList.add('current-drag-and-drop-target')
}

// onDragLeave :: DOMEvent -> *
const onDragLeave = e => {
  e.target.classList.remove('current-drag-and-drop-target')
}

// onColumnDrop :: dispatch -> DOMEvent -> *
const onColumnDrop = (dispatch, draggedColumn, targetColumn, listId) => e => {
  dispatch(sortColumn(listId, draggedColumn, targetColumn))
  dispatch(setDraggedColumn(''))

  e.target.classList.remove('current-drag-and-drop-target')
}

const propsFromState = {
  draggedColumn: compose(getDraggedColumn, path([ 'ListEdit' ])),
  language: path([ 'Root', 'user', 'language' ])
}

const ZmrEditColumns = ({ draggedColumn, language }) => {

  const dispatch = useDispatch()

  const { columns, id, columnBeingCreated, columnBeingEdited, columnBeingRemoved, systemName }
    = useSelector(state => state.ListEdit)

  const trans = translate(translation)(language)

  const onAddColumnClick = () => {
    dispatch(setColumnBeingCreated({}))
  }

  const onEditClick = column => () => {
    dispatch(setColumnBeingEdited(column))
    dispatch(setSelectedReferenceField({
      id: column.referenceFieldId ? `${ column.referenceFieldId }.${ column.field?.id }` : column.field?.id,
      value: column.referenceFieldLabel || column.field?.systemName,
      label: column.referenceFieldLabel || column.field?.systemName
    }))
  }

  const onDeleteClick = columnId => () => dispatch(setColumnBeingRemoved(columnId))
  const onDeleteCancelClick = () => dispatch(setColumnBeingRemoved(null))
  const onDeleteConfirmClick = () => dispatch(removeColumn())

  const onCloseCreateColumnModal = () => {
    dispatch(setColumnBeingCreated(null))
    resetForm()
  }

  const onCloseEditColumnModal = () => {
    dispatch(setColumnBeingEdited(null))
    resetForm()
  }

  const onEditColumnSubmit = data => {
    dispatch(setColumnBeingEdited(data))
    dispatch(editColumn())
    resetForm()
  }

  const onCreateColumnSubmit = data => {
    dispatch(setColumnBeingCreated(data))
    dispatch(addColumn())
    resetForm()
  }

  const resetForm = () => {
    dispatch(setSelectedReferenceField(null))
    dispatch(setSelectedReferenceFieldDetails(null))
  }

  return <>
    <ColumnsWrapper>
      { columns.map(column =>
        <BlocItem key={ column.id }
                  draggable={ 'true' }
                  onDragStart={ onColumnDragStart(dispatch, column.id) }
                  onDragOver={ onColumnDragOver }
                  onDrop={ onColumnDrop(dispatch, draggedColumn, column, id) }
                  onDragLeave={ onDragLeave }
        >
          <BarsIcon className={ 'fas fa-bars' }/>
          <FieldType>
            <i className={ column.field?.type?.icon }/>
          </FieldType>
          <BlocItemContent>{ column.label }</BlocItemContent>
          <ActionsWrapper>
            <i className={ 'me-1 fas fa-edit' }
               title={ 'Edit' }
               tabIndex={ '0' }
               onClick={ onEditClick(column) }
               onKeyDown={ e => e.key === 'Enter' && onEditClick(column)(e) }
            />
            <i className={ 'fas fa-trash' }
               title={ 'Delete' }
               tabIndex={ '0' }
               onClick={ onDeleteClick(column.id) }
               onKeyDown={ e => e.key === 'Enter' && onDeleteClick(column.id)(e) }
            />
          </ActionsWrapper>
        </BlocItem>
      ) }

    </ColumnsWrapper>

    <AddRowButton onClick={ onAddColumnClick }>
      <span>{ trans('addColumn') }</span>
      <span><i className={ 'fas fa-lg fa-plus-circle' }></i></span>
    </AddRowButton>

    <Modal isOpen={ !!columnBeingCreated } title={ trans('addColumn') } onClose={ onCloseCreateColumnModal }
           size={ 'lg' }>
      <ColumnForm trans={ trans } onSubmit={ onCreateColumnSubmit } list={ { id, systemName } }/>
    </Modal>

    <Modal isOpen={ !!columnBeingEdited } title={ trans('editColumn') } onClose={ onCloseEditColumnModal }
           size={ 'lg' }>
      <ColumnForm trans={ trans } onSubmit={ onEditColumnSubmit } defaultColumn={ columnBeingEdited }
                  list={ { id, systemName } }/>
    </Modal>

    <DeleteModal title={ trans('confirmDeleteModal.title') }
                 isOpen={ !!columnBeingRemoved }
                 onClose={ onDeleteCancelClick }
                 onDelete={ onDeleteConfirmClick }
    >
      { trans('confirmDeleteModal.description') }
    </DeleteModal>
  </>
}

export default withState(propsFromState)(ZmrEditColumns)

