import { CalendarFilterName, CalendarFilterValues, DEFAULT_FILTER_VALUES } from 'src/Types/CalendarFilter'

export const setDefaultActiveFilters = (filters: CalendarFilterValues) => {
  if (filters)
    localStorage.setItem('calendarActiveFilters', JSON.stringify(filters))
}

export const getDefaultActiveFilters = (): CalendarFilterValues => {
  const calendarRawActiveFilters = JSON.parse(localStorage.getItem('calendarActiveFilters'))

  if (!calendarRawActiveFilters || typeof calendarRawActiveFilters !== 'object' || Array.isArray(calendarRawActiveFilters)) {
    return DEFAULT_FILTER_VALUES
  }

  const partialCalendarActiveFilters = calendarRawActiveFilters as Partial<CalendarFilterValues>

  return Object.keys(DEFAULT_FILTER_VALUES)
    .reduce((result, name: CalendarFilterName) => ({
      ...result, [name]: partialCalendarActiveFilters[name] || DEFAULT_FILTER_VALUES[name]
    }), {}) as CalendarFilterValues
}
