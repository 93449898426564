import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Select from 'src/Components/Select'
import { Form, Button, Row, Col } from 'react-bootstrap'
import EntitySelect from 'src/Components/EntitySelect/index'
import LocalizedDatepicker from 'src/Components/LocalizedDatepicker'
import { translate } from 'src/Services/translation'
import translation from 'src/Views/Tasks/translations'

const TaskForm = ({
  onSubmit = () => {},
  deleteTask = () => {},
  item = null
}) => {

  const { language } = useSelector(state => state.Root.user)
  const { taskTypes, taskCategories, taskStatuses, roles: availableRoles } = useSelector(state => state.Dictionary)

  const trans = translate(translation)(language)

  const [ dueAtDate, setDueAtDate ] = useState(null)
  const [ completedAtDate, setCompletedAtDate ] = useState(null)

  const [ type, setType ] = useState(null)
  const [ category, setCategory ] = useState(null)
  const [ status, setStatus ] = useState(null)

  const [ roles, setRoles ] = useState(null)
  const [ users, setUsers ] = useState(null)

  const [ assignTo, setAssignTo ] = useState(null)
  const [ formErrors, setFormErrors ] = useState([])

  useEffect(() => {
    setDueAtDate(item?.dueAt || null)
    setCompletedAtDate(item?.completedAt || null)

    setType(item?.type || null)
    setCategory(item?.category || null)
    setStatus(item?.status || null)

    setRoles(item?.roles || [])
    setUsers(item?.users || [])

    if (item?.roles?.length)
      setAssignTo('ROLES')
    else if (item?.users?.length)
      setAssignTo('USERS')
    else
      setAssignTo(null)
  }, [ item ])

  const onFormSubmit = e => {
    e.preventDefault()

    if (!isValid()) return

    setFormErrors([])

    let fields = {
      name: e.target.name.value,
      description: e.target.description.value,
      note: e.target.note.value,
      dueAt: dueAtDate,
      completedAt: completedAtDate,
      type: type?.id,
      category: category?.id,
      status: status?.id,
      instance: null,
      users: users?.map(u => u.id),
      roles: roles?.map(r => r.id)
    }

    //Adds an id for the query url
    if (item) {
      fields = {
        ...fields,
        id: item.id,
        isSetItem: true
      }
    }

    onSubmit(fields)
  }

  const isValid = () => {
    let isValid = true
    let errors = {}

    if (users?.length && roles?.length) {
      errors.assignTo = 'You have to assign this task to either a role or a user'
      isValid = false
    }
    if (!roles?.length && !users?.length) {
      errors.assignTo = 'You have to assign this task to at least a role or user'
      isValid = false
    }

    setFormErrors(errors)

    return isValid
  }

  const onAssignTo = e => {
    setRoles([])
    setUsers([])
    setAssignTo(e.target.value || null)
  }

  return (
    <Form name={ 'field-type' } onSubmit={ onFormSubmit }>
      <Row className={ 'mb-2' }>
        <Col>
          <Form.Group>
            <Form.Label>{ trans('form.name') }</Form.Label>
            <Form.Control defaultValue={ item?.name }
                          name={ 'name' }
                          type="text"
                          placeholder={ trans('form.name') }
                          required
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className={ 'mb-2' }>
        <Col>
          <Form.Group>
            <Form.Label>{ trans('form.description') }</Form.Label>
            <Form.Control as="textarea"
                          rows={ 3 }
                          defaultValue={ item?.description }
                          name={ 'description' }
                          placeholder={ trans('form.description') }
                          required
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className={ 'mb-2' }>
        <Col>
          <Form.Group>
            <Form.Label>Type</Form.Label>
            <Select value={ type }
                    onChange={ setType }
                    options={ taskTypes }
                    getOptionLabel={ o => o.name }
                    getOptionValue={ o => o.id }
            />
          </Form.Group>
        </Col>

        <Col>
          <Form.Group>
            <Form.Label>Category</Form.Label>
            <Select value={ category }
                    onChange={ setCategory }
                    options={ taskCategories }
                    getOptionLabel={ o => o.name }
                    getOptionValue={ o => o.id }
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Status</Form.Label>
            <Select value={ status }
                    onChange={ setStatus }
                    options={ taskStatuses }
                    getOptionLabel={ o => o.name }
                    getOptionValue={ o => o.id }
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className={ 'mb-2' }>
        <Form.Group>
          <Form.Label>{ trans('form.note') }</Form.Label>
          <Form.Control as="textarea"
                        rows={ 3 }
                        defaultValue={ item?.note }
                        name={ 'note' }
                        placeholder={ trans('form.note') }
          />
        </Form.Group>
      </Row>

      <Row className={ 'mb-2' }>
        <Col>
          <Form.Group>
            <Form.Label>{ trans('form.dueAt') }</Form.Label>
            <LocalizedDatepicker name="dueAt"
                   placeholder={ trans('form.dueAt') }
                   onChange={ setDueAtDate }
                   selected={ dueAtDate }
                   isClearable
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>{ trans('form.dateCompleted') }</Form.Label>
            <LocalizedDatepicker name="dateCompleted"
                   placeholder={ trans('form.dateCompleted') }
                   onChange={ setCompletedAtDate }
                   selected={ completedAtDate }
                   isClearable
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className={ 'mb-4' }>
        <Col>
          <Form.Group>
            <Form.Label>{ trans('form.assignTo.label') }</Form.Label>
            <Form.Select value={ assignTo || '' }
                         name={ 'assignTo' }
                         aria-label={ trans('form.assignTo.label') }
                         onChange={ onAssignTo }
                         required
                         className={ 'mb-1' }
            >
              <option value={ '' }>{ trans('form.defaultAssignTo') }</option>
              { [ 'ROLES', 'USERS' ].map(key =>
                <option value={ key } key={ key }>{ trans(`form.assignTo.${ key }`) }</option>) }
            </Form.Select>

            { formErrors.assignTo &&
              <Form.Text className={ 'text-danger' }>{ formErrors.assignTo }</Form.Text>
            }
          </Form.Group>

          { assignTo && <Form.Group>
            <Form.Label>{ trans(`form.assignTo.${ assignTo }`) }</Form.Label>
            { assignTo === 'USERS' &&
              <EntitySelect id={ 'taskUsers' }
                            name={ 'taskUsers' }
                            isMulti
                            value={ users }
                            onChange={ setUsers }
                            fetchUrl={ '/users' }
                            labelKey={ 'username' }
                            valueKey={ 'id' }
              /> }
            { assignTo === 'ROLES' &&
              <Select isMulti
                      name={ 'taskRoles' }
                      value={ roles }
                      getOptionLabel={ o => o.name }
                      getOptionValue={ o => o.id }
                      options={ availableRoles }
                      labelKey={ 'name' }
                      valueKey={ 'id' }
                      onChange={ setRoles }
              /> }
          </Form.Group> }

        </Col>
      </Row>

      <Row>
        <Col>
          { item && <Button variant={ 'danger' } className={ 'me-2' }
                            onClick={ e => deleteTask(e, item) }
          >
            { trans('delete') }
          </Button> }
          <Button variant={ 'primary' }
                  type={ 'submit' }
          >
            { trans('submit') }
          </Button>
        </Col>
      </Row>
    </Form>

  )
}

export default TaskForm
