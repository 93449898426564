interface CalendarFilter {
  name: CalendarFilterName,
  label?: string|null,
  options?: CalendarFilterOption[],
}

export interface CalendarFilterOption {
  value: string,
  label: string,
  systemName: string,
  color: string|null,
}

export type CalendarFilterValues = Record<CalendarFilterName, CalendarFilterOption['value'][]>

export enum CalendarFilterName {
  LOCATION = 'LOCATION',
  EVENT_STATUS = 'EVENT_STATUS',
  EVENT_TYPE = 'EVENT_TYPE',
  SLOT_TYPE = 'SLOT_TYPE',
  RESOURCE = 'RESOURCE',
}

export const DEFAULT_FILTER_VALUES: CalendarFilterValues = {
  [CalendarFilterName.LOCATION]: [ '*' ],
  [CalendarFilterName.EVENT_STATUS]: [ '*' ],
  [CalendarFilterName.EVENT_TYPE]: [ '*' ],
  [CalendarFilterName.SLOT_TYPE]: [ '*' ],
  [CalendarFilterName.RESOURCE]: [ '*' ],
}

export default CalendarFilter
