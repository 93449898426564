import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, InputGroup, Overlay, Tooltip } from 'react-bootstrap'
import { StoreState } from 'src/Services/Store/reducers'
import { useSelector } from 'react-redux'
import { toSnakeCase } from 'src/Utils'
import { translate } from 'src/Services/translation'
import APIMethods from 'src/Services/api/apiMethods'
import translations from './translations'

interface Props {
  systemName: string,
  onChange: (value: string, isAvailable ?: boolean) => void,
  label: string,
  parent?: string,
  onFieldBlur: () => void,
  model?: string,
  name?: string,
  required?: boolean,
  size?: 'sm' | 'lg',
  disabled?: boolean,
  placeholder?: string
}

const SystemNameInput = ({
  systemName, onChange, label, parent = null, onFieldBlur,
  model = null, name = 'systemName', required = false,
  ...props
}: Props) => {
  const { language } = useSelector((state: StoreState) => state.Root.user)
  const trans = translate(translations)(language)

  const [ value, setValue ] = useState(systemName || null)
  const [ showTooltip, setShowToolTip ] = useState(false)
  const [ end, setEnd ] = useState(parent || null)

  const [ isChecking, setIsChecking ] = useState(false)
  const [ isAvailable, setIsAvailable ] = useState(null)
  const [ checkAvailabilityRequest, setCheckAvailabilityRequest ] = useState(null)
  const targetTooltipField = useRef(null)
  const systemNameInput = useRef(null)

  const getFullValue = (newValue?: string) => {
    const result = toSnakeCase(newValue || value)

    return end ? `${ result }#${ end }` : result
  }

  const onValueChange = (value?: string) => {

    onChange(getFullValue(value), null)

    clearTimeout(checkAvailabilityRequest)

    if (!model || !value || !getFullValue(value))
      return setIsAvailable(null)

    setIsChecking(true)

    setCheckAvailabilityRequest(setTimeout(() =>
      APIMethods.get(`/check/system_name_availability/${ model }`, { systemName: getFullValue(value) })
        .then(res => {
          setIsAvailable(res.data === true)
          onChange(getFullValue(value), res.data === true)
        })
        .finally(() => setIsChecking(false))
    , 1000))
  }

  const setValues = (value?: string) => {
    if (!value || typeof value !== 'string' || value.length === 0)
      return

    const splitValue = value.split('#')

    if (value.length > 1) {
      setValue(toSnakeCase(splitValue[0]))
      splitValue.shift()
      const returnedEnd = splitValue.length > 1 ? splitValue.join('#') : parent
      setEnd(returnedEnd)
    } else {
      setValue(toSnakeCase(splitValue[0]))
    }
  }

  useEffect(() => {
    if (!props.disabled)
      setValues(label)
  }, [ label ])

  useEffect(() => {
    if (!props.disabled)
      onValueChange(value)
    else
      setValues(value)
  }, [ value ])

  useEffect(() => {
    setEnd(parent)
  }, [ parent ])

  const onInputKeyDown = (e: React.KeyboardEvent) => {

    /** Does not cancel anything typed after caret (^) */
    if (
      [ 37, 38, 39, 40, 8 ].includes(e.keyCode)
            || /^[a-zA-Z0-9_#]$/.test(e.key)
    )
      return

    e.preventDefault()
    return false
  }

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== value) {
      setValue(e.target.value)
    }
  }

  const onInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setValue(e.target.value.toUpperCase())
    onFieldBlur()
  }

  const onCopyClick = () => {
    const fullValue = value + '#' + end
    navigator.clipboard.writeText(fullValue)
    setShowToolTip(true)
    setTimeout(() => setShowToolTip(false), 1500)
  }

  const getValue = () => !model || isAvailable ? getFullValue(value) : ''

  return <>
    <Form.Control type={ 'hidden' } name={ name } required={ required } value={ getValue() }/>
    <InputGroup size={ props.size || null }>
      <Form.Control
        ref={ systemNameInput }
        value={ value || '' }
        onKeyDown={ onInputKeyDown }
        onChange={ onInputChange }
        onBlur={ onInputBlur }
        required={ required }
        size={ props.size || null }
        { ...props }
      />
      {
        end &&
                <>
                  <InputGroup.Text>#{ end }</InputGroup.Text>
                  <Button onClick={ onCopyClick } ref={ targetTooltipField }>
                    <i className={ 'fas fa-copy' }/>
                  </Button>
                  <Overlay
                    target={ targetTooltipField.current }
                    show={ showTooltip }
                    placement={ 'top' }
                  >
                    { props => (
                      <Tooltip  { ...props }>
                        { trans('contentIsCopied') }
                      </Tooltip>
                    ) }
                  </Overlay>
                </>
      }
    </InputGroup>
    { isChecking && <Form.Text>{ trans('checking') }</Form.Text> }
    { !isChecking && isAvailable !== null && <Form.Text className={ `text-${ isAvailable ? 'success' : 'danger' }` }>
      { trans(isAvailable ? 'isAvailable' : 'isNotAvailable') }
    </Form.Text> }
  </>
}

export default SystemNameInput
