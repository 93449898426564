import { EditableField, Field } from 'src/Types/Field'
import { ListColumn } from 'src/Types/ListColumn'
import { PartialDeep } from 'src/Utils/Typing/PartialDeep'
import { MergeWithPrecedence } from 'src/Utils/Typing/MergeWithPrecedence'

export enum FormCustomizationConditionTypeDate {
  DATE_TODAY          = 'DATE_TODAY',
  DATE_CURRENT_WEEK   = 'DATE_CURRENT_WEEK',
  DATE_CURRENT_MONTH  = 'DATE_CURRENT_MONTH',
  DATE_CURRENT_YEAR   = 'DATE_CURRENT_YEAR',
}

export enum FormCustomizationConditionTypeOneTime {
  CLICK               = 'CLICK',
}

export enum FormCustomizationConditionType {
  CLICK               = 'CLICK',
  VALUE               = 'VALUE',
  DATE_TODAY          = 'DATE_TODAY',
  DATE_CURRENT_WEEK   = 'DATE_CURRENT_WEEK',
  DATE_CURRENT_MONTH  = 'DATE_CURRENT_MONTH',
  DATE_CURRENT_YEAR   = 'DATE_CURRENT_YEAR',
}

export enum FormCustomizationConditionOperator {
  NOT_EQUALS        = 'NOT_EQUALS',
  EQUALS            = 'EQUALS',
  CONTAINS          = 'CONTAINS',
  SUPERIOR          = 'SUPERIOR',
  INFERIOR          = 'INFERIOR',
  SUPERIOR_OR_EQUAL = 'SUPERIOR_OR_EQUAL',
  INFERIOR_OR_EQUAL = 'INFERIOR_OR_EQUAL',
  BEGIN_WITH        = 'BEGIN_WITH',
  ENDS_WITH         = 'ENDS_WITH',
}

export default interface FormCustomizationCondition {
  id: string
  field: Field
  listColumn?: ListColumn
  type: FormCustomizationConditionType | FormCustomizationConditionTypeDate | FormCustomizationConditionTypeOneTime
  operator: FormCustomizationConditionOperator
  value: any
}

export type EditableFormCustomizationCondition = PartialDeep<MergeWithPrecedence<FormCustomizationCondition, { field: EditableField }>, { recurseIntoArrays: true }>
