import React, { FormEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { translate } from 'src/Services/translation'
import FieldValueSetter from 'src/Components/FieldValueSetter'
import ReferenceFieldSelect from 'src/Components/ReferenceFieldSelect'
import { StoreState } from 'src/Services/Store/reducers'
import { ListFixedFilterOperator } from 'src/Types/ListFixedFilter'
import ReferenceField from 'src/Types/ReferenceField'
import translation from '../translations'
import { addFilter, setSelectedReferenceField, setSelectedReferenceFieldDetails } from '../state/actions'

interface FilterFormElements extends HTMLFormControlsCollection {
  alias: HTMLInputElement
  operator: HTMLInputElement
}

interface FilterForm extends HTMLFormElement {
  readonly elements: FilterFormElements
}

const FilterForm = () => {
  const dispatch = useDispatch()

  const { language } = useSelector((state: StoreState) => state.Root.user)
  const {
    id: listId, form, refForm, type: listType, operators, addFilterStatus, selectedReferenceField, selectedReferenceFieldDetails,
  } = useSelector((state: StoreState) => state.ListEdit)

  const trans = translate(translation)(language)

  const [ value, setValue ] = React.useState(null)

  const onAddFilterSubmit = (e: FormEvent<FilterForm>) => {
    e.preventDefault()

    dispatch(addFilter({
      listId,
      fieldId: selectedReferenceField?.id,
      fieldDetails: selectedReferenceFieldDetails,
      operator: e.currentTarget.elements.operator.value || null,
      alias: e.currentTarget.elements.alias.value || null,
      value,
    }))
  }

  const resetForm = () => {
    dispatch(setSelectedReferenceField(null))
    dispatch(setSelectedReferenceFieldDetails(null))
    setValue(null)
  }

  const onReferenceFieldSelect = (field: ReferenceField) => dispatch(setSelectedReferenceField(field))

  return <Form onSubmit={ onAddFilterSubmit }>
    <Form.Group className={ 'mb-2' }>
      <Form.Label>{ trans('label') }</Form.Label>
      <Form.Control type={ 'text' } name={ 'alias' } maxLength={ 255 } required/>
    </Form.Group>
    <Form.Group className={ 'mb-2' }>
      <Form.Label>{ trans('formField') }</Form.Label>
      <ReferenceFieldSelect
        type={ listType }
        refForm={ refForm }
        form={ form }
        value={ selectedReferenceField }
        onChange={ onReferenceFieldSelect }
        elementId={ 'fixedFilterFieldSelector' }
      />
    </Form.Group>
    { selectedReferenceFieldDetails && <>
      <Form.Group className={ 'mb-2' }>
        <Form.Label>{ trans('operator') }</Form.Label>
        <Form.Select name={ 'operator' } required disabled={ operators.length < 1 }>
          { operators.length < 1 && <option value={ '' }>{ trans('operatorPlaceholder') }</option> }
          { operators.map((operator: ListFixedFilterOperator) => <option key={ operator } value={ operator }>{ operator }</option>) }
        </Form.Select>
      </Form.Group>
      <Form.Group className={ 'mb-2' }>
        <Form.Label>
          <span className={ 'me-1' }>{ trans('value') }</span>
          <OverlayTrigger
            key={ 'top' }
            placement={ 'top' }
            overlay={
              <Tooltip id={ 'setFilterValue' }>
                <p>{ trans('fieldTooltipTitle') }</p>
                <ul>
                  <li>{ trans('fieldTooltipItem1') }</li>
                </ul>
              </Tooltip>
            }
          >
            <i className={ 'fa-solid fa-circle-info ml-2' }></i>
          </OverlayTrigger>
        </Form.Label>
        <FieldValueSetter field={ selectedReferenceFieldDetails } value={ value } onChange={ setValue }/>
      </Form.Group>
    </> }
    <Button variant={ 'primary' } type={ 'submit' } disabled={ !selectedReferenceFieldDetails } className={ `${ addFilterStatus === 'pending' ? 'is-loading' : '' }` }>
      { trans('confirm') }
    </Button>
  </Form>
}

export default FilterForm
