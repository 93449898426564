import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { translate } from 'src/Services/translation'
import Modal from 'src/Components/Modals/BaseModal'
import LocalizedDatepicker from 'src/Components/LocalizedDatepicker'
import { showWarning } from 'src/Services/notifier/actions'
import { formatDateTime } from 'src/Utils/Date'
import translations from 'src/Components/PatientCreator/translations'
import { StoreState } from 'src/Services/Store/reducers'
import Patient, { PatientGender } from 'src/Types/Patient'
import { Size } from 'src/Types/Size'
import { createPatient, resetPatientCreation, searchSimilarPatients } from 'src/Components/PatientCreator/state/actions'

const PatientCreator = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { isLoading } = useSelector((state: StoreState) => state.View)
  const { language, locale, portalTimezone } = useSelector((state: StoreState) => state.Root.user)
  const { isOpen, patient, similarPatients } = useSelector((state: StoreState) => state.PatientCreator)

  const trans = translate(translations)(language)
  const formatDate = formatDateTime(locale, portalTimezone)

  const [ newPatient, setNewPatient ] = React.useState<Patient>(patient)
  const [ hasSearchOnce, setHasSearchOnce ] = React.useState(false)

  const reset = () => {
    setNewPatient(patient)
    setHasSearchOnce(false)
  }

  React.useEffect(() => {
    if (patient)
      setNewPatient(patient)
    else reset()
  }, [ patient ])

  const onInput = (name: string) => (value: any) => {
    setNewPatient(old => ({ ...old, [name]: value }))
  }

  const onCreateClick = () => {
    if (!newPatient['FIRST_NAME#PATIENT'] || !newPatient['LAST_NAME#PATIENT'] || !newPatient['DOB#PATIENT'] || !newPatient['GENDER#PATIENT'])
      return dispatch(showWarning(trans('formIncomplete')))

    const fullPatient = { ...patient, ...newPatient }

    const searchPatient = { ...newPatient }
    delete searchPatient['GENDER#PATIENT']

    if (!hasSearchOnce) {
      dispatch(searchSimilarPatients(searchPatient, fullPatient, navigate))
      setHasSearchOnce(true)
    } else {
      dispatch(createPatient(fullPatient, navigate))
    }
  }

  if (!newPatient) {
    return <></>
  }

  return <Modal title={ trans('title') } isOpen={ isOpen } onClose={ () => dispatch(resetPatientCreation()) }>
    <>
      <Form>
        <Form.Group className={ 'mb-3' }>
          <Form.Label>{ trans('lastName') }</Form.Label>
          <Form.Control type={ 'text' } value={ newPatient['LAST_NAME#PATIENT'] || '' }
            onChange={ e => onInput('LAST_NAME#PATIENT')(e.target.value) }
          />

        </Form.Group>
        <Form.Group className={ 'mb-3' }>
          <Form.Label>{ trans('firstName') }</Form.Label>
          <Form.Control type={ 'text' } value={ newPatient['FIRST_NAME#PATIENT'] || '' }
            onChange={ e => onInput('FIRST_NAME#PATIENT')(e.target.value) }
          />
        </Form.Group>
        <Form.Group className={ 'mb-3' }>
          <Form.Label>{ trans('dateOfBirth') }</Form.Label>
          <LocalizedDatepicker
            selected={ newPatient?.['DOB#PATIENT'] }
            size={ Size.MEDIUM }
            showDateOnly
            onChange={ onInput('DOB#PATIENT') }
          />
        </Form.Group>
        <Form.Group className={ 'mb-3' }>
          <Form.Label>{ trans('gender') }</Form.Label>
          <div key={ 'inline-radio' } className={ 'mb-3' }>
            <Form.Check
              inline
              label={ trans('gender_male') }
              name={ 'gender' }
              type={ 'radio' }
              id={ 'gender-0' }
              value={ PatientGender.MALE }
              onChange={ () => onInput('GENDER#PATIENT')(PatientGender.MALE) }
              checked={ PatientGender.MALE === newPatient?.['GENDER#PATIENT'] }
              required={ true }
            />
            <Form.Check
              inline
              label={ trans('gender_female') }
              name={ 'gender' }
              type={ 'radio' }
              id={ 'gender-1' }
              value={ PatientGender.FEMALE }
              onChange={ () => onInput('GENDER#PATIENT')(PatientGender.FEMALE) }
              checked={ PatientGender.FEMALE === newPatient?.['GENDER#PATIENT'] }
              required={ true }
            />
          </div>
        </Form.Group>
      </Form>

      <Row>
        <Col>
          { !!similarPatients.length && <div className={ 'blue-card' }>
            <div><b>{ trans('similarPatientFoundLabel') } :</b></div>
            { similarPatients.map(patient =>
              <div className={ 'py-2' } key={ patient.id }>
                <Link to={ `/patient/${ patient.id }/instance/${ patient.id }` } onClick={ () => dispatch(resetPatientCreation()) }>
                  <span>
                    <b>{ patient['USE_NAME#PATIENT'] ?? patient['LAST_NAME#PATIENT'] } { patient['FIRST_NAME#PATIENT'] } </b>
                    { patient['USE_NAME#PATIENT'] && <span>{ trans('born') } <b>{ patient['USE_NAME#PATIENT'] ? patient['LAST_NAME#PATIENT'] : null } </b></span> }
                    { formatDate(patient['DOB#PATIENT'], true, false) }.&nbsp;
                  </span>
                  { patient['MOBILE_PHONE#PATIENT'] && <span><b>{ trans('mobilePhone') } : </b>{ patient['MOBILE_PHONE#PATIENT'] }&nbsp;</span> }
                  { patient['HOME_PHONE#PATIENT'] && <span><b>{ trans('homePhone') } : </b>{ patient['HOME_PHONE#PATIENT'] }&nbsp;</span> }
                  { patient['EXTERNAL_ID#PATIENT'] && <span><b>{ trans('externalId') } : </b>{ patient['EXTERNAL_ID#PATIENT'] }&nbsp;</span> }
                </Link>
              </div>) }
          </div> }
        </Col>
      </Row>
      <Row className={ 'mt-3' }>
        <Col>
          <Button className={ 'd-block m-auto' } variant={ 'primary' }
            disabled={ isLoading }
            onClick={ onCreateClick }
          >
            { similarPatients.length ? trans('createForSure') : trans('create') }
          </Button>
        </Col>
      </Row>
    </>
  </Modal>
}

export default PatientCreator
