import React from 'react'
import { useSelector } from 'react-redux'
import { Col, Form } from 'react-bootstrap'
import { translate } from 'src/Services/translation'
import FieldValueSetter from 'src/Components/FieldValueSetter'
import SystemFieldSelect from 'src/Components/SystemFieldSelect/index'
import { EditableField, Field } from 'src/Types/Field'
import { StoreState } from 'src/Services/Store/reducers'
import { TargetValueType } from 'src/Types/FormCustomizationOrder'
import translations from '../translations'

interface Props {
  type: string
  value: string
  targetField: Field | EditableField
  onValueChange: (value: string) => void
  onTypeChange: (type: TargetValueType) => void
  withLabel?: boolean
}

const TargetValueField = ({ type, value, targetField, onValueChange, onTypeChange, withLabel = false }: Props) => {
  const { language } = useSelector((state: StoreState) => state.Root.user)
  const { form } = useSelector((state: StoreState) => state.FormEditor.formReducer)

  const trans = translate(translations)(language)

  const onNewType = (type: TargetValueType) => {
    onValueChange(null)
    onTypeChange(type)
  }

  return <>
    <Col sm={ 2 }>
      { withLabel && <Form.Label>{ trans('modal.targetValueField.typeLabel') }</Form.Label> }
      <Form.Select value={ type } onChange={ e => onNewType(e.target.value as TargetValueType) }>
        { Object.values(TargetValueType).map(type =>
          <option value={ type } key={ type }>
            { trans(`modal.targetValueField.type.${ type }`) }
          </option>) }
      </Form.Select>
    </Col>
    { targetField && <>
      { type === TargetValueType.CONSTANT && <Col sm={ 4 }>
        { withLabel && <Form.Label>{ trans('modal.targetValueField.constantTypeLabel') }</Form.Label> }
        <Form.Group className={ 'mb-2' }>
          <FieldValueSetter field={ targetField as Field } value={ value } onChange={ onValueChange }/>
        </Form.Group>
      </Col> }
      { type === TargetValueType.DYNAMIC && <Col sm={ 4 }>
        { withLabel && <Form.Label>{ trans('modal.targetValueField.dynamicTypeLabel') }</Form.Label> }
        <SystemFieldSelect
          formId={ form.id }
          name={ 'field' }
          value={ value as string }
          onChange={ sf => onValueChange(sf.name) }
        />
        <Form.Text>{ trans('modal.targetValueField.dynamicValueBasedOn') }</Form.Text>
      </Col> }
    </> }
  </>
}

export default TargetValueField
