import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { translate } from 'src/Services/translation'
import translation from 'src/Views/Workflows/translations'
import WorkflowAction from 'src/Types/WorkflowAction'
import WorkflowItem from 'src/Types/WorkflowItem'
import WorkflowTrigger from 'src/Types/WorkflowTrigger'
import { StoreState } from 'src/Services/Store/reducers'
import { setUpdatingItem as setUpdatingWorkflow } from 'src/Views/Workflows/Modals/Workflows/state/actions'
import Column from './Column'
import { selectWorkflow } from '../state/actions'
import { setUpdatingItem as setUpdatingTrigger } from '../Modals/Triggers/state/actions'
import { setUpdatingItem as setUpdatingAction } from '../Modals/Actions/state/actions'

const WorkflowColumns = () => {
  const dispatch = useDispatch()

  const { language } = useSelector((state: StoreState) => state.Root.user)
  const { selectedWorkflow } = useSelector((state: StoreState) => state.WorkflowManager)
  const {
    originalTriggers, filteredTriggers, originalWorkflows, filteredWorkflows, originalActions,
    filteredActions, isFilterApplied,
  } = useSelector((state: StoreState) => state.WorkflowManager)

  const trans = translate(translation)(language)

  const onEditTrigger = (trigger: WorkflowTrigger) => dispatch(setUpdatingTrigger(trigger))
  const onEditWorkflow = (workflow: WorkflowItem) => dispatch(setUpdatingWorkflow(workflow))
  const onEditAction = (action: WorkflowAction) => dispatch(setUpdatingAction(action))

  const onSelectWorkflow = (item: WorkflowItem) => selectedWorkflow?.id === item.id
    ? dispatch(selectWorkflow(null))
    : dispatch(selectWorkflow(item))

  const triggers = isFilterApplied ? filteredTriggers : originalTriggers
  const workflows = isFilterApplied ? filteredWorkflows : originalWorkflows
  const actions = isFilterApplied ? filteredActions : originalActions

  return <div className={ 'workflow-columns' }>
    <Column title={ trans('trigger') }
            items={ triggers }
            renderText={ (item: WorkflowTrigger) => item.name }
            onEditClick={ onEditTrigger }
    />

    <Column title={ trans('workflow') }
            selectable
            items={ workflows }
            renderText={ (item: WorkflowItem) => `${ item.trigger.name } \n ${ item.actions.map(a => a.name).join(', ') }` }
            onClick={ onSelectWorkflow }
            onEditClick={ onEditWorkflow }
    />

    <Column title={ trans('action') }
            items={ actions }
            renderText={ (item: WorkflowAction) => item.name }
            onEditClick={ onEditAction }
    />
  </div>
}

export default WorkflowColumns
