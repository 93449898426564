import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { translate } from 'src/Services/translation'
import Modal from 'src/Components/Modals/BaseModal'
import { TYPES_ALLOWED_FOR_PATIENT } from 'src/Services/Constants/Form'
import {
  updateDetails, toggleDetailsModal, toggleSwpConfigModal, toggleMsSanteConfigModal, toggleRdConfigModal
} from 'src/Views/FormEditor/state/actions.ts'
import translation from '../translations'

const DetailsModal = () => {
  const dispatch = useDispatch()

  const { language } = useSelector(state => state.Root.user)
  const { form, detailsError: saveDetailsUpdateError, isDetailsModalOpen } =
    useSelector(state => state.FormEditor.formReducer)

  const trans = translate(translation)(language)

  const onSubmit = e => {
    e.preventDefault()
    dispatch(updateDetails({
      label: e.target.label.value,
      type: e.target.type.value,
      isResource: e.target.isResource.checked,
      isAvailableForPatient: e.target?.isAvailableForPatient?.checked,
    }))
  }

  const onSwpDevicesClick = () => {
    dispatch(toggleDetailsModal())
    dispatch(toggleSwpConfigModal())
  }

  const onMsSanteConfigClick = () => {
    dispatch(toggleDetailsModal())
    dispatch(toggleMsSanteConfigModal())
  }

  const onRdConfigClick = () => {
    dispatch(toggleDetailsModal())
    dispatch(toggleRdConfigModal())
  }

  return (
    <Modal title={ 'Details' } onClose={ () => dispatch(toggleDetailsModal()) } isOpen={ isDetailsModalOpen }>
      <Form onSubmit={ onSubmit }>
        <Row className={ 'mb-2' }>
          <Col>
            <Form.Group>
              <Form.Label>{ trans('details.formLabel') }</Form.Label>
              <Form.Control name={ 'label' } defaultValue={ form?.label } required
                            placeholder={ trans('details.formLabel') } size={ 'sm' }/>
            </Form.Group>
          </Col>

        </Row>
        <Row className={ 'mb-2' }>
          <Col className={ 'd-flex' } md={ 5 }>
            <Form.Switch className={ 'd-flex align-items-center' }
                         defaultChecked={ form?.isResource }
                         label={ trans('details.isResource') }
                         name={ 'isResource' }
            />
          </Col>
          {
            TYPES_ALLOWED_FOR_PATIENT.includes(form.type) &&
            <Col className={ 'd-flex' }>
              <Form.Switch label={ trans('details.isPatientResource') }
                           defaultChecked={ form?.isAvailableForPatient }
                           className={ 'd-flex align-items-center' }
                           name={ 'isAvailableForPatient' }
              />
            </Col>
          }
        </Row>
        <Row className={ 'mb-2' }>
          <Col>
            <Form.Control name={ 'type' } size={ 'sm' } defaultValue={ form?.type } disabled>
            </Form.Control>
          </Col>
        </Row>
        <Row className={ 'mb-2' }>
          <Col className={ 'd-flex align-items-center' }>
            <div>
              <span>{ trans('details.biosDevices') }</span>&nbsp;
              <span className={ form.isSwpEnabled ? 'text-success' : 'text-danger' }>
                ({ form.isSwpEnabled ? trans('on') : trans('off') })
              </span>
            </div>
          </Col>
          <Col>
            <Button size={ 'sm' }
                    variant={ 'secondary' }
                    className={ 'd-block ms-auto' }
                    onClick={ onSwpDevicesClick }
            >
              { trans('details.openFeatureButton') }
            </Button>
          </Col>
        </Row>
        <Row className={ 'mb-2' }>
          <Col className={ 'd-flex align-items-center' }>
            <div>
              <span>{ trans('details.msSanteFeature') }</span>&nbsp;
              <span className={ form.isMsSanteEnabled ? 'text-success' : 'text-danger' }>
                ({ form.isMsSanteEnabled ? trans('on') : trans('off') })
              </span>
            </div>
          </Col>
          <Col>
            <Button size={ 'sm' }
                    variant={ 'secondary' }
                    className={ 'd-block ms-auto' }
                    onClick={ onMsSanteConfigClick }
            >
              { trans('details.openFeatureButton') }
            </Button>
          </Col>
        </Row>
        <Row className={ 'mb-2' }>
          <Col className={ 'd-flex align-items-center' }>
            <div>
              <span>{ trans('details.remoteDesktopFeatureName') }</span>&nbsp;
              <span className={ form.isRdEnabled ? 'text-success' : 'text-danger' }>
                ({ form.isRdEnabled ? trans('on') : trans('off') })
              </span>
            </div>
          </Col>
          <Col>
            <Button size={ 'sm' }
                    variant={ 'secondary' }
                    className={ 'd-block ms-auto' }
                    onClick={ onRdConfigClick }
            >
              { trans('details.openFeatureButton') }
            </Button>
          </Col>
        </Row>
        <Button variant={ 'primary' } size={ 'md' } type={ 'submit' }>
          { trans('save') }
        </Button>
        <span className={ 'is-pulled-right has-text-danger' }>
          { saveDetailsUpdateError }
        </span>
      </Form>
    </Modal>
  )
}

export default DetailsModal
