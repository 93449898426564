export default {
  english: {
    btnAuditLog: 'Audit trails',
    block: 'Lock',
    blocked: 'Locked',
    unblock: 'Unlock',
    unsavedData: 'Unsaved data detected.\n Please save before leaving.',
    title: {
      editUser: 'Edit',
      createUser: 'Create new user'
    },
    editPermissions: 'Edit permissions',
    fields: {
      username: 'Username',
      password: 'Password',
      confirmPassword: 'Confirm Password',
      useTwoFactorAuth: 'Use Two Factor Auth',
      email: 'Email',
      pin: 'PIN',
      locations: 'Locations',
      roles: 'Roles',
      primaryRole: 'Primary Role',
      secondaryRoles: 'Secondary roles',
      isProvider: 'Is a resource',
      createRDPAccount: 'Create RDP account',
      isRdpUser: 'Is RDP user',
      mobilePhone: 'Mobile phone'
    },
    modal: {
      changelog: {
        title: 'Changelogs',
        header: {
          date: 'Date',
          action: 'Action',
          author: 'Author',
          data: 'Changes'
        },
        empty: 'None'
      }
    },
    linkTo: 'Go to the user\'s',
    linkToPerson: 'Person instance',
    linkToPrimaryRoleInstance: 'Primary role instance',
    linkToSecondaryRoleInstance: 'Secondary role instance',
  },
  french: {
    btnAuditLog: 'Audit trails',
    block: 'Bloquer',
    blocked: 'Bloqué',
    unblock: 'Débloquer',
    unsavedData: 'Données non enregistrées détectées.\n Veuillez sauvegarder avant de quitter',
    title: {
      editUser: 'Modifier',
      createUser: 'Créer nouvel utilisateur'
    },
    editPermissions: 'Modifier les permissions',
    fields: {
      username: 'Nom d\'utilisateur',
      password: 'Mot de passe',
      confirmPassword: 'Confirmer le mot de passe',
      useTwoFactorAuth: 'Utiliser la double authentification',
      email: 'Email',
      pin: 'PIN',
      locations: 'Lieus',
      roles: 'Rôles',
      primaryRole: 'Rôle principal',
      secondaryRoles: 'Rôles secondaires',
      isProvider: 'Est une resource',
      createRDPAccount: 'Créer un compte RDP',
      isRdpUser: 'Est un utilisateur RDP',
      mobilePhone: 'Téléphone mobile'
    },
    modal: {
      changelog: {
        title: 'Historique des changements',
        header: {
          date: 'Date',
          action: 'Action',
          author: 'Auteur',
          data: 'Modifications'
        },
        empty: 'Aucun'
      }
    }
  }
}
