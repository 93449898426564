import React, { ChangeEvent, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Row } from 'react-bootstrap'
// @ts-ignore
import { v4 } from 'uuid'
import Uppy, { UppyFile } from '@uppy/core'
import { FileInput } from '@uppy/react'
import { FIELD_BASE_TYPES_WITH_MULTIPLE_VALUES } from 'src/Services/Constants'
import ValueSetting, { EditableFieldOption } from 'src/Views/FormEditor/Components/FieldSettings/Components/ValueSetting'
// @ts-ignore
import UppyLocaleFr from '@uppy/locales/lib/fr_FR'
// @ts-ignore
import UppyLocaleEn from '@uppy/locales/lib/en_US'
import 'src/Style/Components/fileInput-customs.scss'
import '@uppy/core/dist/style.css'
import { translate } from 'src/Services/translation'
import translation from 'src/Views/FormEditor/translations'
import { EditableField } from 'src/Types/Field'
import { StoreState } from 'src/Services/Store/reducers'
import { toggleFieldValuesModal, updateField } from 'src/Views/FormEditor/state/actions'

interface Props {
  field: EditableField
}

const ValueSettings = ({ field }: Props) => {
  const dispatch = useDispatch()

  const fieldOptions: EditableFieldOption[] = field.options.values?.map(v => ({
    systemName: v.systemName, label: v.label, isHidden: v.isHidden, id: v4()
  })) || []
  const [ values, setValues ] = useState<EditableFieldOption[]>(fieldOptions)
  const [ defaultOption, setDefaultOption ] = useState<string|null>(field.options.defaultOption || null)

  const { language } = useSelector((state: StoreState) => state.Root.user)

  const trans = translate(translation)(language)

  UppyLocaleEn.strings.chooseFiles = trans('editField.importValues')
  UppyLocaleFr.strings.chooseFiles = trans('editField.importValues')

  const [ draggedValueIndex, setDraggedValueIndex ] = useState(null)

  const uppy = useMemo(() => new Uppy(), [])

  const addFieldValue = () => setValues([ ...values, { label: null, systemName: null, id: v4(), isNew: true, isHidden: null } ])
  const removeFieldValue = (i: number) => () => setValues(current => current.filter((v, j) => j !== i))
  const updateFieldValue = (i: number) => (value: EditableFieldOption) => {
    setValues(current => {
      return current.map((v, j) => i === j ? { ...v, ...value } : v)
    })
  }

  uppy.setOptions({
    debug: true,
    autoProceed: true,
    restrictions: {
      // allowedFileTypes: getAllowedMIMETypes(constraints)
      maxNumberOfFiles: 1
    }
  })
  uppy.on('complete', () => {

    const regex = /(?<line>\w+)\n?/g

    for (const file of uppy.getFiles()) {
      const reader = new FileReader()
      reader.onload = function () {
        const content = reader.result
        // @ts-ignore
        const results = content.matchAll(regex)
        const formattedResults = []

        for (const result of results)
          formattedResults.push({ label: result.groups.line, systemName: result.groups.line , isHidden: result.groups.line })

        setValues([ ...values, ...formattedResults ])
      }
      reader.readAsText(file.data)
    }
  })

  // @ts-ignore
  uppy.on('error', (file: UppyFile, err: Error) => {
    console.error('error', file, err)
  })
  const submit = () => {
    if (values.some(v => !v?.systemName))
      return alert(trans('formInvalid'))

    const isSystemNameDuplicatesFound = values.some(v =>
      values.filter(_ => _.systemName === v.systemName).length > 1)
    if (isSystemNameDuplicatesFound)
      return alert(trans('modal.fieldValues.form.systemNameDuplicates'))

    // removes UUID from submitted value
    const submittedValues = values.map(v =>
      ({ systemName: v.systemName, label: v.label, isHidden: v.isHidden }))

    dispatch(updateField({ ...field,  options: { ...field.options, defaultOption, values: submittedValues } }))
    dispatch(toggleFieldValuesModal())
  }

  const onDrop = (droppedIndex: number) => () =>
    setValues(currentValues => {
      const draggedValue = currentValues[draggedValueIndex]

      currentValues[draggedValueIndex] = currentValues[droppedIndex]
      currentValues[droppedIndex] = draggedValue

      return [ ...currentValues ]
    })

  const onDefaultOptionClick = (value: EditableFieldOption) => (e: ChangeEvent<HTMLInputElement>) => {
    if (FIELD_BASE_TYPES_WITH_MULTIPLE_VALUES.includes(field.type.baseFieldType)) {
      let defaultOptions = defaultOption?.split(',') || []
      if (e.target.checked)
        defaultOptions.push(value.systemName)
      else
        defaultOptions = defaultOptions.filter(o => o !== value.systemName)

      return setDefaultOption(defaultOptions.join(','))
    }

    setDefaultOption(e.target.checked ? value.systemName : null)
  }

  const isOptionDefault = (value: EditableFieldOption) => {
    if (FIELD_BASE_TYPES_WITH_MULTIPLE_VALUES.includes(field.type.baseFieldType))
      return defaultOption?.split(',')?.includes(value.systemName) || false

    return defaultOption === value.systemName
  }

  return <>
    { values.map((val, i) =>
      <ValueSetting key={ val.id }
        onUpdate={ updateFieldValue(i) }
        onRemove={ removeFieldValue(i) }
        onDefaultOptionClick={ onDefaultOptionClick(val) }
        value={ val }
        field={ field }
        isDefaultOption={ isOptionDefault(val) }
        values={ values }
        onDrag={ () => setDraggedValueIndex(i) }
        onDrop={ onDrop(i) }
        onDragEnd={ () => setDraggedValueIndex(null) }
      />) }
    <Row className={ 'd-flex mt-1' }>
      <Col md={ 2 }>
        <Button variant={ 'outline-primary' } onClick={ addFieldValue } size={ 'sm' }>
          { trans('editField.addValue') }
        </Button>
      </Col>
      <Col md={ 3 }>
        <FileInput uppy={ uppy } locale={ language === 'fr' ? UppyLocaleFr : UppyLocaleEn }/>
      </Col>
    </Row>
    <Button className={ 'float-end' } onClick={ submit }>{ trans('submit') }</Button>
  </>
}

export default ValueSettings
