export default {
  english: {
    loginCredentialsRefused: 'Credentials refused.',
    userBlocked: 'User locked',
    userUnblocked: 'User unlocked',
    notAuthenticated: 'Session expired, logging out...',
    noPermission: 'Sorry, you don\'t have the permission to access / do that.',
    notFound: 'Sorry, the resource you requested was not found.',
    userAuthSucceeded: 'Authenticated successfully.',
    userAuthFailed: 'Authentication failed.',
    uploadFileTooBig: 'The file is too big to be upload',
    uploadFileSuccess: 'File upload succeeded',
    uploadFileFailed: 'File upload failed',
    duplicateNameError: 'This name is already used.',
    mercureError: 'A real-time error occurred.',
    saveAsFinalSucceeded: 'Successfully saved as final.',
    createSucceeded: 'Creation done',
    updateSucceeded: 'Update done',
    fetchSucceeded: 'Request succeeded.',
    deleteSucceeded: 'Delete succeeded.',
    deleteUserSucceeded: 'Delete user succeeded',
    actionSucceeded: 'Action has succeeded.',
    actionFailed: 'Action has failed.',
    fetchFailed: 'Our attempt to fetch data has failed.',
    saveFailed: 'Our attempt to save has failed.',
    noDataToSave: 'No data to save.',
    updateFailed: 'Our attempt to update has failed.',
    loadListFailed: 'Our attempt to load list has failed.',
    loadFormFailed: 'Our attempt to load form has failed.',
    loadFieldFailed: 'Our attempt to load field has failed.',
    updateFieldSucceeded: 'Field successfully updated.',
    updateFieldFailed: 'Our attempt to update field has failed.',
    loadModulesFailed: 'Our attempt to load navbar modules has failed.',
    resizeRowFailed: 'Our attempt to resize a row has failed.',
    updateFormTypeFailed: 'Our attempt to update form type has failed.',
    createFieldFailed: 'Our attempt to create a field has failed.',
    addRowFailed: 'Our attempt to add a row has failed',
    addSectionFailed: 'Our attempt to add a section has failed.',
    updateSectionFailed: 'Our attempt to update a section has failed.',
    deleteSectionFailed: 'Our attempt to delete a section has failed.',
    deleteRowFailed: 'Our attempt to delete a row has failed.',
    reorderSectionFailed: 'Our attempt to reorder a section has failed.',
    moveRowToAnotherSectionFailed: 'Our attempt to move a row to another section has failed.',
    moveFieldFailed: 'Our attempt to move field has failed.',
    reorderARowFailed: 'Our attempt to reorder a row has failed.',
    saveFormFailed: 'Our attempt to save a form has failed.',
    undoChangesFailed: 'Our attempt to undo form changes has failed.',
    loadPermissionFailed: 'Our attempt to load permissions has failed.',
    uploadFailed: 'File(s) upload failed.',
    loadFormTypeFailed: 'Our attempt to load form type has failed.',
    loadUserFailed: 'Our attempt to load user has failed.',
    loadEventStatusesFailed: 'Our attempt to load event statuses has failed.',
    loadFormListFailed: 'Our attempt to load form list has failed.',
    searchFormFailed: 'Our attempt to search a form has failed.',
    lockFormFailed: 'Our attempt to lock/unlock a form has failed.',
    addFormFailed: 'Our attempt to add a form has failed.',
    loadZmrListFailed: 'Our attempt to load list has failed.',
    searchListFailed: 'Our attempt to search list has failed.',
    loadZmrListTypesFailed: 'Our attempt to load list types has failed.',
    loadFormsFailed: 'Our attempt to load list forms has failed.',
    addListFailed: 'Our attempt to add this list has failed.',
    cloneListFailed: 'Our attempt to clone this list has failed',
    deleteListFailed: 'Our attempt to delete a list has failed.',
    deleteFormFailed: 'Our attempt to delete a form has failed.',
    fetchEncountersFailed: 'Our attempt to fetch encounters has failed.',
    subscribeLockFailed: 'Our attempt to subscribe to forms lock events has failed.',
    editColumnFailed: 'Our attempt to edit a column has failed.',
    removeColumnFailed: 'Our attempt to delete a column has failed.',
    removeFilterFailed: 'Our attempt to delete a fixed filter has failed.',
    addColumnFailed: 'Our attempt to add a column has failed.',
    addFilterFailed: 'Our attempt to add a fixed filter has failed.',
    fetchZmrFailed: 'Our attempt to load a list has failed.',
    searchReferencedFieldFailed: 'Our attempt to search a form field has failed.',
    sortColumnFailed: 'Our attempt to sort columns has failed.',
    fetchOperatorsFailed: 'Our attempt to load operators list has failed.',
    fetchFiltersFailed: 'Our attempt to load fixed filters has failed.',
    fetchColumnsFailed: 'Our attempt to load columns has failed.',
    restoreInstanceFailed: 'Our attempt to restore an instance has failed.',
    loadListTypesFailed: 'Our attempt to load list types has failed.',
    fetchSelectedFieldTypeFailed: 'Our attempt to fetch selected field type has failed.',
    fetchRowsFailed: 'Our attempt to to load list results has failed.',
    fetchListInfosFailed: 'Our attempt to load list information has failed.',
    addReferenceFieldFailed: 'Our attempt to add a reference field has failed.',
    sortInsideAnExtensible: 'Our attempt to sort a field inside an extensible has failed.',
    initExtensibleScrollButtons: 'Our attempt to initialize extensible fields scroll buttons has failed.',
    addFieldInExtensible: 'Our attempt to add a field inside an Extensible table has failed.',
    searchReferenceFailed: 'Our attempt to search references has failed.',
    msSanteSendFailed: 'Shipment has failed please contact IT service at xx-xx-xx-xx-xx',
    msSanteSendSuccess: 'Sending in progress',
    referenceInstanceCreationFailed: 'Our attempt to create the referenced instance has failed.',
    referenceChangeValueFailed: 'Our attempt to change the reference\'s value has failed.',
    loadUserListFailed: 'Our attempt to load users has failed.',
    searchUserFailed: 'Our attempt to search user has failed.',
    addNewUserFailed: 'Our attempt to add user has failed.',
    updateUserFailed: 'Our attempt to update user has failed.',
    deleteUserFailed: 'Our attempt to delete user has failed.',
    fetchEventsFailed: 'Our attempt to load events has failed.',
    editExtensibleNoChange: 'No changes found.',
    saveExtensibleSucceeded: 'Extensible field saved.',
    saveExtensibleFailed: 'Failed to save extensible field.',
    editExtensibleFailed: 'Failed to edit extensible field.',
    deleteExtensibleFailed: 'Failed to delete extensible field.',
    saveInstanceFailed: 'Instance failed to save',
    userPinIncorrectError: 'Unable to save because PIN code is incorrect',
    fileConstraintFailedError: 'File constraints not met.',
    saveInstanceSucceeded: 'Instance successfully saved',
    fetchEventForEditFailed: 'Our attempt to load an event to edit it has failed.',
    loadListDictionaryFailed: 'Our attempt to load lists has failed.',
    loadReportDictionaryFailed: 'Our attempt to load report list has failed.',
    loadFormDictionaryFailed: 'Our attempt to load form list has failed.',
    loadUserModulePermissionsFailed: 'Our attempt to load user module privileges has failed.',
    loadUserListPermissionsFailed: 'Our attempt to load user list privileges has failed.',
    loadUserReportPermissionsFailed: 'Our attempt to load user report privileges has failed.',
    loadUserSchedulingPermissionsFailed: 'Our attempt to load user scheduling privileges has failed.',
    loadUserFormPermissionsFailed: 'Our attempt to load user form privileges has failed.',
    loadUserEFaxPermissionsFailed: 'Our attempt to load user efax privileges has failed.',
    updateUserModulePermissionsFailed: 'Our attempt to update user module privileges has failed.',
    updateUserListPermissionsFailed: 'Our attempt to update user list privileges has failed.',
    updateUserReportPermissionsFailed: 'Our attempt to update user report privileges has failed.',
    loadRoleModulePermissionsFailed: 'Our attempt to load role module privileges has failed.',
    loadRoleListPermissionsFailed: 'Our attempt to load role list privileges has failed.',
    loadRoleReportPermissionsFailed: 'Our attempt to load role report privileges has failed.',
    loadRoleSchedulingPermissionsFailed: 'Our attempt to load role scheduling privileges has failed.',
    loadRoleFormPermissionsFailed: 'Our attempt to load role form privileges has failed.',
    loadRoleEFaxPermissionsFailed: 'Our attempt to load role efax privileges has failed.',
    updateRoleModulePermissionsFailed: 'Our attempt to update role module privileges has failed.',
    updateRoleListPermissionsFailed: 'Our attempt to update role list privileges has failed.',
    updateRoleReportPermissionsFailed: 'Our attempt to update role report privileges has failed.',
    createFormPermissionFailed: 'Our attempt to create form privilege has failed.',
    updateFormPermissionFailed: 'Our attempt to update form privilege has failed.',
    deleteFormPermissionFailed: 'Our attempt to delete form privilege has failed.',
    loadReportListFailed: 'Our attempt to load reports has failed.',
    loadFieldTypeListFailed: 'Our attempt to load field types has failed.',
    createFieldTypeFailed: 'Our attempt to create field type has failed.',
    deleteFieldTypeFailed: 'Our attempt to delete field type has failed.',
    updateFieldTypeFailed: 'Our attempt to update field type has failed.',
    searchReportFailed: 'Our attempt to search reports has failed.',
    runReportFailed: 'Our attempt to run report has failed.',
    loadAppointmentDictionaryFailed: 'Our attempt to load appointment dictionary has failed.',
    searchEventsFailed: 'Our attempt to search events has failed.',
    searchSlotsFailed: 'Our attempt to search slots has failed.',
    createEventsFailed: 'Our attempt to create events has failed.',
    searchPatientFailed: 'Our attempt to search patients has failed.',
    fetchSystemFieldsFailed: 'Our attempt to fetch system fields has failed.',
    createFailed: 'There was an error within the process',
    deleteFailed: 'There was an error within the process',
    formIncomplete: 'Form incomplete',
    createPatientUserDataIncomplete: 'Patient data incomplete. (First name, last name and email required)',
    saveFormSucceeded: 'Form successfully saved',
    workflowFailedError: 'Workflow failed',
    emptyForm: 'Form is empty.',
    mandatoryFieldMissing: 'A mandatory field is missing',
    systemFieldNotFound: 'A system field is missing.',
    rdFeatureDisable: 'Instance\'s form RD feature is not enabled.',
    rdSessionNotInCreableStatus: 'Session not in a status that allows creation retry.',
    rdSoftwareInvalid: 'Software is not handle by remote desktop feature.',
  },
  french: {
    loginCredentialsRefused: 'Identifiants refusés.',
    userBlocked: 'Utilisateur bloqué',
    userUnblocked: 'Utilisateur débloqué',
    notAuthenticated: 'La session a expiré, déconnexion en cours...',
    noPermission: 'Désolé, vous n\'avez pas la permission d\'accéder à ces informations ou effectuer cette action.',
    notFound: 'Désolé, la ressource que vous avez demandé n\'a pas été trouvée.',
    userAuthSucceeded: 'Authentifié avec succès.',
    userAuthFailed: 'Échec de l\'authentification.',
    uploadFileTooBig: 'Le fichier est trop gros pour être uploadé.',
    uploadFileSuccess: 'Fichier(s) uploadé avec succès.',
    uploadFileFailed: 'Échec de l\'upload du fichier.',
    saveAsFinalSucceeded: 'Sauvegardé avec succès.',
    createSucceeded: 'Création terminée',
    updateSucceeded: 'Modification terminée',
    fetchSucceeded: 'Requête effectuée avec succès.',
    deleteSucceeded: 'Suppression effectuée.',
    deleteUserSucceeded: 'Suppression utilisateur effectuée avec succès',
    actionSucceeded: 'Action réussie.',
    actionFailed: 'Action échouée.',
    fetchFailed: 'Notre tentative de récupérer les données a échouée.',
    saveFailed: 'Notre tentative de sauvegarde a échouée.',
    noDataToSave: 'Pas de modification à sauvegarder.',
    updateFailed: 'Échec de la modification.',
    loadListFailed: 'Échec du chargement de la liste.',
    duplicateNameError: 'Ce nom est déjà utilisé.',
    loadFormFailed: 'Échec du chargement du formulaire.',
    loadFieldFailed: 'Échec du chargement du champ de formulaire.',
    updateFieldSucceeded: 'Mise à jour du champ de formulaire réussie.',
    updateFieldFailed: 'Échec de la mise à jour du champ de formulaire.',
    resizeRowFailed: 'Échec du redimensionnement de la rangée.',
    updateFormTypeFailed: 'Échec de la mise a jour du type du formulaire.',
    createFieldFailed: 'Échec de la création du champ de formulaire.',
    addRowFailed: 'Our attempt to add a row has failed',
    loadModulesFailed: 'Échec du chargement des modules de la navbar.',
    addSectionFailed: 'Échec de l\'ajout d\'une section.',
    deleteRowFailed: 'Échec de la suppression de la ligne.',
    deleteSectionFailed: 'Échec de la suppression de la section.',
    updateSectionFailed: 'Échec de la mise à jour de la section.',
    reorderSectionFailed: 'Échec de réordonnancement de la section.',
    moveRowToAnotherSectionFailed: 'Échec du déplacement d\'une rangée dans une autre section.',
    moveFieldFailed: 'Échec du déplacement du champ de formulaire.',
    reorderARowFailed: 'Échec du réordonnancement de la rangée.',
    saveFormFailed: 'Échec de l\'enregistrement du formulaire.',
    undoChangesFailed: 'Échec de l\'annulation des changements du formulaire.',
    loadPermissionFailed: 'Échec du chargement des permissions.',
    uploadFailed: 'L\'upload du ou des fichiers a échoué.',
    loadFormTypeFailed: 'Échec du chargement du type de formulaire.',
    loadUserFailed: 'Échec du chargement de l\'utilisateur.',
    loadEventStatusesFailed: 'Échec du chargement des status d\'évènements.',
    loadFormListFailed: 'Échec du chargement de la liste des formulaires.',
    searchFormFailed: 'Échec de la recherche de formulaire.',
    addFormFailed: 'Échec de l\'ajout de formulaire.',
    loadZmrListFailed: 'Échec du chargement de la liste.',
    searchListFailed: 'Échec de la recherche de liste.',
    loadZmrListTypesFailed: 'Échec du chargement des types de liste.',
    loadFormsFailed: 'Échec du chargement des formulaires de liste.',
    editExtensibleNoChange: 'Aucune modification détecté.',
    saveExtensibleSucceeded: 'Champ étendu sauvegardé.',
    saveExtensibleFailed: 'Impossible de sauvegarder le champ étendu.',
    editExtensibleFailed: 'Impossible d\'éditer le champ étendu.',
    deleteExtensibleFailed: 'Impossible de supprimer le champ étendu.',
    addListFailed: 'Échec de l\'ajout d\'une liste.',
    cloneListFailed: 'Impossible de cloner cette liste',
    saveInstanceFailed: 'Erreur lors de la sauvegarde',
    userPinIncorrectError: 'Sauvegarde impossible car le code PIN est incorrect',
    fileConstraintFailedError: 'Contraintes de fichier pas respectées.',
    saveInstanceSucceeded: 'Instance sauvegardée',
    deleteListFailed: 'Échec de la suppression d\'une liste.',
    deleteFormFailed: 'Échec de la suppression d\'un formulaire.',
    fetchEncountersFailed: 'Échec du chargement des rencontres.',
    subscribeLockFailed: 'Échec de la souscription aux evenements de verrouillage des formulaires.',
    mercureError: 'Erreur avec un evenement temps-réel.',
    editColumnFailed: 'Échec de l\'édition d\'une colonne.',
    removeColumnFailed: 'Échec de la suppression d\'une colonne.',
    removeFilterFailed: 'Échec de la suppression d\'un filtre fixe.',
    addColumnFailed: 'Échec de l\'ajout d\'une colonne.',
    addFilterFailed: 'Échec de l\'ajout d\'un filtre fixe.',
    fetchZmrFailed: 'Échec du chargement de la liste.',
    searchReferencedFieldFailed: 'Échec de la recherche d\'un champ de formulaire.',
    sortColumnFailed: 'Échec du réordonnancement des colonnes',
    fetchOperatorsFailed: 'Échec du chargement de la liste des opérateurs.',
    fetchFiltersFailed: 'Échec du chargement des filtres fixes.',
    fetchColumnsFailed: 'Échec du chargement des colonnes.',
    restoreInstanceFailed: 'Échec de la restauration de l\'instance.',
    loadListTypesFailed: 'Échec du chargement des types de listes.',
    fetchSelectedFieldTypeFailed: 'Échec du chargement du type de champ.',
    fetchRowsFailed: 'Échec du chargement des résultat de la liste.',
    fetchListInfosFailed: 'Échec du chargement des informations de la liste.',
    addReferenceFieldFailed: 'Échec de l\'ajout d\'un champ Reference.',
    sortInsideAnExtensible: 'Échec du réordonnancement au sein d\'un champ Extensible.',
    initExtensibleScrollButtons: 'Échec de l\'initialisation des boutons de défilement d\'un champ Extensible.',
    addFieldInExtensible: 'Échec de l\'ajout d\'un champ dans un champ Extensible.',
    searchReferenceFailed: 'Échec de la recherche de références.',
    msSanteSendFailed: 'L\'envoi a échoué veuillez contacter le service IT au xx-xx-xx-xx-xx',
    msSanteSendSuccess: 'Envoi en cours',
    referenceInstanceCreationFailed: 'Échec de la création de l\'instance de référence.',
    referenceChangeValueFailed: 'Échec du changement de valeur du champ référence.',
    loadUserListFailed: 'Impossible de charger les utilisateurs.',
    searchUserFailed: 'Impossible de rechercher l\'utilisateur.',
    addNewUserFailed: 'Impossible d\'ajouter l\'utilisateur.',
    updateUserFailed: 'Échec de la mise à jour de l\'utilisateur.',
    deleteUserFailed: 'Impossible de supprimer l\'utilisateur.',
    fetchEventsFailed: 'Échec lors du chargement des évènements.',
    fetchEventForEditFailed: 'Échec lors du chargement d\'un évènement dans le but de le modifier.',
    loadListDictionaryFailed: 'Impossible de charger les listes.',
    loadReportDictionaryFailed: 'Impossible de charger les listes de rapports.',
    loadFormDictionaryFailed: 'Impossible de charger les listes de formulaires.',
    loadUserModulePermissionsFailed: 'Impossible de charger les privilèges du module utilisateur.',
    loadUserListPermissionsFailed: 'Impossible de charger les privilèges de liste d\'utilisateurs.',
    loadUserReportPermissionsFailed: 'Impossible de charger les privilèges de rapport utilisateur.',
    loadUserSchedulingPermissionsFailed: 'Impossible de charger les privilèges de planification des utilisateurs.',
    loadUserFormPermissionsFailed: 'Impossible de charger les privilèges du formulaire utilisateur.',
    loadUserEFaxPermissionsFailed: 'Impossible de charger les privilèges efax de l\'utilisateur.',
    updateUserModulePermissionsFailed: 'Impossible de mettre à jour les privilèges du module utilisateur.',
    updateUserListPermissionsFailed: 'Impossible de mettre à jour les privilèges de la liste d\'utilisateurs.',
    updateUserReportPermissionsFailed: 'Impossible de mettre à jour les privilèges de rapport utilisateur.',
    loadRoleModulePermissionsFailed: 'Impossible de charger les privilèges du module de rôle.',
    loadRoleListPermissionsFailed: 'Impossible de charger les privilèges de liste de rôles.',
    loadRoleReportPermissionsFailed: 'Impossible de charger les privilèges de rapport de rôle.',
    loadRoleSchedulingPermissionsFailed: 'Impossible de charger les privilèges de planification des rôles.',
    loadRoleFormPermissionsFailed: 'Impossible de charger les privilèges du formulaire de rôle.',
    loadRoleEFaxPermissionsFailed: 'Impossible de charger les privilèges efax du rôle.',
    loadFieldTypeListFailed: 'Impossible de charger les types de champ.',
    createFieldTypeFailed: 'Impossible de créer le type de champ.',
    deleteFieldTypeFailed: 'Impossible de supprimer le type de champ.',
    updateFieldTypeFailed: 'Impossible de modifier le type de champ.',
    updateRoleModulePermissionsFailed: 'Impossible de mettre à jour les privilèges du module de rôle.',
    updateRoleListPermissionsFailed: 'Impossible de mettre à jour les privilèges de la liste de rôles.',
    updateRoleReportPermissionsFailed: 'Impossible de mettre à jour les privilèges de rapport de rôle.',
    createFormPermissionFailed: 'Impossible de créer le privilège de formulaire.',
    updateFormPermissionFailed: 'Impossible de mettre à jour le privilège de formulaire.',
    deleteFormPermissionFailed: 'Impossible de supprimer le privilège de formulaire.',
    loadReportListFailed: 'Impossible de charger la liste des rapports.',
    searchReportFailed: 'Impossible de rechercher des rapports.',
    runReportFailed: 'Impossible d\'exécuter le rapport.',
    loadAppointmentDictionaryFailed: 'Impossible de charger le dictionnaire de rendez-vous.',
    searchEventsFailed: 'Impossible de rechercher des événements.',
    searchSlotsFailed: 'Impossible de rechercher des emplacements.',
    createEventsFailed: 'Impossible de créer des événements.',
    searchPatientFailed: 'Échec de la recherche de patient.',
    fetchSystemFieldsFailed: 'Notre tentative de récupération des champs système a échoué.',
    createFailed: 'Il y a une erreur dans la requête',
    deleteFailed: 'Il y a une erreur dans la requête',
    formIncomplete: 'Formulaire incomplet',
    createPatientUserDataIncomplete: 'Données patient incomplètes. (Prénom, nom et email nécessaires)',
    saveFormSucceeded: 'Formulaire enregistré avec succès',
    workflowFailedError: 'Échec d\'un workflow',
    emptyForm: 'Le formulaire est vide.',
    mandatoryFieldMissing: 'Un champ obligatoire est manquant',
    systemFieldNotFound: 'Un champ système est manquant.',
    rdFeatureDisable: 'La fonctionnalité Remote desktop n\'est pas activée sur ce type d\'instance.',
    rdSessionNotInCreableStatus: 'Le status de la session ne permets pas de relancer la création.',
    rdSoftwareInvalid: 'Le logiciel renseigné n\'est pas supporté.',
  }
}
