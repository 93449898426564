import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DeleteModal from 'src/Components/Modals/DeleteModal'
import { translate } from 'src/Services/translation'
import { deleteFormList, setFormToDelete } from '../state/actions'
import translation from '../translations'

const DeleteFormModal = () => {
  const dispatch = useDispatch()
  const { language} = useSelector(state => state.Root.user)
  const { formToDelete } = useSelector(state => state.FormList)

  const trans = translate(translation)(language)

  const onConfirm = (dispatch, formId) => () => {
    dispatch(deleteFormList(formId))
  }

  const onCancel = dispatch => () => {
    dispatch(setFormToDelete(''))
  }

  return (
    <DeleteModal title={ trans('modal.deleteForm.title') }
                 isOpen={ Boolean(formToDelete) }
                 onClose={ onCancel(dispatch) }
                 onDelete={ onConfirm(dispatch, formToDelete) }
    >
      { trans('modal.deleteForm.description') }
    </DeleteModal>
  )
}

export default DeleteFormModal
