import { Form } from 'src/Types/Form'
import { Field } from 'src/Types/Field'
import Section from 'src/Types/Section'

export const getFieldsFromForm = (form: Form): Field[] => form?.sections?.reduce((results, section) => {
  for (const row of section.rows)
    for (const field of row.fields)
      results.push(field)
  return results
}, []) || []

export const getFieldsFromSection = (section: Section): Field[] =>
  section?.rows.reduce((fields, row) => fields.concat(row.fields), []) || []
