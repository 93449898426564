import React, { ChangeEvent, FormEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Form, Row } from 'react-bootstrap'
import LocalizedDatepicker from 'src/Components/LocalizedDatepicker'
import { editSlot, setDeletingSlot, setEditingSlot } from 'src/Components/Calendar/state/actions'
import { SlotForApi } from 'src/Types/Slot'
import { StoreState } from 'src/Services/Store/reducers'
import { translate } from 'src/Services/translation'
import translations from 'src/Components/Calendar/translations'
import { Size } from 'src/Types/Size'
import Accordion from 'react-bootstrap/Accordion'
import AuditTrailsSimpleTable from '../../AuditTrailsSimpleTable'
import EntitySelect from '../../EntitySelect'
import { isUuidV4String, UuidV4 } from '../../../Types/Uuid'

interface Props {
  onSubmit: (slot: SlotForApi) => void,
  onClose: () => void
}

const SlotForm = ({ onSubmit, onClose }: Props) => {
  const dispatch = useDispatch()

  const { language } = useSelector((state: StoreState) => state.Root.user)
  const { eventTypes, locations } = useSelector((state: StoreState) => state.Dictionary)
  const { editingSlot } = useSelector((state: StoreState) => state.Calendar)


  const trans = translate(translations)(language)

  const handleDeleteClick = () => {
    dispatch(setEditingSlot(null))
    dispatch(setDeletingSlot(editingSlot))
  }

  const getUserDefaultLocationId = (): UuidV4 | null => {
    const defaultLocationIsSet = locations.some(_ => _.id === getDefaultLocationId())
    return defaultLocationIsSet ? getDefaultLocationId() : null
  }

  const getDefaultLocationId = (): UuidV4 | null =>
    editingSlot.location?.id || null

  const handleLocationSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const locationId = isUuidV4String(e.target.value) ? e.target.value : null
    setLocation(locationId)
  }

  const [ resource, setResource ] = useState(null)
  const [ location, setLocation ] = useState<string>(getDefaultLocationId)
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    const data: SlotForApi = {
      ...editingSlot,
      startAt: editingSlot.start || null,
      endAt: editingSlot.end || null,
      type: editingSlot.type?.id || null,
      location: location || null,
      event: editingSlot.event?.id || null,
      resource: resource?.id || null,
      serie: editingSlot.serie?.id || null,
    }

    dispatch(editSlot(data))
    onSubmit(data)
  }

  return editingSlot ? <Form onSubmit={ handleSubmit }>
    <Row className={ 'mb-2' }>
      <Col>
        <div>
          { editingSlot.event &&
            <small className={ 'text-muted' }>{ trans('form.slot.disabledDeleteTitle') }</small> }</div>
      </Col>
    </Row>
    <Row className={ 'mb-2' }>
      <Col>
        <Form.Group>
          <Form.Label>{ trans('form.slot.startAt') }</Form.Label>
          <LocalizedDatepicker selected={ editingSlot.start } size={ Size.MEDIUM } disabled />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Label>{ trans('form.slot.endAt') }</Form.Label>
          <LocalizedDatepicker selected={ editingSlot.end } size={ Size.MEDIUM } disabled />
        </Form.Group>
      </Col>
    </Row>
    <Row className={ 'mb-2' }>
      <Col>
        <Form.Group>
          <Form.Label>{ trans('form.slot.resource') }</Form.Label>
          <EntitySelect fetchUrl={ `/instances/resources/${ editingSlot.type?.eventType?.id }` }
            name={ 'resource' }
            placeholder={ 'Select a resource' }
            labelKey={ 'identityString' }
            value={ editingSlot.resource ?? null }
            // key is dynamic to force a rerender of the resources when changing the location
            key={ location ?? 'resources' }
            additionalParams={ location ? { locationId: location } : null }
            isDefaultOptions
            onChange={ selected => setResource(selected?.id ?? null) }
          />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Label>{ trans('form.slot.location') }</Form.Label>
          <Form.Select name={ 'location' }
            defaultValue={ getUserDefaultLocationId() }
            onChange={ handleLocationSelect }
          >
            <option value={ '' }>{ trans('none') }</option>
            { locations.map(l => <option value={ l.id } key={ l.id }>{ l.identityString }</option>) }
          </Form.Select>
        </Form.Group>
      </Col>
    </Row>
    <Row className={ 'mb-2' }>
      <Col md={ 6 }>
        <Form.Group>
          <Form.Label>{ trans('form.event.type') }</Form.Label>
          <Form.Control name={ 'duration' } defaultValue={ eventTypes.find(eventType => eventType.id === editingSlot.type.eventType.id)?.label } disabled/>
        </Form.Group>
      </Col>
    </Row>
    <Row className={ 'mb-2' }>
      <Col md={ 6 }>
        <h4>{ trans('informations') }</h4>
      </Col>
    </Row>
    <Row className={ 'mb-2' }>
      <Col md={ 6 }>
        <Form.Group>
          <Form.Label>{ trans('form.slot.id') }</Form.Label>
          <Form.Control name={ 'duration' } defaultValue={ editingSlot.id } disabled/>
        </Form.Group>
      </Col>
      <Col md={ 6 }>
        {
          editingSlot.serie.id && <Form.Group>
            <Form.Label>{ trans('form.slot.seriesId') }</Form.Label>
            <Form.Control name={ 'duration' } defaultValue={ editingSlot.serie?.id } disabled/>
          </Form.Group>
        }
      </Col>
    </Row>
    <Row className={ 'mb-2' }>
      <Col md={ 6 }>
        <Form.Group>
          <Form.Label>{ trans('form.event.createdBy') }</Form.Label>
          <Form.Control name={ 'duration' } defaultValue={ editingSlot.createdBy?.username } disabled/>
        </Form.Group>
      </Col>
      <Col md={ 6 }>
        <Form.Group>
          <Form.Label>{ trans('form.event.createdAt') }</Form.Label>
          <LocalizedDatepicker selected={ editingSlot.createdAt } disabled size={ Size.MEDIUM }/>
        </Form.Group>
      </Col>
    </Row>
    <Row className={ 'd-flex justify-content-end' }>
      <Col className={ 'd-flex justify-content-evenly' } md={ 3 }>
        <Button variant={ 'danger' } onClick={ handleDeleteClick } disabled={ !!editingSlot.event }
          title={ trans('delete') }>
          <i className={ 'icon fas fa-trash' }/>
        </Button>
        <Button onClick={ onClose } className={ 'ms-1' } variant={ 'outline-primary' }>{ trans('cancel') }</Button>
        <Button type={ 'submit' } variant={ 'primary' } className={ 'ms-1' } disabled>{ trans('submit') }</Button>
      </Col>
    </Row>
    { editingSlot?.id &&
      <div className={ 'container mt-3' }>
        <Accordion>
          <Accordion.Item eventKey={ '0' }>
            <Accordion.Header>{ trans('form.event.changeLogs') }</Accordion.Header>
            <Accordion.Body>
              <AuditTrailsSimpleTable slot={ editingSlot }/>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    }
  </Form> : <></>
}

export default SlotForm
