import React, { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { useSelector } from 'react-redux'
import { translate } from 'src/Services/translation'
import { enUS, fr } from 'date-fns/locale'
import { FormControl } from 'react-bootstrap'
import { PatternFormat } from 'react-number-format'
import {
  formatDateToIso8601, formatDateToUtcIso8601WithCustomTimezone, formatUtcIso8601ToDateWithCustomTimezone,
} from 'src/Utils/Date'
import {
  DATE_FORMAT, DATE_TIME_FORMAT, DATE_TIME_SERVICE_REACT_DATE_PICKER, TIME_FORMAT,
} from 'src/Services/Constants/Locales'
import { Size } from 'src/Types/Size'
import { Locale as AppLocale } from 'src/Types/Locale'
import { Iso8601 } from 'src/Types/Date'
import { StoreState } from 'src/Services/Store/reducers'
import translations from './translations'

interface Props {
  selected: string
  showTimeOnly?: boolean
  showDateOnly?: boolean
  isClearable?: boolean
  name?: string
  size?: Size
  placeholder?: string
  onChange?: (newValue: Iso8601) => void
  [extraProperties: string]: any
}

/**
 * use portalId for overflow in modals ex. portalId="root"
 *
 * https://reactdatepicker.com/
 */
const LocalizedDatepicker = ({
  showTimeOnly = false, showDateOnly = false,
  size = Size.SMALL, selected = null, name = 'date',
  placeholder = '', isClearable = false, onChange = () => {},
  ...props
}: Props) => {

  const { language, locale: userLocale, portalTimezone } = useSelector((state: StoreState) => state.Root.user)

  const [ format, setFormat ] = useState<string>(DATE_TIME_FORMAT[DATE_TIME_SERVICE_REACT_DATE_PICKER][AppLocale.US])
  const [ locale, setLocale ] = useState<Locale>(enUS)
  const [ date, setDate ] = useState<Date | null>()

  const trans = translate(translations)(language)

  useEffect(() => {
    if (showTimeOnly)
      setFormat(TIME_FORMAT[DATE_TIME_SERVICE_REACT_DATE_PICKER][userLocale])
    else if (showDateOnly)
      setFormat(DATE_FORMAT[DATE_TIME_SERVICE_REACT_DATE_PICKER][userLocale])
    else
      setFormat(DATE_TIME_FORMAT[DATE_TIME_SERVICE_REACT_DATE_PICKER][userLocale])

    setLocale(userLocale === AppLocale.US ? enUS : fr)
  }, [ userLocale, showTimeOnly, showDateOnly ])

  useEffect(() => {
    if (selected) {
      setDate(showDateOnly
      // Will return date as it has been sent
        ? formatUtcIso8601ToDateWithCustomTimezone(selected, 'UTC')
        : formatUtcIso8601ToDateWithCustomTimezone(selected, portalTimezone),
      )
    }
  }, [ selected ])

  const onNewValue = (newValue: Date | null) => {

    setDate(newValue)
    onChange(showDateOnly
    // Will return date as the user see it
      ? formatDateToIso8601(newValue, '+00:00', true)
      : formatDateToUtcIso8601WithCustomTimezone(newValue, portalTimezone),
    )
  }

  return (
    <div className={ 'mb-2' }>
      <ReactDatePicker
        className={ `form-control form-control-${ size }` }
        dateFormat={ format }
        todayButton={ trans('today') }
        selected={ date }
        onChange={ onNewValue }
        locale={ locale }
        showTimeSelect={ !showDateOnly }
        showYearDropdown={ !showTimeOnly }
        scrollableYearDropdown
        yearDropdownItemNumber={ 100 }
        showTimeSelectOnly={ showTimeOnly }
        placeholderText={ placeholder }
        isClearable={ isClearable }
        customInput={
          (showDateOnly && !showTimeOnly) && <PatternFormat format={ '##/##/####' }/>
        }
        { ...props }
      />
      <FormControl type={ 'hidden' } value={ date?.toISOString() || '' } name={ name } data-sub-type={ 'date' }/>
    </div>
  )
}

export default LocalizedDatepicker
