import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import { getFieldPermissions } from 'src/Views/Permissions/selectors'
import { getFieldsFromSection } from 'src/Utils/FormUtils'
import FieldPermissionForm from './FieldPermissionForm'
import translation from '../translations'
import { translate, translateConf } from '../../../Services/translation/index'

const FieldContent = ({ section }) => {
  const dispatch = useDispatch()
  const { language } = useSelector(state => state.Root.user)
  const { configTranslations } = useSelector(state => state.Root)
  const fieldPermissions = useSelector(state => getFieldPermissions(state.Permissions))

  const transConf = translateConf(configTranslations)
  const trans = translate(translation)(language)

  const getPermissionByField = field => fieldPermissions.find(p => field.id === p.field.id) || null

  return <>
    <Row>
      { getFieldsFromSection(section).map(field =>
        <Col md={ 4 } key={ `section${ section.id }field${ field.id }` } className={ 'mb-1' }>
          <FieldPermissionForm permission={ getPermissionByField(field) } field={ field } />
        </Col>) }
    </Row>
  </>
}

export default FieldContent
