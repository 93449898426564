import React, { useRef, useState } from 'react'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { setFormErrors } from '../state/actions'
import SystemNameInput from '../../../Components/SystemNameInput'

const SlotTypesForm = ({ trans, onSubmit, slotType = null }) => {
  const { selectedForm } = useSelector(state => state.SlotTypes)
  const { eventTypes } = useSelector(state => state.Dictionary)
  const dispatch = useDispatch()
  const slotForm = useRef(null)

  const [ validated, setValidated ] = useState(false)
  const [ label, setLabel ] = React.useState(null)
  const [ isAvailable, setIsAvailable ] = useState(null)

  const onFormSubmit = e => {
    e.preventDefault()
    slotForm.current.reportValidity()

    if (slotForm.current.checkValidity()) {
      setValidated(true)
    } else {

      return
    }

    const data = {
      label: e.target.label.value,
      description: e.target.description.value,
      color: e.target.color.value,
      eventType: e.target.eventType.value || null
    }

    if (!slotType) {
      data.systemName = e.target.systemName.value

      if (selectedForm)
        data.form = selectedForm.value
      else
        dispatch(setFormErrors({ selectedForm: trans('required') }))
    }

    onSubmit(data)
  }

  const onSystemNameChange = (systemName, isAvailable) => {
    setIsAvailable(isAvailable)
  }

  return (
    <Form
      noValidate
      validated={ validated }
      name={ 'slot-type' }
      onSubmit={ onFormSubmit }
      ref={ slotForm }
    >
      { slotType && <small className={ 'text-muted' }>{ trans('systemName') } : { slotType.systemName }</small> }
      <Row className={ 'mb-2' }>
        <Col>
          <Form.Group>
            <Form.Label>{ trans('slotForm.label') } <span className={ 'text-danger' }>*</span></Form.Label>
            <Form.Control name={ 'label' } defaultValue={ slotType?.label || '' } placeholder={ trans('slotForm.label') }
              onChange={ e => setLabel(e.target.value) } required/>
          </Form.Group>
        </Col>
        { !slotType &&
          <Col>
            <Form.Group>
              <Form.Label>{ trans('slotForm.systemName') } <span className={ 'text-danger' }>*</span></Form.Label>
              <SystemNameInput label={ label } model={ 'SlotType' } placeholder={ trans('slotForm.systemName') }
                required onChange={ onSystemNameChange } name={ 'systemName' }/>
            </Form.Group>
          </Col> }
      </Row>
      <Row className={ 'mb-2' }>
        <Col>
          <Form.Group>
            <Form.Label>{ trans('description') }</Form.Label>
            <Form.Control defaultValue={ slotType?.description }
              name={ 'description' }
              type={ 'text' }
              placeholder={ trans('description') }/>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>{ trans('eventType') } <span className={ 'text-danger' }>*</span></Form.Label>
            <Form.Select name={ 'eventType' } required defaultValue={ slotType?.eventType?.id }>
              <option value={ null }></option>
              { eventTypes.map(eventType => <option key={ eventType.id } value={ eventType.id }>{ eventType.label }</option>) }
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row className={ 'mb-2' }>
        <Col>
          <Form.Group>
            <Form.Label>{ trans('color') }</Form.Label>
            <Form.Control defaultValue={ slotType?.color } name={ 'color' } required type={ 'color' }
              placeholder={ trans('color') }/>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button variant={ 'primary' } style={ { float: 'right' } } type={ 'submit' } disabled={ !slotType && !isAvailable }>
            { trans('submit') }
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default SlotTypesForm
