import React, { FormEvent } from 'react'
import { Form as BForm, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import EntitySelect from 'src/Components/EntitySelect'
import { showWarning } from 'src/Services/notifier/actions'
import { translate } from 'src/Services/translation'
import { StoreState } from 'src/Services/Store/reducers'
import WorkflowItem, { WorkflowItemForApi } from 'src/Types/WorkflowItem'
import { setDeletingItem } from 'src/Views/Workflows/Modals/Workflows/state/actions'
import translation from '../translations'

interface Props {
  onSubmit: (item: WorkflowItemForApi) => void
  item?: WorkflowItem | null
}

interface WorkflowItemForm extends HTMLFormElement {
  readonly elements: WorkflowItemFormElements
}

interface WorkflowItemFormElements extends HTMLFormControlsCollection {
  email: HTMLInputElement
  sortOrder: HTMLInputElement
  trigger: HTMLInputElement
  actions: HTMLInputElement
  role: HTMLInputElement
  user: HTMLInputElement
}

const Form = ({ onSubmit, item = null }: Props) => {
  const dispatch = useDispatch()

  const { language } = useSelector((state: StoreState) => state.Root.user)
  const { roles } = useSelector((state: StoreState) => state.Dictionary)

  const trans = translate(translation)(language)

  const onFormSubmit = (e: FormEvent<WorkflowItemForm>) => {
    e.preventDefault()

    const newItem: WorkflowItemForApi = {
      sortOrder: parseInt(e.currentTarget.elements.sortOrder.value, 10) || 0,
      email: e.currentTarget.elements.email.value || null,
      trigger: e.currentTarget.elements.trigger.value,
      actions: (e.currentTarget.elements.actions.value ? JSON.parse(e.currentTarget.elements.actions.value) : null) || [],
      role: e.currentTarget.elements.role.value !== 'null' ? e.currentTarget.elements.role.value : null,
      user: e.currentTarget.elements.user.value || null
    }

    if (item?.id)
      newItem.id = item?.id

    if (!newItem.trigger || !newItem.actions?.length)
      return dispatch(showWarning('formIncomplete'))

    onSubmit(newItem)
  }

  const onDeleteClick = () => dispatch(setDeletingItem(item))

  return <BForm onSubmit={ onFormSubmit }>
    <Row className={ 'mb-2' }>
      <Col>
        <BForm.Group>
          <BForm.Label>{ trans('form.email') }</BForm.Label>
          <BForm.Control defaultValue={ item?.email } name={ 'email' } placeholder={ trans('form.email') } />
          <small className={ 'text-muted' }>{ trans('form.emailHelp') }</small>
        </BForm.Group>
      </Col>
      <Col>
        <BForm.Group>
          <BForm.Label>{ trans('form.sortOrder') }</BForm.Label>
          <BForm.Control defaultValue={ item?.sortOrder || 0 } name={ 'sortOrder' } type={ 'number' }
                         placeholder={ trans('form.sortOrder') }/>
        </BForm.Group>
      </Col>
    </Row>

    <Row className={ 'mb-2' }>
      <Col>
        <BForm.Group>
          <BForm.Label>{ trans('form.role') }</BForm.Label>
          <BForm.Select defaultValue={ item?.role?.id || '' } name={ 'role' } aria-label={ trans('form.role') }>
            <option value={ 'null' }>{ trans('none') }</option>
            { roles.map(({ id, name }) => <option key={ id } value={ id }>{ name }</option>) }
          </BForm.Select>
        </BForm.Group>
      </Col>
      <Col>
        <BForm.Group>
          <BForm.Label>{ trans('form.user') }</BForm.Label>
          <EntitySelect value={ item?.user || null }
                        name={ 'user' }
                        clearable={ true }
                        id={ 'workflowItemFormUserSelect' }
                        fetchUrl={ '/users' }
                        labelKey={ 'username' }
          />
        </BForm.Group>
      </Col>
    </Row>

    <Row className={ 'mb-2' }>
      <Col>
        <BForm.Group>
          <BForm.Label>{ trans('form.trigger') }</BForm.Label>
          <EntitySelect value={ item?.trigger || null }
                        name={ 'trigger' } required
                        id={ 'workflowItemFormTriggerSelect' }
                        fetchUrl={ '/workflow/triggers' }
          />
        </BForm.Group>
      </Col>
      <Col>
        <BForm.Group>
          <BForm.Label>{ trans('form.actions') }</BForm.Label>
          <EntitySelect value={ item?.actions || null }
                        name={ 'actions' }
                        required
                        id={ 'workflowItemFormActionSelect' }
                        fetchUrl={ '/workflow/actions' }
                        isMulti
          />
        </BForm.Group>
      </Col>
    </Row>

    <Row className={ 'mt-2' }>
      <Col>
        <Button type={ 'button' } variant={ 'danger' } onClick={ onDeleteClick }>
          { trans('delete') }
          <i className={ 'ms-2 bi bi-trash3' }></i>
        </Button>
      </Col>
      <Col>
        <Button variant={ 'success' } style={ { float: 'right' } } type={ 'submit' }>
          { trans('submit') }
          <i className={ 'ms-2 bi bi-check-circle' }></i>
        </Button>
      </Col>
    </Row>
  </BForm>
}

export default Form
