import React, { useState } from 'react'
import Filter from 'src/Components/Calendar/Components/Sidebar/Tabs/Events/Filter'
import { useSelector } from 'src/Services/Store'
import { useDispatch } from 'react-redux'
import { getEvents, setFilterValue } from 'src/Components/Calendar/state/actions'
import { StoreState } from 'src/Services/Store/reducers'
import CalendarFilter, { CalendarFilterOption, DEFAULT_FILTER_VALUES } from 'src/Types/CalendarFilter'

export default function Filters() {
  const dispatch = useDispatch()

  const { filters, filterValues } = useSelector((state: StoreState) => state.Calendar)

  const [ fetchEventsRequest, seFetchEventsRequest ] = useState(null)

  const applyNewFilters = () => {
    clearTimeout(fetchEventsRequest)
    seFetchEventsRequest(setTimeout(() => dispatch(getEvents()), 2000))
  }

  const onFilterChange = (filter: CalendarFilter) => (options: CalendarFilterOption[]) => {
    dispatch(setFilterValue(filter.name, options.map(o => o.value)))
    applyNewFilters()
  }

  return <>
    { filters.map(f =>
      <Filter key={ f.name } filterValue={ filterValues?.[f.name] || DEFAULT_FILTER_VALUES[f.name] } filter={ f } onFilterChange={ onFilterChange(f) } />) }
  </>
}
