import React from 'react'
import Select from 'src/Components/Select'
import { SelectProps } from 'src/Types/Components/Select'

const WorkflowFilter = ({ label, options, onChange, value }: SelectProps) => (
  <div className={ 'workflow-filter' }>
    <span>{ label }</span>
    <Select isClearable
            options={ options }
            value={ value }
            onChange={ onChange }
    />
  </div>
)

export default WorkflowFilter
