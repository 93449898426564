import { ListColumn } from 'src/Types/ListColumn'
import { Instance } from 'src/Types/Instance'
import { Form } from 'src/Types/Form'
import { Iso8601 } from 'src/Types/Date'
import { UuidV4 } from 'src/Types/Uuid'

export enum PatientGender {
  MALE = 'MALE#GENDER#PATIENT',
  FEMALE = 'FEMALE#GENDER#PATIENT',
}

interface Patient {
  id: UuidV4
  'FIRST_NAME#PATIENT': string
  'LAST_NAME#PATIENT': string
  'GENDER#PATIENT': PatientGender
  'USE_NAME#PATIENT': string
  'DOB#PATIENT': Iso8601
  'MOBILE_PHONE#PATIENT': string|null
  'HOME_PHONE#PATIENT': string|null
  'EXTERNAL_ID#PATIENT': string
  'EMAIL#PATIENT': string
}

export type EditablePatient = Partial<Patient>

export interface PatientSidebarInstances {
  headers: ListColumn[]
  instances: Instance[]
  form: Form
}

export interface PatientEncountersInstances {
  headers: ListColumn[]
  instances: Instance[]
  form: Form
}

export default Patient
