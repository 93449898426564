import React, { FormEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { setCreatingEvent } from 'src/Components/Calendar/state/actions'
import { translate } from 'src/Services/translation'
import translations from 'src/Components/Calendar/translations'
import { StoreState } from 'src/Services/Store/reducers'
import { Event } from 'src/Types/Event'

interface Props {
  onSubmit: (event: Event) => null
}

interface EventTypeForm extends HTMLFormElement {
  readonly elements: EventTypeFormElements
}

interface EventTypeFormElements extends HTMLFormControlsCollection {
  type: HTMLSelectElement
}

const EventTypeForm = ({ onSubmit }: Props) => {
  const dispatch = useDispatch()

  const { language } = useSelector((state: StoreState) => state.Root.user)
  const { eventTypes }
      = useSelector((state: StoreState) => state.Dictionary)
  const { creatingEvent }
      = useSelector((state: StoreState) => state.Calendar)

  const trans = translate(translations)(language)

  const [ message, setMessage ] = useState(null)

  const handleSubmit = (e: FormEvent<EventTypeForm>) => {
    e.preventDefault()

    const type = eventTypes.find(_ => _.id === e.currentTarget.elements.type.value)

    const data = {
      ...creatingEvent,
      start: creatingEvent.start,
      type,
      duration: type?.defaultDuration,
    }

    if (!isValid(data)) return

    dispatch(setCreatingEvent(data))
    onSubmit(data)
  }

  const isValid = (data: any) => {
    if (!data.type) {
      setMessage('eventTypeRequired')
      return false
    }

    if (data.type.isSlotRequired && !creatingEvent.slot?.id) {
      setMessage('slotRequired')
      return false
    }

    return true
  }

  return <Form onSubmit={ handleSubmit }>
    <Row className={ 'mb-2' }>
      <Col>
        <Form.Group>
          <Form.Label>{ trans('form.event.type') }</Form.Label>
          <Form.Select name={ 'type' } required>
            <option value={ null }>{ trans('none') }</option>
            { eventTypes.map(eventType =>
                                <option key={ eventType.id } value={ eventType.id }>
                                  { eventType.label || eventType.systemName }
                                </option>,
            ) }
          </Form.Select>
          { message && <Form.Text className={ 'text-muted' }>{ trans(`form.eventType.${ message }`) }</Form.Text> }
        </Form.Group>
      </Col>
    </Row>
    <Row>
      <Col>
        <Button type={ 'submit' } variant={ 'primary' } className={ 'float-right' }>{ trans('submit') }</Button>
      </Col>
    </Row>
  </Form>
}

export default EventTypeForm
