import { EditableField, FieldType } from 'src/Types/Field'
import * as action from 'src/Views/FormEditor/state/actionTypes'
import { EditableFormCustomizationOrder } from 'src/Types/FormCustomizationOrder'
import { UuidV4 } from 'src/Types/Uuid'
import ExtensibleScrollButton from 'src/Views/FormEditor/Types/ExtensibleScrollButton'

export const loadForm = (formId: unknown) => ({
  type: action.LOAD_FORM,
  payload: { formId }
})

export const receiveForm = (form: unknown) => ({
  type: action.RECEIVE_FORM,
  payload: { form }
})

export const fetchFailed = (error: unknown) => ({
  type: action.FETCH_FORM_FAILED,
  payload: error
})

export const setSystemFields = (systemFields: unknown) => ({
  type: action.SET_SYSTEM_FIELDS,
  payload: { systemFields }
})

export const toggleDetailsModal = () => ({
  type: action.TOGGLE_DETAILS_MODAL
})
export const toggleSwpConfigModal = () => ({
  type: action.TOGGLE_SWP_CONFIG_MODAL
})

export const toggleMsSanteConfigModal = () => ({
  type: action.TOGGLE_MS_SANTE_CONFIG_MODAL
})

export const toggleRdConfigModal = () => ({
  type: action.TOGGLE_RD_CONFIG_MODAL
})

export const updateDetails = (data: unknown) => ({
  type: action.UPDATE_DETAILS,
  payload: data
})

export const detailsUpdated = () => ({
  type: action.DETAILS_UPDATED
})

export const detailsNotUpdated = (error: unknown) => ({
  type: action.DETAILS_NOT_UPDATED,
  payload: error
})

export const toggleScriptDialog = () => ({
  type: action.TOGGLE_SCRIPT_DIALOG
})

export const setDraggedFieldType = (data: FieldType) => ({
  type: action.SET_DRAGGED_FIELD_TYPE,
  payload: data
})

export const setIsEditingTitleTemplate = (data: unknown) => ({
  type: action.SET_IS_EDITING_TITLE_TEMPLATE,
  payload: data
})

export const setDraggedExistingField = (field: unknown) => ({
  type: action.SET_DRAGGED_EXISTING_FIELD,
  payload: field
})

export const moveFieldToAnotherPlace = (fieldId: unknown, rowId: unknown = null, rowSize: unknown = null, rowColumn: unknown = null) => ({
  type: action.MOVE_FIELD_TO_ANOTHER_PLACE,
  payload: { fieldId, rowId, rowSize, rowColumn }
})

export const fieldMoved = () => ({
  type: action.FIELD_MOVED
})

export const draggingStopped = () => ({
  type: action.DRAGGING_STOPPED
})

export const setTargetField = (field: unknown) => ({
  type: action.SET_TARGET_FIELD,
  payload: { field }
})

export const setDraggedRow = (section: unknown, row: unknown, rowSortOrder: unknown) => ({
  type: action.SET_DRAGGED_ROW,
  payload: { section, row, rowSortOrder }
})

export const reorderRow = (row: unknown, targetRow: unknown, targetSection: unknown, targetRowSortOrder: unknown = null) => ({
  type: action.REORDER_ROW,
  payload: { row, targetRow, targetSection, targetRowSortOrder }
})

export const rowReordered = () => ({
  type: action.ROW_REORDERED
})

export const requestAddReferenceField = (targetField: unknown, newField = {}) => ({
  type: action.REQUEST_ADD_REFERENCE_FIELD,
  payload: { targetField, newField }
})

export const requestAddField = (targetField: unknown, newField = {}) => ({
  type: action.REQUEST_ADD_FIELD,
  payload: { targetField, newField }
})

export const addField = (newField: EditableField) => ({
  type: action.ADD_FIELD,
  payload: { newField }
})

export const fieldAdded = (formId: unknown) => ({
  type: action.FIELD_ADDED,
  payload: formId
})

export const errorAddingField = () => ({
  type: action.ERROR_ADDING_FIELD
})

export const addRow = (sectionId: unknown, formId: unknown) => ({
  type: action.ADD_ROW,
  payload: { sectionId, formId }
})

export const errorAddingRow = () => ({
  type: action.ERROR_ADDING_ROW
})

export const rowAdded = (data: unknown) => ({
  type: action.ROW_ADDED,
  payload: data
})

export const deleteRow = (sectionId: unknown, id: unknown) => ({
  type: action.DELETE_ROW,
  payload: {
    sectionId,
    id
  }
})

export const errorDeletingRow = (data: unknown) => ({
  type: action.ERROR_DELETING_ROW
})

export const rowDeleted = () => ({
  type: action.ROW_DELETED
})

export const deleteField = (id: unknown) => ({
  type: action.DELETE_FIELD,
  payload: id
})

export const fieldDeleted = () => ({
  type: action.FIELD_DELETED
})

export const errorDeletingField = () => ({
  type: action.ERROR_DELETING_FIELD
})

export const openEditField = (id: unknown) => ({
  type: action.OPEN_EDIT_FIELD,
  payload: id
})

export const receiveEditField = (data: unknown) => ({
  type: action.RECEIVE_EDIT_FIELD,
  payload: data
})

export const updateFieldOption = (option: unknown, value: unknown) => ({
  type: action.UPDATE_FIELD_OPTION,
  payload: { option, value }
})

export const closeEditField = () => ({
  type: action.CLOSE_EDIT_FIELD
})

export const updateField = (data: EditableField) => ({
  type: action.UPDATE_FIELD,
  payload: data
})

export const fieldUpdated = () => ({
  type: action.FIELD_UPDATED
})

export const errorUpdatingField = () => ({
  type: action.ERROR_UPDATING_FIELD
})

export const deleteSection = (id: unknown) => ({
  type: action.DELETE_SECTION,
  payload: id
})

export const errorDeletingSection = (data: unknown) => ({
  type: action.ERROR_DELETING_SECTION
})

export const sectionDeleted = () => ({
  type: action.SECTION_DELETED
})

export const updateSection = (id: unknown, details: unknown) => ({
  type: action.UPDATE_SECTION,
  payload: { id, details }
})

export const errorUpdatingSection = (data: unknown) => ({
  type: action.ERROR_UPDATING_SECTION,
  payload: data
})

export const sectionUpdated = () => ({
  type: action.SECTION_UPDATED
})

export const saveForm = (formId: unknown, navigate: unknown, withExit: unknown) => ({
  type: action.SAVE_FORM,
  payload: { formId, navigate, withExit }
})

export const formSaved = () => ({
  type: action.FORM_SAVED
})

export const errorSavingForm = (data: unknown) => ({
  type: action.ERROR_SAVING_FORM,
  payload: data
})

export const resetFormSave = () => ({
  type: action.RESET_FORM_SAVE
})

export const undoChanges = (formId: unknown, navigate: unknown) => ({
  type: action.UNDO_CHANGES,
  payload: { formId, navigate }
})

export const undoChangesFailed = () => ({
  type: action.UNDO_CHANGES_FAILED
})

export const setRefFieldModalOpened = (data: unknown) => ({
  type: action.SET_REF_FIELD_MODAL_OPENED,
  payload: data
})

export const replaceFieldOptionValues = (field: unknown, optionSystemName: unknown, replacementOptionSystemName: unknown) => ({
  type: action.REPLACE_FIELD_OPTION_VALUES,
  payload: { field, optionSystemName, replacementOptionSystemName }
})

export const toggleFieldDetailsModal = () => ({
  type: action.TOGGLE_FIELD_DETAILS_MODAL
})
export const toggleFieldValuesModal = () => ({
  type: action.TOGGLE_FIELD_VALUES_MODAL
})
export const toggleAdvancedFieldConfigModal = () => ({
  type: action.TOGGLE_ADVANCED_FIELD_CONFIG_MODAL
})
export const toggleCalculationFieldModal = () => ({
  type: action.TOGGLE_CALCULATION_FIELD_MODAL
})
export const toggleCustomizationOrdersModal = () => ({
  type: action.TOGGLE_CUSTOMIZATION_ORDERS_MODAL
})

export const addReferenceField = (field: unknown, list: unknown) => ({
  type: action.ADD_REFERENCE_FIELD,
  payload: { newField: field, newList: list }
})

export const setCurrentDeletedOption = (data: unknown) => ({
  type: action.SET_CURRENT_DELETED_OPTION,
  payload: data
})

export const setSavingRefField = (data: unknown) => ({
  type: action.SET_SAVING_REF_FIELD,
  payload: data
})

export const addFullRowField = (fieldType: unknown, afterRow: unknown, sectionId: unknown, formId: unknown) => ({
  type: action.ADD_FULL_ROW_FIELD,
  payload: { fieldType, afterRow, sectionId, formId }
})

export const addFieldInExtensible = (newField: unknown) => ({
  type: action.ADD_FIELD_IN_EXTENSIBLE,
  payload: { newField }
})

export const scrollExtensibleField = (data: unknown) => ({
  type: action.SCROLL_EXTENSIBLE_FIELD,
  payload: data
})

export const setExtensibleScrollButtons = (id: UuidV4, showLeft: ExtensibleScrollButton['showLeft'], showRight: ExtensibleScrollButton['showRight']) => ({
  type: action.SET_EXTENSIBLE_SCROLL_BUTTONS,
  payload: { id, showLeft, showRight }
})

export const initExtensibleScrollButtons = (data: unknown) => ({
  type: action.INIT_EXTENSIBLE_SCROLL_BUTTONS,
  payload: data
})

export const setNewFullRowFieldDetails = (data: unknown) => ({
  type: action.SET_NEW_FULL_ROW_FIELD_DETAILS,
  payload: data
})

export const openFullRowModal = (fieldType: unknown, sectionId: unknown, afterRow: unknown) => ({
  type: action.OPEN_FULL_ROW_MODAL,
  payload: {
    fieldType,
    sectionId,
    afterRow
  }
})

export const closeFullRowModal = () => ({
  type: action.CLOSE_FULL_ROW_MODAL
})

export const sortInsideAnExtensible = (formId: UuidV4, extensibleId: UuidV4, fields: EditableField[]) => ({
  type: action.SORT_INSIDE_AN_EXTENSIBLE,
  payload: { formId, extensibleId, fields }
})

export const updateExtensibleFieldsOrder = (formId: UuidV4, extensibleId: UuidV4, fields: EditableField[]) => ({
  type: action.UPDATE_EXTENSIBLE_FIELDS_ORDER,
  payload: { formId, extensibleId, fields }
})

export const setIsCustomizationOrderLoading = (isLoading: boolean) => ({
  type: action.SET_IS_CUSTOMIZATION_ORDER_LOADING,
  payload: isLoading
})
export const createCustomizationOrder = (item: EditableFormCustomizationOrder) => ({
  type: action.CREATE_CUSTOMIZATION_ORDER,
  payload: { item }
})
export const updateCustomizationOrder = (item: EditableFormCustomizationOrder) => ({
  type: action.UPDATE_CUSTOMIZATION_ORDER,
  payload: { item }
})
export const deleteCustomizationOrder = (item: unknown) => ({
  type: action.DELETE_CUSTOMIZATION_ORDER,
  payload: { item }
})

