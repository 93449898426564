import { Button, Col, Form, Row } from 'react-bootstrap'
import React, { ChangeEvent, DragEventHandler, FormEvent, useEffect, useState } from 'react'
import { EditableField, Field, FieldOption } from 'src/Types/Field'
import { translate } from 'src/Services/translation'
import translation from 'src/Views/FormEditor/translations'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from 'src/Services/Store/reducers'
import SystemNameInput from 'src/Components/SystemNameInput'
import { replaceFieldOptionValues } from 'src/Views/FormEditor/state/actions'

export interface EditableFieldOption extends FieldOption {
  id?: string
  isNew?: boolean
}

interface ReplaceOptionFormElements extends HTMLFormControlsCollection {
  replacementOption: HTMLSelectElement
}

interface ReplaceOptionForm extends HTMLFormElement {
  readonly elements: ReplaceOptionFormElements
}

interface Props {
  field: Field | EditableField
  isDefaultOption: boolean
  values: EditableFieldOption[]
  value: EditableFieldOption
  onRemove: () => void
  onUpdate: (fieldOption: EditableFieldOption) => void
  onDefaultOptionClick: React.ChangeEventHandler<HTMLInputElement>
  onDrop: DragEventHandler<HTMLDivElement>
  onDrag: DragEventHandler<HTMLDivElement>
  onDragEnd: DragEventHandler<HTMLDivElement>
}

const ValueSetting = ({
  field, values, value, onRemove, onUpdate, isDefaultOption,
  onDrop, onDrag, onDragEnd, onDefaultOptionClick,
}: Props) => {

  const { language } = useSelector((state: StoreState) => state.Root.user)

  const dispatch = useDispatch()

  const trans = translate(translation)(language)

  const [ systemName, setSystemName ] = useState<string | null>(value?.systemName || null)
  const [ label, setLabel ] = useState<string | null>(value?.label || null)
  const [ isHidden, setIsHidden ] = useState<boolean | null>(value?.isHidden || null)
  const [ isAvailable, setIsAvailable ] = useState<boolean | null>(null)
  const [ isDeletePending, setIsDeletePending ] = useState<boolean>(false)

  useEffect(() => {
    setSystemName(value?.systemName)
    setLabel(value?.label)
    setIsHidden(value?.isHidden)
  }
  , [])

  useEffect(() => {
    onSystemNameChange(value.systemName)
  }
  , [ values ])

  const onSystemNameChange = (systemName: string) => {
    setSystemName(systemName)

    const isSystemNameDuplicatesFound = values.filter(v => v.systemName === systemName).length > 1
    setIsAvailable(!isSystemNameDuplicatesFound)
  }
  const onFieldBlur = () => {
    onUpdate({ systemName, label, isHidden })
  }

  const onLabelChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLabel(e.target.value)
  }

  const onIsHiddenChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsHidden(e.target.checked)
  }

  const onDeleteButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    if (value.isNew)
      onRemove()
    else
      setIsDeletePending(true)
  }

  const getOtherValues = () => values.filter(value => value.systemName !== systemName)
  const onUndoDeleteButtonClick = () => {
    setIsDeletePending(false)
  }
  const onReplaceOptionSubmit = (e: FormEvent<ReplaceOptionForm>) => {
    e.preventDefault()

    const replacementOption = e.currentTarget.elements.replacementOption.value

    dispatch(replaceFieldOptionValues(field, systemName, replacementOption))
    onRemove()
  }

  return <>
    <div className={ 'blue-card mb-1' } draggable
      onDragOver={ e => e.preventDefault() }
      onDrag={ onDrag } onDrop={ onDrop }
      onDragEnd={ onDragEnd }
    >
      <Row className={ 'mt-1' }>
        <Col>
          <i className={ 'fa-solid fa-grip-lines cursor-drag' }/>
          { !value.isNew && <small className={ 'ms-2 text-muted' }>{ systemName }</small> }
        </Col>
        <Col sm={ 'auto' } className={ 'align-self-end' }>
          { !isDeletePending ?
            <Button variant={ 'danger' } onClick={ onDeleteButtonClick } size={ 'sm' }>
              <i className={ 'fas fa-trash-alt' }/>
            </Button> :
            <Button variant={ 'secondary' } onClick={ onUndoDeleteButtonClick } size={ 'sm' } title={ trans('undo') }>
              <i className={ 'fas fa-undo-alt' }/>
            </Button>
          }
        </Col>
      </Row>
      { !isDeletePending ?
        <>
          <Row className={ 'mb-1' }>
            <Col>
              <Form.Group>
                <Form.Label>{ trans('label') }</Form.Label>
                <Form.Control size={ 'sm' } name={ label } defaultValue={ label || '' } onBlur={ onFieldBlur }
                  onChange={ onLabelChange } required/>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>{ trans('systemName') }</Form.Label>
                <SystemNameInput size={ 'sm' }
                  label={ label }
                  onFieldBlur={ onFieldBlur }
                  parent={ field.systemName }
                  required
                  onChange={ (systemName: string) => onSystemNameChange(systemName) }
                  name={ 'systemName' }
                  systemName={ systemName }
                  disabled={ !value.isNew }
                />
                { isAvailable === false &&
                  <Form.Text className={ 'text-danger' }>{ trans('modal.fieldValues.form.systemNameDuplicates') }</Form.Text> }
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Switch label={ trans('editField.defaultValue') }
                checked={ isDefaultOption || false }
                onChange={ onDefaultOptionClick }
                disabled={ !value.systemName }
              />
            </Col>
            <Col>
              <Form.Switch label={ trans('editField.hideValue') }
                checked={ isHidden || false }
                onChange={ onIsHiddenChange }
                disabled={ !value.systemName }
                onBlur={ onFieldBlur }
              />
            </Col>
          </Row>
        </> :
        <Row className={ 'mb-1' }>
          <Col>
            <Form onSubmit={ onReplaceOptionSubmit }>
              <Form.Group className={ 'mb-2' }>
                <Form.Label className={ 'fw-bold m-0' }>{ trans('modal.fieldValues.form.replaceOptionLabel') }</Form.Label>
                <Form.Select required size={ 'sm' } name={ 'replacementOption' }>
                  { getOtherValues().map((option: FieldOption) =>
                    <option key={ option.systemName } value={ option.systemName }>{ option.label }</option>) }
                </Form.Select>
              </Form.Group>
              <Button style={ { marginLeft: '3px' } } variant={ 'danger' } type={ 'submit' } size={ 'sm' }>
                { trans('modal.fieldValues.form.confirmDelete') }
              </Button>
            </Form>
          </Col>
        </Row>
      }
    </div>
  </>
}

export default ValueSetting
