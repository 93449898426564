import React from 'react'
import { useSelector } from 'react-redux'
import { StoreState } from 'src/Services/Store/reducers'
import WorkflowItem from 'src/Types/WorkflowItem'
import WorkflowTrigger from 'src/Types/WorkflowTrigger'
import WorkflowAction from 'src/Types/WorkflowAction'

interface ColumnProps {
  title: string
  selectable?: boolean
  items: WorkflowItem[] | WorkflowTrigger[] | WorkflowAction[]
  onClick?: (item: WorkflowItem | WorkflowTrigger | WorkflowAction) => void,
  onEditClick: (item: WorkflowItem | WorkflowTrigger | WorkflowAction) => void,
  renderText: (item: WorkflowItem | WorkflowTrigger | WorkflowAction) => string
}

const Column = ({
  title, selectable = false, items, onClick = () => { /* void */ }, onEditClick, renderText,
}: ColumnProps) => {
  const { selectedWorkflow } = useSelector((state: StoreState) => state.WorkflowManager)

  return (
    <div className={ 'workflow-column' }>
      <h5 className={ 'heading' }>
        { title }
      </h5>
      { items.map(item => (
        <div id={ item.id }
             className={
               `workflow-node
                ${ selectable && 'selectable' }
                ${ selectedWorkflow?.trigger?.id === item.id ? 'selected' : '' }`
             }
             key={ item.id }
             onClick={ () => onClick(item) }
        >
          { renderText(item) }
          <i className={ 'edit fas fa-pencil fa-xs' }
             onClick={ e => {
               e.stopPropagation()
               onEditClick(item)
             } }
          />
        </div>
      )) }
    </div>
  )
}

export default Column
