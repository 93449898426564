import { SwpSystemFieldConditions, SystemFieldCondition } from 'src/Views/FormEditor/Components/FeatureConfig/Types'
import * as featureSystemFields from 'src/Services/Constants/FeatureSystemFields'
import { BaseFieldType } from 'src/Types/Field'

export const SWP_SYSTEM_FIELDS: SwpSystemFieldConditions = {
  eeg: [
    {
      name: 'sessionIdField',
      prefix: featureSystemFields.SWP_SESSION_ID_FIELD_SYSTEM_NAME,
      type: BaseFieldType.TEXT,
      isRequired: true,
      isMultiple: false,
    },
    {
      name: 'accessTokensField',
      prefix: featureSystemFields.SWP_ACCESS_TOKENS_FIELD_SYSTEM_NAME,
      type: BaseFieldType.TEXT,
      isRequired: true,
      isMultiple: false,
    },
    {
      name: 'openDeviceButtons',
      prefix: featureSystemFields.SWP_OPEN_RECORD_FIELD_SYSTEM_NAME,
      type: BaseFieldType.BUTTON,
      isRequired: false,
      isMultiple: true,
    },
    {
      name: 'openPlaybackButtons',
      prefix: featureSystemFields.SWP_OPEN_PLAYBACK_FIELD_SYSTEM_NAME,
      type: BaseFieldType.BUTTON,
      isRequired: false,
      isMultiple: true,
    },
    {
      name: 'openMonitorButtons',
      prefix: featureSystemFields.SWP_OPEN_MONITOR_FIELD_SYSTEM_NAME,
      type: BaseFieldType.BUTTON,
      isRequired: false,
      isMultiple: true,
    },
    {
      name: 'productSerialNumberField',
      prefix: featureSystemFields.SWP_RECORDER_SERIAL_FIELD_SYSTEM_NAME,
      type: BaseFieldType.TEXT,
      isRequired: false,
      isMultiple: false,
    },
    {
      name: 'startRecordAtField',
      prefix: featureSystemFields.SWP_START_RECORD_AT_FIELD_SYSTEM_NAME,
      type: BaseFieldType.DATE_TIME,
      isRequired: false,
      isMultiple: false,
    },
    {
      name: 'endRecordAtField',
      prefix: featureSystemFields.SWP_END_RECORD_AT_FIELD_SYSTEM_NAME,
      type: BaseFieldType.DATE_TIME,
      isRequired: false,
      isMultiple: false,
    },
    {
      name: 'recordDurationField',
      prefix: featureSystemFields.SWP_RECORD_DURATION_FIELD_SYSTEM_NAME,
      type: BaseFieldType.TEXT,
      isRequired: false,
      isMultiple: false,
    },
    {
      name: 'sessionStatus',
      prefix: featureSystemFields.SWP_SESSION_STATUS_FIELD_SYSTEM_NAME,
      type: BaseFieldType.TEXT,
      isRequired: false,
      isMultiple: false,
    },
  ],
  ecg: [],
}

export const MS_SANTE_SYSTEM_FIELDS: SystemFieldCondition[] = [
  {
    name: 'recipient',
    prefix: featureSystemFields.FIELD_MS_SANTE_RECIPIENTS_PREFIX,
    type: BaseFieldType.REFERENCE,
    isRequired: true,
    isMultiple: false,
    isReference: true,
    children: [
      {
        name: 'lastName',
        prefix: featureSystemFields.FIELD_MS_SANTE_RECIPIENTS_PREFIX_LAST_NAME,
        type: BaseFieldType.TEXT,
        isRequired: true,
        isMultiple: false,
      },
      {
        name: 'firstName',
        prefix: featureSystemFields.FIELD_MS_SANTE_RECIPIENTS_PREFIX_FIRST_NAME,
        type: BaseFieldType.TEXT,
        isRequired: true,
        isMultiple: false,
      },
      {
        name: 'rpps',
        prefix: featureSystemFields.FIELD_MS_SANTE_RECIPIENTS_PREFIX_RPPS,
        type: BaseFieldType.TEXT,
        isRequired: true,
        isMultiple: false,
      },
      {
        name: 'telecom',
        prefix: featureSystemFields.FIELD_MS_SANTE_RECIPIENTS_PREFIX_TELECOM,
        type: BaseFieldType.TEXT,
        isRequired: true,
        isMultiple: false,
      },
      {
        name: 'zipCode',
        prefix: featureSystemFields.FIELD_MS_SANTE_RECIPIENTS_PREFIX_ZIPCODE,
        type: BaseFieldType.TEXT,
        isRequired: true,
        isMultiple: false,
      },
      {
        name: 'city',
        prefix: featureSystemFields.FIELD_MS_SANTE_RECIPIENTS_PREFIX_CITY,
        type: BaseFieldType.TEXT,
        isRequired: true,
        isMultiple: false,
      },
      {
        name: 'country',
        prefix: featureSystemFields.FIELD_MS_SANTE_RECIPIENTS_PREFIX_COUNTRY,
        type: BaseFieldType.TEXT,
        isRequired: true,
        isMultiple: false,
      },
      {
        name: 'address',
        prefix: featureSystemFields.FIELD_MS_SANTE_RECIPIENTS_PREFIX_ADDRESS,
        type: BaseFieldType.TEXT,
        isRequired: true,
        isMultiple: false,
      },
      {
        name: 'lifenId',
        prefix: featureSystemFields.FIELD_MS_SANTE_RECIPIENTS_PREFIX_LIFENID,
        type: BaseFieldType.TEXT,
        isRequired: true,
        isMultiple: false,
      },
    ],
  },
  {
    name: 'sender',
    prefix: featureSystemFields.FIELD_MS_SANTE_SENDER_PREFIX,
    type: BaseFieldType.REFERENCE,
    isRequired: true,
    isMultiple: false,
    isReference: true,
    children: [
      {
        name: 'rpps',
        prefix: featureSystemFields.FIELD_MS_SANTE_SENDER_PREFIX_RPPS,
        type: BaseFieldType.TEXT,
        isRequired: true,
        isMultiple: false,
      },
    ],
  },
  {
    name: 'contentToSend',
    prefix: featureSystemFields.FIELD_MS_SANTE_PREFIX_CONTENT_TO_SEND,
    // Not sure of which type
    type: BaseFieldType.TEXT,
    isRequired: true,
    isMultiple: true,
    isReference: false,
  },
  {
    name: 'sendButton',
    prefix: featureSystemFields.FIELD_MS_SANTE_PREFIX_SEND_BUTTON,
    type: BaseFieldType.BUTTON,
    isRequired: true,
    isMultiple: true,
    isReference: false,
  },
  {
    name: 'trackingList',
    prefix: featureSystemFields.FIELD_MS_SANTE_PREFIX_TRACKING_LIST,
    // Not sure of which type
    type: BaseFieldType.TEXT,
    isRequired: true,
    isMultiple: false,
    isReference: false,
  },
]

export const RD_SYSTEM_FIELDS: SystemFieldCondition[] = [
  {
    name: 'sessionId',
    prefix: featureSystemFields.RD_SYSTEM_FIELD_PREFIX_SESSION_ID,
    type: BaseFieldType.TEXT,
    isRequired: true,
    isMultiple: false,
  },
  {
    name: 'sessionStatus',
    prefix: featureSystemFields.RD_SYSTEM_FIELD_PREFIX_SESSION_STATUS,
    type: BaseFieldType.TEXT,
    isRequired: true,
    isMultiple: false,
  },
  {
    name: 'software',
    prefix: featureSystemFields.RD_SYSTEM_FIELD_PREFIX_SOFTWARE,
    type: BaseFieldType.DROPDOWN,
    isRequired: true,
    isMultiple: false,
  },
  {
    name: 'acquisitionFiles',
    prefix: featureSystemFields.RD_SYSTEM_FIELD_PREFIX_ACQUISITION_FILES,
    type: BaseFieldType.FILE,
    isRequired: true,
    isMultiple: true,
  },
  {
    name: 'createSession',
    prefix: featureSystemFields.RD_SYSTEM_FIELD_PREFIX_CREATE_SESSION,
    type: BaseFieldType.BUTTON,
    isRequired: true,
    isMultiple: false,
  },
  {
    name: 'openSession',
    prefix: featureSystemFields.RD_SYSTEM_FIELD_PREFIX_OPEN_SESSION,
    type: BaseFieldType.BUTTON,
    isRequired: true,
    isMultiple: false,
  },
]
