import React, { FC } from 'react'
import RSelect, { Props as RSelectProps } from 'react-select'

interface Props extends RSelectProps<any> {
  className?: string;
}


const Select: FC<Props> = ({ className = '', ...restProps }) => (
  <RSelect
    className={ className }
    styles={ {
      // @ts-ignore
      control: (baseStyles, state) => ({
        ...baseStyles,
        border: '2px solid #96B3FC',
        '&:hover': {
          border: '2px solid #96B3FC'
        },
        boxShadow: state.isFocused ? '0 0 0 0.25rem rgba(30, 77, 173, 0.25)' : 'none'
      }),
    } }
    { ...restProps } />
)

export default Select
