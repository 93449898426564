import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ButtonGroup } from 'react-bootstrap'
import {
  changePage,
  changeLimit,
  toggleDeleteSlotType, toggleUpdateSlotType, fetchSlotTypes
} from '../state/actions'
import { translate } from '../../../Services/translation'
import translation from '../translations'
import Table from '../../../Components/NavTable'

const SlotTypesTable = () => {

  const dispatch = useDispatch()
  const {
    slotTypes,
    pagination,
    loading
  } = useSelector(state => state.SlotTypes)
  const { language } = useSelector(state => state.Root?.user)
  const trans = translate(translation)(language)
  const [ searchRequestTimeout, setSearchRequestTimeout ] = useState(null)

  const onDeleteClick = slotType => dispatch(toggleDeleteSlotType(slotType))
  const onEditClick = slotType => dispatch(toggleUpdateSlotType(slotType))

  const columnHeaders = [ trans('label'), trans('systemName'), trans('description'),
    trans('color'), trans('eventType'), trans('actions') ]
  const getRows = () =>
    slotTypes.map(item => [
      <>{ item.label }</>,
      <>{ item.systemName }</>,
      <>{ item.description }</>,
      <>{ item.color }</>,
      <>{ item.eventType?.systemName }</>,
      <ActionCell onDeleteClick={ () => onDeleteClick(item) }
                  onEditClick={ () => onEditClick(item) }
                  trans={ trans }
      />
    ])

  const applyNewFilters = () => {
    clearTimeout(searchRequestTimeout)
    setSearchRequestTimeout(setTimeout(() => dispatch(fetchSlotTypes()), 1000))
  }

  const onSearchInputChange = e => {
    e.preventDefault()
    applyNewFilters()
  }

  const onPageClick = (currentPage, page) => {
    if (currentPage === page)
      return

    dispatch(changePage(page))
    applyNewFilters()
  }

  const onLimitChange = limit => {
    dispatch(changeLimit(limit))
    applyNewFilters()
  }
  return <Table pagination={ pagination }
                rows={ getRows() }
                columnHeaders={ columnHeaders }
                onSearchInputChange={ onSearchInputChange }
                onLimitChange={ onLimitChange }
                onPageClick={ onPageClick }
                isLoading={ loading }
  />
}

export const ActionCell = ({ onDeleteClick = () => {}, onEditClick = () => {}, trans }) =>
  <ButtonGroup>
    <Button variant={ 'outline-info' } size={ 'sm' } onClick={ onDeleteClick }>
      <i className={ 'fas fa-trash' }/> { trans('delete') }
    </Button>
    <Button variant={ 'info' } size={ 'sm' } onClick={ onEditClick }>
      <i className={ 'fas fa-edit' }/> { trans('edit') }
    </Button>
  </ButtonGroup>

export default SlotTypesTable
