import React from 'react'
import { Form } from 'react-bootstrap'

interface Props {
  label: string
  warningText: string
  isWarningShown: boolean
  warningAdditionalClass?: string
}

const LabelWithWarning = ({ label, warningText, isWarningShown, warningAdditionalClass }: Props) => (
  <Form.Label>
    <span>{ label }</span>
    { isWarningShown &&
      <span className={ `field-warning ${ warningAdditionalClass }` }>
        <i className={ 'fas fa-regular fa-triangle-exclamation me-1' }/>
        { warningText }
      </span>
    }
  </Form.Label>
)

export default LabelWithWarning
